/* HEADER */
header {
    z-index: 10;
    position: sticky !important;
    .panel.header {
        display: block;
        max-width: 1406px !important;
        padding: 0 10px !important;
        height: 35px;
        margin-bottom: 0 !important;
        [data-content-type="row"][data-appearance="contained"] {
            max-width: 100% !important;
        }
        [data-content-type="row"][data-appearance="contained"] [data-element="inner"] {
            padding: 0 !important;
            margin: 0 !important;
        }

        @media (min-width: 1024px) {
            padding: 0 20px !important;
        }
    }
    .minicart-wrapper {
        order: 11;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        margin-right: 10px;
        overflow: visible;

        .action.showcart {
            position: relative;
            display: flex !important;
            align-self: center;

            &::after {
                display: none !important;
            }

            .counter.qty {
                top: -15px;
                right: -4px;
                background-color: $orange;
            }
        }
    }

    .header-links-holder {
        display: flex;
        justify-content: flex-end;
        width: 175px;

        .minicart-wrapper,
        .compare.wrapper,
        .block-search {
            flex: 0 0 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .minicart-wrapper {
        display: flex;
        justify-content: flex-end;

        @media (min-width: 1024px) {
            padding-left: 40px;
        }
    }

    .action.compare {
        display: flex;
        justify-content: center;
        width: 27px;
        margin: auto;
        position: relative;

        @media (max-width: 1023px) {
            margin-top: 1px;

            .compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items {
                top: -2px;
            }
        }

        @media (min-width: 1024px) {
            padding-left: 40px;
        }
    }

    .block-search {
        padding-right: 0;

        .block-content {
            @media (min-width: 1024px) {
                position: relative;
            }
        }

        form {
            position: absolute;
            width: 200px;
            right: 0;
            top: 4px;
        }

        .search-toggle-mobile {
            @media (min-width: 1024px) {
                display: none;
            }

            @media (max-width: 1023px) {
                border: none;
                background: none;
                margin-top: 3px;

                svg {
                    width: 22.45px;
                    height: 22.45px;
                }
            }
        }

        .minisearch {
            @media (min-width: 1024px) {
                .action.search svg {
                    display: none;
                }
            }

            @media (max-width: 1023px) {
                display: block;

                &.active {
                    display: block;
                    top: 100%;
                    right: 0;
                    z-index: 999;
                    max-width: 414px;
                    width: 100%;
                    max-height: 566px;
                    height: 75vh;
                    padding: 25px;
                    box-sizing: border-box;
                    background: white;

                    .control {
                        flex: 1 0 100%;
                        border: none;
                        max-height: 80vh;

                        input {
                            position: static;
                            width: 100%;
                            height: 47px;
                            border-radius: 2px 2px 0 0;
                            border-bottom: 3px solid black;
                            box-sizing: border-box;
                            font-size: 13px;
                        }
                    }

                    .label {
                        display: none;
                    }

                    .action.search {
                        position: absolute;
                        top: 41px;
                        right: 20px;
                        display: block;
                        background: none !important;
                        border: none !important;
                        opacity: 1;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .compare.wrapper {
        display: none;

        @media (min-width: 1024px) {
            order: 10;
            display: block;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
            position: relative;
            float: none;
            list-style: none;
            padding: 0;
            margin: 1px 0 0 0;
            .link.compare {
                margin: 0;
                .action.compare {
                    &.no-display {
                        display: none !important;
                    }
                    display: flex;
                    text-decoration: none;
                    .compare-icon {
                        &:before {
                            content: "\f0ec";
                            font-family: "FontAwesome";
                            font-size: 27.2px;
                            font-weight: 100;
                            color: $black;

                            @media (max-width: 1023px) {
                                font-size: 24px;
                            }
                        }
                    }
                    .compare-qty-items {
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        background: $orange;
                        min-width: 12px;
                        top: -8px;
                        right: -10px;

                        .counter {
                            font-size: 0.8em;
                            color: #fff;
                            text-align: center;
                            vertical-align: top;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:before,
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.header-cms-content {
    display: flex !important;
    * {
        font-family: $rift;
        font-weight: bold;
    }
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-top: 0 !important;
    .social-wrapper,
    .shipping,
    .top-links {
        flex-grow: 1;
    }
    .social-wrapper {
        margin-bottom: 0 !important;
        padding: 0;
        display: flex;
        align-items: center;
        a {
            height: 20px;
            margin: 0 9px;
            svg path {
                fill: $white;
            }
            &:hover {
                svg path {
                    fill: $orange;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .shipping {
        flex: 1;
        font-size: 15px;
        line-height: 22px;
        pointer-events: none;
    }
    .top-links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 10px;
            margin-right: 10px;
            padding-left: 10px;
            padding-right: 0;
            font-family: $rift;
            white-space: nowrap;

            span {
                font-size: 1.4rem;
                line-height: 17px;
                margin-left: 8px;
                color: $white;
                font-family: inherit;
                font-weight: 700;
            }
            svg {

                path {
                    fill: $white;
                }
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                text-decoration: none;
                span {
                    color: $orange;
                }
                svg path {
                    fill: $orange;
                    stroke: $orange;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .rootmenu > .rootmenu-list .root-col-1 {
        min-height: auto !important;
    }
    header {
        /* Bottom Row of Header */
        .mobile-only,
        .mobile-header {
            display: none;
        }
        .header.content {
            max-width: 1406px !important;
            margin-bottom: 0;
            padding: 0 10px 0 20px !important;
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
            justify-content: center;
            a.logo {
                margin: 0;
                line-height: 0;
            }
            .sections.nav-sections {
                // margin-left: auto;
                // margin-right: auto;
                flex: 2 1 auto;
                width: fit-content;
                bottom: unset;
                text-align: center;
                background-color: transparent;
                #store\.menu > div > div > div {
                    padding: 0 !important;
                    margin: 0 !important;
                }
                .page-main {
                    width: fit-content;
                    margin: 0 auto;
                    .rootmenu-list {
                        width: fit-content;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    /* Menu */
    .rootmenu {
        a {
            .tabimgtext.tabimgtext.tabimgtext {
                font-family: $rift;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                margin-top: 7px;
                padding-bottom: 14px !important;
                transition: color 0.2s linear;
            }
            .childtext {
                color: $body-color;
                font-family: $roboto;
                font-size: 13px;
                line-height: 27px;
                transition: color 0.2s linear;
            }
            &:hover {
                .tabimgtext,
                .childtext {
                    color: $orange !important;
                }
            }
        }
        .tabimgwpr,
        .verticalopen {
            min-height: unset !important;
            height: unset !important;
            border: 0 !important;
            .desktop-only {
                padding: 10px;
            }
        }
        .tabimgwpr {
            flex-grow: 1;
            flex-shrink: 1 !important;
            max-width: 200px;
            width: auto;
            min-width: 0;
            padding-left: 20px !important;
            padding-right: 20px !important;
            white-space: nowrap;

            @media (min-width: 1200px) {
                padding-left: 25px !important;
                padding-right: 25px !important;
            }
            ul {
                padding: 0;
            }
        }
        .mainmenuwrap {
            max-width: 1426px!important;
            margin: 0 auto;
            padding: 0;
        }
        .verticalopen {
            display: flex !important;
            position: relative !important;
            align-items: flex-start;
            justify-content: flex-start;
            right: unset !important;
            left: unset !important;
            top: 0 !important;
            bottom: unset !important;
            margin: 0 auto !important;
            width: 100% !important;
            float: none !important;
            padding: 20px 0 !important;
            background: #fff !important;

            li {
                flex-shrink: 0;
            }
        }
        > .rootmenu-list {
            > li {
                & > a {
                    padding: 0px 22px;
                    font-family: $rift;
                    font-weight: bold;
                    font-size: 20px;
                    transition: color 0.2s linear;
                }
                &:hover > a {
                    background-color: transparent;
                    color: $orange !important;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -2rem;
                        bottom: -2rem;
                        left: -2.5rem;
                        right: -2.5rem;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -2rem;
                        left: -3.5rem;
                        right: -3.5rem;
                        height: 2rem;
                        width: auto;
                        border: none;
                    }
                }
            }
            .root-col-5 {
                width: 100% !important;
                max-width: 125px !important;
                padding: 0 !important;
                margin: 10px 24px;
            }
        }
    }

    .submenu-title {
        ul {
            li {
                margin-bottom: 8px;
            }
        }
    }

    /* Megamenu */
    .megamenu {
        min-height: 0 !important;
        height: 486px !important;
        // width: 100vw !important;
        position: absolute;
        // left: 50% !important;
        // right: 50% !important;
        // margin-left: -50vw !important;
        // margin-right: -50vw !important;
        padding-top: 40px;
        padding-bottom: 40px;
        top: 100% !important;
        left: 0;
        right: 0;
        display: none;
        .desktop-only,
        .tabimgwpr {
            padding-left: 20px;
            padding-right: 20px;
        }
        .tabimgwpr {
            ul {
                li {
                    a,
                    a div {
                        font-family: $roboto !important;
                        width: fit-content;
                        display: block;
                        padding: 1.5px 0;
                    }
                }
            }
        }

        .rootmenu > .rootmenu-list > li:hover > & {
            display: block;
        }
    }
    .catalog-article {
        background-position: center;
        background-size: cover;
        width: 380px;
        max-width: 380px;
        padding-top: 100%;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.38);
            z-index: 1;
        }
        .content {
            z-index: 2;
            position: absolute;
            left: 40px;
            right: 40px;
            bottom: 43px;
            text-align: center;
            h2 {
                font-family: $rift;
                font-weight: bold;
                font-size: 73px;
                line-height: 1;
                letter-spacing: 14.6px;
                color: $white;
                margin-top: 0;
                margin-bottom: 0;
                white-space: nowrap;;
                text-shadow: 0px 3px 6px #00000052;
            }
            h4 {
                font-family: $rift;
                font-weight: normal;
                font-size: 21px;
                line-height: 26px;
                color: $white;
                margin-top: 7px;
                margin-bottom: 16px;
                text-shadow: 0px 3px 6px #0000004d;
            }
            .btn {
                display: inline-block;
            }
        }
    }
    .field.search {
        label[data-role="minisearch-label"] {
            left: auto;
            right: 20px;
            &:before {
                display: none !important;
            }
            svg {
                display: block;
                margin: 3px;
            }
        }
        .control {
            input {
                max-width: 0;
                overflow: hidden;
                visibility: hidden;
                transition: max-width 0.2s linear, visibility 0.2s linear;
                &:focus {
                    max-width: 200px;
                    visibility: visible;
                }
            }
        }
    }
    .header.content,
    .nav-sections,
    .nav-sections-items,
    .rootmenu {
        position: static !important;
    }
}
@media (max-width: 1023px) {
    .desktop-only {
        display: none;
    }
    .rootmenu > .rootmenu-list > li:hover > a::after {
        display: none !important;
    }
    .rootmenu > .rootmenu-list .rootmenu-click {
        display: flex;
        padding-left: 15px;
        height: 70px;
        align-items: center;
        padding-right: 25px;
    }
    .rootmenu > .rootmenu-list .submenu-title .rootmenu-click {
        height: 50px;
    }
    .rootmenu-list > li .megamenu.tabmenu {
        opacity: 1 !important;
        visibility: visible !important;
    }
    header {
        .panel.header {
            .social-wrapper,
            .top-links {
                display: none;
            }
        }
        .showcart {
            align-self: center;

            .qty {
                height: 15px !important;
                line-height: 15px !important;
                min-width: 11px !important;
                padding: 0 2px !important;
                top: -10px !important;
                right: 8px !important;
                .counter-number{
                    font-size: 11px;
                }
            }
        }
        .header.content {
            height: 60px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            &::before,
            &::after {
                display: none;
            }

            .nav-toggle {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                flex: 0;
                margin-right: auto;
            }

            a.logo {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 0;
                transform: translate(-50%, -50%);
                margin: 0;
                width: 132px;
                line-height: 0;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
            .minicart-wrapper {
                overflow: visible;
                margin: 0;
                flex: unset;
                .action.showcart {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    margin-left: 10px;
                    margin-right: 5px;

                    svg {
                        width: 23.86px;
                        height: 21.84px;
                    }

                    &:after {
                        margin-top: 20px;
                    }
                    .counter.qty {
                        top: -5px;
                        right: 10px;
                        background-color: $orange;
                        width: 17px;
                        height: 17px !important;
                        margin: 10px -14px 0 0;
                        padding: 0 !important;
                        line-height: 16px !important;
                    }
                }
            }
        }
    }
    html {
        .nav-sections-item-content {
            padding: 0;
        }
        .nav-sections {
            left: -100% !important;
            right: auto !important;
            width: 100%;
            transition: left 0.2s linear;
            .page-main.page-main {
                padding: 0;
            }
            .social-wrapper,
            .top-links {
                display: none !important;
            }
            [data-content-type="row"][data-appearance="contained"] [data-element="inner"] {
                padding: 0 !important;
                margin: 0 !important;
                background-color: $black;
                .shipping {
                    color: $white;
                }
            }
            .mobile-only {
                display: block;
                .contact-wrapper {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    width: 100%;
                    min-width: fit-content;
                    max-width: fit-content;
                    padding: 0 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-left: auto;
                    margin-right: auto;

                    @media (max-width: 767.98px) {
                        margin-left: 0;
                        padding-left: 2rem;
                    }
                    .wrap {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        p {
                            color: #000;
                        }

                        @media (max-width: 767.98px) {
                            text-align: left;
                        }
                    }
                    a:not(.btn) {
                        font-family: $rift;
                        font-weight: bold;
                        font-size: 37px;
                        line-height: 44px;
                        text-decoration: none;
                        transition: color 0.2 linear;
                        color: $black;
                        &:hover {
                            color: $orange;
                        }
                    }
                    .btn {
                        margin-left: auto;
                        margin-right: auto;

                        @media (max-width: 767.98px) {
                            margin-left: 0;
                        }
                    }
                    .social-wrapper {
                        display: flex !important;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-top: 15px;
                        width: 100%;

                        @media (max-width: 767.98px) {
                            justify-content: flex-start;
                        }

                        a {
                            margin: 0 23px;
                            &:first-child {
                                margin: 0;
                            }
                            &:last-child {
                                margin: 0;
                            }
                            svg path {
                                fill: $black;
                            }
                            &:hover {
                                svg path {
                                    fill: $orange;
                                }
                            }
                        }
                    }
                }
                .logins {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 767.98px) {
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 1rem 2rem;
                    }

                    a {
                        width: 50%;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: $rift;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        color: $black;
                        transition: color 0.2s linear;
                        text-decoration: none;
                        svg {
                            margin-right: 7px;
                            path {
                                transition: fill 0.2s linear;
                                fill: $black;
                            }
                        }
                        &:hover {
                            color: $orange;
                            svg path {
                                fill: $orange;
                            }
                        }

                        @media (max-width: 767.98px) {
                            height: auto;
                            width: auto;
                            padding: 1rem 0;
                        }
                    }
                }
            }
            .mobile-header {
                display: flex;
                height: 60px;
                background-color: #eeeeee;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                a {
                    width: fit-content;
                    height: fit-content;
                    height: 27px;
                    display: flex;
                    align-items: center;
                }
                .close {
                    position: absolute;
                    right: 9px;
                    cursor: pointer;
                    background: none;
                    border: none;

                    &:focus,
                    &:hover {
                        box-shadow: 0 0 3px 1px rgba(35, 31, 32, 0.2);
                    }
                }
            }
        }
        &.nav-open {

            .nav-sections {
                left: 0 !important;
            }

            .page-wrapper {
                right: auto;
            }
        }
        .header-cms-content {
            display: flex !important;
            * {
                font-family: $rift;
                font-weight: bold;
            }
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            margin-top: 0 !important;
            .social-wrapper,
            .shipping,
            .top-links {
                flex-basis: 0;
                flex-grow: 1;
            }
            .social-wrapper {
                margin-bottom: 0 !important;
                padding: 0;
                display: flex;
                align-items: center;
                a {
                    height: 20px;
                    margin: 0 9px;
                    svg path {
                        fill: $white;
                    }
                    &:hover {
                        svg path {
                            fill: $orange;
                        }
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .shipping {
                font-size: 15px;
                line-height: 22px;
                pointer-events: none;
            }
            .top-links {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    text-decoration: none;
                    span {
                        font-size: 14px;
                        line-height: 17px;
                        margin-left: 8px;
                        color: $white;
                        // transition: color 0.2s linear;
                    }
                    svg path {
                        fill: $white;
                        // transition: all 0.2s linear;
                    }
                    margin-left: 20px;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        span {
                            color: $orange;
                        }
                        svg path {
                            fill: $orange;
                            stroke: $orange;
                        }
                    }
                }
            }
        }
        .rootmenu {
            .rootmenu-list > li {
                position: relative;
            }
            .rootmenu-list > li > a {
                border-bottom: solid 1px #4c4c4c;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: #4c4c4c;
            }
            .rootmenu > .rootmenu-list .megamenu {
                visibility: visible !important;
                opacity: 1 !important;
            }
            .rootmenu-list.mobile-sub {
                .rootmenu-arrow {
                    margin: 0 !important;
                    background-image: url(../images/right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px 14px;
                    transform: rotate(90deg);

                    &.rootmenu-rotate {
                        transform: rotate(270deg);
                    }
                }
                .submenu-title {
                    .rootmenu-arrow {
                        border-color: #000;
                    }
                }
                > li {
                    > a {
                        background-color: $black !important;
                        color: $white !important;
                        padding: 17px 34px !important;
                        font-family: $rift;
                        font-weight: 700;
                        font-size: 20px;
                        &.active {
                            color: $white !important;
                        }
                    }
                    &:hover {
                        > a {
                            background-color: $black !important;
                        }
                    }
                    .root-col-4 {
                        display: none;
                    }
                    .verticalopen {
                        right: auto;
                        min-height: 500px;
                        padding: 0;
                        position: relative !important;
                        // display: block !important;
                        .tabimgwpr {
                            min-height: 0 !important;
                            padding: 0;
                            background-color: $white;
                            display: flex;
                            flex-wrap: wrap;
                            float: none;
                            width: 100%;
                            .root-col-75 {
                                padding: 0;
                            }
                            .submenu-title {
                                width: 100%;
                                position: relative;
                                .rootmenu-click {
                                    .rootmenu-arrow {
                                        position: relative;
                                        left: 1px;
                                        margin-top: 14px !important;
                                        margin-bottom: auto !important;
                                        background-image: url(../images/right-dark.svg);
                                    }
                                }
                                // &:first-child{ border-top: 0; }
                                // &:last-child{ border-bottom: 0; }
                            }
                            ul {
                                border-top: 1px solid #dddddd;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: space-between;
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height 0.3s linear, padding 0.3s linear;
                                width: 100%;
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                -webkit-box-sizing: border-box;
                                li {
                                    width: calc(50% - 76px);
                                    margin-left: 38px;
                                    margin-right: 38px;
                                    a {
                                        display: block;
                                        margin: 5px 0;

                                        div {
                                            font-family: inherit;
                                        }
                                    }
                                }
                                &.open {
                                    padding: 26px 0;
                                    max-height: 100vh;
                                    border-bottom: 1px solid #dddddd;
                                }
                            }
                            a:not(.tabimtag) {
                                width: fit-content;
                                .tabimgtext {
                                    padding: 0 !important;
                                }
                                &:hover div {
                                    color: $orange !important;
                                }
                            }
                            a.tabimtag {
                                width: 100%;
                                clear: both;
                                padding: 18px 34px;
                                font-size: 20px;
                                font-family: $rift;
                                div {
                                    padding: 0;
                                    font-size: inherit !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .rootmenu-list {
            width: 100%;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100% !important;
            > li {
                width: 100%;
                display: block !important;
                > a,
                > a.active {
                    text-align: left;
                    color: $white !important;
                    background: $black !important;
                }
                &:hover {
                    > a {
                        background-color: transparent !important;
                    }
                }
                .megamenu.tabmenu {
                    height: auto !important;
                    min-height: auto !important;
                    position: relative;
                    top: 0;
                    padding: 0;
                    width: 100%;
                    transform: none;
                    .vertical-menu {
                        min-height: auto !important;
                        .verticalopen {
                            height: auto !important;
                            width: 100% !important;
                            right: 0 !important;
                            background-color: $white;
                            .root-col-5 {
                                float: none !important;
                                min-height: 0 !important;
                                height: fit-content !important;
                                position: relative;
                                padding: 0 20px;
                                // width: 100vw !important;
                                left: 50% !important;
                                right: 50% !important;
                                margin-left: -50vw !important;
                                margin-right: -50vw !important;
                                border-bottom: 1px solid #dddddd;
                                &:last-child {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.block-search {
    position: static;
    form {
        position: relative;
        display: flex;
        align-items: right;
        justify-content: center;
        &.active {
        }
        .field.search {
            position: relative;
            display: flex;
            align-items: right;
            justify-content: center;
            width: 100%;
            label {
                position: absolute;
                right: 10px;
                top: 2px;
                right: 5px;
                margin: 0 !important;
            }
            svg {
                margin: 0 !important;
                height: 25px !important;
            }
        }
        input {
            @media (min-width: 1024px) {
                padding: 0 !important;
                height: 0 !important;
                border: 0 transparent;
                overflow: hidden;
                &:focus {
                    padding: 14px 30px 14px 14px !important;
                    border-bottom: 2px solid #000000 !important;
                }
            }
        }
    }
}


@media (min-width: 1024px) {
    html .rootmenu .rootmenu-list > li {
        &:last-child > a {
            font-style: italic;
            color: $red !important;

            &:hover,
            &.active {
                color: $orange!important;
            }
        }

        .megamenu {
            transform: none;
            transition: none;
            background: #fff;
            box-shadow: 0px 3px 6px #00000029;
        }

        > .rootmenu-submenu {
            top: 100%;
            border: none;
            box-shadow: 0 3px 6px #00000029;

            .cat-arrow {
                background-image: url(../images/right-dark.svg);
            }

            a {
                font-family: $roboto;
                font-size: 13px !important;
                text-decoration: none;
            }

            .rootmenu-submenu-sub {
                top: 0;
                left: 100%;
                border: none;
                box-shadow: 0 3px 6px #00000029;

                .cat-arrow {
                    display: none;
                }
            }
        }

        .rootmenu-submenu-sub-sub {
            display: none !important;
        }
    }
}
@media (max-width: 1023px) {
    html .rootmenu .rootmenu-list.mobile-sub > li:last-child > a {
        padding: 17px 34px !important;
        font-size: 0;
        width: 100%;
        background-image: url(../images/shift-white.svg) !important;
        background-repeat: no-repeat !important;
        background-position: 30px center !important;
        background-size: 94px 23px !important;
    }
    .nav-toggle:before {
        display: none !important;
    }
    .burger {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: inherit;
        color: #757575;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        text-align: center;
        overflow: visible;
        div {
            width: 33px;
            height: 2px;
            background-color: #000000;
            margin-bottom: 6px;
            &:nth-child(2) {
                width: 75%;
            }
            &:last-child {
                margin: 0;
                width: 50%;
            }
        }
    }
}

.mini-block-search {
    .label-search {
        cursor: pointer;
    }

    .label-mobile {
        display: none;
    }
}

#search_mini_form {
    width: 200px;
    height: 35px;
    top: 0;

    .input-search-image {
        display: none;
    }

    .field.search {
        .control {
            width: 100%;
            display: block;
            &.inactive {
                display: none;
            }
        }

        label[data-role="minisearch-label"] {
            right: 0;
        }

        input {
            position: relative;
            display: block;
            max-width: 331px;
            width: 100%;
            height: 35px;

            @media (min-width: 1024px) {
                top: 3px;
            }

            @media (max-width: 400px) {
                font-size: 16px; // gets around iOS zoom "feature"
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .block-search .label {
        width: 27px;
        height: 35px;
    }
}

@media screen and (max-width: 1023px) {
    .block-search .label {
        top: 4px;
        position: relative;
        svg {
            position: relative;
            transform: scale(0.85);
        }
        &:before {
            content: none;
        }
    }

    header .header-links-holder {
        position: static;
        width: 100%;

        .block-search {
            max-width: none !important;
            position: static;
            margin-left: 0 !important;
            flex: 1;

            .label-mobile {
                display: block;
                &:before {
                    content: none;
                }
            }

            .block-content {
                width: 100%;
                position: static;
                display: block;


                .search-wrapper.active {
                    top: 96px !important;
                    left: 50%;
                    transform: translatex(-50%);
                    position: fixed;
                    width: 100vw !important;
                    height: 100vh !important;
                    max-width: none !important;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    background: rgba(0,0,0,0.7);
                }

                form.minisearch {
                    top: 100% !important;
                    left: 0;
                    position: absolute;
                    width: 100% !important;
                    max-width: none !important;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                    display: none;
                    max-height: 80vh !important;
                    height: auto !important;

                    .input-search-image {
                        position: absolute;
                        right: 3px;
                        top: 3px;
                        display: block !important;
                    }

                    input#search {
                        border: 0;
                        background: none;
                        outline: none;
                        border-bottom: 2px solid #000000;

                    }

                    &.active {
                        display: block;
                    }

                    &:after {
                        content: ' ';
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .actions {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        margin: -1px;
                        overflow: hidden;
                        clip: rect(0,0,0,0);
                        white-space: nowrap;
                        border: 0;
                    }
                }
            }
        }
    }

    .field.search {
        position: relative;
        height: 100%;

        .twitter-typeahead {
            height: 90%;
            display: inline-block;
        }
    }



    #search_mini_form .field.search input {
        max-width: none !important;
        width: 100%;
        margin: 0 auto;
        border: none;
        border-bottom: 2px solid black;
    }
}

.rootmenu .empty_category {
    display: none !important;
}

a.product-item-info.tt-suggestion.tt-selectable:hover{
    background-color: #eee !important;
}

.header .header-cms-content {
    justify-content: center;
    height: 3.5rem;

    .social-wrapper {
        .customer-greeting {
            margin: auto;
        }
    }
}


.page-header {
  margin-bottom: 60px;
}

header .header.content {
  justify-content: space-between;

  &:before,
  &:after {
    display: none;
  }

  .sections.nav-sections{
    order: 99;
    width: 100%;
    flex-basis: 100%;
    background-color: #efefef;
  }
}

nav.rootmenu {
  position: relative !important;
  left: 0;
  right: 0;
  background-color: #eeeeee !important;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    margin-top: 17px;
  }

  > ul > li > a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

@media (min-width: 1024px){

  nav.rootmenu{
    position: absolute !important;
    bottom: -64px;
  }

  .header .megamenu {
    top: 100% !important;
    z-index: 999;
  }
}

.header.wrapper {
    background: none !important;
}
