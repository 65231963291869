// Product View page
.catalog-product-view {
    .afterpay-installments-amount {
        font-weight: 100 !important;
        font-style: normal !important;
        font-size: 12px;
        .afterpay_instalment_price {
            font-weight: 700;
        }
        .afterpay_instalments_logo {
            margin-left: 5px;
        }
        .afterpay-modal-popup-trigger {
            margin-left: 5px;
            text-decoration: underline;
        }
    }
}

// overrides for the pop up
@media only screen and (min-width: 992px) {
    .modal-popup.afterpay-learn-more .modal-inner-wrap {
        width: 600px !important;
        height: 510px;
    }
}

@media only screen and (max-width: 991px) {
    .modal-popup.afterpay-learn-more .modal-inner-wrap {
        width: 300px !important;
        height: auto;
    }

    .modal-popup .modal-inner-wrap {
        height: auto;
    }
}
