// Main styles found in category.scss
.products-grid.wishlist {
    margin-top: 0;

    ol.products.list.items.product-items {
        @media screen and (max-width: 1023px) {
            justify-content: center;
        }

        li.item.product.product-item {
            position: relative;
            margin-left: calc((100% - 3 * 29%) / 2) !important;
            width: 29% !important;

            .actions-primary, .actions-secondary {
                margin: 0;
            }

            @media screen and (min-width: 1023px) {
                &:nth-child(3n + 1) {
                    margin-left: 0 !important;
                }
            }

            @media screen and (max-width: 1023px) {
                margin-left: 5% !important;
                width: 40% !important;
                &:nth-child(2n + 1) {
                    margin-left: 0 !important;
                }
            }

            .btn-remove.action.delete {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
            }
        }
    }
}

// Magento Overrides, because Magento...
#wishlist-view-form .product-item-inner {
    margin-top: 0;
}
#wishlist-view-form .price-box .price {
    font-family: $rift;
    font-size: 16px;
}

.products-grid.wishlist .product-item-photo {
    float: none;
    text-align: center;
}
.products-grid.wishlist .product-image-container {
    max-width: 100%;
}
