.size-accordion{
    // padding: 10px 20px;
    width: 100%;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    .content{
        max-width: 95%;
        margin: 0 auto;
        transition: max-height 1s linear;
        max-height: 0;
        overflow: hidden;
        .sub-title{
            font-family: $rift;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            padding: 10px 0;
            border-bottom: 1px solid #eeeeee;
            margin-top: 20px;
            &:first-child{ margin-top: 0;}
        }
    }
    .title{
        font-family: $rift;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        padding: 20px 30px;
        cursor: pointer;
        @media (max-width: 500px){
            padding: 20px 15px;
        }
        &.active{ 
            border-bottom: 1px solid #eeeeee;
            & + .content{
                // max-height: 600vh;
                max-height: max-content;
            }
        }
    }
    table{
        @media (max-width: 500px){
            font-size: 10px;
        }
        tr{
            th{
                color: $white;
                background-color: #333333;
            }
            &:nth-child(odd){ background-color: #eeeeee; }
            &:nth-child(even){ background-color: $white; }
        }
    }
}