.filter.block {
    margin-bottom: 20px;
}

.products-grid,
.amsearch-pagebuilder-carousel {
    margin-top: 0;

    ol.products.list.items.product-items {
        //display: inline-block;
        width: 100%;
        margin-left: 0 !important;
        text-align: left;

        display: flex !important;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        .product-item {
            .old-price {
                .price,
                .regular-price {
                    color: $black !important;
                }
            }
            .special-price .price {
                color: $red !important;
            }
            .special-price .special-price {
                color: $red !important;
            }
        }


        li.item.product.product-item {
            display: flex;
            padding: 0 !important;
            width: 22.623%;
            max-width: 240px !important; // 4 Items on larger screens
            height: auto;
            text-align: left;
            border: 3px solid #F0F0F0 !important;
            max-height: none !important;
            position: relative;

            &:hover {
                border: inherit;
                border-color: $borderColor !important;

                .product-item-info {
                    border: none !important;
                    position: relative;
                    box-shadow: none;
                }

                .product-item-inner {
                    display: inline-block !important;
                    box-shadow: none !important;
                }
            }

            &:before {
                content: none !important;
            }

            > div {
                height: 100%;
            }

            .stock-level-colour {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
                background: $orange;
                border-radius: 30px;
                height: 14px;
                width: 14px;
                margin: 4px 5px;
                line-height: 0.8em;
                text-align: center;
                display: inline-block;

                &.low-stock {
                    background: $orange;
                }

                &.no-stock {
                    background: $red;

                    svg {
                        display: none;
                    }
                }

                &.in-stock {
                    background: $green;
                }
            }

            .stock-levels-wrapper {
                display: flex;
                align-items: center;
                flex: 0;
            }

            .stock-level-text {
                font-family: $roboto;
                font-size: 12px;
                display: inline-block;
                margin: 2px 0;
            }

            .product-item-info {
                display: flex;
                flex-direction: column;
                padding: 0 !important;
                z-index: 1 !important;

                a {
                    display: block;
                    line-height: 0;

                    &:hover {
                        .product-image-container {
                            &:not(.primary-image) .product-image-wrapper {
                                display: block;
                            }

                            &.primary-image:not(:last-of-type) .product-image-wrapper {
                                display: none;
                            }
                        }
                    }
                }

                .product-image-container {
                    display: block;

                    ~ .product-image-container.secondary-image {
                        position: static !important;
                    }
                    ~ .product-image-container .product-image-wrapper {
                        display: none;
                    }

                    .amlabel-position-wrapper {
                        z-index: 95;
                        .amasty-label-container:nth-child(2) {
                            display: none !important;
                            margin-top: 0 !important;
                        }
                    }
                }

                // Product Image
                .product-image-wrapper {
                    padding-bottom: 100% !important;

                    img.product-image-photo {
                        height: 100%;
                    }
                }

                .product-image-container .secondary-image {
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                .product-item-photo {
                    .product-image-container .secondary-image:hover {
                        display: block;
                    }
                }

                .cart-form {

                    [data-role="tocart-form"]{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    .qty-wrapper {
                        flex: 1 1 32%;

                        input {
                            height: 40px;
                        }
                    }

                    button {
                        flex: 1 1 62%;

                        @media screen and (max-width: 1024px) {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                    .btn {
                        &.btn-secondary {
                            font-family: $rift !important;
                            display: inline-block;
                            padding: 8px 15px;
                            margin: 0 !important;
                            border-radius: 2px;
                            background: $white;
                            border: 2px solid $buttonColor;
                            color: $buttonColor;
                            font-weight: 700;
                            font-size: 16px;
                            text-transform: uppercase;
                            white-space: nowrap;

                            &:hover {
                                background: $buttonColor;
                                color: $white;
                            }

                            &.invert {
                                border: 2px solid $buttonColor;
                                color: $white;
                                background: $buttonColor;

                                &:hover {
                                    background: $white;
                                    color: $buttonColor;
                                }

                                &.orange {
                                    border-color: $orange !important;
                                    background: $orange !important;
                                    &:hover {
                                        background-color: $white !important;
                                        color: $orange !important;
                                    }
                                }
                            }
                        }
                    }
                }

                // Product Tags
                .product-tags {
                    z-index: 1;
                    top: 6px;
                    left: 2px;
                    display: flex;
                    gap: 6px;
                    align-items: flex-start;
                    pointer-events: none;
                    position: absolute;
                    flex-direction: column;
                    font-size: 12px;
                    min-width: 91px;

                    .product-tag {

                        @media screen and (max-width: 666px) {
                            font-size: 11px;
                            width: 92%;
                            padding: 0 0 0 0;
                        }
                        display: inline-block;
                        width: 80%;
                        padding: 0 9px 0 9px;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 600;
                        color: $black;
                        font-family: $roboto;
                        border: 1px solid;
                        border-radius: 50px;
                        text-align: center;
                        background:$white;


                        &.tag-sale,
                        &.tag-hot-offer,
                        &.tag-clearance {
                            color: $red;
                        }
                    }
                }

                // The rest
                .product-item-details {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-grow: 1;
                    padding: 0 20px 15px;

                    [class*="swatch-opt-"]:last-child {
                        margin-bottom: auto;
                    }

                    .cart-form {
                        margin-top: auto;
                    }

                    // Title
                    .product-item-name {
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding-bottom: 0;
                        flex-basis: 5rem;
                        overflow: hidden;

                        .product-item-link {
                            width: 100%;
                            display: block;
                            margin: 0;
                            padding-bottom: 0;
                            font-size: 20px;
                            line-height: 24px;
                            font-family: $rift;
                            font-style: italic;
                            font-weight: 700;
                            color: $black;
                            white-space: normal;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }

                    div[class^='swatch-opt-'] {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .swatch-attribute.size {
                            margin-top: auto;

                            .swatch-attribute-options {

                                @media (max-width: 767.98px) {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: 7px;
                                    padding-bottom: 1.2rem;
                                }
                            }

                            .swatch-option.text {
                                margin: 0 0.4rem 0.7rem 0;

                                @media (max-width: 767.98px) {
                                    flex: 0 0 27%;
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }

                        .swatch-attribute.color {

                            .swatch-attribute-options {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 5px;

                                .swatch-option {
                                    background-size: contain !important;
                                    flex: 0 0 16%;
                                    margin: 0;

                                    @media (max-width: 767.98px) {
                                        flex: 0 0 27.8%
                                    }
                                }

                                .swatch-option:not(.image) {
                                    background-position: center;
                                    background-repeat: no-repeat;

                                    &:not(.disabled):hover {
                                        &.selected {
                                            outline-color: #969696;
                                        }
                                    }

                                    &.disabled {
                                        pointer-events: none;
                                        opacity: 0.3;

                                        &::after {
                                            display: none;
                                        }
                                    }
                                }

                                .swatch-option:not(.image) {
                                    background-image: url(../images/swatch_1.png);
                                }
                            }
                        }
                    }

                    // Ratings / Reviews
                    .product-rating-stars {
                        display: block;
                        width: 100%;

                        .product-reviews-summary {
                            margin-bottom: 0;
                            position: relative;
                            height: 17px;

                            &.empty {
                                font-size: 10px;
                                line-height: 1em;
                            }

                            .rating-summary {
                                margin-right: 0;
                                width: 100%;
                                display: flex;
                                flex-direction: row-reverse;
                                align-items: center;

                                // The Label
                                > span.label {
                                    position: relative;
                                    display: inline-block;
                                    flex: 1;
                                    width: auto;
                                    height: auto;
                                    line-height: 20px;
                                    font-size: 12px;
                                    font-family: $roboto;
                                    margin-left: 9px;
                                    margin-top: 1px;
                                }

                                // Stars Container
                                .rating-result {
                                    display: inline-block;
                                    position: relative !important;
                                    width: $starLetterWidth;

                                    &:before {
                                        font-size: 15px;
                                        letter-spacing: $starLetterSpacing;
                                        width: 100%;
                                    }

                                    > span:before {
                                        letter-spacing: $starLetterSpacing;
                                        color: $starYellow;
                                        font-size: 15px;
                                        width: $starLetterWidth;
                                    }
                                }
                            }
                        }
                    }

                    // Bottom Row
                    .product-bottom-row {
                        justify-content: space-between;
                        width: 100%;
                        display: flex;

                        // Price
                        .price-box {
                            margin: 0;
                            flex: 1;
                            flex-direction: row;
                            width: 100%;
                            vertical-align: middle;
                            font-family: $rift;
                            font-weight: bold;
                            font-size: 16px;

                            > div {
                                line-height: 1em;
                            }

                            .price {
                                font-family: $rift;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 1em;
                            }

                            .old-price {
                                display: inline;
                                margin: 0 0 0 0.5rem;

                                .price-label {
                                    display: none;
                                }
                            }

                            .price-final_price {
                                font-family: inherit;
                            }
                        }

                        // Just because...
                        .product-item-inner {
                            display: inline-block;
                        }

                        // Actions (Buttons)
                        .product-item-actions {
                            display: flex;
                            margin: 0;

                            .actions-primary {
                                vertical-align: middle;

                                form {
                                    display: inline-block;

                                    button.action.tocart.primary {
                                        background: none;
                                        border: none;
                                        width: 35px;
                                        height: 30px;
                                        margin: 3px 0 0 0;
                                        padding: 5px;
                                        fill: $black;
                                        transition: opacity 0.3s;

                                        &:hover {
                                            opacity: 0.5;
                                        }
                                    }
                                }
                            }

                            .actions-secondary {
                                display: flex;
                                align-items: center;

                                .action {
                                    color: $black;
                                    transition: opacity 0.3s;
                                    height: 30px;
                                    width: 25px !important;
                                    border: none;
                                    padding: 0;
                                    margin-bottom: 0;
                                    line-height: 43px;

                                    &:before {
                                        content: none !important;
                                    }

                                    &:hover {
                                        opacity: 0.5;
                                    }

                                    svg {
                                        height: 17px;
                                        width: 17px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        &.quickview,
                                        &.tocompare {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.amsearch-pagebuilder-carousel  {

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;

        > div {
            height: 100%;
        }
    }

    ol.products.list.items.product-items {

        @media screen and (max-width: 1023px) {
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
        }

        li.item.product.product-item {
            max-width: calc(100% - 16px) !important;
            height: 100%;
        }
    }
}

@media (max-width: 1024px) {
    .column.main {
        margin-top: 0;
    }
}

.page-products {
    .page-title {
        font-weight: 700;
        color: $black;
    }

    .page-title-wrapper {
        border-bottom: solid 1px $black;
        margin-bottom: 35px;

        h1#page-title-heading {
            font-size: 32px;
            font-weight: bold;
            font-family: $rift;
            color: $black;

            @media (min-width: 1025px) {
                margin-bottom: 1rem;
            }
        }
    }

    .toolbar-products {
        display: flex;
        align-items: center;
        border: none;
        margin-bottom: 4px;
    }

    select {
        border-radius: 2px;
        height: 40px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .sorter {
        position: relative;
        display: flex;
        align-items: center;
        right: 0;
    }

    .toolbar-amount {
        float: none;
        font-family: $roboto;
        font-weight: 400;
        font-size: 13px;

        strong {
            font-weight: 700;
        }
    }

    @media (max-width: 1024px) {
        .page-main {
            margin-bottom: 4rem;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    @media (min-width: 1025px) {
        .breadcrumbs,
        #maincontent.page-main {
            max-width: 1406px !important;
            padding-left: 20px;
            padding-right: 20px;
        }

        .breadcrumbs {
            margin-bottom: 0;
        }

        .page-main .breadcrumbs {
            padding: 0 !important;
        }

        .page-title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .columns {
            display: flex;
        }

        .sidebar-main {
            float: none;
            order: -1;
            width: 207px;
            padding-right: 0;
            flex: 0 0 207px;
        }

        .column.main {
            float: none;
            flex: 1 1 0;
            width: auto;
            padding-left: 80px;
        }
    }

    @media (min-width: 1366px) {
        .column.main {
            padding-left: 50px;
        }
    }

    @media (max-width: 1023px) {
        #layered-filter-block {
            .block-subtitle.filter-subtitle {
                height: 80px;
                padding: 0 30px;
                line-height: 80px;
                background-color: black;
                color: white;
                font-family: $rift;
                font-weight: 700;
                font-size: 30px;
                box-sizing: border-box;
            }

            &.active {
                .filter-title {
                    height: 80px;

                    strong {
                        position: absolute;
                        right: 0;
                        transform: none;
                        bottom: auto;
                        height: 100%;
                        width: 80px;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-image: url(../images/close.svg);
                        background-size: 26px 34.39px;
                        background-repeat: no-repeat;
                        background-position: center;

                        &::after {
                            display: none;
                        }
                    }
                }

                .filter-options {
                    top: 80px;

                    .filter-options-content {
                        padding: 1.5rem 3.5rem;
                        border-bottom: 1px solid #ddd;
                    }
                }

                .filter-options-title {
                    font-family: $rift;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                    color: $black;
                    padding: 8px 35px;
                    border: solid 1px #ddd;
                    border-top: none;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .breadcrumbs {
            display: block;
            max-width: 100% !important;
            margin: 10px auto 0;
            text-align: center;
            box-sizing: border-box;
        }

        .page-title {
            width: 100%;
            text-align: center;
            margin-top: 0;
        }

        .toolbar-amount {
            position: relative;
            top: -65px;
            font-size: 11px;
            color: black;
            text-align: center;
            padding: 0;
        }

        .sorter {
            width: 50%;
            right: 0;

            &::before {
                content: "Sort";
                position: absolute;
                background: white;
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                z-index: 1;
                pointer-events: none;
                font-family: $rift;
                font-weight: 700;
                font-size: 16px;
                line-height: 39px;
            }
        }

        .toolbar-products {
            position: relative;
            justify-content: flex-end;
            margin: 0;
        }

        select {
            margin: 0 0 0 -2px;
            width: 100%;
            border-radius: 0;
            font-family: $rift;
            font-size: 16px;
            font-weight: 700;
            line-height: 39px;
            padding: 0 1.5rem;
            border-right: none;
            border-color: black;
            border-bottom: none;
        }

        .filter-title strong {
            bottom: 100%;
            top: auto !important;
            display: block;
            transform: translateY(-36px);
            margin: 0 !important;
            box-sizing: border-box;
            border-color: black;
            border-bottom: none;
            width: 50%;
            height: 40px;
            border-radius: 0;
            border-left: none;
            font-family: $rift;
            font-size: 16px;
            font-weight: 700;
            line-height: 39px;
            padding: 0;
        }
    }

    .block-title,
    .toolbar-products {
        background-image: none;
        border-image: none;
        border: none;

        @media (max-width: 1023px) {
            margin: 0;
            padding: 0;
        }
    }

    .filter-actions {
        margin-bottom: 37.5px;
        padding: 0;
        margin-bottom: 1.5rem;
        font-size: 13px;
        text-decoration: underline;
    }
}

.category-image {
    margin-bottom: 0;
}

.catalog-topnav {
    @media (max-width: 1024px) {
        display: block;
    }
}

#layered-filter-block .block-content.filter-content {
    padding: 0;
    border: none;

    @media (max-width: 1024px) {
        margin-top: -75px;
        margin-bottom: 83px;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #eeeeee;

        .filter-current-subtitle {
            display: none !important;
        }

        .filter-title::before {
            display: none;
        }

        .filter-current {
            border: none;
            margin: 0;
        }

        .amshopby-items.items {
            display: flex;
            justify-content: center;
            padding-right: 0;
            text-align: center;
        }

        .amshopby-item.item {
            margin: 0;
            padding: 0;
        }

        .amshopby-remove {
            display: none;
        }

        .amshopby-filter-name {
            font-size: 11px;

            &::after {
                display: none;
            }
        }

        .amshopby-filter-value {
            padding-right: 1.5rem;
            font-size: 11px;
            color: #333333;

            @media (max-width: 410px) {
                padding-right: 0.7rem;
            }
        }

        .swatch-option-link-layered > .swatch-option.text {
            border: none;
            font-size: 11px;
            color: #333333;
            height: auto;
            padding: 0;
            margin: 0;
            min-width: 0;
            line-height: inherit;
        }

        .block-actions.filter-actions {
            margin: 0;
            padding: 6.25px 6px;
            font-size: 11px;

            span {
                font-size: 0;

                &::before {
                    content: "Clear All";
                    font-size: 11px;
                }
            }
        }
    }

    @media (min-width: 1025px) {
        display: flex;
        flex-direction: column;

        .filter-actions {
            order: -1;
        }
    }
}

.filter .block-subtitle {
    display: none;
}

#am-shopby-container .amshopby-items.items {
    display: none;
}

#layered-filter-block .block-actions.filter-actions {
    padding-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.filter-options {
    .filter-options-item {
        @media (max-width: 1023px) {
            padding-bottom: 0;
        }

        .filter-options-content {
            padding-left: 0;

            .items {
                max-height: none;
                overflow: hidden;
                margin-left: 13px;

                .amshopby-link-selected .label {
                    font-weight: 700 !important;
                }
            }

            .count {
                display: none;
            }

            .amshopby-slider-wrapper {
                margin: 0;
            }
        }

        .filter-options-title {
            font-family: $rift;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $black;
            padding: 12px 35px;
            border: solid 1px #ddd;
            margin-left: 0 !important;
            margin-right: 0 !important;

            &:after {
                content: "" !important;
                width: 20px;
                height: 20px;
                background-image: url(../images/right-dark.svg);
                background-repeat: no-repeat;
                background-position: center;
                transform: rotate(90deg);
            }
        }

        .filter-options-content {
            padding: 10px 0 0 0;
        }

        &.active {
            .filter-options-title::after {
                transform: rotate(-90deg);
            }
        }
    }

    .swatch-option.text {
        border: solid 1px $black;
        background-color: $white;
        color: $black;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-right: 5px;
        margin-bottom: 5px;

        &.selected {
            background-color: #000 !important;
            color: $white;
        }
    }

    .am-filter-items-attr_category_ids.items {
        input[type="checkbox"] {
            display: block;
            position: relative !important;
            top: auto !important;
            margin: 0 !important;
        }

        ul.items-children.-folding {
            padding-left: 13px;
        }

        li.item {
            margin: 7px 0;
            padding-left: 0;

            a {
                padding-left: 40px !important;
                line-height: 29px;
                color: $body-color;

                @media (max-width: 1023px) {
                    line-height: 27px;
                }

                &:visited {
                    color: $body-color;
                }

                &:hover {
                    background: transparent;
                    color: $orange;
                }

                .label {
                    padding: 0 !important;
                }
            }

            .am-collapse-icon {
                top: 0;
                right: 0;
                left: auto;

                &:after {
                    content: "" !important;
                    display: block;
                    width: 15px;
                    height: 15px;
                    background-image: url(../images/right-dark.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    border: none;
                    transform: rotate(0deg);
                    transition: none;
                }

                &._active:after {
                    transform: rotate(90deg);
                }
            }

            input.am-input {
                opacity: 0;
                width: 0;
            }

            input.am-input ~ a {
                position: relative;
                font-weight: 400;
            }

            input.am-input ~ a:before, span.amshopby-choice::before {
                width: 25px;
                height: 25px;
                border: solid 1px #dddddd;
                border-radius: 2px;
                position: absolute;
                display: block !important;
                left: 0;
                top: 0;
                content: "";

                @media (max-width: 1023px) {
                    width: 23px;
                    height: 23px;
                }
            }

            input.am-input:checked ~ a:before {
                background-image: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
                background-size: 12px;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .amshopby-slider-container {
        margin: 10px 2px 15px 1px;

        .ui-slider-horizontal {
            height: 3px;
            background-color: #b1b1b1;
            border-radius: 0;
        }

        .ui-slider-range {
            height: 3px;
            background-color: $black;
        }

        .am-slider .ui-slider-handle {
            background-color: transparent !important;
            border-radius: 0;
            margin-top: -8px;
            cursor: pointer;
            margin-top: -8px;
            margin-left: -5px;
            padding: 0;
            width: 20px;
            height: 20px;

            &:before {
                content: "";
                display: block;
                width: 3px;
                height: 100%;
                background-color: $black;
                position: absolute;
                top: 0;
                left: 4px;
            }
        }

        .amshopby-slider-display {
            text-align: left;
            padding-bottom: 15px;
            font-size: 13px;
        }
    }
}

body.catalog-category-view {
    div.swatch-option-tooltip {
        display: none !important;
    }
}

.category-view {
    font-family: $rift;
    font-weight: 700;
    font-size: 42px;
    text-shadow: 0px 3px 6px #00000073;

    @media (min-width: 1025px) {
        font-size: 62px;
    }
}

.category-details-holder {
    height: 180px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;

    @media (min-width: 1025px) {
        height: 240px;
    }

    .category-image-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $black;
        z-index: 1;

        @media (max-width: 1024px) {
            &.cat-full {
                display: none;
            }
        }

        @media (min-width: 1025px) {
            &.cat-thumb {
                display: none;
            }
        }
    }

    .category-description {
        font-family: $rift;
        font-weight: 700;
        font-size: 42px;
        text-shadow: 0px 3px 6px #00000073;
        position: relative;
        z-index: 2;
        color: $white;
        text-align: center;
        line-height: 1;

        [data-element="inner"] {
            margin-bottom: 0 !important;
        }

        @media (min-width: 1025px) {
            font-size: 62px;
        }
    }
}

.sorter-action {
    display: inline-block;
    border: 1px solid #cccccc;
    border-radius: 2px;
    height: 38px;
    position: relative;
    top: 0;
    width: 35px;
    &:before {
        line-height: 36px;
    }
}

.swatch-option-tooltip {
    display: none !important;
}

.sizing-guide-holder {
    @media (max-width: 1023px) {
        display: none;
    }

    @media (min-width: 1024px) {
        text-align: center;
        border: solid 2px $black;
        font-size: 13px;
        padding: 30px 20px 20px 20px;
        margin-bottom: 30px;

        h3 {
            font-family: $rift;
            font-size: 22px;
            line-height: 27px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 0;
        }

        a {
            color: $white;
            display: inline-block;
            text-transform: uppercase;
            text-decoration: none;
            font-family: $rift;
            font-weight: 700;
            font-size: 16px;
            padding: 7px 15px;
            border: solid 2px $black;
            border-radius: 2px;
            background-color: $black;

            &:hover {
                color: $black;
                background-color: $white;
            }
        }
    }
}

#amasty-shopby-product-list {
    @media (max-width: 1023px) {
        .toolbar-products .toolbar-amount {
            font-size: 13px;
            color: #333;
            top: 0;
            padding-top: 10px;
        }

        .ias-trigger a {
        }
    }

    .products-grid + .toolbar.toolbar-products {
        border-top: none;
    }

    .toolbar-products:first-child {
        display: none;
    }

    .toolbar-products .pages {
        display: none;
    }

    > dl.block:first-child {
        &,
        & + dl {
            display: none;
        }
    }

    .ias-trigger {
        position: relative;
        a {
            position: absolute;
            z-index: 20;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            padding: 10px 20px;
            background: $black;
            color: $white;
            border: 2px solid $black;
            font-size: 16px;
            font-family: $rift;
            font-weight: 700;
            line-height: 20px;
            border-radius: 2px;
            &:hover {
                background-color: $white;
                color: $black;
                text-decoration: none;
            }
        }
    }

    .ias-spinner {
        font-size: 0;
        color: transparent !important;
        height: 12px;

        background-image: url(../images/loader.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        img {
            display: none !important;
        }
    }

    @media (max-width: 1024px) {
        .product-items {
            margin-top: 0;
        }
    }
}

.column.main {
    .toolbar-products {
        .field.limiter {
            display: none;
        }

        .toolbar-amount {
            display: block;
            text-align: center;
            width: 100%;
            margin-bottom: 40px;
            position: relative;

            .percentage-holder {
                display: inline-block;
                width: 204px;
                height: 3px;
                background-color: #000;
                position: relative;

                .percentage-display {
                    background-color: #b1b1b1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    display: block;
                }
            }
        }
    }
}

.catalogsearch-result-index {
    .breadcrumbs {
        display: none;
    }

    .category-details-holder {
        margin-bottom: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &::before {
            content: "Search Results";
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            color: white;
            font-family: "Rift";
            font-weight: 700;
            font-size: 42px;
            text-shadow: 0 3px 6px #00000073;
            width: 100%;
            text-align: center;
            padding: 0 20px;
            box-sizing: border-box;
            line-height: 1;
        }

        @media (min-width: 768px) {
            background-image: url(../images/search-banner-desktop.jpg);

            &::before {
                font-size: 62px;
            }
        }

        @media (max-width: 767px) {
            background-image: url(../images/search-banner-mobile.jpg);
        }
    }
}

.catalog-category-view {
    .filter-options .swatch-option.color,
    .filter-options .swatch-option.image {
        margin-right: 5px;
        margin-bottom: 5px;
        background-size: cover;
        border: solid 1px #fff;
        outline: solid 1px #000;

        &.selected,
        &:hover {
            outline: solid 2px #000;
        }

        &.selected + .am-shopby-swatch-label .label {
            font-weight: bold;
        }
    }

    .filter-options .swatch-option {
        &.selected + .am-shopby-swatch-label .label {
            font-weight: bold;
        }
    }
}
tbody .product-tag {
    max-width: fit-content;
}

.filter-options span.amshopby-choice.amshopby-choice::before {
    width: 25px;
    height: 25px;
    border: solid 1px #dddddd;
    border-radius: 2px;
    position: absolute;
    display: block !important;
    left: 0;
    top: 0;
    content: "";

    @media (max-width: 1023px) {
        width: 23px;
        height: 23px;
    }
}

.filter-options input[type='checkbox']:checked + span.amshopby-choice.amshopby-choice::before {
    border: solid 1px #dddddd;
    border-radius: 2px;
}

.filter-options input[type='checkbox']:checked + span.amshopby-choice.amshopby-choice::after {
    background: none;
}

.block-actions.filter-actions a.action.clear.filter-clear {
    color: #ff7900;
}

.filter-options .am-labels-folding .item {
    margin: 7px 0;
    padding-left: 0;
}

.filter-options li.item .am-collapse-icon.am-collapse-icon {
    top: 0;
    right: 0;
    left: auto;
}

.filter-options li.item .am-collapse-icon:after {
    content: "" !important;
    display: block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    transform: rotate(270deg);
    transition: none;
}

.filter-options li.item .am-collapse-icon.-active:after {
    transform: rotate(0deg);
}

.filter-options li.item .am-collapse-icon {
    top: 0;
    right: 0;
    left: auto;
}

.filter-options.filter-options li.item .am-collapse-icon.am-collapse-icon ~ a {
    position: relative;
    font-weight: 400;
    padding-left: 0;
}

.filter-options ol.am-filter-items-brand li.item {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: flex-end;
}

.filter-options li.item a {
    padding-left: 38px !important;
    line-height: 29px;
    color: #333;
}

.filter-options ol.am-filter-items-brand li.item .amshopby-choice {
    display: none;
}

.label.label {
    padding: 0;
}

.filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view), .filter-options-item .filter-options-content .am-category-wrapper, .filter-options-item .filter-options-content .amshopby-fromto-wrap {
    margin: 0;
    margin-left: 13px;
}

.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type='checkbox'] + span.amshopby-choice {
    left: 0;
    top: 0;
}

.filter-content [class*='am-filter-item']:hover ~ .amshopby-choice:before {
    border: solid 1px #ddd;
}

.filter-options input[type='checkbox'] + .amshopby-choice:hover:before {
    border: solid 1px #ddd !important;
    border-color: #ddd !important;
}

.filter-options-item .filter-options-content .item.item.item.item {
    line-height: 1.5em;
    margin: 7px 0;
    padding-left: 0;
    display: flex;
    margin-left: 0 !important;
}

.items {
    margin: 0;
    padding: 0;
    list-style: none none;
}

input [type="checkbox"] {
    margin: 2px 6.25px 0 0;
    position: relative;
    top: 2px;
}

.filter-options-content .items a {
    margin-left: 0;
}

.am-collapse-icon {
    height: 29px;
}

.filter-options li.item {
    margin: 7px 0;
    padding-left: 0;
}

.am-filter-items-size, .am-filter-items-color {
    display: flex;
    flex-wrap: wrap;
}

.am-shopby-form {
    margin: 0 !important;
}

.filter-options-item .filter-options-content .am-filter-items-stock.items [class*='am-filter-item'] {
    line-height: 21px;
}

.filter-options.filter-options li.item .am-collapse-icon.am-collapse-icon~a,
.filter-options-item .filter-options-content .items [class*=am-filter-item] {
    padding-left: 40px !important;
}

.items[class*='category_ids']:not(.items-children) > .item > a {
    font-weight: 400;
}

.filter-options-item .filter-options-content .am-ranges .items.am-filter-items-stock .item [class*='am-filter-item'] {
    padding-left: 10px !important;
    padding-right: 7px !important;
}

.filter-options-content .am-filter-items-stock.items a:hover {
    background-color: #e8e8e8 !important;
    text-decoration: none;
}

.filter-options-content a {
    line-height: 21px;
}

.filter-options-item .filter-options-content .am-swatch-wrapper {
    margin-bottom: 5px !important;
    margin-right: 5px !important;
}

.am-filter-items-brand input {
    width: 13px !important;
    height: 13px !important;
    opacity: 1 !important;
    position: relative !important;
}

.filter-options-content .am-filter-items-brand.am-filter-items-brand .item > a {
    padding-left: 20px !important;
}

.filter-options li.item input.am-input:checked ~ span:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
}

.mgz-products-grid .products-grid,
.page-products .products-grid,
.block-new-products .products-grid,
.pagebuilder-column-group .products-grid {

    .product-item.product-item {

        padding: 0;
        margin: 0 auto !important;
        width: 100%;
        border-radius: 3px;
        box-shadow: none;
        overflow: hidden !important;
        height: auto;
        display: flex;
        flex-direction: column;
        z-index: 0;

        @media (max-width: 767.98px) {
            margin: 0 8px 16px !important;
            width: auto;
            max-width: 224px;
            flex-basis: calc(50% - 20px);
        }

        @media (min-width: 768px) {
            margin: 0 !important;
            margin-bottom: 18px !important;
            margin-right: 18px !important;
            margin-left: 18px !important;
        }

        &:hover {
            border: inherit;
            box-shadow: inherit;
            border-color: $orange !important;

            .product-item-info {
                border: none;
                box-shadow: none;
            }
        }

        .product-item-info {
            display: flex !important;
            flex-direction: column !important;
            padding: 0 !important;
            position: static !important;
            flex-grow: 1;

            &:hover {
                border: none;
                box-shadow: none;
            }

            .product-item-details {
                margin: 0;
                padding: 0 20px 15px;
                font-family: $rift;
                text-align: left;
                color: $black;
                flex-direction: column;
                display: flex;
                flex-grow: 1;

                .product-item-name.product-item-name {
                    font-family: $rift;
                    white-space: normal;
                    text-overflow: initial;
                    line-height: 1em;

                    .product-item-link {
                        font-family: $rift;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .amrelated-attributes {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 1.2em;
                }

                .stock-level-colour {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0;
                    background: $orange;
                    border-radius: 30px;
                    height: 14px;
                    width: 14px;
                    margin: 4px 5px;
                    line-height: 0.8em;
                    text-align: center;
                    display: inline-block;

                    &.low-stock {
                        background: $orange;
                    }

                    &.no-stock {
                        background: $red;
                        svg {
                            display: none;
                        }
                    }
                }

                .stock-levels-wrapper {
                    display: flex;
                    align-items: center;
                    flex: 0;
                }

                .stock-level-text {
                    font-family: $roboto;
                    font-size: 12px;
                    display: inline-block;
                    margin: 2px 0;
                }

                a {
                    color: $black;
                    font-size: 20px;
                    font-weight: bold;
                    font-style: italic;
                }

                .price-container.price-final_price {
                    font-family: $rift;
                    font-weight: bold;
                    line-height: 1.2em;

                    .price-wrapper {
                        border-bottom: none;
                        padding: 0;
                    }

                    .rrp-price::before {
                        content: 'RRP';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                    }

                    .special-price {
                        color: $red;
                        display: inline-block;
                    }

                    .price {
                        font-size: 1.6rem;
                    }

                    .bundle-strike {
                        text-decoration: line-through;
                        color: #999;
                        padding-right: 10px;
                    }

                    .wholesale-price::before {
                        content: 'WHOLESALE';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                        text-decoration: none;
                    }
                }

                .actions-primary{
                    display: block;

                    [data-role="tocart-form"]{
                      display: flex;
                      flex-wrap: wrap;
                      gap: 8px;

                      input {
                          height: 44px;
                          flex: 1 1 32%;
                      }
                  }
                }

                .action.primary {
                    position: relative;
                    z-index: 2;
                    font-family: $rift !important;
                    display: block;
                    padding: 10px 15px !important;
                    margin: 0 !important;
                    border-radius: 2px;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: uppercase;
                    border: 2px solid $orange;
                    color: $white;
                    background: $orange;
                    height: auto;
                    flex: 1 1 62%;
                }

                .action.primary:hover {
                    background: $white;
                    color: $orange;
                }

                .product-item-actions {
                    margin: auto 0 0;
                    padding-top: 1rem;
                    display: block;
                }
            }
        }
    }
}
