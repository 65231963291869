.mgz-product-listing .product-image-wrapper .product-image-photo {
    position: absolute;
}

.mgz-products-grid {
    .products-grid ol.products.list.items.product-items {
        justify-content: center;
    }
}

.mgz-single-product {
    .products-grid ol.products.list.items.product-items {
        justify-content: center;
    }
    .products-grid .product-item.product-item {
        @media (max-width: 767.98px) {
            flex-basis: 100%;
        }
    }
}

.mgz-product-slider {
    .products-grid ol.products.list.items.product-items {
        justify-content: center;

        li.item.product.product-item {
            width: auto;

            @media (min-width: 768px) {
                margin: 0 auto;
            }
        }
    }
    .owl-carousel .owl-item img {
        width: auto;
    }
}
