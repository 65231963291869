.product-qty-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 0.2rem;
    height: 35px;

    .product-item-pricing {
        .adjust-qty-wrapper {
            position: relative;
            width: 75px !important;

            .product-qty {
                padding-left: 3rem;
                width: 4rem;
                height: 3rem;
                font-weight: normal;
                border: 2px solid #000000;
                background-color: #ffffff;
            }

            .update-cart-decrement {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 20%;
                top: 20%;
                left: 7px;
                color: #ddd;
            }

            .update-cart-increment {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 20%;
                top: 20%;
                right: 7px;
                color: #ddd;
            }

            button {
                padding: 0;
                border: 0;

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }
        }
    }
    .actions .secondary .action.delete::before{
        content: none!important;
    }
}



.checkout-cart-index{
    h1{ display: none !important; }
    .cart-empty{

        h2 {
            margin-bottom: 0;
            font-weight: 700;
            color: $black;
        }

        a {
            background: inherit !important;
            border: none;
            color: inherit !important;
            font-weight: inherit;
            text-transform: none;
            box-shadow: inherit !important;
            outline: inherit !important;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.cart-container{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 1140px;
    margin: 2rem auto 0;
    margin-top: 5rem;
    @media (max-width: 1170px){
        max-width: 775px;
    }
    @media( max-width: 767px ){
        width: calc(100% - 48px);
        margin: 0 24px;
    }
    h2{
        display: flex;
        align-items: flex-start;
        width: 100%;
        font-family: $rift;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        color: $black;

        @media( max-width: 767px ){
            margin: 10px 0 18px;
            font-size: 22px;
        }

        .btn.btn-secondary {
            background-color: $orange;
            border: 1px solid $orange;
            border-radius: 2px;
            color: $white;
            margin-left: 2rem;

            &:hover {
                background-color: $white;
                color: $orange;
            }
        }
    }
}

.cart-summary{
    width: 100%;
    min-width: 320px;
    max-width: 320px;
    float: none;
    order: 2;
    top: 0 !important;

    @media (min-width: 1171px) {
        margin-top: -6rem;
    }

    .btn{
        display: block;
        width: 100%;
        margin: 20px auto;
        background-color: $orange;
        color: $white;
        text-align: center;
        box-sizing: border-box;
        height: 60px;
        padding-top: 18px;

        &:hover{
            background-color: $black;
        }
    }
    p{
        text-align: center;
        font-family: $roboto;
        font-size: 12px;

        @media (max-width: 767px) {
            margin: 1rem;
        }
    }
    svg{
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto 40px auto;
    }
    @media (max-width: 1170px){
        margin-left: auto;
    }
    @media( max-width: 767px ){
        margin: 0 auto;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 0 !important;
        box-shadow: none !important;
        border-top: 1px solid #DDDDDD !important;
        max-width: 100%;
    }
    .totals-tax-summary{
        display: table-row !important;
        .price{
            padding-right: 0 !important;
            padding-left: 25px;
            &:after{
                left: 0;
                right: unset !important;
                top: 6px !important;
            }
        }
    }
}



.form-cart{
    .cart.main.actions{ display: none; }
    order: 1;
    #shopping-cart-table{
        .table-caption{
            display: none;
        }
    }
    max-width: 775px;
    margin-right: 45px;
    margin-bottom: 0;
    padding: 0 !important;
    @media (max-width: 1170px){
        margin: 0 auto;
        width: 100%;
    }
}
.cart.table-wrapper{

    th.col {
        border: none !important;
    }

    .cart.item{
        border-top: 1px solid #DDDDDD !important;
        .input-text.qty,
        .item-actions{
            display: none !important;
        }

        .item-info{
            display: flex;
            padding: 20px 0;
            justify-content: flex-end;
            position: relative;
            border-top: 1px solid #ddd;

            @media( max-width: 767px ){
                padding: 20px 0 40px 0;
            }
            .col{
                &.item{
                    padding: 0 !important;
                    display: flex !important;
                    align-items: center;
                    margin-right: auto;
                    a{
                        display: block;
                        width: 100%;
                        margin-right: 20px;
                        padding: 0;
                        line-height: 1.2;
                        color: $black;
                        @media( max-width: 767px ){
                            position: static;
                        }
                        img{
                            width: 100%;
                            max-width: 110px;
                            height: auto;
                            max-height: 110px;
                        }
                    }
                    .product-item-details{
                        padding: 0;
                    }
                }
                &.price{
                    display: flex !important;
                    align-items: center;
                    padding: 0 22px;
                    &:before{ display: none; }

                    @media (min-width: 768px) {
                        padding: 0 40px;
                    }
                }
                &.qty{
                    display: flex !important;
                    align-items: center;
                    padding: 0;
                    &:before{ display: none; }
                    @media( max-width: 767px ){
                        position: absolute;
                        bottom: 10px;
                        left: 87px;
                    }
                }
                &.subtotal{
                    display: flex !important;
                    align-items: center;
                    padding: 0 40px;
                    &:before{ display: none; }
                    @media( max-width: 767px ){
                        display: none !important;
                    }
                }
                &.action{
                    display: flex !important;
                    align-items: center;
                    .primary{
                        margin-right: 18px;
                    }
                    @media( max-width: 767px ){
                        position: absolute;
                        bottom: 10px;
                        right: 22px;
                        padding: 0;
                    }
                    @media( min-width: 768px ){
                        padding: 0 25px 0 0;
                    }
                }
            }
        }
    }
}
