
$input_height: 40px;
$input_border_radius: 2px;

.custom-radio {
    padding-left: 30px;
    height: 30px;
    position: relative;

    input {
        z-index: -1;
        display: none;
    }

    label {
        line-height: 30px;
        font-weight: 700 !important;

        &:before {
            width: 28px;
            height: 28px;
            display: block;
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid #231F204D;
        }

        span {
            margin-left: 2rem;
        }
    }

    input:checked~label {

        &:before {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
            background-size: 12px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:after {
            background-color: #fff;
        }
    }
}

.custom-form {

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select {
        height: $input_height;
        border: solid 1px #DDDDDD;
        border-radius: $input_border_radius;
        padding-left: 15px;
    }

    label {
        font-size: 13px;
    }

    .input-group {
        display: flex;
        flex-direction: row !important;

        .input-group-prepend {
            width: $input_height;
            height: $input_height;
            border-top-left-radius: $input_border_radius;
            border-bottom-left-radius: $input_border_radius;
            border-right: none;
            display: flex;
            box-sizing: border-box;

            .input-group-text {
                display: flex;
                align-items: center;
                text-align: center;
                width: 100%;
                justify-content: space-around;
            }
        }

        .input-group-prepend~input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.password-with-meter {
            flex-wrap: wrap;

            input {
                width: calc(100% - 40px);
            }

            #password-strength-meter-container {
                width: 100%;
            }
        }

        &.prepend-with-verification {
            flex-wrap: wrap;

            input {
                width: calc(100% - 40px);
            }

            div.mage-error {
                width: 100%;
            }
        }

        div.mage-error {
            position: absolute;
            top: 100%;
            left: 0;
            line-height: 1.2;
            font-size: 11px;
            margin-top: 2px;
        }
    }
}

.input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;

    .form-control {
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        height: auto;
        margin-bottom: 0;
        padding: 10px 15px;
        font-size: 13px;
        line-height: 20px;
        border: none;

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.input-group-append,
.input-group-prepend {
    display: flex;
}

.input-group-prepend {
    margin-right: -1px;

    .btn,
    .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.input-group-append {
    margin-left: -1px;

    .btn,
    .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}