.catalog-product-view {
    main.page-main {
        padding: 0 20px !important;
    }

    .breadcrumbs {
        max-width: 1366px !important;

        @media (max-width: 767px) {
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: center;
        }
    }

    @media (min-width: 1200px) {
        #maincontent {
            box-sizing: border-box;

            .page.messages,
            .columns {
                max-width: 1043px !important;
            }
        }
    }

    @media (min-width: 768px) {
        .column.main {
            display: flex;
            flex-wrap: wrap;
        }

        .product.media {
            order: -1;
        }

        .gallery-placeholder {
            position: sticky;
            top: 120px;
            &._block-content-loading {
                > img {
                    display: none;
                }
            }
        }
    }

    .product.media {
        .product-tags {
            z-index: 1;
            top: 6px;
            left: 2px;
            display: flex;
            gap: 6px;
            align-items: flex-start;
            pointer-events: none;
            position: absolute;
            flex-direction: column;
            font-size: 12px;
            min-width: 91px;

            .product-tag {

                @media screen and (max-width: 666px) {
                    font-size: 11px;
                    width: 92%;
                    padding: 0 0 0 0;
                }
                display: inline-block;
                width: 80%;
                padding: 0 9px 0 9px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $black;
                font-family: $roboto;
                border: 1px solid;
                border-radius: 50px;
                text-align: center;
                background:$white;

                &.tag-sale,
                &.tag-hot-offer,
                &.tag-clearance {
                    color: $red;
                }
            }
        }
    }

    .product-info-main {
        .product.attribute.overview {
            margin-top: 0;
            margin-left: 0;
            margin-bottom: 0;
        }

        .product-info-price {
            width: 100% !important;
            border: none !important;
        }



        .top-holder {
            border-bottom: 1px solid black;
            display: flex;
            padding-bottom: 4px;
            position: relative;
            margin-bottom: 10px;

            .top-holder-left {
                flex: 1;
                padding-bottom: 5px;

                .product.attribute.sku .value {
                    font-size: 13px;
                    color: $body-color;
                    font-weight: 400;
                }
            }

            .top-holder-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 5px;

                .brand-logo {
                    max-width: 60px;
                    margin-top: auto;
                }

                .product-reviews-summary {
                    margin: 0;
                    float: right;
                    position: relative;
                    font-size: 13px;

                    .rating-summary {
                        display: flex;
                        align-items: center;
                        margin-right: 0;

                        .label {
                            position: relative;
                            width: auto;
                            height: auto;
                            font-size: 13px;
                            margin-right: 8px;
                            font-weight: 100;
                        }

                        .rating-result {
                            &:before {
                                font-size: 13px;
                            }

                            > span:before {
                                color: $starYellow;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .product-social-links {
            display: none;
        }

        .product-add-form {
            .item-no-option {
                .giftcard-info {
                    .giftcard-fieldset {
                        padding: 20px 0;

                        .price-box {
                            font-weight: 700;
                        }

                        .step-label {
                            h3 {
                                border-bottom: 1px solid #000;
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 51px;
                                border-color: #000 !important;
                            }
                        }

                        .required {
                            em {
                                margin-left: 2px;
                            }
                        }

                        #card-amount,
                        .field input[type="text"] {
                            height: 50px;
                            padding: 12px 15px;
                            font-size: 14px;
                            color: #333333;
                        }

                        #card-preview {
                            color: #333333;
                        }
                    }

                    .calendar-icon {
                        top: -40px;
                    }
                }

                .product-view-cart {
                    .add-cart {
                        margin-right: 0 !important;
                    }

                    .wish-list {
                        display: none !important;
                    }
                }
            }
        }

        .page-title-wrapper.product {
            h1.page-title {
                font-size: 32px;
                font-style: italic;
                margin-top: 0;
                margin-bottom: 0;
                font-family: $rift;
                font-weight: 700;
                color: #000;
            }
        }

        .price-box.price-final_price {
            .price {
                font-weight: bold;
                font-size: 22px;
                font-family: $rift;
                color: #000;
                font-weight: 700;
            }

            .special-price .price {
                color: $red !important;
            }

            .old-price {
                display: inline;
                margin: 0 0 0 1rem;

                .price {
                    color: $black !important;
                    text-decoration: line-through;
                }

                .price-label {
                    display: none;
                }

                .price-wrapper {
                    text-decoration: none;
                }
            }

            .price-final_price {
                font-family: inherit;
            }
        }

        // The Product swatches
        .swatch-opt {
            .swatch-attribute {
                .swatch-attribute-label {
                    display: inline-block;
                    font-size: 22px;
                    font-weight: bold;
                    font-family: $rift;
                    color: #000;
                }

                .sizing-guide a {
                    color: $orange !important;
                    text-decoration: underline;
                    padding-left: 25px;

                    &:visited,
                    &:hover {
                        color: $orange;
                    }
                }

                &.color {
                    .swatch-attribute-selected-option {
                        position: relative;
                        font-size: 13px;
                        font-family: $roboto;
                        display: block;
                        height: 25px;
                        padding-left: 0;
                        margin: 0 0 1px;
                        color: $body-color;
                    }

                    > div {
                        .swatch-option:not(.image),
                        .swatch-option.image {
                            height: 45px !important;
                            width: 45px !important;
                            position: relative;
                            background-size: contain !important; // Override initial
                            outline-width: 1px;
                            margin-right: 1.2rem;
                            padding: 0;
                            box-sizing: border-box;

                            &:not(.disabled):hover {
                                &.selected {
                                    outline-color: $black;
                                }
                            }

                            &.disabled {
                                pointer-events: none;
                                opacity: 0.3;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                        .swatch-option:not(.image) {
                            background-image: url(../images/swatch_1.png);
                        }
                    }
                }

                &.size {
                    .swatch-attribute-selected-option {
                        display: none;
                    }

                    > div {
                        .swatch-option.text {
                            height: 45px !important;
                            width: 45px !important;
                            position: relative;
                            text-align: center;
                            vertical-align: middle;
                            line-height: 45px;
                            font-size: 13px;
                            font-family: $roboto;
                            border-radius: 0;
                            outline: none !important;
                            border: 1px solid $black;
                            font-weight: 100;
                            margin-right: 0.8rem;
                            padding: 0;

                            &.selected {
                                border: 1px solid $black !important;
                            }

                            &:not(.disabled):hover {
                                outline: none !important;
                                border: 1px solid #969696 !important;
                            }

                            &.disabled {
                                pointer-events: none;
                                opacity: 0.3;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Wish List
        .product-addto-links {
            a.add-to-wishlist {
                line-height: 35px;
                text-align: center;
                width: 35px;
                border: 1px solid red;

                &:hover {
                    opacity: 0.5;
                }

                span {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
        }

        .full-details-link {
            text-align: center;
            display: none;
        }

        // Responsive Video
        .embed-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
        }

        .embed-container iframe,
        .embed-container object,
        .embed-container embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        // Product Option Bottom
        .product-options-bottom,
        .item-no-option {
            width: 100%;

            .product-view-cart {
                width: 100%;
                position: relative;
                display: flex;
                overflow: visible;

                // All the action buttons
                > .actions {
                    display: flex;
                    position: relative;

                    &.dealer-button {
                        flex-grow: 1;

                        &:not(:last-child) {
                            margin-right: 16px;
                        }

                        a {
                            margin-right: 0;
                            background-color: $orange;
                            border-color: $orange;
                            border-radius: 2px;
                            font-family: $rift;
                            font-size: 16px;
                            line-height: 1.8;
                            text-align: center;

                            &:hover {
                                background-color: $black;
                                border-color: $black;
                            }
                        }
                    }

                    &.qty-selection {
                        flex: 0 0 100px;
                        position: relative;
                        margin-right: 14px;
                        width: 100px;
                        border: 2px solid $black;
                        box-sizing: border-box;
                        height: 60px;
                        display: inline-flex !important;
                        border-radius: 2px;

                        .mage-error {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            line-height: 1.2;
                        }

                        .qty-control {
                            display: inline;
                            flex: 1;
                            height: 56px;
                            font-size: 15px;
                            font-family: $roboto;
                            color: $body-color;
                            line-height: 56px;

                            &.input-text.qty {
                                background: none;
                                border: none !important;
                                max-width: 35px;
                                padding: 0;
                                margin-top: 1px;
                            }

                            &.qty-change {
                                font-weight: 100;
                                text-align: center;
                                line-height: 58px;
                                cursor: pointer;
                                color: $body-color;
                                font-size: calc(100% + 5px);
                            }

                            &:before {
                                height: 100%;
                                width: 30%;
                                margin: 0 auto;
                                position: relative;
                                display: block;
                                background-size: auto;
                                background-repeat: no-repeat;
                                background-position: center center;
                            }

                            &.qty-up:before {
                                content: " ";
                                background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" class="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>');
                            }

                            &.qty-down:before {
                                content: " ";
                                background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="minus" class="svg-inline--fa fa-minus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>');
                            }
                        }
                    }

                    &.add-cart {
                        flex: 8;
                        position: relative;
                        display: inline-block !important;
                        margin-right: 14px;

                        button.action.primary.tocart {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            font-family: $rift !important;
                            border: 2px solid black;
                            white-space: nowrap;

                            &:hover {
                                background: white;
                                color: black;
                            }
                        }
                    }

                    &.wish-list {
                        flex: 1;
                        position: relative;
                        flex: 0 0 44px;

                        a.add-to-wishlist {
                            display: block;
                            line-height: 60px;
                            font-size: 38px;
                            color: $black;
                            transition: opacity 0.3s;
                            text-align: center;
                            width: 100%;

                            &:hover {
                                opacity: 0.5;
                                text-decoration: none !important;
                            }

                            > span {
                                border: 0;
                                clip: rect(0, 0, 0, 0);
                                height: 1px;
                                margin: -1px;
                                overflow: hidden;
                                padding: 0;
                                position: absolute;
                                width: 1px;
                                text-decoration: none;
                            }
                        }
                    }
                }

                @media (max-width: 768px) {
                    > .actions {
                        &.qty-selection {
                            flex: 0 0 auto;
                        }

                        &.add-cart {
                            flex-grow: 1;
                        }

                        &.wish-list {
                            flex: 0 0 auto;
                        }
                    }
                }
            }
        }
    }

    .click-collect-wrapper {
        clear: both;
        padding-top: 1rem;
        .click-collect-item {
            border: 2px solid $black;
            border-radius: 2px;
            width: 100%;
            display: flex;
            margin-bottom: 15px;
            flex-wrap: wrap;
            padding: 24px;
            box-sizing: border-box;

            @media (max-width: 768px) {
                padding: 19px;
            }

            a {
                color: $orange;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .click-collect-current-wrapper {
                display: flex;
            }

            .click-collect-image {
                margin-right: 18px;

                svg path {
                    stroke-width: 0px !important;
                }
            }

            .content {
                font-size: 13px;

                @media (max-width: 768px) {
                    font-size: 11px;

                    > .title {
                        font-size: 13px;
                    }
                }

                > .title {
                    font-weight: bold;
                }
            }

            .store-locator-product {
                display: none;
                width: 100%;
                margin: 25px 0;

                .search-wrapper {
                    height: 40px;
                    justify-content: flex-start;

                    @media (max-width: 768px) {
                        padding: 0;
                    }

                    .location {
                        position: relative;
                        width: 100%;
                        max-width: none;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 36px;
                            z-index: 1;
                            height: 100%;
                            width: 1px;
                            background: #ddd;
                        }
                    }

                    .location-input {
                        border: 1px solid #ddd;
                        font-size: 13px;
                        background-size: 15px 20px;
                        background-position: left 10px center;
                    }

                    .search button {
                        width: 44px;
                        margin-left: -1px;
                        border: 1px solid #ddd;
                        padding: 0;
                        line-height: 0;

                        svg {
                            width: 17.83px;
                            height: 17.83px;
                        }
                    }
                }

                .stores-info {
                    display: none;
                }

                .store-blocks {
                    .block {
                        padding-top: 21px;
                        border-top: 0;
                        display: flex;

                        .brands-sold {
                            display: none;
                        }

                        .store-selector {
                            margin-right: 6px;

                            @media (max-width: 768px) {
                                margin-left: 8px;
                                margin-right: 16px;
                            }

                            label {
                                padding: 0;
                            }

                            input {
                                display: none;
                            }
                        }

                        .store-info {
                            @media (max-width: 768px) {
                                padding-left: 0;
                            }

                            .store-name {
                                font-family: $roboto !important;
                                font-size: 13px;
                                line-height: 1.5;
                                text-transform: none;
                                margin-bottom: 0;
                                margin-top: 0;

                                @media (max-width: 768px) {
                                    font-size: 11px;
                                }
                            }

                            .directions,
                            .phone,
                            .website {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .accordion {
        .product.data.items {
            .item.title {
                border: none;

                a {
                    border-bottom: 1px solid $black;
                    font-family: $rift;
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 56px;
                    background: none;
                    padding-left: 0;

                    &.switch {
                        border-color: $black;
                    }

                    &:after {
                        content: none !important;
                    }
                }
            }

            .item.content {
                padding-left: 0;
                background: none !important;

                @media (min-width: 768px) {
                    display: block !important;
                    height: auto !important;
                }

                h2 {
                    font-family: $rift !important;
                    font-size: 22px;
                    font-weight: 700;
                    margin-top: 10px;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 13px;
                    line-height: 22px;
                    color: $body-color;
                }

                li {
                    margin-bottom: 0;
                }
            }
        }

        .counter {
            display: none;
        }

        /** TAB: REVIEW START **/
        .review-add {
            .write-review-text {
                font-size: 13px;
            }

            .review-block {
                margin-top: 10px;

                button {
                    &.primary {
                        font-family: $rift;
                        background: $black;
                        border: none;
                        transition: all 0.3s ease;
                        font-size: 16px;
                        line-height: 40px;
                        padding: 0 27px 0 27px;
                    }
                }
            }
        }

        .clear {
            clear: both;
        }

        .review-block {
            margin-bottom: 20px;
            font-size: 13px;

            > span {
                display: block;
                line-height: 2em;
                margin-bottom: 10px;
            }

            button.primary {
                background: black;
                border: 2px solid black;
                font-family: $rift;
                font-size: 16px;
                padding: 10px 28px;

                &:hover {
                    background-color: #fff;
                    color: black;
                }
            }
        }

        .block.review-list,
        #product-review-container {
            display: block;
            float: none;
            width: 100%;

            li.item {
                font-size: 13px;
                padding-bottom: 10px;
                margin-bottom: 15px;
                line-height: 1.5em;
                border-bottom: none !important;

                .item-header {
                    display: block;
                    position: relative;

                    > .name {
                        float: left;
                        font-weight: bold;

                        > span {
                            font-weight: 100;
                        }
                    }

                    > .date-posted {
                        float: right;
                        color: $grey;
                        margin-right: 25px;
                        text-align: right;
                    }
                }

                > .title {
                    margin-bottom: 2px;
                    font-weight: bold;
                }

                > .content {
                    color: $body-color;
                }

                .ratings {
                    .rating-result {
                        width: 102px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;

                        &:before {
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            z-index: 1;
                            -webkit-font-smoothing: antialiased;
                            color: #c7c7c7;
                            font-family: "FontAwesome";
                            font-size: 16px;
                            height: 16px;
                            letter-spacing: $starLetterSpacing;
                            line-height: 16px;
                            content: "\f005""\f005""\f005""\f005""\f005";
                            display: block;
                            font-style: normal;
                            font-weight: normal;
                            speak: none;
                        }

                        > span {
                            display: block;
                            overflow: hidden;
                            margin-bottom: 5px;

                            &:before {
                                position: relative;
                                z-index: 2;
                                -webkit-font-smoothing: antialiased;
                                color: #ff7a0e;
                                color: $starYellow !important;
                                font-family: "FontAwesome";
                                font-size: 16px;
                                height: 16px;
                                letter-spacing: $starLetterSpacing;
                                line-height: 16px;
                                content: "\f005""\f005""\f005""\f005""\f005";
                                display: block;
                                font-style: normal;
                                font-weight: normal;
                                speak: none;
                            }

                            span {
                                border: 0;
                                clip: rect(0, 0, 0, 0);
                                height: 1px;
                                margin: -1px;
                                overflow: hidden;
                                padding: 0;
                                position: absolute;
                                width: 1px;
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .column {
        &.main {
            .block {
                border-color: #000000;

                .block-title {
                    strong {
                        font-family: $rift !important;
                        font-size: 32px;
                    }
                }

                .block-actions {
                    display: none;
                }
            }
        }
    }

    aside.modal-popup._inner-scroll._show {
        .modal-inner-wrap {
            width: max-content;
        }

        .modal-footer {
            text-align: center;

            .action-primary {
                font-family: $rift;
                background-color: $black;
                border-radius: 2px;
                color: $white;
                cursor: pointer;
                display: inline-block;
                font-weight: 700;
                font-style: normal;
                font-size: 15px;
                text-transform: uppercase;
                padding: 0.5em 2em;
                text-align: center;
                transition: all 0.3s ease;
                height: 44px;
                min-width: 80px;
                border: none;

                &:hover {
                    background-color: #454343;
                }
            }
        }
    }

    .item-additional-info {
        width: 100%;

        @media (min-width: 1200px) {
            margin-left: calc((100vw - 60px - 1043px) / 2);
        }

        @media (min-width: 1400px) {
            margin-left: calc((1366px - 1043px) / 2);
        }

        @media (max-width: 767px) {
            width: auto;
            margin-left: -2rem;
            margin-right: -2rem;

            .products-grid {
                margin-bottom: 0;

                .products.list.items.product-items {
                    display: flex;
                    flex-wrap: wrap;

                    .item.product.product-item {
                        width: 50%;

                        &:nth-child(4) ~ .product-item {
                            display: none;
                        }
                    }
                }
            }
        }

        .related {
            position: relative;
            margin-bottom: 0;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: -1px;
                width: 100vw;
                height: 0px;
                box-sizing: border-box;
                outline: 1px solid #000;
                transform: scaleY(0.5);
            }

            &::before {
                right: 100%;
            }

            &::after {
                left: 100%;
            }
        }
    }

    /** STOCK LEVELS **/
    .stock-levels-wrapper {
        padding-top: 2px;
        flex: 1;
        > div {
            float: left;

            &.stock-level-colour {
                background: $green;
                border-radius: 30px;
                height: 14px;
                width: 14px;
                margin: 4px 5px;
                line-height: 0.8em;
                text-align: center;
                display: inline-block;

                &.low-stock {
                    background: $orange;
                }

                &.no-stock {
                    background: $red;
                    svg {
                        display: none;
                    }
                }
            }

            &.stock-level-text {
                display: inline-block;
                line-height: 1.4em;
                margin: 2px 0;
            }
        }
    }
}

.column.main .product.media {
    text-align: center;
    position: relative;

    @media (min-width: 1200px) {
        box-sizing: border-box;
        padding-right: 17px;
    }

    @media (min-width: 768px) {
        margin-right: 22px;
    }
}

.fotorama__stage__frame {
    > img {
        width: 500px;
    }
}

.fotorama__nav {
    @media (min-width: 768px) {
        height: 240px !important;
        margin-top: 15px;

        &::before,
        &::after {
            display: none !important;
        }
    }
    width: 100% !important;
}

.fotorama__nav__shaft {
    .fotorama__nav__frame {
        @media (min-width: 768px) {
            width: 240px !important;
            height: 240px !important;
            margin-right: 18px;
        }
    }
}

.fotorama__thumb-border {
    display: none;
}

.magnify-lens {
    border: none;
}

.fotorama__fullscreen-icon {
    background-image: url(../images/close.svg);
    background-size: 25px 25px;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    }
}

.fotorama__arr__arr {
    .fotorama__arr--prev &,
    .fotorama__arr--next & {
        .fotorama__fullscreen & {
            background-image: url(../images/right.svg);
            background-size: 14px 24.5px;
        }
    }

    .fotorama__arr--prev & {
        .fotorama__fullscreen & {
            transform-origin: 20px 20px;
            transform: rotate(180deg);
        }
    }
}

.fotorama--fullscreen {
    background-color: rgba($black, 0.3);

    .fotorama__nav-wrap,
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        display: none !important;
    }

    .fotorama__arr {
        width: 80px;
        height: 80px;
        margin: auto 0;
    }

    .fotorama__arr__arr,
    .fotorama__fullscreen-icon {
        background-position: center !important;
    }

    .fotorama__arr,
    .fotorama__thumb__arr,
    .fotorama__fullscreen-icon {
        display: block !important;
        background-color: transparent !important;
        background-repeat: no-repeat;

        &:hover {
            background-color: rgba($black, 0.3) !important;
        }
    }
}

@media (max-width: 767px) {
    .product-reviews-summary .reviews-actions {
        display: none;
    }
    .column.main .product.media {
        margin-bottom: 25px;

        .fotorama__nav__shaft {
            display: flex;
            justify-content: center;
            width: 100% !important;
            margin: 0 auto;

            .fotorama__nav__frame--thumb {
                display: none !important;
            }

            .fotorama__nav__frame {
                width: auto !important;
                height: 10px !important;
                margin: 10px 5px 0px !important;
                flex: 1 1 auto;
                display: flex;
                align-items: center;

                .fotorama__dot {
                    border-radius: 0;
                    height: 3px;
                    background: #dddddd;
                    border: none;
                    width: 95%;
                    left: 0;
                    top: 0;
                    flex: 1;
                    margin: 0 auto;
                }

                &.fotorama__active {
                    .fotorama__dot {
                        background: #000000;
                    }
                }
            }
        }
    }
}

.review-block {
    margin-bottom: 20px;

    > span {
        display: block;
        line-height: 2em;
        margin-bottom: 10px;
    }

    button.primary {
        background: black;
        border: 2px solid black;
        font-family: $rift;
        font-size: 16px;
        padding: 10px 30px;

        &:hover {
            background-color: #fff;
            color: black;
        }
    }
}

#review-form-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    display: block;
    z-index: 900;

    &.active {
        width: 100%;
        height: 100%;

        .review-form-add {
            right: 0;
        }
    }

    .review-fieldset {
        margin-bottom: 0;

        .label {
            margin-bottom: 4px;
        }

        input,
        textarea {
            font-size: 13px;
            border-radius: 2px;
        }
    }

    .btn {
        min-width: 127px;
        line-height: 1;
        border-width: 2px;
    }

    .review-form-add {
        position: fixed;
        right: -100%;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 414px;
        background: white;
        z-index: 901;
        overflow-y: auto;
        box-shadow: 0px 3px 6px rgba($black, 0.3);
        transition: right 0.3s linear;

        .inner-wrapper {
            top: 0;
            margin: 0 auto !important;
            border: 0;
            min-width: auto;
            background: white;

            .sidebar-heading {
                background: black;
                color: white;
                padding-top: 5.3rem;
                padding-left: 3.2rem;
                padding-right: 2.5rem;
                padding-bottom: 2rem;
                background-color: #000;
                box-sizing: border-box;

                h2 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 32px;
                    font-family: $rift;
                    display: inline-block;
                    line-height: 1.2;
                }

                .btn.close {
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    display: inline-block;
                    min-width: 0;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                }
            }

            .block-content {
                width: 100%;
                margin: 0 auto;

                form {
                    padding: 30px;

                    .product-reviewing {
                        margin-bottom: 15px;
                    }

                    .fieldset .review-field-ratings > .label,
                    .fieldset .field,
                    .field {
                        margin-bottom: 15px;
                        margin-left: 0;
                        padding-inline-start: 2px;
                    }

                    .fieldset .review-field-ratings {
                        margin: 0;
                        padding: 0;
                    }

                    // Required Stars
                    .field.required {
                        label.label:after {
                            content: "*" !important;
                            color: red;
                            margin-left: 3px;
                        }
                    }

                    // Fix the font
                    .label span {
                        font-family: $roboto;
                        font-weight: bold;
                        color: black;
                        display: inline-block;
                        font-family: $roboto;
                        text-transform: none;
                        font-size: 12px;
                    }

                    input,
                    textarea {
                        border: 1px solid #dddddd;
                    }

                    // Star Control
                    .review-control-vote {
                        margin: 0 0 35px 0;

                        &:before {
                            content: "\f005""\f005""\f005""\f005""\f005";
                            color: $starYellowFaded;
                            font-size: 24px;
                            letter-spacing: $starLetterSpacing;
                            line-height: 24px;
                            height: auto;
                        }

                        label {
                            &:before {
                                color: $starYellow;
                                font-size: 24px;
                                letter-spacing: $starLetterSpacing;
                                line-height: 24px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    &:before {
        content: " ";
        background: rgba(0, 0, 0, 0.3);
        z-index: 900;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.tarkikComandSlider {
    width: 1100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    display: none;
}

.slick-dots li:before {
    top: 1px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    content: "";
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    position: absolute;
}

.slick-dots li.slick-active:before {
    top: 0;
    width: 8px;
    height: 8px;
    margin-left: -2px;
    background-color: #964848;
}

.slick-prev {
    left: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.slick-next {
    right: 0;
}

.slick-arrow {
    top: 50%;
    height: 40px;
    width: 26px;
    transform: translateY(-50%);
    position: absolute;
    font-size: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &.slick-next::before,
    &.slick-prev::before {
        content: "";
        display: block;
        width: 26px;
        height: 40px;
        background-image: url(../images/right.svg);
        background-size: 17.74px 35.47px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.slick-prev::before {
        transform: rotate(180deg);
    }

    .desktop-home-categories & {
        margin-top: -38px;
        background: none;

        &.slick-prev {
            left: 36px;
        }

        &.slick-next {
            right: 36px;
        }
    }
}

.slick-dots {
    .desktop-home-categories & {
        bottom: 95px;
    }

    li {
        max-width: 100px;
        width: 29%;

        &::before {
            position: absolute;
            background-color: $white;
            border-radius: 0;
            width: 100%;
            height: 3px;
            margin-left: 5px;
            margin-right: 5px;
            -webkit-box-shadow: none;
            box-shadow: none;
            left: 0;
        }

        &.slick-active::before {
            margin-left: 5px;
            margin-right: 5px;
            width: 100%;
            height: 3px;
            background-color: $black;
        }
    }
}

.comandSlider__item {
    -webkit-transition: box-shadow 2s;
    transition: box-shadow 2s;
    cursor: pointer;
    position: relative;
    background-color: #344c65;
    margin: 0 10px;
    padding: 30px;
    box-shadow: inset -141px -129px 200px rgba(0, 0, 0, 0.25);
}

.comandSlider__item:hover {
    box-shadow: inset 141px 129px 200px rgba(0, 0, 0, 0.25);
}

.comandSlider__item_name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #3eaef2;
    margin: 0;
    margin-bottom: 7px;
}

.comandSlider__item_status {
    margin: 0;
    color: #fff;
    font-size: 12px;
    margin-bottom: 80px;
}

.comandSlider__item_sendMail {
    width: 25px;
    height: 20px;
    position: absolute;
    background-image: url(https://tarkikromanski.github.io/files/mail-ico.png);
    top: 34px;
    right: 30px;
}

.comandSlider__item_photo {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
    margin-bottom: 54px;
}

.comandSlider__item_contact {
    font-size: 12px;
    color: #fff;
    margin: 2px;
}

.comandSlider__item_contact span {
    color: #3eaef2;
    font-weight: 700;
    margin-right: 5px;
}

.price-box.price-final_price {
    display: flex;
}

.old-price .price-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sly-old-price {
    display: block;
    margin-left: 0.5rem;
}

.product-gift-card {
    .click-collect-wrapper,
    .top-holder-left .product.attribute.sku,
    .top-holder-right,
    .accordion.product.info {
        display: none !important;
    }
}

.rating-result.rating-result {
    width: 102px;

    &,
    span {
        &::before {
            content: "" !important;
            display: block;
            letter-spacing: 0px;
            font-size: 2.4rem;
            height: 14px;
            padding: 2px 0px;
            white-space: nowrap;
            background-image: url(../images/star.svg);
            background-size: 20.7px 13.6px;
            background-repeat: repeat-x;
            background-position: left center;
        }
    }

    &::before {
        width: 100%;
        opacity: 0.5;
    }

    .rating-1::before {
        width: 19px;
    }

    .rating-2::before {
        width: 39px;
    }

    .rating-3::before {
        width: 59px;
    }

    .rating-4::before {
        width: 80px;
    }

    .rating-5::before {
        width: 102px;
    }
}

.gallery-placeholder {
    position: relative;
}

.magnifier-preview {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.modal-popup {
    .modal-inner-wrap {
        #modal-content-24,
        .modal-content {
            padding: 0;
            overflow: hidden;
        }

        .modal-title {
            font-weight: 700;
            color: $black;
        }

        .modal-header {
            padding: 0;
            height: 0;
        }

        .action-close {
            height: 40px;
            position: absolute;

            &:before {
                content: " " !important;
                background: no-repeat url("../images/close_loader.svg");
                height: 45px;
                width: 45px;
                position: absolute;
                left: -40px;
            }

            &:active {
                transform: none;
            }
        }

        iframe {
            background: url("../images/loader.svg") no-repeat center center;
        }

        padding: 0;
        width: 100vw !important;
        max-width: 993px;
        height: 580px;
        overflow: hidden;
        border: 2px solid black;
    }
}

/* Quick View Styling */
.quickview-scroll {
    .page-wrapper {
        padding-top: 40px;
    }

    .column.main {
        .product-info-main {
            width: 51% !important;
            margin-bottom: 20px;
        }
    }

    .column.main .product.media {
        width: 377px !important;
    }

    .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame {
        width: 111px !important;
        height: 111px !important;
    }

    .column.main .product.media .fotorama__nav {
        height: 111px !important;
    }

    .product-info-main {
        position: relative;

        &:before {
            content: " ";
            background: #e6e6e6;
            position: absolute;
            left: -15px;
            width: 6px;
            height: 100%;
        }

        .product-social-links {
            display: none;
        }

        .full-details-link {
            display: block;
            font-size: 13px;

            a {
                color: $orange !important;
                text-decoration: underline;

                &:visited,
                &:hover {
                    color: $orange;
                }
            }
        }
    }

    .catalog-product-view
        .product-info-main
        .product-options-bottom
        .product-view-cart
        > .actions.add-cart {
        flex: 4;
    }

    .fotorama__stage.fotorama__shadows--left:before,
    .fotorama__nav.fotorama__shadows--left:before,
    .fotorama__stage.fotorama__shadows--right:after,
    .fotorama__nav.fotorama__shadows--right:after {
        background: none;
    }
}

// Stubborn Magento2 Styles
.product-info-main .product.attribute.sku .value {
    font-weight: 100;
}

.sizing-guide {
    div[data-content-type="html"] {
        display: inline;
    }

    a {
        font-size: 13px;
    }
}

.column.main .product-info-main button.action.primary.tocart {
    font-family: $rift !important;
}

body.page-product-configurable .product-description.description > div > div {
    display: block !important;
}

.catalog-product-view
    .product-info-main
    .price-box.price-final_price
    .old-price {
    display: inline !important;
}

.flex-prices {
    margin-bottom: 5px;
    .price-box {
        width: 100% !important;
        line-height: 1em !important;
        flex-direction: column;

        > div {
            width: 100%;
        }

        > div span {
            line-height: 1.1em !important;
        }

        .price-label {
            margin-right: 4px;
        }

        .price-wrapper {
            .price.strike .price {
                margin-right: 5px !important;
                color: #b5b5b5 !important;
                text-decoration: line-through;
            }
            .price.special .price {
                color: $red !important;
            }
        }
    }
}

.stock-level-colour.no-stock svg {
    display: none;
}

