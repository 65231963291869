/* FOOTER */
.page-footer {
    min-height: 0;
    background: $black !important;
    color: $white;
    font-family: $rift;
    font-weight: bold;

    @media (min-width: 768px) {
        padding-top: 42px !important;
    }
    [data-content-type="row"][data-appearance="contained"] {
        max-width: none;
    }
    .footer.content {
        max-width: $max_width !important;
        margin-bottom: 0;
        padding: 0;
        ul {
            padding: 0;
            flex-grow: 1;
            width: auto;
            margin-right: 40px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .flex-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        align-items: top;
        justify-content: space-between;
    }
    .links-wrapper,
    .contact-wrapper,
    .subscribe-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;

        @media (min-width: 768px) {
            min-width: fit-content;
            max-width: fit-content;
        }
    }
    .links-wrapper {
        display: flex;
        flex-direction: row;
        align-items: top;
        justify-content: space-between;
        h4 {
            color: $orange;
            font-size: 20px;
            line-height: 44px;
            margin-top: 0;
            margin-bottom: 6px;
            font-weight: bold;
        }
        li a {
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
            text-decoration: none;
            transition: color 0.2s linear;
            color: inherit;
            &:hover {
                color: $orange;
            }
        }
    }
    .contact-wrapper {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .wrap {
            display: flex;
            flex-direction: column;
            > a:not(.btn) {
                font-weight: bold;
                font-size: 37px;
                line-height: 44px;
                text-decoration: none;
                transition: color 0.2 linear;
                color: $white;
                &:hover {
                    color: $orange;
                }
            }
        }
        .social-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            a {
                margin: 0 13px 0;
                color: inherit;
                @media (min-width: 768px) {
                    margin: 0 23px 0 0;
                }
                &:hover {
                    svg path {
                        fill: $orange;
                    }
                }
            }
        }
        p {
            margin-bottom: 0.5rem;
            font-family: $roboto;
            font-weight: normal;
            font-size: 13px;
            line-height: 22px;
            color: $white;
            text-transform: none;
        }
    }
    .subscribe-wrapper {
        width: 100%;
        max-width: 468px;
        height: 238px;
        border: 1px solid $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        h3 {
            font-family: $rift;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 3.2px;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 10px;
        }
        p {
            font-family: $roboto;
            font-weight: normal;
            text-transform: none;
            font-size: 13px;
            line-height: 19px;
            margin-bottom: 20px;
            width: 100%;
            max-width: 350px;
            padding: 0 10px;
            color: inherit;
        }
        .input-group {
            background-color: #fff;
            border-radius: 3px;
        }
    }
    .footer-toolbar-bottom {
        max-width: 100% !important;
        margin-top: 30px;

        .content {
            max-width: $max_width !important;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            @media (min-width: 1024px) {
                max-width: 1406px!important;
                padding: 1.5rem 20px;
            }
        }

        .copyright {
            display: flex;
            align-items: center;
            color: $body-color;
            text-transform: none;
            line-height: 1.5;
            font-family: $roboto;
            font-size: 10px;
            font-weight: normal;

            span {
                padding-left: 1.2rem;
            }
        }
    }

    .subscribe-wrapper {

        .input-group {
            max-width: 353px;
            width: inherit;

            .input-group-text,
            .form-control,
            .btn {
                height: 40px;
            }

            .input-group-text {
                position: relative;
                background-repeat: no-repeat;
                background-position: center;
                box-sizing: border-box;
                background-image: url(../images/envelope.svg);
                background-size: 15.63px 11.72px;
                width: 36px;
            }

            @media (min-width: 768px) {
                max-width: 353px;
            }

            .btn {
                border-width: 0 0 0 1px;
            }

            #newsletter-email-error {
                position: absolute;
                bottom: -30px;
                left: 0;
                color: white;
                font-weight: 500;
                width: 100%;
            }
        }
    }
}
@media (max-width: 1280px) {
    .page-footer {
        .flex-row {
            justify-content: space-around;
        }
    }
}
@media (max-width: 900px) {
    .page-footer {
        .flex-row {
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
        }
        .links-wrapper {
            margin-top: 0;
            border-top: 1px solid $white;
            padding-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .contact-wrapper {
            .social-wrapper {
                margin-top: 25px;
                margin-left: auto;
                margin-right: auto;
            }
            .wrap {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                .btn {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .footer-toolbar-bottom {
            .content {
                .copyright {
                    align-items: center;
                    justify-content: center;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
}

/* Buttons */
.page-footer {
    .btn {
        width: fit-content;
        background-color: $orange;
        color: $white;
        padding: 10px 35px;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.2s linear;
        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}

/* Footer override */
.page-footer {
    .flex-row {
        > div {
            &.links-wrapper {
                flex: 0 0;

                @media (max-width: 768px) {
                    flex: 1 0 100%;
                }

                @media (min-width: 1420px) {
                    min-width: 400px;
                }
                ul {
                    margin-right: 10px;
                }
            }
            &.subscribe-wrapper {
                @media screen and (min-width: 1117px) and (max-width: 1215px) {
                    max-width: 350px;
                }
            }
        }
    }
}


