$lime: #89de00;

.page-layout-product-reboot, .page-layout-product-hero {

    .top-container {
        margin-left: 0;
        margin-right: 0;
        background-color: #ebede3;
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;

        &,
        * {
            box-sizing: border-box;
        }

        .cta-reboot-left-banner {
            max-width: 47rem;
        }

        .cta-reboot-banner {

            [data-content-type='row'][data-appearance='contained'] {
                max-width: 100% !important;

                [data-element='inner'] {
                    margin: 0 !important;
                    padding: 0 !important;
                    height: 47px;
                    background-repeat: repeat-x;
                    background-position: center !important;
                }
            }
        }

    .cta-reboot-hero-banner-container {
        max-width: 1920px;
        margin: 0 auto;
    }

    .cta-reboot-hero-banner {
        height: 0;
        font-size: 10rem;
        background-position: center;
        background-size: cover;
        background-color: $black;
        padding-bottom: 42.5%;

        &:not(:only-child) {
            display: none;
        }

        &.mobile {
            display: block;
            padding-bottom: 100%;
        }

        @media (min-width: 768px) {

            &.cta-reboot-hero-banner {
                display: block;
            }

            &.mobile {

                &:not(:only-child) {
                    display: none;
                }
            }
        }
    }

        .cta-reboot-grid {
            margin-left: auto;
            margin-right: auto;
            max-width: 1366px;
            padding: 2rem 2rem 4rem;
            color: $black;

            @media (min-width: 1024px) {
                display: flex;
                justify-content: space-between;
            }
        }

        .cta-reboot-left-column {
            flex-grow: 1;
            font-size: 1.7rem;

            @media (min-width: 1024px) {
                padding-right: 2rem;
            }

            ul {
                padding-left: 1.7rem;
            }

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }

        .cta-reboot-title {
            font-family: $rift !important;
            font-weight: 700;
            font-size: 8rem;
            line-height: 1;
            margin: 2rem 0;
        }

        .cta-reboot-tagline {
            font-family: $rift;
            font-size: 2rem;
            font-weight: bold;
            margin: 2rem 0 5rem;
        }

        .cta-reboot-right-column {
            flex: 0 0 427px;
            max-width: 427px;
        }

        .cta-reboot-size {
            position: relative;
            z-index: 0;
            margin-top: 3rem;
            padding: 3rem;
            max-width: 42rem;

            .size-top,
            .size-bottom {

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    width: 40px;
                    height: 40px;
                }
            }

            .size-top {

                &::before,
                &::after {
                    border-top: 1px solid $lime;
                }

                &::before {
                    border-left: 1px solid $lime;
                    top: 0;
                    left: 0;
                }

                &::after {
                    border-right: 1px solid $lime;
                    top: 0;
                    right: 0;
                }
            }

            .size-bottom {

                &::before,
                &::after {
                    border-bottom: 1px solid $lime;
                }

                &::before {
                    border-left: 1px solid $lime;
                    bottom: 0;
                    left: 0;
                }

                &::after {
                    border-right: 1px solid $lime;
                    bottom: 0;
                    right: 0;
                }
            }

            .size-leftcenter,
            .size-rightcenter,
            .size-topcenter,
            .size-bottomcenter {

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                }
            }

            .size-leftcenter {

                &::before {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    height: 12px;
                    border-left: 1px solid $lime;
                }

                &::after {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 6px;
                    border-top: 1px solid $lime;
                }
            }

            .size-rightcenter {

                &::before {
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    height: 12px;
                    border-right: 1px solid $lime;
                }

                &::after {
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 6px;
                    border-top: 1px solid $lime;
                }
            }

            .size-topcenter {

                &::before {
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    width: 12px;
                    border-top: 1px solid $lime;
                }

                &::after {
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    height: 6px;
                    border-left: 1px solid $lime;
                }
            }

            .size-bottomcenter {

                &::before {
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: 12px;
                    border-bottom: 1px solid $lime;
                }

                &::after {
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    height: 6px;
                    border-left: 1px solid $lime;
                }
            }

            ul {
                margin-bottom: 0;
                padding-left: 1.7rem;

                li:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cta-reboot-size-heading {
            font-family: $rift !important;
            margin: 0 0 2rem;
            font-weight: 700;
            font-size: 1.6rem;
            text-transform: uppercase;
        }

        .cta-reboot-durability-gauge {
            position: relative;
            margin-top: 5rem;
            margin-bottom: 2rem;
            display: flex;
            justify-content: space-between;
            border: 1px solid $black;
            padding: 0.2rem 1.2rem;
            font-size: 1.3rem;
            background: transparent linear-gradient(90deg, rgba(239, 239, 239, 0) 0%, rgba(131, 131, 131, 1) 100%) 0% 0% no-repeat padding-box;

            .cta-reboot-durability-gauge-value {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                background-image: url(../images/fox-star.png);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 42px;
                height: 42px;
            }
        }

        // The Product swatches
        .swatch-opt {
            .swatch-attribute {
                .swatch-attribute-label {
                    display: inline-block;
                    font-size: 22px;
                    font-weight: bold;
                    font-family: $rift;
                    color: #000;
                }

                .sizing-guide {
                    div[data-content-type="html"] {
                        display: inline;
                    }

                    a {
                        color: $orange !important;
                        text-decoration: underline;
                        padding-left: 25px;

                        &:visited,
                        &:hover {
                            color: $orange;
                        }
                    }
                }

                &.color {
                    .swatch-attribute-selected-option {
                        position: relative;
                        font-size: 13px;
                        font-family: $roboto;
                        display: block;
                        height: 25px;
                        padding-left: 0;
                        margin: 0 0 1px;
                        color: $body-color;
                    }

                    > div {
                        .swatch-option:not(.image),
                        .swatch-option.image {
                            height: 45px !important;
                            width: 45px !important;
                            position: relative;
                            background-size: contain !important; // Override initial
                            outline-width: 1px;
                            margin-left: 0.1rem;
                            margin-right: 1.2rem;
                            padding: 0;
                            box-sizing: border-box;

                            &:not(.disabled):hover {
                                &.selected {
                                    outline-color: $black;
                                }
                            }

                            &.disabled {
                                pointer-events: none;
                                opacity: 0.3;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                        .swatch-option:not(.image) {
                            background-image: url(../images/swatch_1.png);
                        }
                    }
                }

                &.size {
                    .swatch-attribute-selected-option {
                        display: none;
                    }

                    > div {
                        .swatch-option.text {
                            height: 45px !important;
                            width: 45px !important;
                            position: relative;
                            text-align: center;
                            vertical-align: middle;
                            line-height: 45px;
                            font-size: 13px;
                            font-family: $roboto;
                            border-radius: 0;
                            outline: none !important;
                            border: 1px solid $black;
                            font-weight: 100;
                            margin-right: 0.8rem;
                            padding: 0;

                            &.selected {
                                border: 1px solid $black !important;
                            }

                            &:not(.disabled):hover {
                                outline: none !important;
                                border: 1px solid #969696 !important;
                            }

                            &.disabled {
                                pointer-events: none;
                                border-color: rgba(0, 0, 0, 0.3);
                                color: rgba(0, 0, 0, 0.3);

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Wish List
        .product-addto-links {
            a.add-to-wishlist {
                line-height: 35px;
                text-align: center;
                width: 35px;
                border: 1px solid red;

                &:hover {
                    opacity: 0.5;
                }

                span {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
        }

        .product-view-cart {
            width: 100%;
            position: relative;
            display: flex;
            overflow: visible;

            // All the action buttons
            > .actions {
                display: flex;
                position: relative;

                &.dealer-button {
                    flex-grow: 1;

                    &:not(:last-child) {
                        margin-right: 16px;
                    }

                    a {
                        margin-right: 0;
                        background-color: $orange;
                        border-color: $orange;
                        border-radius: 2px;
                        font-family: $rift;
                        font-size: 16px;
                        line-height: 1.8;
                        text-align: center;

                        &:hover {
                            background-color: $black;
                            border-color: $black;
                        }
                    }
                }

                &.qty-selection {
                    flex: 0 0 100px;
                    position: relative;
                    margin-right: 14px;
                    width: 100px;
                    border: 2px solid $black;
                    box-sizing: border-box;
                    height: 60px;
                    display: inline-flex !important;
                    border-radius: 2px;
                    background-color: $white;

                    .mage-error {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        line-height: 1.2;
                    }

                    .qty-control {
                        display: inline;
                        flex: 1;
                        height: 56px;
                        font-size: 15px;
                        font-family: $roboto;
                        color: $body-color;
                        line-height: 56px;

                        &.input-text.qty {
                            background: none;
                            border: none !important;
                            max-width: 35px;
                            padding: 0;
                            margin-top: 1px;
                        }

                        &.qty-change {
                            font-weight: 100;
                            text-align: center;
                            line-height: 58px;
                            cursor: pointer;
                            color: $body-color;
                            font-size: calc(100% + 5px);
                        }

                        &:before {
                            height: 100%;
                            width: 30%;
                            margin: 0 auto;
                            position: relative;
                            display: block;
                            background-size: auto;
                            background-repeat: no-repeat;
                            background-position: center center;
                        }

                        &.qty-up:before {
                            content: " ";
                            background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" class="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>');
                        }

                        &.qty-down:before {
                            content: " ";
                            background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="minus" class="svg-inline--fa fa-minus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>');
                        }
                    }
                }

                &.add-cart {
                    display: flex;
                    width: 100%;

                    button.action.primary.tocart {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        font-family: $rift !important;
                        border: 2px solid black;
                        background-color: $black;
                        white-space: nowrap;

                        &:hover {
                            background: white;
                            color: black;
                        }
                    }
                }

                &.wish-list {
                    flex: 1;
                    position: relative;
                    flex: 0 0 44px;

                    a.add-to-wishlist {
                        display: block;
                        line-height: 60px;
                        font-size: 38px;
                        color: $black;
                        transition: opacity 0.3s;
                        text-align: center;
                        width: 100%;

                        &:hover {
                            opacity: 0.5;
                            text-decoration: none !important;
                        }

                        > span {
                            border: 0;
                            clip: rect(0, 0, 0, 0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                            text-decoration: none;
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                > .actions {
                    &.qty-selection {
                        flex: 0 0 auto;
                    }

                    &.add-cart {
                        flex-grow: 1;
                    }

                    &.wish-list {
                        flex: 0 0 auto;
                        margin-left: 1.4rem;
                    }
                }
            }
        }
    }

    .cta-reboot-right-column {
        .product-add-form {
            .product-options-bottom {
                width: 100%;
                float: none;
            }
        }

        .product-options-wrapper {
            float: none;
            margin-bottom: 0 !important;
            overflow: auto;
        }
    }

    .swatch-attribute-options .swatch-option.text {
        opacity: 1;
        color: $black;
        background-color: $white;
    }

    .item-additional-info {
        max-width: 1366px !important;
        margin: 0 auto !important;

        .tradein-offer {

            @media (max-width: 767.98px) {
                margin: 0 -2rem;
            }
        }

        .cta-hero-cashback-offer {
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            color: $black;

            @media (min-width: 768px) {
                margin-top: 8rem;
                font-size: 6.5rem;
            }
        }

        [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
            padding: 0 !important;
        }

        input {
            display: block;
            max-width: 597px;
            width: 100%;
            margin: 0 auto;
            border-radius: 3px;
            font-family: $rift;
            font-size: 2rem;
            font-weight: 700;
            padding: 2.5rem 2rem;
        }

        .store-list {
            display: flex;
            flex-wrap: wrap;
            max-width: 1208px !important;
            margin: 3rem auto;
            padding: 0.3rem 0;

            @media (min-width: 1024px) {
                overflow-y: auto;
                max-height: 393px;
                margin-top: 6rem;
            }

            .block-wrapper {
                flex: 0 100%;
                box-sizing: border-box;
                margin-bottom: 1.9rem;
                padding-left: 0.95rem;
                padding-right: 0.95rem;

                @media (min-width: 550px) {
                    flex: 0 50%;
                }

                @media (min-width: 768px) {
                    flex: 0 33.333%;
                }

                @media (min-width: 1024px) {
                    flex: 0 25%;
                }

                .block {
                    position: relative;
                    display: flex;
                    border-radius: 3px;
                    margin-bottom: 0;
                    height: 100%;
                    padding: 2.1rem 5.6rem 2.1rem 1.8rem;
                    box-sizing: border-box;
                    box-shadow: 0px 3px 6px rgba($black, 0.16);
                    border: none;

                    @media (max-width: 767.98px) {
                        padding: 2.5rem 5.6rem 2.5rem 1.9rem;
                    }

                    .brands-sold {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 1rem 1.6rem 0 0;

                        @media (max-width: 767.98px) {
                            padding: 0.7rem 1.6rem 0 0;
                        }

                        .filter-icon {
                            flex: 0 0 3.6rem;
                            margin-bottom: 0.4rem;
                        }
                    }
                }
            }
        }
    }

    .product-options-bottom .box-tocart {
        float: none;
    }

    #maincontent .columns {
        max-width: 100% !important;
    }

    .price-box .price {
        font-size: 2.2rem;
        font-family: $rift;
        color: $black;
        font-weight: 700;
    }

    .page-title-wrapper .page-title {
        margin-bottom: 2rem;
    }
}
