
.instagram-holder {

    width: 100%;
    box-sizing: border-box;

    @media (max-width: 767px) {
        margin-bottom: 0;

        .instagram-desktop {
            display: none;
        }
    }

    @media (min-width: 768px) {
        margin-top: 4rem;
        padding: 40px 0 0px;
        background-color: rgba(238, 238, 238, 0.5);

        .instagram-mobile {
            display: none;
        }
    }

    .instagram-mobile {
        background-image: url(../images/ig-bg-mobile.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        text-align: center;
        min-height: 200px;
        padding: 40px;
        box-sizing: border-box;

        a {
            display: inline-block;
            font-family: $rift;
            color: #fff;
            font-size: 28px;
            line-height: 32px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2.8px;

            &:hover,
            &:visited {
                color: #fff;
            }
        }

        svg {
            margin-bottom: 8px;
        }
    }

    .instagram-desktop {
        max-width: $max_width;
        margin: 0 auto;

        .instagram-description {
            font-size: 32px;
            line-height: 38px;
            font-family: $rift;
            font-weight: 700;
            text-transform: uppercase;
            color: #000;
            text-align: center;
            margin-bottom: 40px;
            letter-spacing: 3.2px;

            svg {
                display: inline-block;
                margin-right: 15px;
                vertical-align: bottom;
                transform: translateY(1px);
            }
        }
    }

    .snptwdgt-rtd__fottr {
        height: 0;
        overflow: hidden;
        #wdgt-ftr-usr_profl {
            display: none;
        }
    }

    .snptwdgt__headr {
        display: none;
    }

    // Temporary hack to deal with 2 row 6 col caching
    #snpt_CHbVZt7DMIN {
        display: none;
    }
}

@media (max-width: 767px) {
    .instagram-holder+.page-footer {
        margin-top: 0;
    }
}