$roboto: roboto, sans-serif;
$rift: rift, sans-serif;
$max_width: 1366px;
// $orange: #FF7900;
$white: #ffffff;
$black: #000000;
$grey: #ABABAB;

/* Home */
body.cms-index-index {
    .page-title-wrapper {
        display: none;
    }

    .column.main {
        padding: 0;
    }

    // Boots Video Overlay
    .top-green-bar {
        position: absolute;
        top: 106px;
        width: 100%;
        left: 0;

        @media (max-width: 1024px) {
            display: none !important;
        }
    }

    .home-video-wrapper {
        position: relative;
        justify-content: center !important;
        max-height: 500px;
        height: auto;
        overflow: hidden;

        @media (max-width: 1024px) {
            margin-top: 0 !important;
        }

        @media (max-width: 1024px) {
            max-height: unset;
        }
    }

    .home-video-player {
        @media (max-width: 768px) {
            display: none;
        }
    }

    .home-boots-mobile {
        display: none;

        @media (max-width: 768px) {
            display: block;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .home-video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0,0,0,0.4);
        justify-content: center;

        h1 {
            color: rgba(255,255,255,0.7);
            font-size: 15rem;
            line-height: 0.8em;
            font-weight: bold;

            @media (max-width: 1024px) {
                font-size: 6rem;
            }

            @media (max-width: 480px) {
                font-size: 4rem;
            }
        }

        a {
            font-family: $rift;
            background-color: $mxred;
            border-color: $mxred;
            color: $white;
            border-radius: 2px;
            padding: 1.5rem 3rem;
            font-size: 2rem;
            font-weight: bold;
            transition: background-color 0.2s ease;
            text-decoration: none;

            &:hover {
                background-color: $black;
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .aw-rbslider-container {
        box-sizing: border-box;
        width: 100% !important;
        max-width: $max_width;

        @media (min-width: 1024px) {
            margin-top: 0;
            margin-bottom: 0;

            &:first-child {
                margin-top: 2rem;
            }

            &:last-child {
                margin-bottom: 3.6rem;
            }
        }

        .uk-slideshow{
            // height: 568px;
            overflow: hidden;
            background-color: $black;
        }
        *{
            font-family: $rift;
            font-weight: bold;
        }

        h2 {
            font-size: 73px;
            line-height: 1;
            margin-top: 0;
            margin-bottom: 0;
            letter-spacing: 14.6px;
            color: $white;
            text-shadow: 0px 3px 6px #00000052;
        }

        h3 {
            font-weight: 500;
            font-size: 30px;
            line-height: 36px;
            margin-top: 0;
            margin-bottom: 0;
            color: $white;

            @media (min-width: 1024px) {
                font-weight: 700;
                font-size: 22px;
                text-shadow: 0px 3px 6px #0000004D;
            }
        }

        div[data-content-type=row] {
            z-index: 10;
        }

        a.pagebuilder-button-primary {
            background-color: $orange;
            border-color: $orange;
            color: $white;
            border-radius: 2px;
            padding: 8px 35px;
            transition: all 0.2s linear;
            font-size: 16px;

            &:hover {
                border-color: #FFFFFF;
                background-color: $white;
                color: $black;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: $orange;
                z-index: -1;
                opacity: 0;
                transition: opacity 0.2s linear;
            }
        }

        .aw-rbslider-item:hover {

            .pagebuilder-button-primary {
                background-color: $black;
                border-color: $black;
                color: $white;

                &::before {
                    opacity: 1;
                }
            }

            picture img {
                opacity: 0.2;
            }
        }

        p {
            margin-bottom: 0;
        }

        em {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: white;
            font-size: 391px;
            font-style: normal;
            z-index: -1;
            opacity: 0.21;
            letter-spacing: 78.2px;
            text-shadow: 0px 3px 6px #00000052;
            white-space: nowrap;
        }

        picture img {
            opacity: 0.5;
            object-fit: cover;
            transition: opacity 0.2 linear;
        }

        .aw-rbslider-item,
        picture img {
            height: 568px !important;
        }

        ul.uk-dotnav {
            display: flex;
            margin: 0 !important;

            li {
                margin-top: 38px;
                margin-bottom: 38px;
                max-width: 100px;
                padding-left: 5px;
                padding-right: 5px;

                a {
                    background-color: #FFFFFF;
                    border-radius: 0;
                    width: 100px;
                    height: 3px;
                    margin-left: 5px;
                    margin-right: 5px;
                    box-shadow: none;
                }

                &.uk-active a {
                    background-color: #000000;
                }
            }
        }
    }


    .desktop-home-categories,
    .boot-categories-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px){
            display: none;

            &.boot-categories-mobile {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .category {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }

        * {
            font-family: $rift;
            font-weight: bold;
        }

        .category {
            width: 100%;
            max-width: 100%;
            margin-left: 5px;
            margin-right: 5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .wrapper {
                width: 100%;
                height: 315px;
                overflow: hidden;

                a:not(.boots-button) {
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 315px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 42px;
                    line-height: 50px;
                    letter-spacing: 4.2px;
                    color: $white;
                    text-decoration: none;
                    transition: all 0.2s linear;

                    &:hover {
                        color: $orange;
                        transform: scale(1.05);
                    }
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    margin: 28px 19.5px 53px 19.5px;
                    text-align: center;

                    @media (max-width: 767px) {
                        margin: 30px 19.5px 25px 19.5px;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        color: $black;
                        font-size: 16px;
                        line-height: 20px;
                        text-decoration: underline;
                        transition: color 0.2s linear;

                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
        }

        &.boot-categories-mobile {

            .category {

                .wrapper {
                    height: 475px;

                    .boots-link {
                        background-size: cover;
                        background-color: $black;
                        background-position: center;
                        display: flex;
                        height: 100%;
                        flex-direction: column;
                        justify-content: flex-end;
                        text-align: center;
                        align-items: center;
                        transition: all 1s ease;
                        color: $white;

                        h2 {
                            font-size: 4.5rem;
                            font-weight: bold;
                            letter-spacing: 1rem;
                        }

                        p {
                            font-family: $roboto;
                            color: $white;
                        }

                        .boots-button {
                            font-family: $rift;
                            background-color: $mxred;
                            border-color: $mxred;
                            color: $white;
                            border-radius: 2px;
                            padding: 1.5rem 3rem;
                            font-size: 2rem;
                            line-height: 2.4rem;
                            font-weight: bold;
                            transition: background-color 0.2s ease;
                            text-decoration: none;
                            max-width: 50%;
                            margin: 6rem 0 3rem;

                            &.motion {
                                background-color: $white;
                                color: $black;
                            }

                            &.comp {
                                background-color: $black;
                                color: $white;
                            }

                            &:hover {
                                background-color: $black;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    ul.mobile-categories {
        list-style: none;
        padding: 0;
        margin: 0;
        display: none;
        align-items: center;
        justify-content: center;

        li {
            margin: 28px 19.5px 53px 19.5px;
            text-align: center;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                color: $black;
                font-family: $rift;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-decoration: underline;
                transition: color 0.2s linear;

                &:hover {
                    color: $orange;
                }
            }
        }
    }

    .desktop-home-clearance-banner {
        position: relative;
        height: 160px;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $orange;
            opacity: 0.85;
            z-index: 1;
        }

        h2 {
            color: $white;
            text-shadow: #00000052 0px 3px 6px;
            font-size: 42px;
            line-height: 50px;
            letter-spacing: 4.2px;
            font-family: $rift;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 10px;
            z-index: 2;
        }

        a {
            background-color: $black;
            color: $white;
            font-family: $rift;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-shadow: #00000052 0px 3px 6px;
            padding: 5px 49px;
            z-index: 2;
            text-decoration: none;
            transition: all 0.2s linear;

            &:hover {
                background-color: $white;
                color: $orange;
            }
        }
    }

    div[data-aw-rbslider-banner-id='4'] {
        width: 100vw !important;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        margin-bottom: 0;

        img {
            opacity: 1;
        }

        * {
            max-height: 460px;
        }
    }

    .block-new-products.widget {
        margin-top: 40px;
        margin-bottom: 30px;

        h2 {
            font-weight: 700;
            color: $black;
            font-size: 32px;
            letter-spacing: 3.2px;
            text-align: center;
        }

        @media (max-width: 767px) {

            &.widget {
                margin-top: 0;
                margin-bottom: 0;
            }

            h2 {
                margin: 0;
                font-size: 22px;
                letter-spacing: 2.2px;
            }

            .product-item:nth-child(4) ~ .product-item {
                display: none !important;
            }
        }
    }
}

.join-fox-crew-banner {
    background-position: center;
    background-size: cover;
    height: 265px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 3.2px;
        margin-top: 0;
        margin-bottom: 10px;
        color: $white;
    }

    p {
        color: $white;
    }
}

.shop-lifestyle {
    display: none;
    width: 100vw !important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    text-align: center;

    h2 {
        display: none;
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            width: 33.33%;
            height: 460px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-size: cover;

            a {
                color: $white;
                background-color: $black;
                padding: 10px 30px;
                font-weight: bold;
                font-family: $rift;
                text-decoration: none;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.view-articles {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;

    h2 {
        margin-top: 0;
        margin-bottom: 30px;
        font-family: $rift;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
    }
}
@media( min-width: 768px ){
    div[data-aw-rbslider-banner-id='4']{ display: none; }
}

@media(max-width: 767px) {
    body.cms-index-index {
        .aw-rbslider-container {
            width: 100vw !important;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            margin-bottom: 0;

            .uk-slideshow {
                overflow: hidden;

                &[style*='height'] {
                    height: 480px !important;
                }
            }

            .aw-rbslider-item,
            picture img {
                height: 480px !important;
            }

            h2,
            h3 {
                margin-bottom: 0;
            }

            h3 {
                max-width: 302px;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
            }

            em {
                display: none;
            }
        }

        ul.mobile-categories {
            display: none;
        }

        [data-content-type='html'] {
            display: flex;
            flex-direction: column;

            .block-new-products {
                order: -1;
            }

            div[data-aw-rbslider-banner-id='4'] {
                order: 1;
            }

            div[data-aw-rbslider-banner-id='4'] + div {
                order: 2;
            }

            div[data-aw-rbslider-banner-id='4'] + div + div {
                order: 3;
            }

            .block-new-products + div + div {
                order: 4;
            }
        }
    }
    .join-fox-crew-banner {
        display: none;
    }
    .shop-lifestyle {
        display: block;
    }
}

.cms-index-index {

    .product-item-inner {
        display: inline-block !important;
    }

    @media (min-width: 1024px) {

        #maincontent.page-main {
            max-width: 1406px!important;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
}

// Main styles found in category.scss
// Home Page Product Grid
ol.widget-new-grid {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    flex-wrap: wrap;


    @media screen and (min-width: 667px) and (max-width: 1366px) {
        padding: 0 20px;
        box-sizing: border-box;
    }

    li.product-item {
        flex: 1 !important;
        margin-left: 0 !important;
        width: 19.5% !important;

        &:nth-child(5n + 1) {
            margin-left: 0 !important;
        }

        // Drop one off
        @media screen and (min-width: 850px) and (max-width: 1024px) {
            margin-left: 0 !important;
            width: 23% !important;

            &:nth-child(4n + 1) {
                margin-left: 0 !important;
            }

            &:last-child {
                display: none;
            }
        }

        @media screen and (min-width: 667px) and (max-width: 849px) {
            margin-left: calc((100% - 3 * 30%) / 2) !important;
            width: 30% !important;

            &:nth-child(3n + 1) {
                margin-left: 0 !important;
            }

            &:last-child, &:nth-last-child(2) {
                display: none;
            }
        }

        // Drop off another
        @media screen and (max-width: 666px) {
            flex: 1;
            margin-left: calc((100% - 2 * 48%) / 1) !important;
            width: 48% !important;

            &:nth-child(2n + 1) {
                margin-left: 0 !important;
            }

            &:last-child {
                display: none;
            }
        }
    }
}


// Magento Overrides, because
.product-item .action.towishlist:before,
.product-item .action.tocompare:before{
    content: none;
}

.tocompare {
    margin-left: 1rem;
}

.towishlist {
    margin-left: 0.8rem;
}

.block.widget .products-grid .product-item .product-item-inner,
.page-layout-1column .block.widget .products-grid .product-item .product-item-inner {
    margin: 0;
    box-shadow: none !important;
}

.product-item-actions .actions-primary + .actions-secondary > .action {
    height: 30px;
    width: 25px;
    transition: opacity 0.3s;
    svg {
        height: 17px;
        width: 17px;
    }

    &:hover {
        opacity: 0.5;
    }
}

.product-item-actions .actions-primary {
    vertical-align: middle;
    .action.primary {
        background: none;
        border: none;
        width: 30px;
        height: 30px;
        margin: 3px 0 0 0;
        padding: 5px;
        fill: #000;
        transition: opacity 0.3s;
    }
}

.product-item .price-box {
    margin: 0;
    flex: 1;
    width: 40%;
    vertical-align: middle;
    font-family: $rift;
    font-weight: bold;
    font-size: 16px;

    .price {
        font-family: $rift;
        font-weight: bold;
        font-size: 16px;
        line-height: 38px;
    }

    .old-price {
        display: inline;

        .price-label {
            display: none;
        }
    }
}

.zendesk-warranty-button {
    display: block;
    position: fixed;
    bottom:20px;
    right:20px;
    z-index: 9999;
    min-width: 150px;
    border:3px solid $orange;
    color:$black !important;
    text-align: center;
    font-size: 20px;
    font-weight:bold;
    font-family: $rift;
    text-transform: uppercase;
    letter-spacing: 0px;
    text-decoration: none;
    padding:10px;
    border-radius: 50px;
    background-color:$white;
    cursor: pointer;
}

.zendesk-warranty-button:hover{
    background-color: $orange;
    color: $white !important;
}
