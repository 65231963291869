.minicart {
    overflow-y: auto;

    .block-minicart {
        padding: 0;
        box-shadow: 0px 3px 6px #00000029;
        right: -100%;
        transition: right 0.3s;

        .minicart-content-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            bottom: 0;
            background: $white;

            .block-content {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                bottom: 0;
            }
        }

        #btn-minicart-close {
            width: 40px;
            height: 40px;
            position: absolute;
            right: 6px;
            top: 6px;
            background-image: none;
            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-shadow: none;
            font-weight: 400;
            color: #333333;
            display: inline-block;
            text-decoration: none;

            svg {
                fill: $white;
            }
        }


        .your-cart {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            padding-top: 5.3rem;
            padding-left: 3.2rem;
            padding-right: 2.5rem;
            padding-bottom: 2rem;
            background-color: #000;

            h2 {
                margin: 0;
                font-weight: 700;
                color: $white;
                font-size: 32px;
                line-height: 1.2;
            }

            .cart-wrapper {
                display: flex;

                svg {
                    fill: $white;
                    color: $white;
                    margin-top: 4px;
                }

                .badge {
                    padding-left: 9px;
                    padding-right: 9px;
                    -webkit-border-radius: 9px;
                    -moz-border-radius: 9px;
                    border-radius: 9px;
                  }

                  .label-warning[href],
                  .badge-warning[href] {
                    background-color: #c67605;
                  }
                  #lblCartCount {
                    font-size: 12px;
                    background: $orange;
                    color: #fff;
                    vertical-align: top;
                    margin-left: 0;
                    width: 18px;
                    height: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    margin-left: -5px;
                  }
            }

            .items-in-cart-count {
                color: #fff;
                font-size: 24px;
            }
        }

        .free-shipping-wrapper {
            padding: 2rem;

            .free-shipping {
                position: relative;
                background: #eee;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                padding: 1rem;
                height: 100px;

                p {
                    font-family: "Roboto";
                    font-size: 13px;
                    color: $body-color;
                }

                strong {
                    font-weight: bold;
                }

                span {
                    font-weight: bold;
                    color: $orange;
                }

                .continue-shopping {
                    color: $body-color;
                    text-decoration: underline;
                }

                svg {
                    width: 100px;
                    // stupid svg .a class fill
                    .a {
                        fill: #fff;
                        color: #fff;
                    }
                }
            }

            .free-shipping:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: #eee;
                border-width: 15px;
                margin-left: -15px;
            }
        }

        .minicart-items-wrapper {
            margin: 0;
            border: 0;
            padding: 30px 40px;
            height: auto !important;
            overflow-y: auto;

            li.item.product-item.odd.last:first-child {
                padding-top: 0;
            }

            li.item.product-item:last-child {
                padding-bottom: 0;
            }

            .product-item{
                padding: 22px 0;
            }

            .product {
                display: flex;
                justify-content: center;
                width: 100%;
                line-height: 15px;

                .product-image-wrapper {
                    display: flex;
                    flex: 0 20%;
                    align-items: center;

                    .product-image-container {
                        width: 70px;
                    }
                }

                .product-item-details {
                    flex: 0 80%;
                    padding-left: 0;

                    .product-sku-wrapper {
                        p {
                            font-size: 13px;
                            font-family: "Roboto";
                            color: $body-color;
                        }
                    }

                    .product-name-price-wrapper {
                        display: flex;
                        justify-content: space-between;

                        a {
                            font-family: "Roboto";
                            font-weight: bold;
                            font-size: 13px;
                            color: #000;
                        }

                        .price-wrapper {
                            .price-including-tax {
                                margin: 0;

                                .price {
                                    font-family: "Roboto";
                                    font-size: 13px;
                                    color: #000;
                                }
                            }
                        }
                    }

                    .product-details {
                        color: $body-color;
                        font-size: 13px;
                        font-weight: 400;
                        font-family: "Roboto";
                    }

                    .product-qty-actions-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        margin-top: 1rem;
                        height: 35px;

                        .product-item-pricing {
                            .adjust-qty-wrapper {
                                width: 100%;
                                height: 30px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                border: 2px solid $black;

                                .product-qty {
                                    width: 25px;
                                    padding: 0 5px;
                                    margin: 0;
                                    text-align: center;
                                    border: 0;
                                    font-weight: 700;
                                    background: transparent;
                                }

                                button {
                                    border: 0;
                                    padding: 0 5px;

                                    &:hover,
                                    &:focus {
                                        background-color: transparent;
                                    }

                                    svg {
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                        .actions .secondary .action.delete::before{
                            content: none!important;
                        }
                    }
                }
            }
        }

        .totals-wrapper {
            width: 80%;
            margin: 0 auto;
            padding: 22px 0;
            border-top: 1px solid #cccccc;

            > div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;
            }

            span {
                font-family: "Roboto";
                font-size: 13px;
            }

            .total {
                font-weight: bold;

                > .price {
                    font-weight: bold;
                }
            }
        }

        .minicart-footer {
            width: 80%;
            margin: 0 auto 25px;

            .actions {
                justify-content: center;
                text-align: center;
                margin-bottom: 1rem;

                a {
                    font-family: $roboto;
                    font-size: 12px;
                    color: $orange;
                    text-decoration: underline;
                }
            }

            .checkout-button {
                width: 100%;
                padding: 2rem;
                background-color: $black;
                font-family: "Rift";
                font-weight: bold;
                text-transform: uppercase;
                color: #fff;
                border: 0;
                border-radius: 0.2rem;
                display: block;
                width: auto;
                text-align: center;
                border: 2px solid $black;
                &:hover {
                    background: $white;
                    color: $black;
                }
            }

            .payments-accepted-wrapper {
                margin-top: 2rem;

                p {
                    font-family: "Roboto";
                    font-size: 12px;
                    text-align: center;
                    margin-bottom: 1rem;
                }

                .payments-accepted {
                    display: flex;
                    justify-content: center;

                    .mastercard {
                        content: url("../images/mastercard.png");
                        width: 30px;
                        height: auto;
                    }
                    .visa {
                        content: url("../images/visa.png");
                        width: 40px;
                        height: auto;
                    }
                    .afterpay {
                        content: url("../images/afterpay.png");
                        width: 70px;
                        height: auto;
                    }
                    .zip {
                        content: url("../images/zip.png");
                        width: 40px;
                        height: auto;
                    }
                    .paypal {
                        content: url("../images/paypal.png");
                        width: 60px;
                        height: auto;
                    }
                }
            }
        }
    }
}

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.mage-dropdown-dialog {
    display: block!important;
}

.minicart-wrapper.minicart.active #ui-id-1::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
    pointer-events: none;
}

.minicart-wrapper.minicart.active #ui-id-1 {
    position: fixed;
    right: 0;
}

.modal-popup.confirm {
    .modal-inner-wrap {
        height: auto;
        max-width: 450px;
        .modal-header {
            .action-close {
                padding: 0;
                &:before {
                    content: ' '!important;
                    background: no-repeat url(../images/close_loader.svg);
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    left: -35px;
                    background-size: contain;
                    top: 10px;
                    padding: 0;
                }
            }
        }

        .modal-footer {
            margin-top: 0;
        }
    }
}

@media (max-width: 600px) {
    .minicart-wrapper {
        .block-minicart {
            max-width: 100% !important;
            top: 0;
            .cart-wrapper {
                z-index: -1;
            }
            #btn-minicart-close {
                right: 20px;
                top: 33px;

                svg {
                    width: 27px;
                    height: 27px;
                }
            }
            .logo {
                z-index: 999;
            }

            .your-cart {
                padding-top: 3rem;
                width: auto;
            }
        }
    }
}
