.ampickupmsi-loader-container {
  clear: both;
}

.ampickupmsi-main-container {
  background-color: white;
  border: 2px solid black;
  border-radius: 2px;
  padding: 2.3rem;

  @media (max-width: 767.98px) {
    padding: 1.5rem 1.5rem 2rem !important;
  }

  .ampickupmsi-locations {
    display: flex;
    flex-wrap: wrap;
  }

  .ampickupmsi-title {
    display: flex;
    align-items: center;
    margin-left: 0;
    text-transform: uppercase;
    color: black;
    font-family: $rift;
    font-weight: 700;
    font-size: 1.8rem;

    &::before {
      content: '';
      background-image: url(../images/icon-basket.svg);
      width: 32px;
      height: 32px;
      background-size: contain;
      flex-shrink: 0;
      margin-right: 0;
    }
  }

  .ampickupmsi-locations {
    max-height: 181px;
  }

  .ampickupmsi-location {
    padding: 0.842rem 2rem;
    border: solid 2px transparent;
    border-radius: 3px;
    position: relative;
    width: 100%;

    &:hover {
      background-color: #F5F5F5;
    }

    &.active {
      border-color: $orange;
    }

    &.initial-active {
      order: -1;
    }

    .btn-sm {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.3rem;
      padding: 0.3rem 1.5rem;
      margin-left: 1.5rem;
      font-weight: 400;
      border-width: 1px;
    }
  }

  .ampickupmsi-map {
    display: none;
  }

  .ampickupmsi-info {
    font-size: 1.2rem;
    color: black;
    width: 65%;

    span {
      color: inherit;
    }
  }

  .ampickupmsi-stock {
    width: 35%;
  }

  .ampickupmsi-stock-status {
    display: none;
  }

  .ampickupmsi-name {
    font-weight: 700;
  }
}

.ampickup-map-popup {

  .ampickup-content {
    position: relative;
    width: 100%;

    @media (max-width: 767.98px) {
      max-width: 90vw;
      padding: 2.5rem;

      .amlocator-map-container {
        display: flex;
        flex-wrap: wrap;
      }

      .amlocator-search-container,
      .amlocator-store-list,
      .-map {
        width: 100%;
      }

      .amlocator-store-list {
        order: 1;
      }

      .-map {
        position: static !important;
        width: 100%;
        height: 200px !important;
        margin-bottom: 1.4rem;
      }

      .ampickup-locations {
        margin-top: 4.4rem;
        padding-right: 0;

        h2 {
          padding-right: 3rem;
          box-sizing: border-box;
          top: 2.5rem;
          left: 2.5rem;
          right: 2.5rem;
          font-size: 2.6rem;
          box-sizing: border-box;
          padding-right: 3rem;
        }
      }

      .ampickup-close {
        top: 2.5rem;
        right: 2.5rem;
      }
    }

    @media (max-width: 359px) {

      .ampickup-locations {

        h2 {
          font-size: 2.2rem;
        }
      }
    }

    @media (min-width: 768px) {
      max-width: 720px;
      padding: 3rem 37.8rem 4rem 3rem;
    }

    @media (min-width: 1145px) {
      max-width: 1100px;
      padding: 4.1rem 70.8rem 4.1rem 3.7rem;
    }
  }

  .ampickup-close {
    position: absolute;
    top: 30px;
    right: 31px;
    opacity: 1;
    z-index: 1;

    &::before,
    &::after {
      width: 1px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .search-container {
    display: flex;
    margin-bottom: 1rem;

    .btn-use-current-location {
      flex-shrink: 0;
      padding: 0;
      width: 37px;
      height: 40px;
      border-color: #ddd;
      margin-right: -1px;

      &:hover,
      &:focus {
        background-color: #ddd;
      }
    }

    .amlocator-text {
      height: 40px;
      border-color: #ddd;
    }
  }

  .amlocator-select {
    height: 40px;
    margin-bottom: 1rem;
  }

  .amlocator-button.-nearby {
    width: 100%;
    height: 40px;
    margin-bottom: 1rem;
    background-color: rgb(0, 0, 0);
    color: white;
    font-family: $rift;
    font-size: 1.6rem
  }

  .ampickup-locations {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 1rem;
    margin-top: 5.4rem;
  }

  .ampickup-locations h2,
  .ampickup-title {
    position: absolute;
    top: 3.125rem;
    left: 3.7rem;
    margin-top: 0;
    font-family: $rift;
    font-weight: 700;
    font-size: 3.2rem;
  }

  .ampickup-title {
    margin-top: -1.5rem;
  }

  .amlocator-main-container .amlocator-map-container .amlocator-block.-map {
    position: absolute;
    top: 95px;
    left: 400px;
    bottom: 41px;
    right: 36px;
    display: block !important;
    height: auto;

    @media (min-width: 768px) {
      top: 85px;
      left: 360px;
      bottom: 40px;
      right: 30px;
    }

    @media (min-width: 1100px) {
      top: 95px;
      left: 400px;
      bottom: 41px;
      right: 36px;
    }
  }

  .amlocator-map {
    height: 100%;
  }

  .amlocator-store-desc {

    + .amlocator-store-desc {
      margin-top: 1rem;
    }
  }

  .amlocator-store-information {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 2.1rem 1.8rem;
    font-size: 1.2rem;

    p {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &.directions {
        font-size: 0;

        &::before {
          content: 'Directions';
          font-size: 1.2rem;
        }
      }
    }

    .amlocator-button.-pickup {
      position: absolute;
      top: 1.7rem;
      right: 1.6rem;
      margin: 0 !important;
      font-family: $rift;
      font-weight: 700;
      font-size: 1.3rem;
      border-radius: 2px;
      border-color: black !important;
      background-color: black !important;
      color: white !important;
    }
  }

  .-active .amlocator-store-information {
    border-color: $orange;
    border-width: 2px;
    padding: 2rem 1.7rem;

    .amlocator-button.-pickup {
      top: 1.6rem;
      right: 1.5rem;
    }
  }

  .amlocator-title {
    font-weight: 700;
    font-size: 1.4rem;

    + br {
      display: none;
    }
  }
}

.ampickup-store-container.-checkout {

  .label {
    font-weight: normal;

    @media (min-width: 1200px) {
      margin-bottom: 2.9rem !important;
    }
  }

  .ampickup-choosemap {
    background-color: black;
    border-color: black;
    color: white;
    font-family: $rift;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 2px;
    padding: 0.56rem 4.1rem;

    @media (min-width: 1200px) {
      margin-left: auto;
    }
  }
}

.ampickup-details-container {

  .pickup-content:not(:empty) {
    margin-top: 3rem;
    padding: 3rem;
    background-color: rgba(221, 221, 221, 0.29);
    color: black;
    box-sizing: border-box;

    @media (max-width: 767.98px) {
      margin-top: 2rem;
      padding: 2rem;
    }

    &::before {
      content: 'Your chosen Click and Collect store is:';
      display: block;
      margin-bottom: 2rem;

      @media (max-width: 767.98px) {
        margin-bottom: 1rem;
      }
    }
  }

  .store-info {
    max-width: 340px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    background-color: white;
    padding: 2rem 1.8rem;
    box-sizing: border-box;
    font-size: 1.2rem;

    .store-name {
      display: block;
      margin: 0 0 0.5rem;
      font-family: $roboto !important;
      font-weight: 700;
      font-size: 1.4rem !important;
      line-height: 1.5;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }

      &.directions {
        font-size: 0;

        &::before {
          content: 'Directions';
          font-size: 1.2rem;
        }
      }
    }

    .website {
      word-break: break-all;
    }

    br:last-child {
      display: none;
    }
  }
}

.select-holder {
  flex-grow: 1;
  margin-bottom: 1rem;
}

.select-location-holder {
  max-width: 400px;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
  padding-left: 0;
  padding-right: 2rem;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;

  li {
      padding: 0.7rem 2rem;
      border: 2px solid transparent;
      width: 100%;

      &:hover {
          background-color: #f5f5f5;
          cursor: pointer;
      }

      &.initial-active {
          order: -1;
      }

      &.active {
        border: 2px solid $orange;
        border-radius: 3px;
      }
  }
}

.ampickup-field.field.-store .ampickup-wrapper {
  align-items: flex-start;
}

.ampickup-separator.-checkout {
  display: none;
}
