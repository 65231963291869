// amrelated grid
.amrelated-grid-wrapper.amrelated-grid-wrapper {
    width: 100%;
    margin-bottom: 0;

    .products-grid .slick-slider{
        max-height: unset;
        margin-top:20px;
        .slick-arrow{
            background: none !important;
            &.slick-prev{
                margin-left: 20px;
            }
        }
        .slick-slide > div{
            height: 100%;
        }
    }

    .product-items.product-items {
        display: flex;
        justify-content: center;

        @media (max-width: 767.98px) {
            flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .block-title.block-title {
        margin-bottom: 3rem;
        strong {
            font-weight: 700 !important;
        }
    }

    .product-item.product-item {
        border: 3px solid #F0F0F0 !important;
        padding: 0;
        margin: 0 auto !important;
        width: 100%;
        border-radius: 3px;
        box-shadow: none;
        overflow: hidden !important;
        height: auto;
        display: flex;
        flex-direction: column;
        z-index: 0;

        @media (max-width: 767.98px) {
            flex: 1 1 50%;
            margin: 0 8px 16px !important;
            width: auto;
            max-width: 224px;
            flex-basis: calc(50% - 20px);
        }

        @media (min-width: 768px) {
            margin: 0 !important;

            + .product-item {
                margin-left: 13px !important;
            }
        }

        &:hover {
            border: inherit;
            box-shadow: inherit;
            border-color: $borderColor !important;

            .product-item-info {
                border: none;
                box-shadow: none;
            }
        }

        .product-item-info {
            display: flex !important;
            flex-direction: column !important;
            padding: 0 !important;
            position: static !important;
            flex-grow: 1;

            &:hover {
                border: none;
                box-shadow: none;
            }

            .product-item-details {
                margin: 0;
                padding: 0 20px 15px;
                font-family: $rift;
                text-align: left;
                color: $black;
                flex-direction: column;
                display: flex;
                flex-grow: 1;

                .product-item-name.product-item-name {
                    font-family: $rift;
                    white-space: normal;
                    text-overflow: initial;
                    line-height: 1em;

                    .product-item-link {
                        font-family: $rift;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .amrelated-attributes {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 1.2em;
                }

                .stock-level-colour {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0;
                }

                .stock-levels-wrapper {
                    display: flex;
                    align-items: center;
                    flex: 0;
                }

                .stock-level-text {
                    font-family: $roboto;
                    font-size: 12px;
                }

                a {
                    color: $black;
                    font-size: 20px;
                    font-weight: bold;
                    font-style: italic;
                }

                .price-container.price-final_price {
                    font-family: $rift;
                    font-weight: bold;
                    line-height: 1.2em;

                    .price-wrapper {
                        border-bottom: none;
                        padding: 0;
                    }

                    .rrp-price::before {
                        content: 'RRP';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                    }

                    .special-price {
                        color: $red;
                        display: inline-block;
                    }

                    .price {
                        font-size: 1.6rem;
                    }

                    .bundle-strike {
                        text-decoration: line-through;
                        color: #999;
                        padding-right: 10px;
                    }

                    .wholesale-price::before {
                        content: 'WHOLESALE';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                        text-decoration: none;
                    }
                }

                .actions-primary {
                    display: block;

                    [data-role="tocart-form"]{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;

                        input {
                            height: 44px;
                            flex: 1 1 32%;
                        }
                    }
                }

                .action.primary {
                    position: relative;
                    z-index: 2;
                    font-family: $rift !important;
                    display: block;
                    padding: 10px 15px !important;
                    margin: 0 !important;
                    border-radius: 2px;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: uppercase;
                    border: 2px solid $buttonColor;
                    color: $white;
                    background: $buttonColor;
                    height: auto;
                    flex: 1 1 62%;
                }

                .action.primary:hover {
                    background: $white;
                    color: $buttonColor;
                }

                .product-item-actions {
                    margin: auto 0 0;
                    padding-top: 1rem;
                }
            }
        }
    }
}

// amrelated packs
.amrelated-pack-wrapper.amrelated-pack-wrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 1023.98px) {
        flex-direction: column;
    }

    .amrelated-pack-list {
        flex-wrap: nowrap;
        flex: 0 1 auto;
        max-width: none;

        @media (max-width: 1023.98px) {
            flex-wrap: wrap;

            .amrelated-pack-item.amrelated-pack-item {
                flex: 0 1 calc(50% - 18px);
                max-width: none;
                margin-right: 18px;
                margin-bottom: 18px;

                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
    }

    .amrelated-title {
        display: none;
    }

    .amrelated-plus, .amrelated-equal {
        display: none;
    }

    .amrelated-background.amrelated-background {
        display: none;
    }

    .amrelated-content-wrapper {
        justify-content: center;
        background: none;
        margin-top: 5px;

        @media (max-width: 1023.98px) {
            flex-wrap: wrap;
            max-width: 614px;
        }
    }

    .amrelated-pack-items {

        @media (min-width: 1440px) {
            max-width: 100%;
        }
    }

    .amrelated-summary-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;

        @media (min-width: 1024px) {
            width: 100%;
            max-width: 139px;
            flex: 0 1 139px;

            .action.primary {
                background-color: $green;
                border-color: $green;
            }
        }

        @media (max-width: 1023.98px) {
            max-width: 100%;
            flex: 0 0 100%;
            flex-direction: column;
            padding: 0;

            .action.primary {
                background-color: $green;
                border-color: $green;
            }
        }

        .main.price {
            font-size: 41px;
            font-family: $rift;
        }

        .action.primary {
            width: 100%;
            height: 60px;
        }
    }

    .amrelated-price-wrapper {
        border: none;
        height: auto;
        padding: 0;
        margin-bottom: 22px;

        @media (max-width: 1023.98px) {
            margin-bottom: 9px;
        }
    }

    .amrelated-pack-item.amrelated-pack-item {
        border: 3px solid #F0F0F0;
        border-bottom: 3px solid #F0F0F0;
        padding: 0;
        margin: 0 13px 0 0;
        width: 100%;
        flex: 0 1 194px;
        border-radius: 3px;

        &:not(.-selected) {
            opacity: 1;

            .amrelated-info .amrelated-photo-container .amrelated-checkbox + .amrelated-label.amrelated-label {
                background: #EEEEEE;
                border-color: #eee;
            }
        }

        &.-selected {
            border: 3px solid $orange;
            border-bottom: 3px solid $orange !important;
        }

        .amrelated-info {
            flex-direction: column;
            width: 100%;

            .amrelated-photo-container.amrelated-photo-container {
                width: calc(100% - 40px);
                height: 150px;
                margin: 0;
                padding: 0 20px;
                border: none;

                .amrelated-checkbox + .amrelated-label {
                    top: -3px;
                    background: $orange;
                    border: none;
                    left: auto;
                    right: -3px;
                    width: 37px;
                    height: 37px;

                    &::before {
                        content: '';
                        background-color: white;
                        display: block;
                        transform: rotate(45deg);
                        height: 2px;
                        width: 10px;
                        position: absolute;
                        left: 8px;
                        top: 18px;
                    }
                    &::after {
                        content: '';
                        background-color: white;
                        display: block;
                        transform: rotate(135deg);
                        height: 2px;
                        width: 15px;
                        position: absolute;
                        top: 15px;
                        left: 14px;
                    }
                }
            }

            .amrelated-details {
                margin: 0;
                padding: 0 20px 15px;
                font-family: $rift;
                text-align: left;
                color: $black;
                flex-direction: column;

                .amrelated-attributes {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 1.2em;
                }

                a {
                    color: $black;
                    font-size: 20px;
                    font-weight: bold;
                    font-style: italic;
                }

                .price-container.price-final_price {
                    font-family: $rift;
                    font-weight: bold;
                    line-height: 1.2em;

                    .price-wrapper {
                        border-bottom: none;
                        padding: 0;
                    }

                    .rrp-price::before {
                        content: 'RRP';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                    }

                    .special-price {
                        color: $red;
                        display: inline-block;
                        padding-left: 10px;
                    }

                    .price {
                        font-size: 1.6rem;
                    }

                    .bundle-strike {
                        text-decoration: line-through;
                        color: #999;
                    }

                    .wholesale-price::before {
                        content: 'WHOLESALE';
                        padding-right: 4px;
                        color: #666;
                        font-size: 12px;
                        text-decoration: none;
                    }
                }
            }

            .stock-level-wrapper {
                padding-top: 0;
            }

            .stock-level-colour {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
            }

            .stock-levels-wrapper {
                display: flex;
                align-items: center;
            }

            .stock-level-text {
                font-family: $roboto;
                font-size: 12px;
            }

            .price-box {
                margin-top: 0;
            }
        }

        .amrelated-discount {
            display: inline-block;
            position: static;
            background: none;
            padding: 0;
            color: $red !important;
            font-size: 16px;

            .price.price {
                display: inline-block;
                background: none;
                color: inherit !important;
                font-family: $rift;
                font-weight: 700;
                font-size: inherit;
            }
        }
    }
}