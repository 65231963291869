.amlocator-index-index {
    padding-left: 0 !important;
    padding-right: 0 !important;

    #maincontent {
        max-width: 1500px !important;
        padding: 0;
    }

    .pac-container {
        border: none !important; // not sure what this is
    }

    .page-title-wrapper {
        display: none;
    }
}

.amlocator-index-index, .catalog-product-view {
    .hidden {
        display: none;
    }

    .page-main {
        max-width: 1366px !important;

        .amasty_locator_filter {
            position: relative;
            z-index: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 1366px;
            padding: 2rem;
            color: $white;
            font-size: 1.6rem;
            font-weight: 400;
            background-color: #848484;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../images/fox-stockist-image.png);

            @media (min-width: 1024px) {
                padding-left: 8rem;
                padding-right: 8rem;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                background-color: $black;
                opacity: 0.6;
            }

            .header-wrapper {
                margin: 0 auto;
                padding: 0 2rem 2rem;
            }

            .block-title {
                text-align: center;

                h1 {
                    margin: 0;
                    font-family: $rift;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 4rem;
                    line-height: 1;
                }

                p {
                    font-family: $roboto;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    font-size: 1.2rem;
                    font-weight: 200;
                    color: inherit;
                }
            }
        }

        .filter-location-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 542px;
            margin: 2rem auto;

            @media (max-width: 600) {
                max-width: 560px;
                margin: 2rem auto;

                .filter-wrapper {
                    flex: 1 0 auto;
                    margin: 0 6px;
                }
            }

            .filter-wrapper {
                cursor: pointer;

                @media (max-width: 600px) {
                    flex: 1 0 auto;
                    margin: 0 6px;

                    .content-wrapper {
                        margin: 0 6px !important;
                    }
                }
                input {
                    display: none;
                }

                .content-wrapper {
                    display: flex;
                    align-items: center;
                    padding: 0 1.5rem;

                    @media (min-width: 601px) {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    svg {
                        width: 50px;
                        margin-right: 10px;

                        @media (max-width: 600px) {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    p {
                        font-family: $rift;
                        font-weight: bold;
                        color: inherit;
                        font-size: 26px;
                        margin-bottom: 0;
                        user-select: none;

                        @media (max-width: 600px) {
                            font-size: 18px;
                        }
                    }
                }

                &.checked {
                    background-color: $white;
                    color: $black;
                    border: 2px solid $black;
                    border-radius: 0.25rem;
                }

                &.not-checked {
                    background: transparent;
                    border: 2px solid $black;
                    border-radius: 0.25rem;
                }
            }
        }

        .search-wrapper {
            display: flex;
            justify-content: center;
            height: 45px;
            width: 100%;

            @media (max-width: 600px) {
                padding: 0 6px;
            }

            .amlocator_input {
                margin-top: 0;
            }

            .location {
                flex-grow: 1;

                .location-input {
                    font-family: $roboto;
                    font-size: 1.3rem;
                    background: url('../images/pin-black.svg') no-repeat scroll left 13px center;
                    background-size: 15px 20px;
                    padding-left: 5.5rem;
                    background-color: #FFF;
                    margin-top: 0;
                    border: 1px solid #ddd;
                    border-radius: 0.3rem 0 0 0.3rem;
                    width: 100%;
                    height: 100%;

                    @media (max-width: 767.98px) {
                        padding-left: 4.5rem;
                    }

                    &::placeholder, &::-ms-input-placeholder {
                        font-family: inherit;
                        font-size: inherit;
                        color: inherit;
                    }
                }
            }

            .distance {
                width: 20%;
                max-width: 100px;

                .radius-select {
                    border: 1px solid #ddd;
                    border-left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                }
            }

            .search {
                button {
                    width: 44px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: -1px;
                    background-color: #000;
                    height: 100%;
                    border-top-right-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                    border: 1px solid black;
                    color: white;
                    transition: all .2s linear;

                    &:hover {
                        background: white;
                        border-color: #ddd;
                        color: black;
                    }

                    svg path {
                        fill: currentColor;
                    }
                }
            }
        }

        .search-button-mobile {
            display: none;
        }

        .store-locator-results {
            display: flex;
            width: 100%;

            .map {
                flex: 50%;
                max-height: 600px;
                overflow-y: hidden;
            }

            .stores {
                width: 100%;
                font-family: $rift;
                padding-left: 3rem;
                padding-right: 3rem;

                @media (min-width: 1400px) {
                    padding-left: 6.7rem;
                    padding-right: 6.7rem;
                }

                @media (max-width: 1023.98px) {
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }

                .breadcrumbs {
                    margin: 3rem 0 0 !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;

                    .item.item:not(:last-child):after {
                        padding: 0 0.2rem;
                    }

                    a:hover {
                        text-decoration: none !important;
                    }

                    @media (max-width: 767.98px) {
                        display: none;
                    }
                }

                .stores-info {
                    margin-top: 3rem;
                    padding: 0 0 2rem;

                    @media (min-width: 768px) {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid black;
                        margin-bottom: 4rem;
                    }

                    .location-text {
                        font-weight: 700;
                    }

                    h2 {
                        margin-top: 0;
                        margin-bottom: 1rem;
                        font-weight: 700;
                        font-size: 3.2rem;
                        color: black;

                        @media (max-width: 767.98px) {
                            text-align: center;
                        }
                    }

                    h3 {
                        font-size: 1.4rem;

                        @media (max-width: 767.98px) {
                            text-align: center;
                        }
                    }

                    p {

                        @media (max-width: 767.98px) {
                            text-align: center;
                        }

                    }

                    a {
                        font-size: 18px;
                        margin-top: auto;
                        margin-bottom: auto;
                        font-weight: bold;
                        text-transform: uppercase;

                        span {
                            color: $orange;
                        }
                    }

                    button {
                        font-family: $rift;
                        font-weight: 700;
                        color: #fff;
                        background-color: #000;
                        padding: 1.1rem 3.3rem;
                        border-radius: 2px;
                        font-size: 16px;
                    }

                    .stores-filters {
                        font-family: $roboto;

                        @media (max-width: 767.98px) {
                            width: calc(100% + 1.5rem);
                            overflow-x: auto;
                            scroll-snap-type: x mandatory;
                            padding-top: 0.25rem;
                            padding-bottom: 1rem;
                        }

                        &,
                        ul,
                        a {
                            display: flex;
                            align-items: center;
                        }

                        ul {
                            list-style: none;
                            margin-bottom: 0;
                            padding-left: 1rem;

                            @media (max-width: 767.98px) {
                                margin: auto;
                                padding-left: 0;
                                padding-right: 1.5rem;
                                width: max-content;

                                li:first-child {
                                    margin-left: 0;
                                }
                            }
                        }

                        li {
                            margin-left: 1rem;
                            margin-bottom: 0;
                            white-space: nowrap;

                            @media (max-width: 767.98px) {
                                width: max-content;
                                scroll-snap-align: start;
                            }
                        }

                        a {
                            color: inherit;
                            text-transform: none;
                            background: #F5F5F5;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            font-size: 1.3rem;
                            padding: 0.1rem 1.5rem 0.1rem 1.1rem;
                            font-weight: 400;

                            &.active {
                                border-color: #000;
                            }

                            img {
                                margin-right: 0.5rem;
                            }
                        }

                        b {

                            @media (max-width: 767.98px) {
                                display: none;
                            }
                        }
                    }
                }

                .store-blocks {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0.3rem 0;

                    @media (min-width: 1024px) {
                        overflow-y: auto;
                        max-height: 600px;
                    }

                    @media (min-width: 1200px) {
                        padding-left: 2rem;
                    }

                    @media (min-width: 1400px) {
                        padding-left: 5.25rem;
                    }

                    .block-wrapper {
                        flex: 0 100%;
                        padding-left: 0.95rem;
                        padding-right: 0.95rem;
                        box-sizing: border-box;
                        margin-bottom: 1.9rem;

                        @media (min-width: 550px) {
                            flex: 0 50%;
                        }

                        @media (min-width: 768px) {
                            flex: 0 33.333%;
                        }

                        @media (min-width: 1024px) {
                            flex: 0 25%;
                        }

                        .block {
                            position: relative;
                            display: flex;
                            border-radius: 3px;
                            margin-bottom: 0;
                            height: 100%;
                            padding: 2.1rem 5.6rem 2.1rem 1.8rem;
                            box-sizing: border-box;
                            box-shadow: 0px 3px 6px rgba($black, 0.16);

                            @media (max-width: 767.98px) {
                                padding: 2.5rem 5.6rem 2.5rem 1.9rem;
                            }

                            .brands-sold {
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 1rem 1.6rem 0 0;

                                @media (max-width: 767.98px) {
                                    padding: 0.7rem 1.6rem 0 0;
                                }

                                .filter-icon {
                                    flex: 0 0 3.6rem;
                                    margin-bottom: 0.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .store-info {
            word-break: break-word;
            padding: 0;
            font-size: 1.2rem;
            line-height: 1.6;

            .address-container,
            .phone-container,
            .link-container {
                display: flex;
                margin-bottom: 0.6rem;

                &::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 0.6rem;
                    display: none;
                }
            }

            .address-container::before {
                background-image: url('../images/icon-location.svg');
            }

            .phone-container::before {
                background-image: url('../images/icon-phone.svg');
            }

            .link-container::before {
                background-image: url('../images/icon-web.svg');
            }

            .pickup-container {
                font-family: $roboto;
                color: $orange;
            }

            .store-name {
                font-family: $roboto !important;
                font-weight: 700;
                font-size: 1.4rem;
                margin-top: 2px;
                margin-bottom: 1rem;
                line-height: 1.1;
                color: black;
                padding-right: 7rem;
            }

            .address {
                font-family: $roboto;
                font-size: 12px;
                margin-bottom: 0;

                span {
                    // font-weight: 700;
                }
            }

            .phone {
                margin-bottom: 0;
                font-family: $roboto;
            }

            .directions,
            .website {
                font-family: $roboto;
                font-weight: 400;
                cursor: pointer;
                color: inherit;
                text-decoration: underline;
                text-transform: initial;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .gm-style .gm-style-iw-t {
            &:after {
                box-shadow: none;
                margin-top: -1px;
                height: 25px;
                width: 25px;
                border-bottom: 3px solid black;
                border-left: 3px solid black;
            }
        }
        .gm-style {

            .gm-style-iw-d {

                > div {
                    font-size: 0;
                }

                .store-info {
                    line-height: 1.2;
                }
            }

            .gm-style-iw-c {
                border: 3px solid black;

                & * {
                    margin: 5px 0;
                }
            }
        }
    }
}

.storelocator-container {
    display: flex;
    margin-top: 0.3rem;

    #amasty_locator_block {
        flex: 0 0 41.4%;

        @media (max-width: 767.98px) {
            flex: 0 0 100%;
            height: 250px;
        }
    }

    .map {
      flex: 0 0 58.6%;
      padding-left: 0.3rem;

      @media (max-width: 767.98px) {
          display: none;
      }

      .gm-style-cc {
        display: none;
      }

      .gm-style img[style*='150px'] {
        width: 34.4px !important;
        height: 34.4px !important;
        margin: auto !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .amasty_locator_filter {
        height: 100%;
        box-sizing: border-box;
    }

    .amlocator-map-canvas {
        height: 400px;
      }
}

.global-store-toggler {
    display: flex;
    align-items: center;
    position: relative;
    color: inherit;
    padding: 0 2rem;
    transition: none;
    height: 100%;
    box-sizing: border-box;
    border: none !important;
    border-radius: 0;
    margin-right: -1rem;
    font-size: 1.4rem;
    white-space: nowrap;

    @media (min-width: 1200px) {
        padding: 0 3rem;
    }

    &:hover {
        color: $orange;
    }

    svg {
        margin-right: 0.5rem;
    }

    &.set-store-toggler {

        @media (max-width: 1023.98px) {
            width: 50%;
            justify-content: center;
            font-size: 2rem;
        }

        @media (max-width: 767.98px) {
            width: auto;
            justify-content: flex-start;
            padding: 1rem 0;
        }
    }

    &.active {
        background-color: $orange;
        color: $white;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-color: $orange;
        }

        &::after {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: calc(100vh);
            background: rgba(0, 0, 0, 0.5);
            z-index: -2;
            opacity: 1;
            transition: opacity 0.15s linear 0.2s;
        }

        &.set-store-toggler {
            background: none;

            &::before {
                display: none;
            }

            &::after {
                z-index: 1;
            }
        }
    }
}

.global-store-toggler-mob {
    margin-left: 0.25rem;
    padding: 0.3rem 1.5rem;
    transform: translateY(2px);

    @media (min-width: 1024px) {
        display: none;
    }
}

.global-store-form {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    flex: 0 1 471px;
    max-width: 40%;
    margin: auto;

    @media (max-width: 1023.98px) {
        position: absolute;
        top: 106px;
        left: 2.5rem;
        right: 2.5rem;
        max-width: none;
        width: auto;

        input {
            width: auto;
        }

        .btn.btn.btn {
            flex-shrink: 1;
        }
    }

    input {
        flex: 1 0 auto;
        height: auto;
        border-radius: 0.3rem 0 0 0.3rem;
        background: url('../images/pin-black.svg') no-repeat scroll left 13px center;
        background-size: 15px 20px;
        padding-left: 5.5rem;
        background-color: white;

        @media (max-width: 767.98px) {
            padding-left: 4.5rem;
        }
    }

    .btn.btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0.3rem 0.3rem 0;
        border: 1px solid #000;
        background-color: #000;
        color: #fff;
        width: 44px;
        height: 40px;
        padding: 0;
        margin-left: -1px;
        box-sizing: border-box;
        flex-shrink: 0;

        &:hover {
            background: #fff;
            border-color: #ddd;
            color: #000;
        }

        svg path {
            fill: currentColor;
        }
    }
}

.global-store-container {
    display: flex;
    align-items: center;
    max-width: 1406px !important;
    width: 100%;
    margin: auto;
    padding: 0 !important;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-weight: 400;

    .offcanvas-body & {

        @media (max-width: 1023.98px) {
            padding-top: 3.4rem !important;
        }
    }

    @media (min-width: 1024px) {
        padding: 0 10px 0 20px !important;
    }

    @media (min-width: 1460px) {

        .pagebuilder-column-group {
            margin: 0 -4rem;
        }

        .pagebuilder-column {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
    }

    h3 {
        font-size: 2.6rem;
    }

    a {
        color: inherit;
    }

    .pagebuilder-column-group {

        @media (max-width: 900px) {
            flex-wrap: wrap;

            .pagebuilder-column {
                flex: 0 0 50%;
                width: 50% !important;
                align-self: flex-start !important;
                justify-self: flex-start !important;
            }
        }
    }

    .add-border {

        @media (min-width: 1024px) {
            border-right: 1px solid rgba(112, 112, 112, 0.13) !important;
        }
    }

    .stockist-map {

        @media (max-width: 1023.98px) {
            display: none !important;
        }

        @media (min-width: 1024px) {
            position: relative;
            align-self: flex-start !important;

            p {
                font-family: $rift;
                font-size: 2.3rem;
                font-weight: 700;
                max-width: 200px;
                width: 100%;
                margin: auto;
                position: absolute;
                top: 42%;
                left: 52%;
                transform: translate(-50%,-50%);
                color: black;
                text-align: center;
              }
        }
    }
}

.find-store-global-label {
    flex-shrink: 0;
    font-family: $rift;
    font-weight: 700;
    font-size: 3.2rem;
    text-transform: uppercase;

    @media (max-width: 767.98px) {
        font-size: 2.9rem;
    }
}

.find-store-global {
    font-family: $roboto;
    text-transform: none;

    @media (min-width: 1024px) {
        margin-top: 3.5rem;
    }

    @media (max-width: 1023.98px) {

        &:not(.show) {
            transform: translateX(100%);
        }
    }

    .offcanvas-header {
        background-color: $orange;
        color: white;
    }
}

.set-store-global {

    .offcanvas-header {
        background-color: black;
        color: white;

        .offcanvas-title {
            font-family: $rift;
            font-weight: 700;
            font-size: 3.2rem;
        }
    }

    .btn-sm {
        font-size: 1.3rem;
        padding: 0.3rem 1.5rem;
        margin-left: 1.5rem;
        font-weight: 400;
        border-width: 1px;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;

        @media (min-width: 768px) {
            opacity: 0;
        }
    }

    @media (min-width: 768px) {

        li:hover {
            background-color: #f5f5f5;

            .btn-sm {
                opacity: 1;
            }
        }
    }

    .offcanvas-body {
        padding: 3.25rem;

        p {
            margin-bottom: 1.7rem;
            line-height: 1.3;
        }
    }

    .global-store-form {
        position: static;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;

        input {
            flex: 1 1 auto;
            padding-left: 1.5rem;
            background: none;
            border-color: #ddd;
        }

        .btn.btn {
            width: auto;
            height: 48px;
            padding: 0 2.7rem;
        }
    }

    .btn-uselocation {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        color: inherit;
        font-family: $roboto;
        font-size: 1.2rem;
        padding: 0;
        text-transform: none;
        font-weight: normal;
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    .address-list {
        border-top: 1px solid #ddd;
        margin-top: 2.4rem;
        margin-bottom: 0;
        padding-top: 2.5rem;
        padding-left: 0;
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        flex-wrap: wrap;

        li {
            padding: 0.7rem 2rem;
            border: solid 2px transparent;
            border-radius: 3px;
            width: 100%;
            position: relative;
            padding-right: 90px;

            &.active {
                border-color: $orange;
            }

            &.initial-active {
                order: -1;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
