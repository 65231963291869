@charset "UTF-8";
/* Colours */
/* Components */
.custom-radio {
  padding-left: 30px;
  height: 30px;
  position: relative; }
  .custom-radio input {
    z-index: -1;
    display: none; }
  .custom-radio label {
    line-height: 30px;
    font-weight: 700 !important; }
    .custom-radio label:before {
      width: 28px;
      height: 28px;
      display: block;
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #231F204D; }
    .custom-radio label span {
      margin-left: 2rem; }
  .custom-radio input:checked ~ label:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat; }
  .custom-radio input:checked ~ label:after {
    background-color: #fff; }

.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="password"],
.custom-form select {
  height: 40px;
  border: solid 1px #DDDDDD;
  border-radius: 2px;
  padding-left: 15px; }

.custom-form label {
  font-size: 13px; }

.custom-form .input-group {
  display: flex;
  flex-direction: row !important; }
  .custom-form .input-group .input-group-prepend {
    width: 40px;
    height: 40px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right: none;
    display: flex;
    box-sizing: border-box; }
    .custom-form .input-group .input-group-prepend .input-group-text {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      justify-content: space-around; }
  .custom-form .input-group .input-group-prepend ~ input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .custom-form .input-group.password-with-meter {
    flex-wrap: wrap; }
    .custom-form .input-group.password-with-meter input {
      width: calc(100% - 40px); }
    .custom-form .input-group.password-with-meter #password-strength-meter-container {
      width: 100%; }
  .custom-form .input-group.prepend-with-verification {
    flex-wrap: wrap; }
    .custom-form .input-group.prepend-with-verification input {
      width: calc(100% - 40px); }
    .custom-form .input-group.prepend-with-verification div.mage-error {
      width: 100%; }
  .custom-form .input-group div.mage-error {
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1.2;
    font-size: 11px;
    margin-top: 2px; }

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    height: auto;
    margin-bottom: 0;
    padding: 10px 15px;
    font-size: 13px;
    line-height: 20px;
    border: none; }
    .input-group .form-control:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .input-group .form-control:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px; }

.input-group-append,
.input-group-prepend {
  display: flex; }

.input-group-prepend {
  margin-right: -1px; }
  .input-group-prepend .btn,
  .input-group-prepend .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-append {
  margin-left: -1px; }
  .input-group-append .btn,
  .input-group-append .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.breadcrumbs {
  display: block;
  width: 100% !important;
  max-width: calc(100% - 40px) !important;
  padding: 0 20px !important;
  margin-top: 25px;
  margin-bottom: 25px;
  box-sizing: border-box; }
  .breadcrumbs .item.item:not(:last-child):after {
    content: '/';
    font-family: "Roboto", sans-serif;
    margin: 0; }
    @media (max-width: 767px) {
      .breadcrumbs .item.item:not(:last-child):after {
        line-height: 20px; } }
  @media (max-width: 767px) {
    .breadcrumbs .item.item {
      font-size: 11px; } }
  .breadcrumbs a {
    color: #FF7900 !important;
    text-decoration: underline !important; }
  .breadcrumbs a,
  .breadcrumbs strong {
    font-family: "Roboto", sans-serif;
    font-weight: normal; }

.products-grid {
  margin-top: 0; }
  .products-grid .slick-slider {
    padding-top: 0;
    margin-top: 0;
    position: relative !important;
    max-height: 350px; }
    .products-grid .slick-slider .slick-list {
      margin-left: 25px;
      margin-right: 25px; }
    .products-grid .slick-slider .slick-arrow {
      height: 100%;
      top: 0;
      margin: 0;
      padding: 0;
      width: 50px;
      z-index: 1;
      background: none; }
      .products-grid .slick-slider .slick-arrow.slick-next {
        right: -25px;
        top: 50%; }
      .products-grid .slick-slider .slick-arrow.slick-prev {
        left: -25px;
        transform: none; }
      .products-grid .slick-slider .slick-arrow.slick-next::before, .products-grid .slick-slider .slick-arrow.slick-prev::before {
        background-image: url(../images/right-dark.svg); }
  .products-grid div.products.list.items.product-items {
    display: inline-block;
    width: 100%;
    margin-left: 0;
    text-align: left; }
    .products-grid div.products.list.items.product-items .wrapper {
      padding: 0 17px 0 17px; }
    .products-grid div.products.list.items.product-items div.item.product.product-item {
      margin: 0;
      padding: 0 !important;
      width: 240px;
      margin-bottom: 25px;
      height: auto;
      text-align: left; }
      .products-grid div.products.list.items.product-items div.item.product.product-item:hover .product-item-info {
        position: relative;
        box-shadow: none; }
      .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info {
        padding: 0 !important;
        border-bottom: 1px solid black !important; }
        .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-image-wrapper {
          padding-bottom: 100% !important; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-image-wrapper .product-image-container {
            max-width: 240px !important; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-image-wrapper img.product-image-photo {
            height: 100%; }
        .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags {
          height: 26px;
          margin-bottom: 1px;
          font-size: 16px; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags .product-tag {
            display: inline-block;
            width: 80%;
            padding: 0 9px 0 9px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #ffffff;
            font-family: "Roboto", sans-serif;
            border: 1px solid;
            border-radius: 50px;
            text-align: center;
            background: #ffffff; }
            @media screen and (max-width: 666px) {
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags .product-tag {
                font-size: 11px;
                width: 92%;
                padding: 0 0 0 0; } }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags .product-tag.tag-sale, .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags .product-tag.tag-hot-offer, .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-tags .product-tag.tag-clearance {
              color: #ff0800; }
        .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: space-between; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-item-name {
            width: 100%;
            display: block;
            margin: 0;
            padding-bottom: 0; }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-item-name .product-item-link {
              width: 100%;
              display: block;
              margin: 0;
              padding-bottom: 0;
              font-size: 20px;
              line-height: 24px;
              font-family: "Rift", sans-serif;
              font-style: italic;
              font-weight: 700;
              color: #000;
              white-space: normal; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars {
            display: block;
            width: 100%; }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary {
              margin-bottom: 0;
              position: relative;
              height: 17px; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary.empty {
                font-size: 10px;
                line-height: 1em; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary {
                margin-right: 0;
                width: 100%;
                display: flex;
                flex-direction: row-reverse; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary > span.label {
                  position: relative;
                  display: inline-block;
                  flex: 1;
                  width: auto;
                  height: auto;
                  line-height: 19px;
                  font-size: 13px;
                  font-family: "Roboto", sans-serif;
                  margin-left: 10px; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result {
                  display: inline-block;
                  position: relative !important;
                  width: 100px !important; }
                  .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result:before {
                    font-size: 15px;
                    letter-spacing: 8px !important;
                    width: 100%; }
                  .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result > span:before {
                    letter-spacing: 8px !important;
                    color: #f8d048;
                    font-size: 15px;
                    width: 100px !important; }
          .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row {
            justify-content: space-between;
            width: 100%;
            display: flex; }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box {
              margin: 0;
              flex: 1;
              width: 40%;
              vertical-align: middle;
              font-family: "Rift", sans-serif;
              font-weight: bold;
              font-size: 16px; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price {
                font-family: "Rift", sans-serif;
                font-weight: bold;
                font-size: 16px;
                line-height: 38px; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price {
                display: inline; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price .price-label {
                  display: none; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price-final_price {
                font-family: inherit; }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-inner {
              display: block; }
            .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions {
              margin: 0; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary {
                vertical-align: middle; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form {
                  display: inline-block; }
                  .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary {
                    background: none;
                    border: none;
                    width: 35px;
                    height: 30px;
                    margin: 3px 0 0 0;
                    padding: 5px;
                    fill: #000;
                    transition: opacity 0.3s; }
                    .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary:hover {
                      opacity: 0.5; }
              .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action {
                color: #000;
                transition: opacity 0.3s;
                height: 30px;
                width: 25px !important;
                border: none;
                padding: 0;
                margin-bottom: 0;
                line-height: 43px;
                float: none; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:before {
                  content: none; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:hover {
                  opacity: 0.5; }
                .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action svg {
                  height: 17px;
                  width: 17px; }
                @media screen and (max-width: 1023px) {
                  .products-grid div.products.list.items.product-items div.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action.tocompare {
                    display: none; } }

.block.related .block-title.title {
  margin-bottom: 35px; }

.block.related .block-title h2 {
  font-weight: 700;
  color: #000;
  font-size: 32px;
  letter-spacing: 3.2px;
  text-align: center; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.message.message {
  position: fixed;
  top: 120px;
  right: 20px;
  z-index: 999;
  border-radius: 2px;
  margin: 0;
  text-align: left;
  padding: 21px;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2); }
  .message.message .close {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    width: 25px;
    text-align: center;
    display: block !important;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-family: 'roboto'; }
  .message.message *::before {
    display: none !important; }
  .message.message.success {
    background-color: #00d986;
    color: #ffffff; }
  .message.message.error {
    background-color: #e80000;
    color: #ffffff; }
  .message.message.info {
    background-color: #FF7900;
    color: #FFFFFF; }
  .message.message a {
    color: inherit !important;
    text-decoration: underline; }
    .message.message a:hover {
      text-decoration: none; }
  .message.message dl {
    margin-bottom: 20px; }
  .message.message dt {
    margin-bottom: 0; }

.quickview-scroll .message.message {
  margin: 0;
  padding: 12px !important; }

.instagram-holder {
  width: 100%;
  box-sizing: border-box; }
  @media (max-width: 767px) {
    .instagram-holder {
      margin-bottom: 0; }
      .instagram-holder .instagram-desktop {
        display: none; } }
  @media (min-width: 768px) {
    .instagram-holder {
      margin-top: 4rem;
      padding: 40px 0 0px;
      background-color: rgba(238, 238, 238, 0.5); }
      .instagram-holder .instagram-mobile {
        display: none; } }
  .instagram-holder .instagram-mobile {
    background-image: url(../images/ig-bg-mobile.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    min-height: 200px;
    padding: 40px;
    box-sizing: border-box; }
    .instagram-holder .instagram-mobile a {
      display: inline-block;
      font-family: "Rift", sans-serif;
      color: #fff;
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2.8px; }
      .instagram-holder .instagram-mobile a:hover, .instagram-holder .instagram-mobile a:visited {
        color: #fff; }
    .instagram-holder .instagram-mobile svg {
      margin-bottom: 8px; }
  .instagram-holder .instagram-desktop {
    max-width: 100% !important;
    margin: 0 auto; }
    .instagram-holder .instagram-desktop .instagram-description {
      font-size: 32px;
      line-height: 38px;
      font-family: "Rift", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: #000;
      text-align: center;
      margin-bottom: 40px;
      letter-spacing: 3.2px; }
      .instagram-holder .instagram-desktop .instagram-description svg {
        display: inline-block;
        margin-right: 15px;
        vertical-align: bottom;
        transform: translateY(1px); }
  .instagram-holder .snptwdgt-rtd__fottr {
    height: 0;
    overflow: hidden; }
    .instagram-holder .snptwdgt-rtd__fottr #wdgt-ftr-usr_profl {
      display: none; }
  .instagram-holder .snptwdgt__headr {
    display: none; }
  .instagram-holder #snpt_CHbVZt7DMIN {
    display: none; }

@media (max-width: 767px) {
  .instagram-holder + .page-footer {
    margin-top: 0; } }

.catalog-product-view .afterpay-installments-amount {
  font-weight: 100 !important;
  font-style: normal !important;
  font-size: 12px; }
  .catalog-product-view .afterpay-installments-amount .afterpay_instalment_price {
    font-weight: 700; }
  .catalog-product-view .afterpay-installments-amount .afterpay_instalments_logo {
    margin-left: 5px; }
  .catalog-product-view .afterpay-installments-amount .afterpay-modal-popup-trigger {
    margin-left: 5px;
    text-decoration: underline; }

@media only screen and (min-width: 992px) {
  .modal-popup.afterpay-learn-more .modal-inner-wrap {
    width: 600px !important;
    height: 510px; } }

@media only screen and (max-width: 991px) {
  .modal-popup.afterpay-learn-more .modal-inner-wrap {
    width: 300px !important;
    height: auto; }
  .modal-popup .modal-inner-wrap {
    height: auto; } }

.message.message {
  position: fixed;
  top: 120px;
  right: 20px;
  z-index: 999;
  border-radius: 2px;
  margin: 0;
  text-align: left;
  padding: 21px;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2); }
  .message.message .close {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    width: 25px;
    text-align: center;
    display: block !important;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-family: 'roboto'; }
  .message.message *::before {
    display: none !important; }
  .message.message.success {
    background-color: #00d986;
    color: #ffffff; }
  .message.message.error {
    background-color: #e80000;
    color: #ffffff; }
  .message.message.info {
    background-color: #FF7900;
    color: #FFFFFF; }
  .message.message a {
    color: inherit !important;
    text-decoration: underline; }
    .message.message a:hover {
      text-decoration: none; }
  .message.message dl {
    margin-bottom: 20px; }
  .message.message dt {
    margin-bottom: 0; }

.quickview-scroll .message.message {
  margin: 0;
  padding: 12px !important; }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: black;
  visibility: hidden;
  background-color: white;
  background-clip: padding-box;
  outline: 0;
  transition: all 0.3s; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.121rem 2.5rem; }
  @media (min-width: 1024px) {
    .offcanvas-header {
      padding: 2rem 3rem; } }
  .offcanvas-header .btn-close {
    padding: 1rem 1rem;
    margin-top: -1rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
    border: none; }
    @media (max-width: 1023.98px) {
      .offcanvas-header .btn-close {
        margin-left: auto; } }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 2rem 2rem; }
  @media (min-width: 1024px) {
    .offcanvas-body {
      overflow-y: auto; } }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: min-content;
  max-height: 100%;
  border: none;
  transform: translateY(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  bottom: 0;
  border: none;
  transform: translateX(100%);
  max-width: 414px;
  width: 100%; }

.offcanvas.show {
  transform: none;
  visibility: visible; }
  @media (max-width: 1023.98px) {
    .offcanvas.show {
      overflow-y: auto; } }

.blurred-text {
  filter: blur(5px) !important; }

.privacy-mode svg {
  height: 15px; }

.privacy-mode.active svg path {
  fill: black !important; }

.ampickupmsi-loader-container {
  clear: both; }

.ampickupmsi-main-container {
  background-color: white;
  border: 2px solid black;
  border-radius: 2px;
  padding: 2.3rem; }
  @media (max-width: 767.98px) {
    .ampickupmsi-main-container {
      padding: 1.5rem 1.5rem 2rem !important; } }
  .ampickupmsi-main-container .ampickupmsi-locations {
    display: flex;
    flex-wrap: wrap; }
  .ampickupmsi-main-container .ampickupmsi-title {
    display: flex;
    align-items: center;
    margin-left: 0;
    text-transform: uppercase;
    color: black;
    font-family: "Rift", sans-serif;
    font-weight: 700;
    font-size: 1.8rem; }
    .ampickupmsi-main-container .ampickupmsi-title::before {
      content: '';
      background-image: url(../images/icon-basket.svg);
      width: 32px;
      height: 32px;
      background-size: contain;
      flex-shrink: 0;
      margin-right: 0; }
  .ampickupmsi-main-container .ampickupmsi-locations {
    max-height: 181px; }
  .ampickupmsi-main-container .ampickupmsi-location {
    padding: 0.842rem 2rem;
    border: solid 2px transparent;
    border-radius: 3px;
    position: relative;
    width: 100%; }
    .ampickupmsi-main-container .ampickupmsi-location:hover {
      background-color: #F5F5F5; }
    .ampickupmsi-main-container .ampickupmsi-location.active {
      border-color: #FF7900; }
    .ampickupmsi-main-container .ampickupmsi-location.initial-active {
      order: -1; }
    .ampickupmsi-main-container .ampickupmsi-location .btn-sm {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.3rem;
      padding: 0.3rem 1.5rem;
      margin-left: 1.5rem;
      font-weight: 400;
      border-width: 1px; }
  .ampickupmsi-main-container .ampickupmsi-map {
    display: none; }
  .ampickupmsi-main-container .ampickupmsi-info {
    font-size: 1.2rem;
    color: black;
    width: 65%; }
    .ampickupmsi-main-container .ampickupmsi-info span {
      color: inherit; }
  .ampickupmsi-main-container .ampickupmsi-stock {
    width: 35%; }
  .ampickupmsi-main-container .ampickupmsi-stock-status {
    display: none; }
  .ampickupmsi-main-container .ampickupmsi-name {
    font-weight: 700; }

.ampickup-map-popup .ampickup-content {
  position: relative;
  width: 100%; }
  @media (max-width: 767.98px) {
    .ampickup-map-popup .ampickup-content {
      max-width: 90vw;
      padding: 2.5rem; }
      .ampickup-map-popup .ampickup-content .amlocator-map-container {
        display: flex;
        flex-wrap: wrap; }
      .ampickup-map-popup .ampickup-content .amlocator-search-container,
      .ampickup-map-popup .ampickup-content .amlocator-store-list,
      .ampickup-map-popup .ampickup-content .-map {
        width: 100%; }
      .ampickup-map-popup .ampickup-content .amlocator-store-list {
        order: 1; }
      .ampickup-map-popup .ampickup-content .-map {
        position: static !important;
        width: 100%;
        height: 200px !important;
        margin-bottom: 1.4rem; }
      .ampickup-map-popup .ampickup-content .ampickup-locations {
        margin-top: 4.4rem;
        padding-right: 0; }
        .ampickup-map-popup .ampickup-content .ampickup-locations h2 {
          padding-right: 3rem;
          box-sizing: border-box;
          top: 2.5rem;
          left: 2.5rem;
          right: 2.5rem;
          font-size: 2.6rem;
          box-sizing: border-box;
          padding-right: 3rem; }
      .ampickup-map-popup .ampickup-content .ampickup-close {
        top: 2.5rem;
        right: 2.5rem; } }
  @media (max-width: 359px) {
    .ampickup-map-popup .ampickup-content .ampickup-locations h2 {
      font-size: 2.2rem; } }
  @media (min-width: 768px) {
    .ampickup-map-popup .ampickup-content {
      max-width: 720px;
      padding: 3rem 37.8rem 4rem 3rem; } }
  @media (min-width: 1145px) {
    .ampickup-map-popup .ampickup-content {
      max-width: 1100px;
      padding: 4.1rem 70.8rem 4.1rem 3.7rem; } }

.ampickup-map-popup .ampickup-close {
  position: absolute;
  top: 30px;
  right: 31px;
  opacity: 1;
  z-index: 1; }
  .ampickup-map-popup .ampickup-close::before, .ampickup-map-popup .ampickup-close::after {
    width: 1px; }
  .ampickup-map-popup .ampickup-close:hover {
    opacity: 0.8; }

.ampickup-map-popup .search-container {
  display: flex;
  margin-bottom: 1rem; }
  .ampickup-map-popup .search-container .btn-use-current-location {
    flex-shrink: 0;
    padding: 0;
    width: 37px;
    height: 40px;
    border-color: #ddd;
    margin-right: -1px; }
    .ampickup-map-popup .search-container .btn-use-current-location:hover, .ampickup-map-popup .search-container .btn-use-current-location:focus {
      background-color: #ddd; }
  .ampickup-map-popup .search-container .amlocator-text {
    height: 40px;
    border-color: #ddd; }

.ampickup-map-popup .amlocator-select {
  height: 40px;
  margin-bottom: 1rem; }

.ampickup-map-popup .amlocator-button.-nearby {
  width: 100%;
  height: 40px;
  margin-bottom: 1rem;
  background-color: black;
  color: white;
  font-family: "Rift", sans-serif;
  font-size: 1.6rem; }

.ampickup-map-popup .ampickup-locations {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 5.4rem; }

.ampickup-map-popup .ampickup-locations h2,
.ampickup-map-popup .ampickup-title {
  position: absolute;
  top: 3.125rem;
  left: 3.7rem;
  margin-top: 0;
  font-family: "Rift", sans-serif;
  font-weight: 700;
  font-size: 3.2rem; }

.ampickup-map-popup .ampickup-title {
  margin-top: -1.5rem; }

.ampickup-map-popup .amlocator-main-container .amlocator-map-container .amlocator-block.-map {
  position: absolute;
  top: 95px;
  left: 400px;
  bottom: 41px;
  right: 36px;
  display: block !important;
  height: auto; }
  @media (min-width: 768px) {
    .ampickup-map-popup .amlocator-main-container .amlocator-map-container .amlocator-block.-map {
      top: 85px;
      left: 360px;
      bottom: 40px;
      right: 30px; } }
  @media (min-width: 1100px) {
    .ampickup-map-popup .amlocator-main-container .amlocator-map-container .amlocator-block.-map {
      top: 95px;
      left: 400px;
      bottom: 41px;
      right: 36px; } }

.ampickup-map-popup .amlocator-map {
  height: 100%; }

.ampickup-map-popup .amlocator-store-desc + .amlocator-store-desc {
  margin-top: 1rem; }

.ampickup-map-popup .amlocator-store-information {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 2.1rem 1.8rem;
  font-size: 1.2rem; }
  .ampickup-map-popup .amlocator-store-information p {
    margin-bottom: 0; }
  .ampickup-map-popup .amlocator-store-information a {
    color: inherit;
    text-decoration: underline; }
    .ampickup-map-popup .amlocator-store-information a:hover {
      text-decoration: none; }
    .ampickup-map-popup .amlocator-store-information a.directions {
      font-size: 0; }
      .ampickup-map-popup .amlocator-store-information a.directions::before {
        content: 'Directions';
        font-size: 1.2rem; }
  .ampickup-map-popup .amlocator-store-information .amlocator-button.-pickup {
    position: absolute;
    top: 1.7rem;
    right: 1.6rem;
    margin: 0 !important;
    font-family: "Rift", sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    border-radius: 2px;
    border-color: black !important;
    background-color: black !important;
    color: white !important; }

.ampickup-map-popup .-active .amlocator-store-information {
  border-color: #FF7900;
  border-width: 2px;
  padding: 2rem 1.7rem; }
  .ampickup-map-popup .-active .amlocator-store-information .amlocator-button.-pickup {
    top: 1.6rem;
    right: 1.5rem; }

.ampickup-map-popup .amlocator-title {
  font-weight: 700;
  font-size: 1.4rem; }
  .ampickup-map-popup .amlocator-title + br {
    display: none; }

.ampickup-store-container.-checkout .label {
  font-weight: normal; }
  @media (min-width: 1200px) {
    .ampickup-store-container.-checkout .label {
      margin-bottom: 2.9rem !important; } }

.ampickup-store-container.-checkout .ampickup-choosemap {
  background-color: black;
  border-color: black;
  color: white;
  font-family: "Rift", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  border-radius: 2px;
  padding: 0.56rem 4.1rem; }
  @media (min-width: 1200px) {
    .ampickup-store-container.-checkout .ampickup-choosemap {
      margin-left: auto; } }

.ampickup-details-container .pickup-content:not(:empty) {
  margin-top: 3rem;
  padding: 3rem;
  background-color: rgba(221, 221, 221, 0.29);
  color: black;
  box-sizing: border-box; }
  @media (max-width: 767.98px) {
    .ampickup-details-container .pickup-content:not(:empty) {
      margin-top: 2rem;
      padding: 2rem; } }
  .ampickup-details-container .pickup-content:not(:empty)::before {
    content: 'Your chosen Click and Collect store is:';
    display: block;
    margin-bottom: 2rem; }
    @media (max-width: 767.98px) {
      .ampickup-details-container .pickup-content:not(:empty)::before {
        margin-bottom: 1rem; } }

.ampickup-details-container .store-info {
  max-width: 340px;
  border: 1px solid #DDDDDD;
  border-radius: 3px;
  background-color: white;
  padding: 2rem 1.8rem;
  box-sizing: border-box;
  font-size: 1.2rem; }
  .ampickup-details-container .store-info .store-name {
    display: block;
    margin: 0 0 0.5rem;
    font-family: "Roboto", sans-serif !important;
    font-weight: 700;
    font-size: 1.4rem !important;
    line-height: 1.5; }
  .ampickup-details-container .store-info p {
    margin-bottom: 0; }
  .ampickup-details-container .store-info a {
    color: inherit; }
    .ampickup-details-container .store-info a:hover {
      text-decoration: none; }
    .ampickup-details-container .store-info a.directions {
      font-size: 0; }
      .ampickup-details-container .store-info a.directions::before {
        content: 'Directions';
        font-size: 1.2rem; }
  .ampickup-details-container .store-info .website {
    word-break: break-all; }
  .ampickup-details-container .store-info br:last-child {
    display: none; }

.select-holder {
  flex-grow: 1;
  margin-bottom: 1rem; }

.select-location-holder {
  max-width: 400px;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
  padding-left: 0;
  padding-right: 2rem;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap; }
  .select-location-holder li {
    padding: 0.7rem 2rem;
    border: 2px solid transparent;
    width: 100%; }
    .select-location-holder li:hover {
      background-color: #f5f5f5;
      cursor: pointer; }
    .select-location-holder li.initial-active {
      order: -1; }
    .select-location-holder li.active {
      border: 2px solid #FF7900;
      border-radius: 3px; }

.ampickup-field.field.-store .ampickup-wrapper {
  align-items: flex-start; }

.ampickup-separator.-checkout {
  display: none; }

body.-amsearch-overlay-opened {
  overflow: hidden;
  touch-action: none; }

.amsearch-overlay-block {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: 98; }

.amsearch-result-section {
  margin-top: 0;
  border-radius: 0;
  max-height: calc(90vh - 100px); }
  .amsearch-result-section::before {
    content: '';
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

/* I don't know why this is needed - stuff's just out of alignment after search was put in... */
@media (min-width: 1024px) {
  header .header.content a.logo,
  header .header.content a.logo svg {
    height: 100%; } }

.-amsearch-overlay-opened .btn-search {
  display: none; }

.-amsearch-overlay-opened .nav-sections {
  display: none; }

.-amsearch-overlay-opened .header.content a.logo {
  margin-right: auto; }

.amsearch-form-block {
  display: none;
  width: 100%; }
  .amsearch-form-block.-opened {
    display: flex; }
  .amsearch-form-block .amsearch-input-wrapper + .amsearch-result-section .amsearch-result-block.-preload {
    display: none; }
    @media (min-width: 768px) {
      .amsearch-form-block .amsearch-input-wrapper + .amsearch-result-section .amsearch-result-block.-preload {
        width: 15%; } }
  .amsearch-form-block .amsearch-input-wrapper:not(.-match) + .amsearch-result-section {
    display: none; }
    @media (min-width: 768px) {
      .amsearch-form-block .amsearch-input-wrapper:not(.-match) + .amsearch-result-section .search-cms-block {
        width: 85%; } }
    @media (max-width: 767.98px) {
      .amsearch-form-block .amsearch-input-wrapper:not(.-match) + .amsearch-result-section .search-cms-block img {
        width: 100%; } }
  .amsearch-form-block .amsearch-input-wrapper {
    position: absolute;
    max-width: 455px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 1023.98px) {
      .amsearch-form-block .amsearch-input-wrapper {
        display: flex;
        align-items: center;
        transform: none;
        left: 0;
        top: 0;
        background: #ffffff;
        height: 100%;
        max-width: 100%;
        padding: 1rem 3rem;
        box-sizing: border-box; } }
    .amsearch-form-block .amsearch-input-wrapper.-match + .amsearch-result-section .search-cms-block {
      display: none; }
    .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
      transition: none;
      border: none;
      border-bottom: 2px solid #000000;
      background: none;
      padding-left: 0;
      font-size: 1.2rem;
      font-family: "Roboto", sans-serif; }
      @media (max-width: 1023.98px) {
        .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
          font-size: 1.6rem;
          margin-right: 55px; } }
    .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block {
      width: 34px;
      height: 34px;
      background-color: #eeeeee; }
      @media (max-width: 1023.98px) {
        .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block {
          background-color: #ffffff; } }
      @media (min-width: 1024px) {
        .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block {
          right: 0;
          margin-right: 0; } }
    .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important; }
    .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-close {
      filter: brightness(0%);
      right: -9px;
      display: block !important; }
      @media (max-width: 1023.98px) {
        .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-close {
          right: 39px;
          background-size: 18px; } }

.search-cms-block {
  padding: 0 1.2rem 1.2rem 1.2rem; }
  @media (min-width: 1024px) {
    .search-cms-block {
      padding-top: 1.2rem; } }
  .search-cms-block .pagebuilder-column {
    padding: 1.8rem 1.2rem 2.3rem; }
    @media (max-width: 767.98px) {
      .search-cms-block .pagebuilder-column {
        padding: 0.8rem 1.2rem 0.8rem 1.2rem; }
        .search-cms-block .pagebuilder-column:first-child {
          position: absolute;
          top: 11px;
          left: calc(50% + 3rem);
          right: 0;
          width: auto !important;
          z-index: 3; } }
    .search-cms-block .pagebuilder-column figure {
      line-height: 0; }
  .search-cms-block h3 {
    margin-top: 0;
    font-weight: 700;
    color: #000000;
    font-size: 1.6rem;
    letter-spacing: 0.8px; }
    @media (max-width: 767.98px) {
      .search-cms-block h3 {
        letter-spacing: 1px;
        font-size: 2rem; } }
  .search-cms-block ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .search-cms-block ul li {
      margin: 0;
      font-size: 1.2rem; }
      @media (max-width: 767.98px) {
        .search-cms-block ul li {
          font-size: 1.6rem; } }
      .search-cms-block ul li + li {
        margin-top: 0.6rem; }
  .search-cms-block a {
    color: #333;
    text-decoration: none; }
    .search-cms-block a:hover {
      color: #333;
      text-decoration: underline; }
    .search-cms-block a:visited {
      color: #333; }

@media (min-width: 768px) {
  .amsearch-items-section.amsearch-items-section.amsearch-items-section {
    max-width: 40%;
    display: flex; }
    .amsearch-items-section.amsearch-items-section.amsearch-items-section > div {
      width: 50%; } }

.amsearch-result-block.-preload {
  order: -1; }
  .amsearch-result-block.-preload .amsearch-items-section {
    display: flex;
    padding-top: 3rem; }
    .amsearch-result-block.-preload .amsearch-items-section.amsearch-items-section.amsearch-items-section {
      max-width: 100%; }
    @media (min-width: 768px) and (max-width: 1023.98px) {
      .amsearch-result-block.-preload .amsearch-items-section {
        padding-top: 1.8rem; } }
    .amsearch-result-block.-preload .amsearch-items-section .amsearch-block-header {
      line-height: 2rem; }
    @media (max-width: 767.98px) {
      .amsearch-result-block.-preload .amsearch-items-section {
        padding: 1.8rem 2.4rem 1.8rem 2.4rem; }
        .amsearch-result-block.-preload .amsearch-items-section .amsearch-block-header {
          padding-left: 0; }
        .amsearch-result-block.-preload .amsearch-items-section .amsearch-link.amsearch-link {
          padding-left: 0; } }
  .amsearch-result-block.-preload .amsearch-item-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }

.amsearch-item-container.-recent_searches .amsearch-tab-items {
  padding: 0;
  margin: 1rem 0 0 0; }

.amsearch-item-container.-recent_searches .amsearch-block-header.amsearch-block-header {
  padding: 0.1rem 1.5rem; }

.amsearch-item-container.-recent_searches .amsearch-item {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: block; }
  .amsearch-item-container.-recent_searches .amsearch-item .amsearch-link {
    padding-top: 0;
    padding-bottom: 0; }
  .amsearch-item-container.-recent_searches .amsearch-item.-match {
    background-color: transparent; }

.amsearch-item-container .amsearch-link.amsearch-link.amsearch-link,
.amsearch-products-section .amsearch-link.amsearch-link.amsearch-link {
  color: #333;
  font-family: "Roboto", sans-serif; }
  .amsearch-item-container .amsearch-link.amsearch-link.amsearch-link:hover,
  .amsearch-products-section .amsearch-link.amsearch-link.amsearch-link:hover {
    color: #333;
    background-color: transparent;
    text-decoration: underline; }
  .amsearch-item-container .amsearch-link.amsearch-link.amsearch-link .amsearch-highlight,
  .amsearch-products-section .amsearch-link.amsearch-link.amsearch-link .amsearch-highlight {
    color: #333; }

.amsearch-item-container {
  padding-left: 0.9rem;
  padding-right: 0.9rem; }
  .amsearch-item-container .amsearch-tab-items {
    margin-top: 1rem; }
  .amsearch-item-container .amsearch-link.amsearch-link {
    font-size: 1.2rem;
    padding: 0 1.5rem; }
    @media (max-width: 767.98px) {
      .amsearch-item-container .amsearch-link.amsearch-link {
        font-size: 1.6rem; } }
  .amsearch-item-container .amsearch-item + .amsearch-item {
    margin-top: 0.6rem; }

.page-header .amsearch-wrapper-block {
  position: static; }
  @media (max-width: 1023.98px) {
    .page-header .amsearch-wrapper-block {
      width: auto;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end; } }
  .page-header .amsearch-wrapper-block .amsearch-result-section .search-popup {
    max-width: 1400px;
    display: flex;
    width: 100%;
    min-width: 0;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 768px) {
      .page-header .amsearch-wrapper-block .amsearch-result-section .search-popup {
        flex-wrap: nowrap; } }
    @media (max-width: 767.98px) {
      .page-header .amsearch-wrapper-block .amsearch-result-section .search-popup {
        flex-wrap: wrap; } }
  .page-header .amsearch-wrapper-block .amsearch-form-block {
    position: static;
    width: 100% !important; }
  .page-header .amsearch-wrapper-block.-opened {
    width: 100% !important; }

.amsearch-block-header {
  padding: 0 1.5rem 0.2rem 1.5rem; }
  .amsearch-block-header .amsearch-title {
    font-family: "Rift", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    color: #000000;
    letter-spacing: 0.8px; }
    @media (max-width: 767.98px) {
      .amsearch-block-header .amsearch-title {
        font-size: 2rem;
        letter-spacing: 1px; } }

.amsearch-title.pop-main-title {
  display: none; }

@media (max-width: 767.98px) {
  .amsearch-result-block.-preload .amsearch-item-container .amsearch-tab-items {
    width: 44%; } }

.amsearch-result-block.-preload .amsearch-title.pop-alt-title {
  display: none; }

.amsearch-result-block.-preload .amsearch-title.pop-main-title {
  display: inline-block; }

.btn-search {
  border: none !important; }
  .btn-search:hover {
    background-color: transparent; }
  .btn-search svg:last-child {
    display: none; }
  @media (max-width: 1023.98px) {
    .-amsearch-overlay-opened .btn-search {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 99;
      transform: translateY(-50%); }
      .-amsearch-overlay-opened .btn-search svg:first-child {
        display: none; }
      .-amsearch-overlay-opened .btn-search svg:last-child {
        display: block; } }

.amsearch-products-section {
  border-top: none; }
  @media (max-width: 1024px) {
    .amsearch-products-section {
      padding-top: 0; } }
  .amsearch-products-section .amsearch-block-header {
    display: none; }
  .amsearch-products-section .amsearch-highlight {
    font-weight: 400; }
  .amsearch-products-section .amsearch-item:hover {
    background-color: transparent; }
  .amsearch-products-section:not(:first-child) {
    border-left: none; }
  .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
    width: 100%;
    max-width: 100%;
    flex-wrap: nowrap; }
    @media (min-width: 768px) {
      .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(50%); } }
    @media (min-width: 1024px) {
      .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(33.333333%); } }
    @media (max-width: 767.98px) {
      .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        justify-content: center; }
        .amsearch-products-section.-grid:not(.-slider) .amsearch-item .product-item-details {
          width: 50%; } }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .amsearch-image {
      width: 100px;
      margin-right: 1.5rem; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .amsearch-link {
      min-height: 38px; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .amsearch-wrapper-inner {
      min-height: 25px; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .product-item-link {
      padding-bottom: 0;
      line-height: 1.2; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .amsearch-description {
      justify-content: center; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .product-image-wrapper {
      padding-bottom: 100% !important; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item .price-wrapper {
      border-bottom: none; }
    .amsearch-products-section.-grid:not(.-slider) .amsearch-item [data-role='priceBox'] {
      padding-bottom: 0; }
      .amsearch-products-section.-grid:not(.-slider) .amsearch-item [data-role='priceBox'] span {
        line-height: 1.2; }
  .amsearch-products-section .cart-form [data-role="tocart-form"] {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; }
  .amsearch-products-section .cart-form .qty-wrapper {
    flex: 1 1 32%; }
    .amsearch-products-section .cart-form .qty-wrapper input {
      height: 40px; }
  .amsearch-products-section .cart-form button {
    flex: 1 1 62%; }
    @media screen and (max-width: 1024px) {
      .amsearch-products-section .cart-form button {
        padding-left: 10px;
        padding-right: 10px; } }
  .amsearch-products-section .cart-form .btn.btn-secondary {
    font-family: "Rift", sans-serif !important;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 !important;
    border-radius: 2px;
    background: #ffffff;
    border: 2px solid #009645;
    color: #009645;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap; }
    .amsearch-products-section .cart-form .btn.btn-secondary:hover {
      background: #009645;
      color: #ffffff; }
    .amsearch-products-section .cart-form .btn.btn-secondary.invert {
      border: 2px solid #009645;
      color: #ffffff;
      background: #009645; }
      .amsearch-products-section .cart-form .btn.btn-secondary.invert:hover {
        background: #ffffff;
        color: #009645; }
      .amsearch-products-section .cart-form .btn.btn-secondary.invert.orange {
        border-color: #FF7900 !important;
        background: #FF7900 !important; }
        .amsearch-products-section .cart-form .btn.btn-secondary.invert.orange:hover {
          background-color: #ffffff !important;
          color: #FF7900 !important; }

.amsearch-search-page.page-products .columns {
  z-index: 2; }

.page-wrapper .jumbotron {
  z-index: 0; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block.amsearch-loader-block {
  background-color: #ffffff; }

.amsearch-form-block {
  display: block; }
  .amsearch-form-block:not(.-opened) .amsearch-input-wrapper .amsearch-button.-close {
    display: none !important; }
  .amsearch-form-block.-opened .amsearch-input-wrapper .amsearch-button.-loupe {
    display: none !important; }
  @media (min-width: 1024px) {
    .amsearch-form-block .amsearch-input-wrapper {
      margin-top: -5px;
      max-width: 650px; } }
  @media (max-width: 1023.98px) {
    .amsearch-form-block .amsearch-input-wrapper {
      top: 60px;
      border-top: solid 1px #ababab;
      border-bottom: solid 1px #ababab;
      padding-bottom: 1rem;
      padding-top: 1rem;
      height: 70px; } }
  .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block.amsearch-loader-block {
    background-color: transparent;
    right: 19px;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
    color: #FF7900;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    background-image: none;
    width: 20px;
    height: 20px; }
    @media (max-width: 1023.98px) {
      .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block.amsearch-loader-block {
        right: 40px !important; } }
  .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
    background-color: #ffffff;
    border: solid 2px #000000;
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
    height: 47px;
    font-size: 1.5rem;
    color: #000000; }
    .amsearch-form-block .amsearch-input-wrapper .amsearch-input:focus {
      border-color: #FF7900; }
    @media (max-width: 1023.98px) {
      .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
        margin-right: 0; } }
  .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block:not([style="display: none;"]) + .amsearch-button.-close {
    display: none !important; }
  .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-close {
    background-image: url("../images/cross-v2.svg");
    right: 22px;
    background-color: #FF7900;
    border-radius: 10rem;
    height: 18px;
    width: 18px;
    padding: 0;
    background-size: 6px;
    filter: none; }
    @media (max-width: 1023.98px) {
      .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-close {
        right: 48px; } }
  .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto !important;
    width: 28px !important;
    height: 28px !important;
    clip: auto !important;
    right: 20px !important;
    left: auto !important;
    background-image: url("../images/icon-mag-black.svg");
    background-size: cover;
    opacity: 1; }
    @media (max-width: 1023.98px) {
      .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
        right: 46px !important; } }

.amsearch-wrapper-block > .btn-search {
  display: none; }

.amsearch-result-section {
  top: 99%; }
  @media (max-width: 1023.98px) {
    .amsearch-result-section {
      margin-top: 70px;
      max-height: calc(90vh - 150px);
      border-top: solid 1px #ababab; } }

.-amsearch-overlay-opened .nav-sections {
  display: block; }
  .-amsearch-overlay-opened .nav-sections nav.rootmenu:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    z-index: 100; }

.page-header .amsearch-wrapper-block:before {
  display: none; }

.amrelated-grid-wrapper.amrelated-grid-wrapper {
  width: 100%;
  margin-bottom: 0; }
  .amrelated-grid-wrapper.amrelated-grid-wrapper .products-grid .slick-slider {
    max-height: unset;
    margin-top: 20px; }
    .amrelated-grid-wrapper.amrelated-grid-wrapper .products-grid .slick-slider .slick-arrow {
      background: none !important; }
      .amrelated-grid-wrapper.amrelated-grid-wrapper .products-grid .slick-slider .slick-arrow.slick-prev {
        margin-left: 20px; }
    .amrelated-grid-wrapper.amrelated-grid-wrapper .products-grid .slick-slider .slick-slide > div {
      height: 100%; }
  .amrelated-grid-wrapper.amrelated-grid-wrapper .product-items.product-items {
    display: flex;
    justify-content: center; }
    @media (max-width: 767.98px) {
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-items.product-items {
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px; } }
  .amrelated-grid-wrapper.amrelated-grid-wrapper .block-title.block-title {
    margin-bottom: 3rem; }
    .amrelated-grid-wrapper.amrelated-grid-wrapper .block-title.block-title strong {
      font-weight: 700 !important; }
  .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item {
    border: 3px solid #F0F0F0 !important;
    padding: 0;
    margin: 0 auto !important;
    width: 100%;
    border-radius: 3px;
    box-shadow: none;
    overflow: hidden !important;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 0; }
    @media (max-width: 767.98px) {
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item {
        flex: 1 1 50%;
        margin: 0 8px 16px !important;
        width: auto;
        max-width: 224px;
        flex-basis: calc(50% - 20px); } }
    @media (min-width: 768px) {
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item {
        margin: 0 !important; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item + .product-item {
          margin-left: 13px !important; } }
    .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item:hover {
      border: inherit;
      box-shadow: inherit;
      border-color: #000000 !important; }
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item:hover .product-item-info {
        border: none;
        box-shadow: none; }
    .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info {
      display: flex !important;
      flex-direction: column !important;
      padding: 0 !important;
      position: static !important;
      flex-grow: 1; }
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info:hover {
        border: none;
        box-shadow: none; }
      .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details {
        margin: 0;
        padding: 0 20px 15px;
        font-family: "Rift", sans-serif;
        text-align: left;
        color: #000000;
        flex-direction: column;
        display: flex;
        flex-grow: 1; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name {
          font-family: "Rift", sans-serif;
          white-space: normal;
          text-overflow: initial;
          line-height: 1em; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link {
            font-family: "Rift", sans-serif; }
            .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link:hover {
              text-decoration: none; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .amrelated-attributes {
          font-weight: bold;
          font-size: 14px;
          line-height: 1.2em; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .stock-level-colour {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .stock-levels-wrapper {
          display: flex;
          align-items: center;
          flex: 0; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .stock-level-text {
          font-family: "Roboto", sans-serif;
          font-size: 12px; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details a {
          color: #000000;
          font-size: 20px;
          font-weight: bold;
          font-style: italic; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price {
          font-family: "Rift", sans-serif;
          font-weight: bold;
          line-height: 1.2em; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price-wrapper {
            border-bottom: none;
            padding: 0; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .rrp-price::before {
            content: 'RRP';
            padding-right: 4px;
            color: #666;
            font-size: 12px; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .special-price {
            color: #ff0800;
            display: inline-block; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price {
            font-size: 1.6rem; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .bundle-strike {
            text-decoration: line-through;
            color: #999;
            padding-right: 10px; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .wholesale-price::before {
            content: 'WHOLESALE';
            padding-right: 4px;
            color: #666;
            font-size: 12px;
            text-decoration: none; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .actions-primary {
          display: block; }
          .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] {
            display: flex;
            flex-wrap: wrap;
            gap: 8px; }
            .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] input {
              height: 44px;
              flex: 1 1 32%; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .action.primary {
          position: relative;
          z-index: 2;
          font-family: "Rift", sans-serif !important;
          display: block;
          padding: 10px 15px !important;
          margin: 0 !important;
          border-radius: 2px;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          border: 2px solid #000000;
          color: #ffffff;
          background: #000000;
          height: auto;
          flex: 1 1 62%; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .action.primary:hover {
          background: #ffffff;
          color: #000000; }
        .amrelated-grid-wrapper.amrelated-grid-wrapper .product-item.product-item .product-item-info .product-item-details .product-item-actions {
          margin: auto 0 0;
          padding-top: 1rem; }

.amrelated-pack-wrapper.amrelated-pack-wrapper {
  display: flex;
  justify-content: center; }
  @media (max-width: 1023.98px) {
    .amrelated-pack-wrapper.amrelated-pack-wrapper {
      flex-direction: column; } }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-list {
    flex-wrap: nowrap;
    flex: 0 1 auto;
    max-width: none; }
    @media (max-width: 1023.98px) {
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-list {
        flex-wrap: wrap; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-list .amrelated-pack-item.amrelated-pack-item {
          flex: 0 1 calc(50% - 18px);
          max-width: none;
          margin-right: 18px;
          margin-bottom: 18px; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-list .amrelated-pack-item.amrelated-pack-item:nth-child(even) {
            margin-right: 0; } }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-title {
    display: none; }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-plus, .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-equal {
    display: none; }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-background.amrelated-background {
    display: none; }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-content-wrapper {
    justify-content: center;
    background: none;
    margin-top: 5px; }
    @media (max-width: 1023.98px) {
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-content-wrapper {
        flex-wrap: wrap;
        max-width: 614px; } }
  @media (min-width: 1440px) {
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-items {
      max-width: 100%; } }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0; }
    @media (min-width: 1024px) {
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content {
        width: 100%;
        max-width: 139px;
        flex: 0 1 139px; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content .action.primary {
          background-color: #009645;
          border-color: #009645; } }
    @media (max-width: 1023.98px) {
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content {
        max-width: 100%;
        flex: 0 0 100%;
        flex-direction: column;
        padding: 0; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content .action.primary {
          background-color: #009645;
          border-color: #009645; } }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content .main.price {
      font-size: 41px;
      font-family: "Rift", sans-serif; }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-summary-content .action.primary {
      width: 100%;
      height: 60px; }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-price-wrapper {
    border: none;
    height: auto;
    padding: 0;
    margin-bottom: 22px; }
    @media (max-width: 1023.98px) {
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-price-wrapper {
        margin-bottom: 9px; } }
  .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item {
    border: 3px solid #F0F0F0;
    border-bottom: 3px solid #F0F0F0;
    padding: 0;
    margin: 0 13px 0 0;
    width: 100%;
    flex: 0 1 194px;
    border-radius: 3px; }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item:not(.-selected) {
      opacity: 1; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item:not(.-selected) .amrelated-info .amrelated-photo-container .amrelated-checkbox + .amrelated-label.amrelated-label {
        background: #EEEEEE;
        border-color: #eee; }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item.-selected {
      border: 3px solid #FF7900;
      border-bottom: 3px solid #FF7900 !important; }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info {
      flex-direction: column;
      width: 100%; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-photo-container.amrelated-photo-container {
        width: calc(100% - 40px);
        height: 150px;
        margin: 0;
        padding: 0 20px;
        border: none; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-photo-container.amrelated-photo-container .amrelated-checkbox + .amrelated-label {
          top: -3px;
          background: #FF7900;
          border: none;
          left: auto;
          right: -3px;
          width: 37px;
          height: 37px; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-photo-container.amrelated-photo-container .amrelated-checkbox + .amrelated-label::before {
            content: '';
            background-color: white;
            display: block;
            transform: rotate(45deg);
            height: 2px;
            width: 10px;
            position: absolute;
            left: 8px;
            top: 18px; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-photo-container.amrelated-photo-container .amrelated-checkbox + .amrelated-label::after {
            content: '';
            background-color: white;
            display: block;
            transform: rotate(135deg);
            height: 2px;
            width: 15px;
            position: absolute;
            top: 15px;
            left: 14px; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details {
        margin: 0;
        padding: 0 20px 15px;
        font-family: "Rift", sans-serif;
        text-align: left;
        color: #000000;
        flex-direction: column; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .amrelated-attributes {
          font-weight: bold;
          font-size: 14px;
          line-height: 1.2em; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details a {
          color: #000000;
          font-size: 20px;
          font-weight: bold;
          font-style: italic; }
        .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price {
          font-family: "Rift", sans-serif;
          font-weight: bold;
          line-height: 1.2em; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .price-wrapper {
            border-bottom: none;
            padding: 0; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .rrp-price::before {
            content: 'RRP';
            padding-right: 4px;
            color: #666;
            font-size: 12px; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .special-price {
            color: #ff0800;
            display: inline-block;
            padding-left: 10px; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .price {
            font-size: 1.6rem; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .bundle-strike {
            text-decoration: line-through;
            color: #999; }
          .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .amrelated-details .price-container.price-final_price .wholesale-price::before {
            content: 'WHOLESALE';
            padding-right: 4px;
            color: #666;
            font-size: 12px;
            text-decoration: none; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .stock-level-wrapper {
        padding-top: 0; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .stock-level-colour {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .stock-levels-wrapper {
        display: flex;
        align-items: center; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .stock-level-text {
        font-family: "Roboto", sans-serif;
        font-size: 12px; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-info .price-box {
        margin-top: 0; }
    .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-discount {
      display: inline-block;
      position: static;
      background: none;
      padding: 0;
      color: #ff0800 !important;
      font-size: 16px; }
      .amrelated-pack-wrapper.amrelated-pack-wrapper .amrelated-pack-item.amrelated-pack-item .amrelated-discount .price.price {
        display: inline-block;
        background: none;
        color: inherit !important;
        font-family: "Rift", sans-serif;
        font-weight: 700;
        font-size: inherit; }

.mgz-product-listing .product-image-wrapper .product-image-photo {
  position: absolute; }

.mgz-products-grid .products-grid ol.products.list.items.product-items {
  justify-content: center; }

.mgz-single-product .products-grid ol.products.list.items.product-items {
  justify-content: center; }

@media (max-width: 767.98px) {
  .mgz-single-product .products-grid .product-item.product-item {
    flex-basis: 100%; } }

.mgz-product-slider .products-grid ol.products.list.items.product-items {
  justify-content: center; }
  .mgz-product-slider .products-grid ol.products.list.items.product-items li.item.product.product-item {
    width: auto; }
    @media (min-width: 768px) {
      .mgz-product-slider .products-grid ol.products.list.items.product-items li.item.product.product-item {
        margin: 0 auto; } }

.mgz-product-slider .owl-carousel .owl-item img {
  width: auto; }

/* Pages */
@font-face {
  font-family: 'Rift';
  src: url("../fonts/Rift-Bold.woff2") format("woff2"), url("../fonts/Rift-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Rift';
  src: url("../fonts/Rift-Medium.woff2") format("woff2"), url("../fonts/Rift-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("../fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

/* GLOBAL */
body {
  font-family: "Roboto", sans-serif; }

#maincontent {
  max-width: 100% !important !important; }

.uk-dotnav > .uk-active > * {
  transform: none !important; }

h1 {
  font-family: "Rift", sans-serif !important;
  font-family: bold;
  font-size: 32px;
  line-height: 38px; }

h1.hero-heading {
  font-family: "Rift", sans-serif !important;
  font-family: bold;
  font-size: 76px;
  line-height: 91px;
  letter-spacing: 15.2px; }

h1.alternative {
  font-family: "Rift", sans-serif !important;
  font-family: bold;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 3.2px; }

h2 {
  font-family: "Rift", sans-serif !important;
  font-family: bold;
  font-size: 22px;
  line-height: 27px; }

h3 {
  font-family: "Rift", sans-serif !important;
  font-family: bold;
  font-size: 16px;
  line-height: 20px; }

p {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px; }
  p a {
    color: #FF7900;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-decoration: underline; }

.btn {
  font-family: "Rift", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 30px;
  text-decoration: none !important;
  transition: all 0.2s linear;
  width: fit-content;
  border-radius: 2px;
  border-color: transparent; }
  .btn.black {
    background-color: #000000;
    color: #ffffff; }
    .btn.black:hover {
      background-color: #FF7900;
      color: #ffffff; }
  .btn.red {
    background-color: #FF7900;
    color: #ffffff; }
    .btn.red:hover {
      background-color: #000000;
      color: #ffffff; }

[data-content-type="row"][data-appearance="contained"] {
  max-width: 100% !important !important;
  width: 100%; }
  [data-content-type="row"][data-appearance="contained"] [data-element='inner'] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

a,
a:hover,
a:visited {
  color: #FF7900; }

.page-header {
  z-index: 99;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2); }
  @media (min-width: 768px) {
    .page-header {
      position: sticky !important;
      top: 0;
      width: 100%; } }

/* CART - FLOATING */
.mini-cart-wrapper,
.minicart-wrapper .block-minicart {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 414px !important;
  width: 100%;
  bottom: 0;
  z-index: 9000;
  margin: 0 !important;
  border: 0;
  min-width: auto; }

/* Target Simple Pages (Privacy Policy, etc) */
.column.main .columns h1 {
  font-weight: 700;
  margin-bottom: 25px; }

.column.main .columns h4 {
  margin: 12.5px 0; }

.column.main .columns blockquote {
  margin: 10px 0; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

select {
  appearance: none;
  background-image: url(../images/select.svg);
  background-size: 9.58px 5px, 1px 20px;
  background-repeat: no-repeat;
  background-position: right 1.3rem center; }

.d-flex {
  display: flex; }

.justify-content-end {
  justify-content: flex-end; }

.btn-dark {
  background-color: black;
  color: white;
  border-width: 2px; }
  .btn-dark:hover {
    background-color: white;
    color: black; }

.action.primary.disabled, .action-primary.disabled, .action.primary[disabled], .action-primary[disabled], fieldset[disabled] .action.primary, fieldset[disabled] .action-primary {
  opacity: 0.4; }

.action.primary {
  background-color: #FF7900;
  border: 1px solid #FF7900;
  border-radius: 2px;
  font-family: "Rift", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 30px; }
  .action.primary:hover {
    background-color: #000000;
    border-color: #000000; }

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(0px, 0px, 0px, 0px) !important;
  white-space: nowrap !important;
  padding: 0px !important;
  margin: -1px !important;
  overflow: hidden !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important; }

.customer-account-forgotpassword .page-main {
  padding: 2rem; }

.customer-account-forgotpassword .page-title {
  font-weight: 700;
  color: #000000; }

.customer-account-forgotpassword .page-title, .customer-account-forgotpassword .columns .form .fieldset {
  margin-bottom: 20px; }

.catalog-product_compare-index .page-title {
  font-weight: 700;
  color: #000000; }

.catalog-product_compare-index .reviews-actions {
  display: block;
  margin-top: 0;
  line-height: 1.5;
  text-decoration: underline;
  font-size: 13px; }

.catalog-product_compare-index .table-comparison .cell.remove .action.delete {
  background-image: url(../images/close_loader.svg);
  background-size: 26px 34.39px;
  background-repeat: no-repeat;
  background-position: center; }
  .catalog-product_compare-index .table-comparison .cell.remove .action.delete::before {
    display: none; }

.catalog-product_compare-index .table-comparison .product-item-name,
.catalog-product_compare-index .table-comparison .product-reviews-summary,
.catalog-product_compare-index .table-comparison .price-box.price-final_price,
.catalog-product_compare-index .table-comparison .product-item-actions {
  margin: 0; }

.catalog-product_compare-index .table-comparison .actions-secondary.actions-secondary.actions-secondary.actions-secondary {
  margin-right: 0; }

.catalog-product_compare-index .price-box.price-final_price {
  display: flex;
  justify-content: center; }

.catalog-product_compare-index .price {
  font-family: "Rift", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px; }

.catalog-product_compare-index .actions-primary {
  display: none; }

/* HEADER */
header {
  z-index: 10;
  position: sticky !important; }
  header .panel.header {
    display: block;
    max-width: 1406px !important;
    padding: 0 10px !important;
    height: 35px;
    margin-bottom: 0 !important; }
    header .panel.header [data-content-type="row"][data-appearance="contained"] {
      max-width: 100% !important; }
    header .panel.header [data-content-type="row"][data-appearance="contained"] [data-element="inner"] {
      padding: 0 !important;
      margin: 0 !important; }
    @media (min-width: 1024px) {
      header .panel.header {
        padding: 0 20px !important; } }
  header .minicart-wrapper {
    order: 11;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    margin-right: 10px;
    overflow: visible; }
    header .minicart-wrapper .action.showcart {
      position: relative;
      display: flex !important;
      align-self: center; }
      header .minicart-wrapper .action.showcart::after {
        display: none !important; }
      header .minicart-wrapper .action.showcart .counter.qty {
        top: -15px;
        right: -4px;
        background-color: #FF7900; }
  header .header-links-holder {
    display: flex;
    justify-content: flex-end;
    width: 175px; }
    header .header-links-holder .minicart-wrapper,
    header .header-links-holder .compare.wrapper,
    header .header-links-holder .block-search {
      flex: 0 0 0;
      margin-top: 0;
      margin-bottom: 0; }
  header .minicart-wrapper {
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 1024px) {
      header .minicart-wrapper {
        padding-left: 40px; } }
  header .action.compare {
    display: flex;
    justify-content: center;
    width: 27px;
    margin: auto;
    position: relative; }
    @media (max-width: 1023px) {
      header .action.compare {
        margin-top: 1px; }
        header .action.compare .compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items.compare-qty-items {
          top: -2px; } }
    @media (min-width: 1024px) {
      header .action.compare {
        padding-left: 40px; } }
  header .block-search {
    padding-right: 0; }
    @media (min-width: 1024px) {
      header .block-search .block-content {
        position: relative; } }
    header .block-search form {
      position: absolute;
      width: 200px;
      right: 0;
      top: 4px; }
    @media (min-width: 1024px) {
      header .block-search .search-toggle-mobile {
        display: none; } }
    @media (max-width: 1023px) {
      header .block-search .search-toggle-mobile {
        border: none;
        background: none;
        margin-top: 3px; }
        header .block-search .search-toggle-mobile svg {
          width: 22.45px;
          height: 22.45px; } }
    @media (min-width: 1024px) {
      header .block-search .minisearch .action.search svg {
        display: none; } }
    @media (max-width: 1023px) {
      header .block-search .minisearch {
        display: block; }
        header .block-search .minisearch.active {
          display: block;
          top: 100%;
          right: 0;
          z-index: 999;
          max-width: 414px;
          width: 100%;
          max-height: 566px;
          height: 75vh;
          padding: 25px;
          box-sizing: border-box;
          background: white; }
          header .block-search .minisearch.active .control {
            flex: 1 0 100%;
            border: none;
            max-height: 80vh; }
            header .block-search .minisearch.active .control input {
              position: static;
              width: 100%;
              height: 47px;
              border-radius: 2px 2px 0 0;
              border-bottom: 3px solid black;
              box-sizing: border-box;
              font-size: 13px; }
          header .block-search .minisearch.active .label {
            display: none; }
          header .block-search .minisearch.active .action.search {
            position: absolute;
            top: 41px;
            right: 20px;
            display: block;
            background: none !important;
            border: none !important;
            opacity: 1; }
            header .block-search .minisearch.active .action.search::before {
              display: none; } }
  header .compare.wrapper {
    display: none; }
    @media (min-width: 1024px) {
      header .compare.wrapper {
        order: 10;
        display: block;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        position: relative;
        float: none;
        list-style: none;
        padding: 0;
        margin: 1px 0 0 0; }
        header .compare.wrapper .link.compare {
          margin: 0; }
          header .compare.wrapper .link.compare .action.compare {
            display: flex;
            text-decoration: none; }
            header .compare.wrapper .link.compare .action.compare.no-display {
              display: none !important; }
            header .compare.wrapper .link.compare .action.compare .compare-icon:before {
              content: "\f0ec";
              font-family: "FontAwesome";
              font-size: 27.2px;
              font-weight: 100;
              color: #000000; } }
        @media (min-width: 1024px) and (max-width: 1023px) {
          header .compare.wrapper .link.compare .action.compare .compare-icon:before {
            font-size: 24px; } }
    @media (min-width: 1024px) {
            header .compare.wrapper .link.compare .action.compare .compare-qty-items {
              border-radius: 50%;
              width: 18px;
              height: 18px;
              position: absolute;
              background: #FF7900;
              min-width: 12px;
              top: -8px;
              right: -10px; }
              header .compare.wrapper .link.compare .action.compare .compare-qty-items .counter {
                font-size: 0.8em;
                color: #fff;
                text-align: center;
                vertical-align: top;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center; }
                header .compare.wrapper .link.compare .action.compare .compare-qty-items .counter:before, header .compare.wrapper .link.compare .action.compare .compare-qty-items .counter:after {
                  content: none; } }

.header-cms-content {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 0 !important; }
  .header-cms-content * {
    font-family: "Rift", sans-serif;
    font-weight: bold; }
  .header-cms-content .social-wrapper,
  .header-cms-content .shipping,
  .header-cms-content .top-links {
    flex-grow: 1; }
  .header-cms-content .social-wrapper {
    margin-bottom: 0 !important;
    padding: 0;
    display: flex;
    align-items: center; }
    .header-cms-content .social-wrapper a {
      height: 20px;
      margin: 0 9px; }
      .header-cms-content .social-wrapper a svg path {
        fill: #ffffff; }
      .header-cms-content .social-wrapper a:hover svg path {
        fill: #FF7900; }
      .header-cms-content .social-wrapper a:first-child {
        margin-left: 0; }
      .header-cms-content .social-wrapper a:last-child {
        margin-right: 0; }
  .header-cms-content .shipping {
    flex: 1;
    font-size: 15px;
    line-height: 22px;
    pointer-events: none; }
  .header-cms-content .top-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%; }
    .header-cms-content .top-links a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 10px;
      padding-right: 0;
      font-family: "Rift", sans-serif;
      white-space: nowrap; }
      .header-cms-content .top-links a span {
        font-size: 1.4rem;
        line-height: 17px;
        margin-left: 8px;
        color: #ffffff;
        font-family: inherit;
        font-weight: 700; }
      .header-cms-content .top-links a svg path {
        fill: #ffffff; }
      .header-cms-content .top-links a:last-child {
        margin-right: 0; }
      .header-cms-content .top-links a:hover {
        text-decoration: none; }
        .header-cms-content .top-links a:hover span {
          color: #FF7900; }
        .header-cms-content .top-links a:hover svg path {
          fill: #FF7900;
          stroke: #FF7900; }

@media (min-width: 1024px) {
  .rootmenu > .rootmenu-list .root-col-1 {
    min-height: auto !important; }
  header {
    /* Bottom Row of Header */ }
    header .mobile-only,
    header .mobile-header {
      display: none; }
    header .header.content {
      max-width: 1406px !important;
      margin-bottom: 0;
      padding: 0 10px 0 20px !important;
      display: flex;
      align-items: center;
      justify-content: center; }
      header .header.content a.logo {
        margin: 0;
        line-height: 0; }
      header .header.content .sections.nav-sections {
        flex: 2 1 auto;
        width: fit-content;
        bottom: unset;
        text-align: center;
        background-color: transparent; }
        header .header.content .sections.nav-sections #store\.menu > div > div > div {
          padding: 0 !important;
          margin: 0 !important; }
        header .header.content .sections.nav-sections .page-main {
          width: fit-content;
          margin: 0 auto; }
          header .header.content .sections.nav-sections .page-main .rootmenu-list {
            width: fit-content;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center; }
  /* Menu */
  .rootmenu a .tabimgtext.tabimgtext.tabimgtext {
    font-family: "Rift", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 7px;
    padding-bottom: 14px !important;
    transition: color 0.2s linear; }
  .rootmenu a .childtext {
    color: #333;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 27px;
    transition: color 0.2s linear; }
  .rootmenu a:hover .tabimgtext,
  .rootmenu a:hover .childtext {
    color: #FF7900 !important; }
  .rootmenu .tabimgwpr,
  .rootmenu .verticalopen {
    min-height: unset !important;
    height: unset !important;
    border: 0 !important; }
    .rootmenu .tabimgwpr .desktop-only,
    .rootmenu .verticalopen .desktop-only {
      padding: 10px; }
  .rootmenu .tabimgwpr {
    flex-grow: 1;
    flex-shrink: 1 !important;
    max-width: 200px;
    width: auto;
    min-width: 0;
    padding-left: 20px !important;
    padding-right: 20px !important;
    white-space: nowrap; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .rootmenu .tabimgwpr {
      padding-left: 25px !important;
      padding-right: 25px !important; } }

@media (min-width: 1024px) {
    .rootmenu .tabimgwpr ul {
      padding: 0; }
  .rootmenu .mainmenuwrap {
    max-width: 1426px !important;
    margin: 0 auto;
    padding: 0; }
  .rootmenu .verticalopen {
    display: flex !important;
    position: relative !important;
    align-items: flex-start;
    justify-content: flex-start;
    right: unset !important;
    left: unset !important;
    top: 0 !important;
    bottom: unset !important;
    margin: 0 auto !important;
    width: 100% !important;
    float: none !important;
    padding: 20px 0 !important;
    background: #fff !important; }
    .rootmenu .verticalopen li {
      flex-shrink: 0; }
  .rootmenu > .rootmenu-list > li > a {
    padding: 0px 22px;
    font-family: "Rift", sans-serif;
    font-weight: bold;
    font-size: 20px;
    transition: color 0.2s linear; }
  .rootmenu > .rootmenu-list > li:hover > a {
    background-color: transparent;
    color: #FF7900 !important; }
    .rootmenu > .rootmenu-list > li:hover > a::before {
      content: "";
      position: absolute;
      top: -2rem;
      bottom: -2rem;
      left: -2.5rem;
      right: -2.5rem; }
    .rootmenu > .rootmenu-list > li:hover > a::after {
      content: "";
      position: absolute;
      bottom: -2rem;
      left: -3.5rem;
      right: -3.5rem;
      height: 2rem;
      width: auto;
      border: none; }
  .rootmenu > .rootmenu-list .root-col-5 {
    width: 100% !important;
    max-width: 125px !important;
    padding: 0 !important;
    margin: 10px 24px; }
  .submenu-title ul li {
    margin-bottom: 8px; }
  /* Megamenu */
  .megamenu {
    min-height: 0 !important;
    height: 486px !important;
    position: absolute;
    padding-top: 40px;
    padding-bottom: 40px;
    top: 100% !important;
    left: 0;
    right: 0;
    display: none; }
    .megamenu .desktop-only,
    .megamenu .tabimgwpr {
      padding-left: 20px;
      padding-right: 20px; }
    .megamenu .tabimgwpr ul li a,
    .megamenu .tabimgwpr ul li a div {
      font-family: "Roboto", sans-serif !important;
      width: fit-content;
      display: block;
      padding: 1.5px 0; }
    .rootmenu > .rootmenu-list > li:hover > .megamenu {
      display: block; }
  .catalog-article {
    background-position: center;
    background-size: cover;
    width: 380px;
    max-width: 380px;
    padding-top: 100%;
    position: relative; }
    .catalog-article:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.38);
      z-index: 1; }
    .catalog-article .content {
      z-index: 2;
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 43px;
      text-align: center; }
      .catalog-article .content h2 {
        font-family: "Rift", sans-serif;
        font-weight: bold;
        font-size: 73px;
        line-height: 1;
        letter-spacing: 14.6px;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        text-shadow: 0px 3px 6px #00000052; }
      .catalog-article .content h4 {
        font-family: "Rift", sans-serif;
        font-weight: normal;
        font-size: 21px;
        line-height: 26px;
        color: #ffffff;
        margin-top: 7px;
        margin-bottom: 16px;
        text-shadow: 0px 3px 6px #0000004d; }
      .catalog-article .content .btn {
        display: inline-block; }
  .field.search label[data-role="minisearch-label"] {
    left: auto;
    right: 20px; }
    .field.search label[data-role="minisearch-label"]:before {
      display: none !important; }
    .field.search label[data-role="minisearch-label"] svg {
      display: block;
      margin: 3px; }
  .field.search .control input {
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-width 0.2s linear, visibility 0.2s linear; }
    .field.search .control input:focus {
      max-width: 200px;
      visibility: visible; }
  .header.content,
  .nav-sections,
  .nav-sections-items,
  .rootmenu {
    position: static !important; } }

@media (max-width: 1023px) {
  .desktop-only {
    display: none; }
  .rootmenu > .rootmenu-list > li:hover > a::after {
    display: none !important; }
  .rootmenu > .rootmenu-list .rootmenu-click {
    display: flex;
    padding-left: 15px;
    height: 70px;
    align-items: center;
    padding-right: 25px; }
  .rootmenu > .rootmenu-list .submenu-title .rootmenu-click {
    height: 50px; }
  .rootmenu-list > li .megamenu.tabmenu {
    opacity: 1 !important;
    visibility: visible !important; }
  header .panel.header .social-wrapper,
  header .panel.header .top-links {
    display: none; }
  header .showcart {
    align-self: center; }
    header .showcart .qty {
      height: 15px !important;
      line-height: 15px !important;
      min-width: 11px !important;
      padding: 0 2px !important;
      top: -10px !important;
      right: 8px !important; }
      header .showcart .qty .counter-number {
        font-size: 11px; }
  header .header.content {
    height: 60px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap; }
    header .header.content::before, header .header.content::after {
      display: none; }
    header .header.content .nav-toggle {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      flex: 0;
      margin-right: auto; }
    header .header.content a.logo {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 0;
      transform: translate(-50%, -50%);
      margin: 0;
      width: 132px;
      line-height: 0; }
      header .header.content a.logo svg {
        width: 100%;
        height: auto; }
    header .header.content .minicart-wrapper {
      overflow: visible;
      margin: 0;
      flex: unset; }
      header .header.content .minicart-wrapper .action.showcart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-left: 10px;
        margin-right: 5px; }
        header .header.content .minicart-wrapper .action.showcart svg {
          width: 23.86px;
          height: 21.84px; }
        header .header.content .minicart-wrapper .action.showcart:after {
          margin-top: 20px; }
        header .header.content .minicart-wrapper .action.showcart .counter.qty {
          top: -5px;
          right: 10px;
          background-color: #FF7900;
          width: 17px;
          height: 17px !important;
          margin: 10px -14px 0 0;
          padding: 0 !important;
          line-height: 16px !important; }
  html .nav-sections-item-content {
    padding: 0; }
  html .nav-sections {
    left: -100% !important;
    right: auto !important;
    width: 100%;
    transition: left 0.2s linear; }
    html .nav-sections .page-main.page-main {
      padding: 0; }
    html .nav-sections .social-wrapper,
    html .nav-sections .top-links {
      display: none !important; }
    html .nav-sections [data-content-type="row"][data-appearance="contained"] [data-element="inner"] {
      padding: 0 !important;
      margin: 0 !important;
      background-color: #000000; }
      html .nav-sections [data-content-type="row"][data-appearance="contained"] [data-element="inner"] .shipping {
        color: #ffffff; }
    html .nav-sections .mobile-only {
      display: block; }
      html .nav-sections .mobile-only .contact-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        min-width: fit-content;
        max-width: fit-content;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto; } }
      @media (max-width: 1023px) and (max-width: 767.98px) {
        html .nav-sections .mobile-only .contact-wrapper {
          margin-left: 0;
          padding-left: 2rem; } }

@media (max-width: 1023px) {
        html .nav-sections .mobile-only .contact-wrapper .wrap {
          display: flex;
          flex-direction: column;
          text-align: center; }
          html .nav-sections .mobile-only .contact-wrapper .wrap p {
            color: #000; } }
        @media (max-width: 1023px) and (max-width: 767.98px) {
          html .nav-sections .mobile-only .contact-wrapper .wrap {
            text-align: left; } }

@media (max-width: 1023px) {
        html .nav-sections .mobile-only .contact-wrapper a:not(.btn) {
          font-family: "Rift", sans-serif;
          font-weight: bold;
          font-size: 37px;
          line-height: 44px;
          text-decoration: none;
          transition: color 0.2 linear;
          color: #000000; }
          html .nav-sections .mobile-only .contact-wrapper a:not(.btn):hover {
            color: #FF7900; }
        html .nav-sections .mobile-only .contact-wrapper .btn {
          margin-left: auto;
          margin-right: auto; } }
        @media (max-width: 1023px) and (max-width: 767.98px) {
          html .nav-sections .mobile-only .contact-wrapper .btn {
            margin-left: 0; } }

@media (max-width: 1023px) {
        html .nav-sections .mobile-only .contact-wrapper .social-wrapper {
          display: flex !important;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          width: 100%; } }
        @media (max-width: 1023px) and (max-width: 767.98px) {
          html .nav-sections .mobile-only .contact-wrapper .social-wrapper {
            justify-content: flex-start; } }

@media (max-width: 1023px) {
          html .nav-sections .mobile-only .contact-wrapper .social-wrapper a {
            margin: 0 23px; }
            html .nav-sections .mobile-only .contact-wrapper .social-wrapper a:first-child {
              margin: 0; }
            html .nav-sections .mobile-only .contact-wrapper .social-wrapper a:last-child {
              margin: 0; }
            html .nav-sections .mobile-only .contact-wrapper .social-wrapper a svg path {
              fill: #000000; }
            html .nav-sections .mobile-only .contact-wrapper .social-wrapper a:hover svg path {
              fill: #FF7900; }
      html .nav-sections .mobile-only .logins {
        display: flex;
        align-items: center;
        justify-content: center; } }
      @media (max-width: 1023px) and (max-width: 767.98px) {
        html .nav-sections .mobile-only .logins {
          flex-direction: column;
          align-items: flex-start;
          padding: 1rem 2rem; } }

@media (max-width: 1023px) {
        html .nav-sections .mobile-only .logins a {
          width: 50%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Rift", sans-serif;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          transition: color 0.2s linear;
          text-decoration: none; }
          html .nav-sections .mobile-only .logins a svg {
            margin-right: 7px; }
            html .nav-sections .mobile-only .logins a svg path {
              transition: fill 0.2s linear;
              fill: #000000; }
          html .nav-sections .mobile-only .logins a:hover {
            color: #FF7900; }
            html .nav-sections .mobile-only .logins a:hover svg path {
              fill: #FF7900; } }
        @media (max-width: 1023px) and (max-width: 767.98px) {
          html .nav-sections .mobile-only .logins a {
            height: auto;
            width: auto;
            padding: 1rem 0; } }

@media (max-width: 1023px) {
    html .nav-sections .mobile-header {
      display: flex;
      height: 60px;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      html .nav-sections .mobile-header a {
        width: fit-content;
        height: fit-content;
        height: 27px;
        display: flex;
        align-items: center; }
      html .nav-sections .mobile-header .close {
        position: absolute;
        right: 9px;
        cursor: pointer;
        background: none;
        border: none; }
        html .nav-sections .mobile-header .close:focus, html .nav-sections .mobile-header .close:hover {
          box-shadow: 0 0 3px 1px rgba(35, 31, 32, 0.2); }
  html.nav-open .nav-sections {
    left: 0 !important; }
  html.nav-open .page-wrapper {
    right: auto; }
  html .header-cms-content {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-top: 0 !important; }
    html .header-cms-content * {
      font-family: "Rift", sans-serif;
      font-weight: bold; }
    html .header-cms-content .social-wrapper,
    html .header-cms-content .shipping,
    html .header-cms-content .top-links {
      flex-basis: 0;
      flex-grow: 1; }
    html .header-cms-content .social-wrapper {
      margin-bottom: 0 !important;
      padding: 0;
      display: flex;
      align-items: center; }
      html .header-cms-content .social-wrapper a {
        height: 20px;
        margin: 0 9px; }
        html .header-cms-content .social-wrapper a svg path {
          fill: #ffffff; }
        html .header-cms-content .social-wrapper a:hover svg path {
          fill: #FF7900; }
        html .header-cms-content .social-wrapper a:first-child {
          margin-left: 0; }
        html .header-cms-content .social-wrapper a:last-child {
          margin-right: 0; }
    html .header-cms-content .shipping {
      font-size: 15px;
      line-height: 22px;
      pointer-events: none; }
    html .header-cms-content .top-links {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      html .header-cms-content .top-links a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: fit-content;
        text-decoration: none;
        margin-left: 20px;
        margin-right: 20px; }
        html .header-cms-content .top-links a span {
          font-size: 14px;
          line-height: 17px;
          margin-left: 8px;
          color: #ffffff; }
        html .header-cms-content .top-links a svg path {
          fill: #ffffff; }
        html .header-cms-content .top-links a:last-child {
          margin-right: 0; }
        html .header-cms-content .top-links a:hover span {
          color: #FF7900; }
        html .header-cms-content .top-links a:hover svg path {
          fill: #FF7900;
          stroke: #FF7900; }
  html .rootmenu .rootmenu-list > li {
    position: relative; }
  html .rootmenu .rootmenu-list > li > a {
    border-bottom: solid 1px #4c4c4c;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #4c4c4c; }
  html .rootmenu .rootmenu > .rootmenu-list .megamenu {
    visibility: visible !important;
    opacity: 1 !important; }
  html .rootmenu .rootmenu-list.mobile-sub .rootmenu-arrow {
    margin: 0 !important;
    background-image: url(../images/right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 14px;
    transform: rotate(90deg); }
    html .rootmenu .rootmenu-list.mobile-sub .rootmenu-arrow.rootmenu-rotate {
      transform: rotate(270deg); }
  html .rootmenu .rootmenu-list.mobile-sub .submenu-title .rootmenu-arrow {
    border-color: #000; }
  html .rootmenu .rootmenu-list.mobile-sub > li > a {
    background-color: #000000 !important;
    color: #ffffff !important;
    padding: 17px 34px !important;
    font-family: "Rift", sans-serif;
    font-weight: 700;
    font-size: 20px; }
    html .rootmenu .rootmenu-list.mobile-sub > li > a.active {
      color: #ffffff !important; }
  html .rootmenu .rootmenu-list.mobile-sub > li:hover > a {
    background-color: #000000 !important; }
  html .rootmenu .rootmenu-list.mobile-sub > li .root-col-4 {
    display: none; }
  html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen {
    right: auto;
    min-height: 500px;
    padding: 0;
    position: relative !important; }
    html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr {
      min-height: 0 !important;
      padding: 0;
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      float: none;
      width: 100%; }
      html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr .root-col-75 {
        padding: 0; }
      html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr .submenu-title {
        width: 100%;
        position: relative; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr .submenu-title .rootmenu-click .rootmenu-arrow {
          position: relative;
          left: 1px;
          margin-top: 14px !important;
          margin-bottom: auto !important;
          background-image: url(../images/right-dark.svg); }
      html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr ul {
        border-top: 1px solid #dddddd;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s linear, padding 0.3s linear;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr ul li {
          width: calc(50% - 76px);
          margin-left: 38px;
          margin-right: 38px; }
          html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr ul li a {
            display: block;
            margin: 5px 0; }
            html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr ul li a div {
              font-family: inherit; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr ul.open {
          padding: 26px 0;
          max-height: 100vh;
          border-bottom: 1px solid #dddddd; }
      html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr a:not(.tabimtag) {
        width: fit-content; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr a:not(.tabimtag) .tabimgtext {
          padding: 0 !important; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr a:not(.tabimtag):hover div {
          color: #FF7900 !important; }
      html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr a.tabimtag {
        width: 100%;
        clear: both;
        padding: 18px 34px;
        font-size: 20px;
        font-family: "Rift", sans-serif; }
        html .rootmenu .rootmenu-list.mobile-sub > li .verticalopen .tabimgwpr a.tabimtag div {
          padding: 0;
          font-size: inherit !important; }
  html .rootmenu-list {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important; }
    html .rootmenu-list > li {
      width: 100%;
      display: block !important; }
      html .rootmenu-list > li > a,
      html .rootmenu-list > li > a.active {
        text-align: left;
        color: #ffffff !important;
        background: #000000 !important; }
      html .rootmenu-list > li:hover > a {
        background-color: transparent !important; }
      html .rootmenu-list > li .megamenu.tabmenu {
        height: auto !important;
        min-height: auto !important;
        position: relative;
        top: 0;
        padding: 0;
        width: 100%;
        transform: none; }
        html .rootmenu-list > li .megamenu.tabmenu .vertical-menu {
          min-height: auto !important; }
          html .rootmenu-list > li .megamenu.tabmenu .vertical-menu .verticalopen {
            height: auto !important;
            width: 100% !important;
            right: 0 !important;
            background-color: #ffffff; }
            html .rootmenu-list > li .megamenu.tabmenu .vertical-menu .verticalopen .root-col-5 {
              float: none !important;
              min-height: 0 !important;
              height: fit-content !important;
              position: relative;
              padding: 0 20px;
              left: 50% !important;
              right: 50% !important;
              margin-left: -50vw !important;
              margin-right: -50vw !important;
              border-bottom: 1px solid #dddddd; }
              html .rootmenu-list > li .megamenu.tabmenu .vertical-menu .verticalopen .root-col-5:last-child {
                border-bottom: 0; } }

.block-search {
  position: static; }
  .block-search form {
    position: relative;
    display: flex;
    align-items: right;
    justify-content: center; }
    .block-search form .field.search {
      position: relative;
      display: flex;
      align-items: right;
      justify-content: center;
      width: 100%; }
      .block-search form .field.search label {
        position: absolute;
        right: 10px;
        top: 2px;
        right: 5px;
        margin: 0 !important; }
      .block-search form .field.search svg {
        margin: 0 !important;
        height: 25px !important; }
    @media (min-width: 1024px) {
      .block-search form input {
        padding: 0 !important;
        height: 0 !important;
        border: 0 transparent;
        overflow: hidden; }
        .block-search form input:focus {
          padding: 14px 30px 14px 14px !important;
          border-bottom: 2px solid #000000 !important; } }

@media (min-width: 1024px) {
  html .rootmenu .rootmenu-list > li:last-child > a {
    font-style: italic;
    color: #ff0800 !important; }
    html .rootmenu .rootmenu-list > li:last-child > a:hover, html .rootmenu .rootmenu-list > li:last-child > a.active {
      color: #FF7900 !important; }
  html .rootmenu .rootmenu-list > li .megamenu {
    transform: none;
    transition: none;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029; }
  html .rootmenu .rootmenu-list > li > .rootmenu-submenu {
    top: 100%;
    border: none;
    box-shadow: 0 3px 6px #00000029; }
    html .rootmenu .rootmenu-list > li > .rootmenu-submenu .cat-arrow {
      background-image: url(../images/right-dark.svg); }
    html .rootmenu .rootmenu-list > li > .rootmenu-submenu a {
      font-family: "Roboto", sans-serif;
      font-size: 13px !important;
      text-decoration: none; }
    html .rootmenu .rootmenu-list > li > .rootmenu-submenu .rootmenu-submenu-sub {
      top: 0;
      left: 100%;
      border: none;
      box-shadow: 0 3px 6px #00000029; }
      html .rootmenu .rootmenu-list > li > .rootmenu-submenu .rootmenu-submenu-sub .cat-arrow {
        display: none; }
  html .rootmenu .rootmenu-list > li .rootmenu-submenu-sub-sub {
    display: none !important; } }

@media (max-width: 1023px) {
  html .rootmenu .rootmenu-list.mobile-sub > li:last-child > a {
    padding: 17px 34px !important;
    font-size: 0;
    width: 100%;
    background-image: url(../images/shift-white.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 30px center !important;
    background-size: 94px 23px !important; }
  .nav-toggle:before {
    display: none !important; }
  .burger {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #757575;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    text-align: center;
    overflow: visible; }
    .burger div {
      width: 33px;
      height: 2px;
      background-color: #000000;
      margin-bottom: 6px; }
      .burger div:nth-child(2) {
        width: 75%; }
      .burger div:last-child {
        margin: 0;
        width: 50%; } }

.mini-block-search .label-search {
  cursor: pointer; }

.mini-block-search .label-mobile {
  display: none; }

#search_mini_form {
  width: 200px;
  height: 35px;
  top: 0; }
  #search_mini_form .input-search-image {
    display: none; }
  #search_mini_form .field.search .control {
    width: 100%;
    display: block; }
    #search_mini_form .field.search .control.inactive {
      display: none; }
  #search_mini_form .field.search label[data-role="minisearch-label"] {
    right: 0; }
  #search_mini_form .field.search input {
    position: relative;
    display: block;
    max-width: 331px;
    width: 100%;
    height: 35px; }
    @media (min-width: 1024px) {
      #search_mini_form .field.search input {
        top: 3px; } }
    @media (max-width: 400px) {
      #search_mini_form .field.search input {
        font-size: 16px; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .block-search .label {
    width: 27px;
    height: 35px; } }

@media screen and (max-width: 1023px) {
  .block-search .label {
    top: 4px;
    position: relative; }
    .block-search .label svg {
      position: relative;
      transform: scale(0.85); }
    .block-search .label:before {
      content: none; }
  header .header-links-holder {
    position: static;
    width: 100%; }
    header .header-links-holder .block-search {
      max-width: none !important;
      position: static;
      margin-left: 0 !important;
      flex: 1; }
      header .header-links-holder .block-search .label-mobile {
        display: block; }
        header .header-links-holder .block-search .label-mobile:before {
          content: none; }
      header .header-links-holder .block-search .block-content {
        width: 100%;
        position: static;
        display: block; }
        header .header-links-holder .block-search .block-content .search-wrapper.active {
          top: 96px !important;
          left: 50%;
          transform: translatex(-50%);
          position: fixed;
          width: 100vw !important;
          height: 100vh !important;
          max-width: none !important;
          overflow-x: hidden;
          overflow-y: hidden;
          background: rgba(0, 0, 0, 0.7); }
        header .header-links-holder .block-search .block-content form.minisearch {
          top: 100% !important;
          left: 0;
          position: absolute;
          width: 100% !important;
          max-width: none !important;
          overflow-x: hidden;
          overflow-y: hidden;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          display: none;
          max-height: 80vh !important;
          height: auto !important; }
          header .header-links-holder .block-search .block-content form.minisearch .input-search-image {
            position: absolute;
            right: 3px;
            top: 3px;
            display: block !important; }
          header .header-links-holder .block-search .block-content form.minisearch input#search {
            border: 0;
            background: none;
            outline: none;
            border-bottom: 2px solid #000000; }
          header .header-links-holder .block-search .block-content form.minisearch.active {
            display: block; }
          header .header-links-holder .block-search .block-content form.minisearch:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0; }
          header .header-links-holder .block-search .block-content form.minisearch .actions {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0; }
  .field.search {
    position: relative;
    height: 100%; }
    .field.search .twitter-typeahead {
      height: 90%;
      display: inline-block; }
  #search_mini_form .field.search input {
    max-width: none !important;
    width: 100%;
    margin: 0 auto;
    border: none;
    border-bottom: 2px solid black; } }

.rootmenu .empty_category {
  display: none !important; }

a.product-item-info.tt-suggestion.tt-selectable:hover {
  background-color: #eee !important; }

.header .header-cms-content {
  justify-content: center;
  height: 3.5rem; }
  .header .header-cms-content .social-wrapper .customer-greeting {
    margin: auto; }

.page-header {
  margin-bottom: 60px; }

header .header.content {
  justify-content: space-between; }
  header .header.content:before, header .header.content:after {
    display: none; }
  header .header.content .sections.nav-sections {
    order: 99;
    width: 100%;
    flex-basis: 100%;
    background-color: #efefef; }

nav.rootmenu {
  position: relative !important;
  left: 0;
  right: 0;
  background-color: #eeeeee !important;
  box-sizing: border-box; }
  @media (min-width: 1024px) {
    nav.rootmenu {
      margin-top: 17px; } }
  nav.rootmenu > ul > li > a {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }

@media (min-width: 1024px) {
  nav.rootmenu {
    position: absolute !important;
    bottom: -64px; }
  .header .megamenu {
    top: 100% !important;
    z-index: 999; } }

.header.wrapper {
  background: none !important; }

/* Home */
body.cms-index-index .page-title-wrapper {
  display: none; }

body.cms-index-index .column.main {
  padding: 0; }

body.cms-index-index .top-green-bar {
  position: absolute;
  top: 106px;
  width: 100%;
  left: 0; }
  @media (max-width: 1024px) {
    body.cms-index-index .top-green-bar {
      display: none !important; } }

body.cms-index-index .home-video-wrapper {
  position: relative;
  justify-content: center !important;
  max-height: 500px;
  height: auto;
  overflow: hidden; }
  @media (max-width: 1024px) {
    body.cms-index-index .home-video-wrapper {
      margin-top: 0 !important; } }
  @media (max-width: 1024px) {
    body.cms-index-index .home-video-wrapper {
      max-height: unset; } }

@media (max-width: 768px) {
  body.cms-index-index .home-video-player {
    display: none; } }

body.cms-index-index .home-boots-mobile {
  display: none; }
  @media (max-width: 768px) {
    body.cms-index-index .home-boots-mobile {
      display: block;
      width: 100%; }
      body.cms-index-index .home-boots-mobile img {
        width: 100%; } }

body.cms-index-index .home-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center; }
  body.cms-index-index .home-video-overlay h1 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15rem;
    line-height: 0.8em;
    font-weight: bold; }
    @media (max-width: 1024px) {
      body.cms-index-index .home-video-overlay h1 {
        font-size: 6rem; } }
    @media (max-width: 480px) {
      body.cms-index-index .home-video-overlay h1 {
        font-size: 4rem; } }
  body.cms-index-index .home-video-overlay a {
    font-family: rift, sans-serif;
    background-color: #C2332B;
    border-color: #C2332B;
    color: #ffffff;
    border-radius: 2px;
    padding: 1.5rem 3rem;
    font-size: 2rem;
    font-weight: bold;
    transition: background-color 0.2s ease;
    text-decoration: none; }
    body.cms-index-index .home-video-overlay a:hover {
      background-color: #000000; }
  @media (max-width: 768px) {
    body.cms-index-index .home-video-overlay {
      display: none; } }

body.cms-index-index .aw-rbslider-container {
  box-sizing: border-box;
  width: 100% !important;
  max-width: 1366px; }
  @media (min-width: 1024px) {
    body.cms-index-index .aw-rbslider-container {
      margin-top: 0;
      margin-bottom: 0; }
      body.cms-index-index .aw-rbslider-container:first-child {
        margin-top: 2rem; }
      body.cms-index-index .aw-rbslider-container:last-child {
        margin-bottom: 3.6rem; } }
  body.cms-index-index .aw-rbslider-container .uk-slideshow {
    overflow: hidden;
    background-color: #000000; }
  body.cms-index-index .aw-rbslider-container * {
    font-family: rift, sans-serif;
    font-weight: bold; }
  body.cms-index-index .aw-rbslider-container h2 {
    font-size: 73px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 14.6px;
    color: #ffffff;
    text-shadow: 0px 3px 6px #00000052; }
  body.cms-index-index .aw-rbslider-container h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 0;
    color: #ffffff; }
    @media (min-width: 1024px) {
      body.cms-index-index .aw-rbslider-container h3 {
        font-weight: 700;
        font-size: 22px;
        text-shadow: 0px 3px 6px #0000004D; } }
  body.cms-index-index .aw-rbslider-container div[data-content-type=row] {
    z-index: 10; }
  body.cms-index-index .aw-rbslider-container a.pagebuilder-button-primary {
    background-color: #FF7900;
    border-color: #FF7900;
    color: #ffffff;
    border-radius: 2px;
    padding: 8px 35px;
    transition: all 0.2s linear;
    font-size: 16px; }
    body.cms-index-index .aw-rbslider-container a.pagebuilder-button-primary:hover {
      border-color: #FFFFFF;
      background-color: #ffffff;
      color: #000000; }
    body.cms-index-index .aw-rbslider-container a.pagebuilder-button-primary::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #FF7900;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.2s linear; }
  body.cms-index-index .aw-rbslider-container .aw-rbslider-item:hover .pagebuilder-button-primary {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff; }
    body.cms-index-index .aw-rbslider-container .aw-rbslider-item:hover .pagebuilder-button-primary::before {
      opacity: 1; }
  body.cms-index-index .aw-rbslider-container .aw-rbslider-item:hover picture img {
    opacity: 0.2; }
  body.cms-index-index .aw-rbslider-container p {
    margin-bottom: 0; }
  body.cms-index-index .aw-rbslider-container em {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 391px;
    font-style: normal;
    z-index: -1;
    opacity: 0.21;
    letter-spacing: 78.2px;
    text-shadow: 0px 3px 6px #00000052;
    white-space: nowrap; }
  body.cms-index-index .aw-rbslider-container picture img {
    opacity: 0.5;
    object-fit: cover;
    transition: opacity 0.2 linear; }
  body.cms-index-index .aw-rbslider-container .aw-rbslider-item,
  body.cms-index-index .aw-rbslider-container picture img {
    height: 568px !important; }
  body.cms-index-index .aw-rbslider-container ul.uk-dotnav {
    display: flex;
    margin: 0 !important; }
    body.cms-index-index .aw-rbslider-container ul.uk-dotnav li {
      margin-top: 38px;
      margin-bottom: 38px;
      max-width: 100px;
      padding-left: 5px;
      padding-right: 5px; }
      body.cms-index-index .aw-rbslider-container ul.uk-dotnav li a {
        background-color: #FFFFFF;
        border-radius: 0;
        width: 100px;
        height: 3px;
        margin-left: 5px;
        margin-right: 5px;
        box-shadow: none; }
      body.cms-index-index .aw-rbslider-container ul.uk-dotnav li.uk-active a {
        background-color: #000000; }

body.cms-index-index .desktop-home-categories,
body.cms-index-index .boot-categories-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    body.cms-index-index .desktop-home-categories,
    body.cms-index-index .boot-categories-mobile {
      display: none; }
      body.cms-index-index .desktop-home-categories.boot-categories-mobile,
      body.cms-index-index .boot-categories-mobile.boot-categories-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        body.cms-index-index .desktop-home-categories.boot-categories-mobile .category,
        body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 1rem; } }
  body.cms-index-index .desktop-home-categories *,
  body.cms-index-index .boot-categories-mobile * {
    font-family: rift, sans-serif;
    font-weight: bold; }
  body.cms-index-index .desktop-home-categories .category,
  body.cms-index-index .boot-categories-mobile .category {
    width: 100%;
    max-width: 100%;
    margin-left: 5px;
    margin-right: 5px; }
    body.cms-index-index .desktop-home-categories .category:first-child,
    body.cms-index-index .boot-categories-mobile .category:first-child {
      margin-left: 0; }
    body.cms-index-index .desktop-home-categories .category:last-child,
    body.cms-index-index .boot-categories-mobile .category:last-child {
      margin-right: 0; }
    body.cms-index-index .desktop-home-categories .category .wrapper,
    body.cms-index-index .boot-categories-mobile .category .wrapper {
      width: 100%;
      height: 315px;
      overflow: hidden; }
      body.cms-index-index .desktop-home-categories .category .wrapper a:not(.boots-button),
      body.cms-index-index .boot-categories-mobile .category .wrapper a:not(.boots-button) {
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 315px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 4.2px;
        color: #ffffff;
        text-decoration: none;
        transition: all 0.2s linear; }
        body.cms-index-index .desktop-home-categories .category .wrapper a:not(.boots-button):hover,
        body.cms-index-index .boot-categories-mobile .category .wrapper a:not(.boots-button):hover {
          color: #FF7900;
          transform: scale(1.05); }
    body.cms-index-index .desktop-home-categories .category ul,
    body.cms-index-index .boot-categories-mobile .category ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      body.cms-index-index .desktop-home-categories .category ul li,
      body.cms-index-index .boot-categories-mobile .category ul li {
        margin: 28px 19.5px 53px 19.5px;
        text-align: center; }
        @media (max-width: 767px) {
          body.cms-index-index .desktop-home-categories .category ul li,
          body.cms-index-index .boot-categories-mobile .category ul li {
            margin: 30px 19.5px 25px 19.5px; } }
        body.cms-index-index .desktop-home-categories .category ul li:first-child,
        body.cms-index-index .boot-categories-mobile .category ul li:first-child {
          margin-left: 0; }
        body.cms-index-index .desktop-home-categories .category ul li:last-child,
        body.cms-index-index .boot-categories-mobile .category ul li:last-child {
          margin-right: 0; }
        body.cms-index-index .desktop-home-categories .category ul li a,
        body.cms-index-index .boot-categories-mobile .category ul li a {
          color: #000000;
          font-size: 16px;
          line-height: 20px;
          text-decoration: underline;
          transition: color 0.2s linear; }
          body.cms-index-index .desktop-home-categories .category ul li a:hover,
          body.cms-index-index .boot-categories-mobile .category ul li a:hover {
            color: #FF7900; }
  body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper,
  body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper {
    height: 475px; }
    body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link,
    body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link {
      background-size: cover;
      background-color: #000000;
      background-position: center;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      align-items: center;
      transition: all 1s ease;
      color: #ffffff; }
      body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link h2,
      body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link h2 {
        font-size: 4.5rem;
        font-weight: bold;
        letter-spacing: 1rem; }
      body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link p,
      body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link p {
        font-family: roboto, sans-serif;
        color: #ffffff; }
      body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link .boots-button,
      body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link .boots-button {
        font-family: rift, sans-serif;
        background-color: #C2332B;
        border-color: #C2332B;
        color: #ffffff;
        border-radius: 2px;
        padding: 1.5rem 3rem;
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: bold;
        transition: background-color 0.2s ease;
        text-decoration: none;
        max-width: 50%;
        margin: 6rem 0 3rem; }
        body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link .boots-button.motion,
        body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link .boots-button.motion {
          background-color: #ffffff;
          color: #000000; }
        body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link .boots-button.comp,
        body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link .boots-button.comp {
          background-color: #000000;
          color: #ffffff; }
        body.cms-index-index .desktop-home-categories.boot-categories-mobile .category .wrapper .boots-link .boots-button:hover,
        body.cms-index-index .boot-categories-mobile.boot-categories-mobile .category .wrapper .boots-link .boots-button:hover {
          background-color: #000000;
          color: #ffffff; }

body.cms-index-index ul.mobile-categories {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  align-items: center;
  justify-content: center; }
  body.cms-index-index ul.mobile-categories li {
    margin: 28px 19.5px 53px 19.5px;
    text-align: center; }
    body.cms-index-index ul.mobile-categories li:first-child {
      margin-left: 0; }
    body.cms-index-index ul.mobile-categories li:last-child {
      margin-right: 0; }
    body.cms-index-index ul.mobile-categories li a {
      color: #000000;
      font-family: rift, sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-decoration: underline;
      transition: color 0.2s linear; }
      body.cms-index-index ul.mobile-categories li a:hover {
        color: #FF7900; }

body.cms-index-index .desktop-home-clearance-banner {
  position: relative;
  height: 160px;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center; }
  body.cms-index-index .desktop-home-clearance-banner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FF7900;
    opacity: 0.85;
    z-index: 1; }
  body.cms-index-index .desktop-home-clearance-banner h2 {
    color: #ffffff;
    text-shadow: #00000052 0px 3px 6px;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 4.2px;
    font-family: rift, sans-serif;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
    z-index: 2; }
  body.cms-index-index .desktop-home-clearance-banner a {
    background-color: #000000;
    color: #ffffff;
    font-family: rift, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-shadow: #00000052 0px 3px 6px;
    padding: 5px 49px;
    z-index: 2;
    text-decoration: none;
    transition: all 0.2s linear; }
    body.cms-index-index .desktop-home-clearance-banner a:hover {
      background-color: #ffffff;
      color: #FF7900; }

body.cms-index-index div[data-aw-rbslider-banner-id='4'] {
  width: 100vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 0; }
  body.cms-index-index div[data-aw-rbslider-banner-id='4'] img {
    opacity: 1; }
  body.cms-index-index div[data-aw-rbslider-banner-id='4'] * {
    max-height: 460px; }

body.cms-index-index .block-new-products.widget {
  margin-top: 40px;
  margin-bottom: 30px; }
  body.cms-index-index .block-new-products.widget h2 {
    font-weight: 700;
    color: #000000;
    font-size: 32px;
    letter-spacing: 3.2px;
    text-align: center; }
  @media (max-width: 767px) {
    body.cms-index-index .block-new-products.widget.widget {
      margin-top: 0;
      margin-bottom: 0; }
    body.cms-index-index .block-new-products.widget h2 {
      margin: 0;
      font-size: 22px;
      letter-spacing: 2.2px; }
    body.cms-index-index .block-new-products.widget .product-item:nth-child(4) ~ .product-item {
      display: none !important; } }

.join-fox-crew-banner {
  background-position: center;
  background-size: cover;
  height: 265px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .join-fox-crew-banner h3 {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 3.2px;
    margin-top: 0;
    margin-bottom: 10px;
    color: #ffffff; }
  .join-fox-crew-banner p {
    color: #ffffff; }

.shop-lifestyle {
  display: none;
  width: 100vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center; }
  .shop-lifestyle h2 {
    display: none; }
  .shop-lifestyle > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    .shop-lifestyle > ul li {
      width: 33.33%;
      height: 460px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: cover; }
      .shop-lifestyle > ul li a {
        color: #ffffff;
        background-color: #000000;
        padding: 10px 30px;
        font-weight: bold;
        font-family: rift, sans-serif;
        text-decoration: none;
        font-size: 16px;
        line-height: 20px; }

.view-articles {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px; }
  .view-articles h2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: rift, sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px; }

@media (min-width: 768px) {
  div[data-aw-rbslider-banner-id='4'] {
    display: none; } }

@media (max-width: 767px) {
  body.cms-index-index .aw-rbslider-container {
    width: 100vw !important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: 0; }
    body.cms-index-index .aw-rbslider-container .uk-slideshow {
      overflow: hidden; }
      body.cms-index-index .aw-rbslider-container .uk-slideshow[style*='height'] {
        height: 480px !important; }
    body.cms-index-index .aw-rbslider-container .aw-rbslider-item,
    body.cms-index-index .aw-rbslider-container picture img {
      height: 480px !important; }
    body.cms-index-index .aw-rbslider-container h2,
    body.cms-index-index .aw-rbslider-container h3 {
      margin-bottom: 0; }
    body.cms-index-index .aw-rbslider-container h3 {
      max-width: 302px;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto; }
    body.cms-index-index .aw-rbslider-container em {
      display: none; }
  body.cms-index-index ul.mobile-categories {
    display: none; }
  body.cms-index-index [data-content-type='html'] {
    display: flex;
    flex-direction: column; }
    body.cms-index-index [data-content-type='html'] .block-new-products {
      order: -1; }
    body.cms-index-index [data-content-type='html'] div[data-aw-rbslider-banner-id='4'] {
      order: 1; }
    body.cms-index-index [data-content-type='html'] div[data-aw-rbslider-banner-id='4'] + div {
      order: 2; }
    body.cms-index-index [data-content-type='html'] div[data-aw-rbslider-banner-id='4'] + div + div {
      order: 3; }
    body.cms-index-index [data-content-type='html'] .block-new-products + div + div {
      order: 4; }
  .join-fox-crew-banner {
    display: none; }
  .shop-lifestyle {
    display: block; } }

.cms-index-index .product-item-inner {
  display: inline-block !important; }

@media (min-width: 1024px) {
  .cms-index-index #maincontent.page-main {
    max-width: 1406px !important;
    padding-left: 20px !important;
    padding-right: 20px !important; } }

ol.widget-new-grid {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  flex-wrap: wrap; }
  @media screen and (min-width: 667px) and (max-width: 1366px) {
    ol.widget-new-grid {
      padding: 0 20px;
      box-sizing: border-box; } }
  ol.widget-new-grid li.product-item {
    flex: 1 !important;
    margin-left: 0 !important;
    width: 19.5% !important; }
    ol.widget-new-grid li.product-item:nth-child(5n + 1) {
      margin-left: 0 !important; }
    @media screen and (min-width: 850px) and (max-width: 1024px) {
      ol.widget-new-grid li.product-item {
        margin-left: 0 !important;
        width: 23% !important; }
        ol.widget-new-grid li.product-item:nth-child(4n + 1) {
          margin-left: 0 !important; }
        ol.widget-new-grid li.product-item:last-child {
          display: none; } }
    @media screen and (min-width: 667px) and (max-width: 849px) {
      ol.widget-new-grid li.product-item {
        margin-left: calc((100% - 3 * 30%) / 2) !important;
        width: 30% !important; }
        ol.widget-new-grid li.product-item:nth-child(3n + 1) {
          margin-left: 0 !important; }
        ol.widget-new-grid li.product-item:last-child, ol.widget-new-grid li.product-item:nth-last-child(2) {
          display: none; } }
    @media screen and (max-width: 666px) {
      ol.widget-new-grid li.product-item {
        flex: 1;
        margin-left: calc((100% - 2 * 48%) / 1) !important;
        width: 48% !important; }
        ol.widget-new-grid li.product-item:nth-child(2n + 1) {
          margin-left: 0 !important; }
        ol.widget-new-grid li.product-item:last-child {
          display: none; } }

.product-item .action.towishlist:before,
.product-item .action.tocompare:before {
  content: none; }

.tocompare {
  margin-left: 1rem; }

.towishlist {
  margin-left: 0.8rem; }

.block.widget .products-grid .product-item .product-item-inner,
.page-layout-1column .block.widget .products-grid .product-item .product-item-inner {
  margin: 0;
  box-shadow: none !important; }

.product-item-actions .actions-primary + .actions-secondary > .action {
  height: 30px;
  width: 25px;
  transition: opacity 0.3s; }
  .product-item-actions .actions-primary + .actions-secondary > .action svg {
    height: 17px;
    width: 17px; }
  .product-item-actions .actions-primary + .actions-secondary > .action:hover {
    opacity: 0.5; }

.product-item-actions .actions-primary {
  vertical-align: middle; }
  .product-item-actions .actions-primary .action.primary {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    margin: 3px 0 0 0;
    padding: 5px;
    fill: #000;
    transition: opacity 0.3s; }

.product-item .price-box {
  margin: 0;
  flex: 1;
  width: 40%;
  vertical-align: middle;
  font-family: rift, sans-serif;
  font-weight: bold;
  font-size: 16px; }
  .product-item .price-box .price {
    font-family: rift, sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 38px; }
  .product-item .price-box .old-price {
    display: inline; }
    .product-item .price-box .old-price .price-label {
      display: none; }

.zendesk-warranty-button {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  min-width: 150px;
  border: 3px solid #FF7900;
  color: #000000 !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: rift, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0px;
  text-decoration: none;
  padding: 10px;
  border-radius: 50px;
  background-color: #ffffff;
  cursor: pointer; }

.zendesk-warranty-button:hover {
  background-color: #FF7900;
  color: #ffffff !important; }

body.page-layout-checkout .g-recaptcha, body.checkout-index-index .g-recaptcha {
  display: none; }

body.page-layout-checkout .no-quotes-block, body.checkout-index-index .no-quotes-block {
  display: none; }

body.page-layout-checkout .form-login span.note, body.checkout-index-index .form-login span.note {
  display: none !important; }

body.page-layout-checkout fieldset .actions-toolbar .primary, body.checkout-index-index fieldset .actions-toolbar .primary {
  display: flex;
  align-items: center; }
  body.page-layout-checkout fieldset .actions-toolbar .primary button, body.checkout-index-index fieldset .actions-toolbar .primary button {
    margin: 5px !important; }
  @media (max-width: 767px) {
    body.page-layout-checkout fieldset .actions-toolbar .primary .action, body.checkout-index-index fieldset .actions-toolbar .primary .action {
      margin: 0 !important; } }

body.page-layout-checkout h1, body.checkout-index-index h1 {
  display: none; }

body.page-layout-checkout h3, body.checkout-index-index h3 {
  font-family: rift, sans-serif;
  font-weight: bold;
  font-size: 22px !important;
  line-height: 27px;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  color: #000000; }

body.page-layout-checkout .step-title h3, body.checkout-index-index .step-title h3 {
  margin-top: 10px !important; }

body.page-layout-checkout #shipping,
body.page-layout-checkout #opc-shipping_method,
body.page-layout-checkout #payment, body.checkout-index-index #shipping,
body.checkout-index-index #opc-shipping_method,
body.checkout-index-index #payment {
  display: block !important;
  overflow: hidden; }

body.page-layout-checkout .page-wrapper, body.checkout-index-index .page-wrapper {
  overflow: hidden;
  background-color: #ffffff;
  background: linear-gradient(180deg, rgba(221, 221, 221, 0.5) 0%, rgba(221, 221, 221, 0.5) 338px, white 338px, white 100%); }

body.page-layout-checkout header.page-header, body.checkout-index-index header.page-header {
  display: none !important; }

body.page-layout-checkout .page-main, body.checkout-index-index .page-main {
  max-width: 1056px !important;
  padding-top: 0 !important;
  padding-left: 10px;
  padding-right: 10px; }

body.page-layout-checkout .container, body.checkout-index-index .container {
  padding-left: 33px;
  padding-right: 33px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box; }

body.page-layout-checkout .step-title, body.checkout-index-index .step-title {
  background-color: #000000;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 32px !important;
  line-height: 38px !important;
  padding: 5px 24px !important;
  font-family: rift, sans-serif; }
  @media (max-width: 767px) {
    body.page-layout-checkout .step-title, body.checkout-index-index .step-title {
      font-size: 22px !important; } }

body.page-layout-checkout .authentication-wrapper, body.checkout-index-index .authentication-wrapper {
  position: relative;
  float: none;
  margin-top: unset; }

body.page-layout-checkout .field.additional, body.checkout-index-index .field.additional {
  display: none; }

body.page-layout-checkout .field label,
body.page-layout-checkout .field legend, body.checkout-index-index .field label,
body.checkout-index-index .field legend {
  margin-bottom: 3px !important; }
  body.page-layout-checkout .field label span,
  body.page-layout-checkout .field legend span, body.checkout-index-index .field label span,
  body.checkout-index-index .field legend span {
    font-family: roboto, sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #000000; }

body.page-layout-checkout .field input,
body.page-layout-checkout .field select, body.checkout-index-index .field input,
body.checkout-index-index .field select {
  height: 40px;
  border: 1px solid #DDDDDD !important;
  border-radius: 2px !important;
  box-shadow: unset !important;
  font-family: roboto, sans-serif !important;
  font-size: 13px !important;
  line-height: 30px !important;
  background-color: #ffffff;
  opacity: 1 !important; }

body.page-layout-checkout .field input,
body.page-layout-checkout .field select option, body.checkout-index-index .field input,
body.checkout-index-index .field select option {
  padding: 12px 15px !important; }

body.page-layout-checkout .free-shipping-reminder, body.checkout-index-index .free-shipping-reminder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: #eeeeee;
  padding: 17px 22px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px; }
  body.page-layout-checkout .free-shipping-reminder:before, body.checkout-index-index .free-shipping-reminder:before {
    content: '';
    border-top: 23px solid transparent;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-bottom: 23px solid #eeeeee;
    top: -35px;
    left: calc(50% - 11.5px);
    position: absolute; }
  body.page-layout-checkout .free-shipping-reminder p,
  body.page-layout-checkout .free-shipping-reminder p *, body.checkout-index-index .free-shipping-reminder p,
  body.checkout-index-index .free-shipping-reminder p * {
    margin: 0;
    font-family: roboto, sans-serif;
    font-size: 13px;
    line-height: 22px; }
  body.page-layout-checkout .free-shipping-reminder p, body.checkout-index-index .free-shipping-reminder p {
    margin-left: 15px; }
    body.page-layout-checkout .free-shipping-reminder p a, body.checkout-index-index .free-shipping-reminder p a {
      color: #000000; }
      body.page-layout-checkout .free-shipping-reminder p a:hover, body.checkout-index-index .free-shipping-reminder p a:hover {
        color: #FF7900; }
    body.page-layout-checkout .free-shipping-reminder p .price, body.checkout-index-index .free-shipping-reminder p .price {
      color: #FF7900;
      font-weight: bold; }
    body.page-layout-checkout .free-shipping-reminder p b, body.checkout-index-index .free-shipping-reminder p b {
      font-weight: bold; }

body.page-layout-checkout #fatzebra_gateway-form iframe, body.checkout-index-index #fatzebra_gateway-form iframe {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box; }

/* Cart */
.checkout-cart-index .page-main .action.update {
  background-color: #000000;
  box-shadow: none;
  border-radius: 2px;
  margin-top: 0;
  padding: 10px 25px;
  font-family: rift, sans-serif;
  font-size: 16px;
  border: 2px solid black;
  line-height: 1; }
  .checkout-cart-index .page-main .action.update:hover {
    background-color: #ffffff;
    color: #000000; }

.checkout-cart-index .page-main .page-title {
  font-weight: 700;
  font-size: 32px; }

.checkout-cart-index .page-main .cart .col {
  margin-top: 0; }
  @media (min-width: 768px) {
    .checkout-cart-index .page-main .cart .col {
      display: table-cell !important; } }

.checkout-cart-index .page-main .cart .product-item-name {
  margin: -3px 0; }
  .checkout-cart-index .page-main .cart .product-item-name a {
    font-family: roboto, sans-serif !important;
    font-weight: 700;
    text-transform: none; }

.checkout-cart-index .page-main .cart .item-options {
  margin-top: 0; }
  .checkout-cart-index .page-main .cart .item-options dd {
    color: #333; }
    .checkout-cart-index .page-main .cart .item-options dd:not(:last-child)::after {
      content: ',';
      padding-right: 5px; }

.checkout-cart-index .page-main .cart .product-item-details,
.checkout-cart-index .page-main .cart .price.price {
  font-size: 13px !important;
  color: #333; }

.checkout-cart-index .page-main .checkout-methods-items .action.primary.checkout {
  border-radius: 2px;
  padding: 18px 10px;
  background-color: #FF7900;
  border-color: #FF7900;
  font-family: rift, sans-serif;
  font-size: 16px; }
  .checkout-cart-index .page-main .checkout-methods-items .action.primary.checkout:hover {
    background-color: #000000;
    border-color: #000000; }

.checkout-cart-index .page-main .cart-summary {
  background: none;
  border: 2px solid #000000;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0; }
  .checkout-cart-index .page-main .cart-summary::before {
    display: none; }
  .checkout-cart-index .page-main .cart-summary .summary.title {
    margin: 0;
    padding: 6px 25px;
    background: black;
    border: none;
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #ffffff; }
    .checkout-cart-index .page-main .cart-summary .summary.title::after {
      display: none; }
  .checkout-cart-index .page-main .cart-summary .mark::before,
  .checkout-cart-index .page-main .cart-summary .amount::before {
    display: none; }
  .checkout-cart-index .page-main .cart-summary tr,
  .checkout-cart-index .page-main .cart-summary th,
  .checkout-cart-index .page-main .cart-summary td,
  .checkout-cart-index .page-main .cart-summary .cart-totals {
    background: none;
    border: none !important; }
  @media (max-width: 767px) {
    .checkout-cart-index .page-main .cart-summary th,
    .checkout-cart-index .page-main .cart-summary td {
      padding-bottom: 3px !important;
      font-size: 13px; }
      .checkout-cart-index .page-main .cart-summary th strong,
      .checkout-cart-index .page-main .cart-summary td strong {
        font-size: inherit; } }
  .checkout-cart-index .page-main .cart-summary .totals-tax-summary {
    display: none; }
  .checkout-cart-index .page-main .cart-summary .cart-totals,
  .checkout-cart-index .page-main .cart-summary .checkout-methods-items,
  .checkout-cart-index .page-main .cart-summary .accept {
    padding-left: 27px;
    padding-right: 27px; }
    @media (max-width: 767px) {
      .checkout-cart-index .page-main .cart-summary .cart-totals,
      .checkout-cart-index .page-main .cart-summary .checkout-methods-items,
      .checkout-cart-index .page-main .cart-summary .accept {
        padding-left: 12px;
        padding-right: 12px; } }
  .checkout-cart-index .page-main .cart-summary .cart-totals {
    padding-top: 27px; }
    @media (max-width: 767px) {
      .checkout-cart-index .page-main .cart-summary .cart-totals {
        padding-top: 18px; } }
    .checkout-cart-index .page-main .cart-summary .cart-totals th,
    .checkout-cart-index .page-main .cart-summary .cart-totals td {
      padding-left: 0;
      padding-right: 0; }
  .checkout-cart-index .page-main .cart-summary .checkout-methods-items {
    padding-bottom: 28px; }

@media (max-width: 767px) {
  .checkout-cart-index .block.crosssell {
    display: none; } }

@media (min-width: 768px) {
  .checkout-cart-index .block.crosssell {
    width: 100%;
    float: none;
    box-sizing: border-box; }
    .checkout-cart-index .block.crosssell .block-title {
      text-align: center; }
      .checkout-cart-index .block.crosssell .block-title h2 {
        font-size: 32px;
        line-height: 38px;
        font-family: rift, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: #000000; } }

/* Login */
.authentication-wrapper {
  height: 258px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
  max-width: none; }
  .authentication-wrapper .fieldset {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 13px; }
    .authentication-wrapper .fieldset .field {
      flex-grow: 1;
      margin: 13px 11px 0 0; }
      .authentication-wrapper .fieldset .field .control {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 2px; }
        .authentication-wrapper .fieldset .field .control .wrap {
          width: 40px;
          height: 40px;
          margin-right: 0px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .authentication-wrapper .fieldset .field .control input {
          border-top: 0 !important;
          border-bottom: 0 !important;
          border-left: 1px solid #dddddd !important;
          border-right: 0 !important;
          width: 220px;
          height: 40px;
          font-family: roboto, sans-serif;
          font-size: 13px;
          line-height: 22px; }
  .authentication-wrapper button {
    background: #000000;
    color: #ffffff;
    font-family: rift, sans-serif;
    font-weight: bold;
    border-color: #000000;
    font-size: 16px;
    height: 40px;
    border-width: 2px;
    box-sizing: border-box;
    padding: 8px 30px; }
    .authentication-wrapper button:hover {
      background: #ffffff;
      color: #FF7900;
      border-color: #FF7900; }
  .authentication-wrapper .secondary a {
    text-decoration: underline;
    font-family: roboto, sans-serif;
    font-size: 13px;
    line-height: 22px; }

/* Product Item */
.opc-block-summary {
  padding: 0 0 2.8rem;
  border: 2px solid #000000;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029; }
  .opc-block-summary .minicart-items-wrapper {
    margin: 0 !important;
    padding: 0 !important; }
  .opc-block-summary > .wrap {
    padding: 5px 24px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .opc-block-summary > .wrap .title {
      font-family: rift, sans-serif;
      font-weight: bold;
      font-size: 32px !important;
      line-height: 38px !important;
      color: #ffffff; }
      @media (max-width: 767px) {
        .opc-block-summary > .wrap .title {
          font-size: 22px !important; } }
    .opc-block-summary > .wrap a {
      text-decoration: underline; }
      .opc-block-summary > .wrap a:hover {
        text-decoration: none; }
  .opc-block-summary .items-in-cart {
    padding: 28px 23px;
    margin: 0; }
  .opc-block-summary .product-item {
    border-bottom: 1px solid #DDDDDD !important; }
    .opc-block-summary .product-item .product-item-name-block .sku {
      font-family: roboto, sans-serif;
      font-size: 13px;
      line-height: 18px; }
    .opc-block-summary .product-item .product-item-name-block .product-item-name {
      margin: 0;
      font-family: roboto, sans-serif;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px; }
    .opc-block-summary .product-item .product-item-name-block .wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .opc-block-summary .product-item .product-item-name-block .wrap .subtotal {
        width: 80px;
        text-align: left; }
        .opc-block-summary .product-item .product-item-name-block .wrap .subtotal span {
          font-family: roboto, sans-serif;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px; }
          .opc-block-summary .product-item .product-item-name-block .wrap .subtotal span .price {
            margin: 0 10px 0 0; }
        .opc-block-summary .product-item .product-item-name-block .wrap .subtotal .price-including-tax {
          margin: 0 !important; }
      .opc-block-summary .product-item .product-item-name-block .wrap .details-qty {
        margin: 0; }
        .opc-block-summary .product-item .product-item-name-block .wrap .details-qty .label span {
          font-weight: bold; }
        .opc-block-summary .product-item .product-item-name-block .wrap .details-qty span {
          font-family: roboto, sans-serif;
          font-size: 13px;
          line-height: 22px; }
    .opc-block-summary .product-item .content {
      display: block; }
  .opc-block-summary .product-item {
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
    border-color: #DDDDDD; }
    .opc-block-summary .product-item:last-child {
      border: 0; }
    .opc-block-summary .product-item .product {
      display: flex;
      align-items: top;
      justify-content: flex-start; }
      .opc-block-summary .product-item .product .product-image-container,
      .opc-block-summary .product-item .product .product-image-wrapper {
        width: 89px !important;
        height: 89px !important; }
      .opc-block-summary .product-item .product .product-item-details {
        padding-left: 21px !important; }
        .opc-block-summary .product-item .product .product-item-details .item-options {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          margin: 0 !important; }
          .opc-block-summary .product-item .product .product-item-details .item-options .values {
            padding-right: 5px;
            font-family: roboto, sans-serif;
            font-size: 13px;
            line-height: 22px;
            margin: 0 !important; }
            .opc-block-summary .product-item .product .product-item-details .item-options .values:after {
              content: ','; }
            .opc-block-summary .product-item .product .product-item-details .item-options .values:last-child:after {
              display: none; }
  .opc-block-summary .table-totals {
    order: 8;
    padding: 0 23px;
    border-top: 0; }
    .opc-block-summary .table-totals * {
      border: 0 !important; }
  .opc-block-summary .totals * {
    font-family: roboto, sans-serif;
    font-size: 13px !important;
    line-height: 20px !important;
    text-transform: none !important;
    padding: 0 !important; }
  .opc-block-summary .totals th,
  .opc-block-summary .totals span.price {
    line-height: 30px !important; }
  .opc-block-summary .totals-tax-summary .amount .price {
    padding-left: 25px;
    padding-right: 0 !important; }
    .opc-block-summary .totals-tax-summary .amount .price:after {
      display: none !important; }
  .opc-block-summary .totals-tax-details {
    display: none !important; }
  .opc-block-summary .place-order-wrap {
    order: 9;
    margin-top: 30px;
    padding: 0 23px; }
    .opc-block-summary .place-order-wrap .place-order {
      height: 60px;
      cursor: pointer;
      padding: 20px;
      display: block;
      text-align: center;
      background-color: #FF7900;
      width: 100%;
      transition: background-color 0.2s linear; }
      .opc-block-summary .place-order-wrap .place-order span {
        font-family: rift, sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff; }
      .opc-block-summary .place-order-wrap .place-order:hover {
        background-color: #000000; }
    .opc-block-summary .place-order-wrap p {
      margin-top: 17px;
      margin-bottom: 35px;
      text-align: center; }
    .opc-block-summary .place-order-wrap p,
    .opc-block-summary .place-order-wrap p a {
      font-family: roboto, sans-serif;
      font-size: 12px;
      line-height: 18px; }
    .opc-block-summary .place-order-wrap p a {
      display: block; }

#shipping-new-address-form,
.billing-address-same-as-shipping-block fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0; }
  #shipping-new-address-form .field,
  .billing-address-same-as-shipping-block fieldset .field {
    margin-bottom: 10px;
    width: calc(50% - 8px); }
    #shipping-new-address-form .field:nth-child(3),
    .billing-address-same-as-shipping-block fieldset .field:nth-child(3) {
      width: 100%; }
  #shipping-new-address-form fieldset,
  .billing-address-same-as-shipping-block fieldset fieldset {
    width: 100% !important; }
    #shipping-new-address-form fieldset .field,
    .billing-address-same-as-shipping-block fieldset fieldset .field {
      width: 100% !important; }

@media (max-width: 767px) {
  #shipping-new-address-form .field {
    width: 100%; } }

.payment-method {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
  border-color: transparent !important; }
  .payment-method .payment-method-title {
    position: relative;
    padding: 0;
    border: 2px solid #000000 !important;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000029;
    transition: border 0.2s linear; }
    .payment-method .payment-method-title:hover {
      border: 2px solid #FF7900 !important; }
    .payment-method .payment-method-title input {
      display: none; }
    .payment-method .payment-method-title label {
      display: flex !important;
      align-items: center;
      padding: 16px 28px;
      margin-top: 8px !important;
      cursor: pointer; }
      .payment-method .payment-method-title label svg {
        margin-right: 10px; }
      .payment-method .payment-method-title label span {
        font-family: roboto, sans-serif;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px; }
      .payment-method .payment-method-title label span.title + span {
        font-weight: 500 !important; }
  .payment-method._active .payment-method-title {
    border: 2px solid #FF7900 !important; }
  .payment-method .payment-method-content {
    padding: 38px 30px;
    background-color: #eeeeee; }

#checkout-step-payment #co-payment-form .fieldset {
  display: flex;
  flex-direction: column; }
  #checkout-step-payment #co-payment-form .fieldset .billing-address-details {
    display: none; }
  #checkout-step-payment #co-payment-form .fieldset .action-update {
    margin-right: 0 !important; }
    @media (max-width: 767px) {
      #checkout-step-payment #co-payment-form .fieldset .action-update {
        margin: 0 !important; } }

/* Mobile Responsiveness */
@media (max-width: 1000px) {
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address,
  .opc-wrapper .methods-shipping {
    background-color: #ffffff; }
  body.page-layout-checkout .page-main {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  body.page-layout-checkout .continue-banner .container {
    width: 100%;
    margin: 0 33px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; }
    body.page-layout-checkout .continue-banner .container a {
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center; }
  body.page-layout-checkout .continue-banner svg {
    height: 26px !important; }
  body.page-layout-checkout .continue-banner span {
    display: none; }
  body.page-layout-checkout .continue-banner h1 {
    display: block;
    font-family: rift, sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    margin: auto; }
  body.page-layout-checkout .page-wrapper {
    background-color: #ffffff;
    background: linear-gradient(180deg, rgba(221, 221, 221, 0.5) 0%, rgba(221, 221, 221, 0.5) 390px, white 390px, white 100%); }
  body.page-layout-checkout .authentication-wrapper {
    width: 100%;
    height: 310px; }
    body.page-layout-checkout .authentication-wrapper h3 {
      margin-bottom: 2px !important; }
    body.page-layout-checkout .authentication-wrapper form {
      width: 100%; }
    body.page-layout-checkout .authentication-wrapper .fieldset {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start; }
      body.page-layout-checkout .authentication-wrapper .fieldset .field {
        margin: 0 !important;
        width: 100% !important; }
        body.page-layout-checkout .authentication-wrapper .fieldset .field label {
          margin-top: 8px;
          margin-bottom: 0 !important; }
        body.page-layout-checkout .authentication-wrapper .fieldset .field .control input {
          width: 100%; }
    body.page-layout-checkout .authentication-wrapper button[type=submit] {
      position: absolute;
      right: 0;
      bottom: 20px; }
    body.page-layout-checkout .authentication-wrapper .actions-toolbar {
      margin-top: 30px;
      width: fit-content; }
      body.page-layout-checkout .authentication-wrapper .actions-toolbar .secondary {
        width: fit-content; }
  body.page-layout-checkout #shipping {
    margin-top: 30px; }
  body.page-layout-checkout .step-title {
    display: block !important; }
  .opc-estimated-wrapper {
    display: none !important; }
  .opc-wrapper,
  aside.opc-sidebar {
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
    max-width: 660px !important; }
  .opc-wrapper {
    padding-right: 0; }
  aside.opc-sidebar {
    position: relative;
    visibility: visible;
    opacity: 1;
    left: 0; }
    aside.opc-sidebar .modal-inner-wrap {
      position: relative !important;
      transform: unset !important; }
      aside.opc-sidebar .modal-inner-wrap .action-close {
        display: none; }
    aside.opc-sidebar .opc-block-summary {
      margin: 0 auto; }
  #co-shipping-method-form {
    background-color: #ffffff;
    padding: 10px 0 !important;
    margin: 0 !important;
    box-sizing: border-box; }
  .free-shipping-reminder {
    width: calc(100% - 20px) !important;
    padding: 17px 10px !important; }
    .free-shipping-reminder p {
      width: calc(100% - 52px); } }

body.page-layout-checkout .field .control._with-tooltip input {
  width: 100%;
  margin: 0; }

body.page-layout-checkout .field .control._with-tooltip .field-tooltip {
  display: none; }

body.page-layout-checkout .continue-banner {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: #000000;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  body.page-layout-checkout .continue-banner a {
    text-decoration: none; }
    body.page-layout-checkout .continue-banner a span {
      color: #ffffff;
      margin-left: 18px;
      font-size: 13px;
      line-height: 18px; }
    body.page-layout-checkout .continue-banner a svg path {
      fill: #ffffff; }
    body.page-layout-checkout .continue-banner a:hover span {
      color: #FF7900; }
    body.page-layout-checkout .continue-banner a:hover svg path {
      fill: #FF7900; }
  body.page-layout-checkout .continue-banner svg {
    height: 10px; }

body.page-layout-checkout .minicart-items {
  display: block !important; }

body.page-layout-checkout label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px !important;
  margin-bottom: 12px !important; }
  body.page-layout-checkout label span {
    line-height: 45px; }
  body.page-layout-checkout label span.check {
    border: 1px solid #ddd;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px; }
    body.page-layout-checkout label span.check svg path {
      fill: transparent; }

body.page-layout-checkout input:checked + label span.check svg path {
  fill: #000000; }

body.page-layout-checkout input[type=checkbox] {
  display: none; }

body.page-layout-checkout .opc-wrapper {
  max-width: 660px;
  padding-right: 130px; }
  body.page-layout-checkout .opc-wrapper .form-login,
  body.page-layout-checkout .opc-wrapper .form-shipping-address {
    width: 100%; }

body.page-layout-checkout label[for=same-billing] {
  font-family: roboto, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px; }

body.page-layout-checkout .checkout-payment-method .payment-methods {
  margin: 0 !important; }

body.page-layout-checkout .checkout-payment-method .field-select-billing,
body.page-layout-checkout .checkout-payment-method .billing-address-form {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0; }
  body.page-layout-checkout .checkout-payment-method .field-select-billing form fieldset,
  body.page-layout-checkout .checkout-payment-method .billing-address-form form fieldset {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between; }
  body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(1), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(2), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(5), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(6), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(7), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(8), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(9), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(10),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(1),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(2),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(5),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(6),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(7),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(8),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(9),
  body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(10) {
    width: calc(50% - 8px) !important; }
    @media (max-width: 767px) {
      body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(1), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(2), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(5), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(6), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(7), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(8), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(9), body.page-layout-checkout .checkout-payment-method .field-select-billing form .field:nth-child(10),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(1),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(2),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(5),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(6),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(7),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(8),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(9),
      body.page-layout-checkout .checkout-payment-method .billing-address-form form .field:nth-child(10) {
        width: 100% !important; } }
  body.page-layout-checkout .checkout-payment-method .field-select-billing form fieldset,
  body.page-layout-checkout .checkout-payment-method .field-select-billing form fieldset.street,
  body.page-layout-checkout .checkout-payment-method .billing-address-form form fieldset,
  body.page-layout-checkout .checkout-payment-method .billing-address-form form fieldset.street {
    width: 100% !important; }
    body.page-layout-checkout .checkout-payment-method .field-select-billing form fieldset .field,
    body.page-layout-checkout .checkout-payment-method .field-select-billing form fieldset.street .field,
    body.page-layout-checkout .checkout-payment-method .billing-address-form form fieldset .field,
    body.page-layout-checkout .checkout-payment-method .billing-address-form form fieldset.street .field {
      width: 100% !important; }
  body.page-layout-checkout .checkout-payment-method .field-select-billing .field:nth-child(1),
  body.page-layout-checkout .checkout-payment-method .field-select-billing .field:nth-child(2),
  body.page-layout-checkout .checkout-payment-method .billing-address-form .field:nth-child(1),
  body.page-layout-checkout .checkout-payment-method .billing-address-form .field:nth-child(2) {
    width: calc(50% - 8px);
    box-sizing: border-box; }

body.page-layout-checkout #shipping-method-buttons-container {
  display: none; }

body.page-layout-checkout .checkout-billing-address .field.choice {
  display: none; }

body.page-layout-checkout #co-payment-form h3 {
  margin-bottom: 0 !important; }

body.page-layout-checkout #shipping {
  margin-top: 20px; }

.checkout-payment-method .payment-,
.checkout-payment-method .payment-option {
  margin: 0 !important; }

.checkout-payment-method h3 * {
  color: #000000; }

.checkout-payment-method #customerbalance-placer .payment-option-title {
  background-color: #000000;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 32px !important;
  line-height: 38px !important;
  padding: 5px 24px !important;
  font-family: rift, sans-serif; }
  @media (max-width: 767px) {
    .checkout-payment-method #customerbalance-placer .payment-option-title {
      font-size: 22px !important; } }

.checkout-payment-method #afterpaypayovertime-method label {
  display: flex; }
  .checkout-payment-method #afterpaypayovertime-method label .payment-icon {
    max-width: 114px;
    height: auto;
    order: 0; }
  .checkout-payment-method #afterpaypayovertime-method label:before {
    content: 'Afterpay';
    display: block;
    color: #000000;
    font-size: 13px;
    line-height: 15px;
    font-family: roboto, sans-serif;
    font-weight: bold;
    order: 1;
    margin-left: 20px; }

.checkout-payment-method #afterpaypayovertime-method li span {
  color: #000000; }

.checkout-payment-method #afterpaypayovertime-method .afterpay-checkout-redirect {
  background: transparent; }

#checkout-step-shipping_method table tbody {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  @media (min-width: 1001px) {
    #checkout-step-shipping_method table tbody {
      align-items: center; } }
  @media (max-width: 1000px) {
    #checkout-step-shipping_method table tbody {
      flex-direction: column;
      align-items: flex-start; } }
  #checkout-step-shipping_method table tbody tr {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    width: 100% !important;
    margin-top: 9px;
    margin-bottom: 9px;
    padding: 11px 15px;
    box-sizing: border-box;
    line-height: 1; }
    @media (max-width: 1000px) {
      #checkout-step-shipping_method table tbody tr {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px; } }
    #checkout-step-shipping_method table tbody tr td.col-price {
      /*order: 2;*/
      margin: 0;
      padding: 0 4px;
      font-weight: bold;
      border: 0 !important; }
    #checkout-step-shipping_method table tbody tr td.col-method {
      font-weight: bold;
      order: 1;
      position: static !important;
      padding: 0 0 0 10px;
      display: block !important;
      border: 0 !important; }
    #checkout-step-shipping_method table tbody tr td.col-carrier {
      order: 3;
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important; }
    #checkout-step-shipping_method table tbody tr td.icon {
      order: 0;
      padding: 0 !important;
      border: 0 !important; }
      #checkout-step-shipping_method table tbody tr td.icon .collect {
        display: none; }
    #checkout-step-shipping_method table tbody tr td.col-price + td.col-method:after {
      content: ':'; }
    #checkout-step-shipping_method table tbody tr input[type="radio"] {
      width: 0;
      height: 0;
      order: 1; }
      #checkout-step-shipping_method table tbody tr input[type="radio"]:after {
        content: ''; }
      #checkout-step-shipping_method table tbody tr input[type="radio"] + .border {
        background-color: transparent !important;
        border: 2px solid #000000;
        border-radius: 2px;
        width: 100% !important;
        height: 100% !important;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
        order: 0;
        cursor: pointer;
        box-sizing: border-box; }
        #checkout-step-shipping_method table tbody tr input[type="radio"] + .border:after {
          display: none !important; }
      #checkout-step-shipping_method table tbody tr input[type="radio"]:checked + .border {
        background-color: transparent !important;
        border: 2px solid #FF7900;
        box-shadow: 0px 3px 6px #00000029; }
        #checkout-step-shipping_method table tbody tr input[type="radio"]:checked + .border:after {
          background-color: #ffffff; }
    #checkout-step-shipping_method table tbody tr:hover input[type="radio"] + .border {
      border-color: #FF7900 !important; }
    #checkout-step-shipping_method table tbody tr .col-method + .col {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border: 0;
      width: auto; }
      #checkout-step-shipping_method table tbody tr .col-method + .col .wrap {
        display: flex !important;
        align-items: center;
        justify-content: flex-start; }
        #checkout-step-shipping_method table tbody tr .col-method + .col .wrap .col-price {
          font-weight: bold;
          margin-right: 4px; }

.payment-option-title {
  background-color: #000000;
  padding: 0 !important; }
  .payment-option-title .action-toggle {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between; }
    .payment-option-title .action-toggle span {
      color: #ffffff;
      font-size: 32px !important;
      line-height: 38px !important;
      padding: 5px 24px !important;
      font-weight: 700;
      font-family: rift,sans-serif; }
      @media (max-width: 767px) {
        .payment-option-title .action-toggle span {
          font-size: 22px !important; } }
    .payment-option-title .action-toggle:after {
      content: '' !important;
      background-image: url(../images/right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(90deg);
      margin-right: 19px !important;
      height: 14px;
      width: 7px; }

._active .action-toggle::after {
  transform: rotate(270deg); }

.payment-option-content {
  padding: 22px 0 !important; }

#checkout {
  max-width: 1106px !important;
  margin: 0 auto; }
  #checkout button.action:not(.action-login) {
    background-color: #FF7900;
    border: 1px solid #FF7900;
    border-radius: 2px;
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 30px;
    color: #ffffff; }
    #checkout button.action:not(.action-login):hover, #checkout button.action:not(.action-login):focus {
      background-color: #000000 !important;
      border-color: #000000 !important; }
  #checkout .opc-wrapper {
    width: 100%;
    max-width: 660px;
    padding: 0; }
    #checkout .opc-wrapper form,
    #checkout .opc-wrapper table {
      width: 100%;
      max-width: 100%; }
  #checkout #shipping h3 {
    margin-bottom: 5px !important; }
  #checkout #shipping p {
    font-size: 13px;
    line-height: 22px;
    font-family: roboto, sans-serif;
    color: #333333; }
  #checkout #shipping span.note {
    display: none; }
  #checkout #shipping .form-login {
    margin: 0;
    border: 0;
    padding: 0; }
  #checkout #checkout-step-shipping_method h3 {
    margin-bottom: 18px !important; }
    @media (max-width: 767px) {
      #checkout #checkout-step-shipping_method h3 {
        font-size: 16px !important; } }
    @media (min-width: 768px) {
      #checkout #checkout-step-shipping_method h3 {
        margin-top: 3rem !important; } }

#afterpaypayovertime .payment-method-note h3 {
  padding-bottom: 20px !important;
  text-align: center; }

@media (max-width: 1000px) {
  .opc-wrapper .step-content {
    margin: 0 !important; }
  .step-title {
    position: relative; } }

.opc-block-shipping-information,
#giftcardaccount-placer,
.shippit-options {
  display: none !important; }

.checkout-shipping-method.checkout-shipping-method h3 {
  margin: 1rem 0 !important; }

/* Openning and closing of checkout steps */
/*
@media (max-width: 1000px ){
    .form-shipping-address,
    .checkout-shipping-method,
    .checkout-billing-address .fieldset{
        height: 49px;
        overflow: hidden;
        &.active{
            overflow: visible !important;
            height: fit-content !important;
        }

    }
    .step-title{
        position: relative;
        &:after{
            color: #fff!important;
            display: block;
            font-size: 28px!important;
            line-height: 45px!important;
            margin: 0 20px!important;
            content: '\f107';
            font-family: 'FontAwesome';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            speak: none;
            text-align: center;
            overflow: visible;
            position: absolute;
            transform: rotate(0deg);
            right: 0;
        }
    }
    .active{
        .step-title{
            &:after{
                transform: rotate(180deg);
            }
        }
    }
}
*/
.checkout-onepage-success .page-main {
  margin-top: 7rem;
  margin-bottom: 0; }

.checkout-onepage-success h1 {
  margin-bottom: 2rem;
  font-weight: 700;
  color: #000000; }

.checkout-onepage-success .main p {
  margin-bottom: 0; }

.checkout-onepage-success .main a {
  margin-top: 5px !important; }
  .checkout-onepage-success .main a:focus {
    background-color: #000000;
    border-color: #000000; }

#checkout-step-shipping_method table tbody tr td.col-title {
  width: 100%;
  display: flex;
  padding-left: 0; }
  #checkout-step-shipping_method table tbody tr td.col-title .col-carrier:after {
    content: ':';
    padding-right: 5px; }
  #checkout-step-shipping_method table tbody tr td.col-title .col-price {
    font-weight: 400 !important; }

#checkout-step-shipping_method table tbody tr .col-title:before {
  content: '' !important;
  background-image: url(../images/icon-delivery.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 32px;
  width: 32px; }

#checkout-step-shipping_method table tbody tr .col-method-title {
  display: flex;
  align-items: center; }

#checkout-step-shipping_method table tbody tr#shipping_method_amstorepickup_amstorepickup .col-carrier:after,
#checkout-step-shipping_method table tbody tr#shipping_method_amstorepickup_amstorepickup .col-price,
#checkout-step-shipping_method table tbody tr#shipping_method_amstorepickup_amstorepickup .col-title .col-method {
  display: none !important; }

#checkout-step-shipping_method table tbody tr#shipping_method_amstorepickup_amstorepickup .col-title:before {
  background-image: url(../images/icon-basket.svg); }

#checkout-step-shipping_method .free-shipping-reminder {
  display: none !important; }

.catalog-product-view {
  /** STOCK LEVELS **/ }
  .catalog-product-view main.page-main {
    padding: 0 20px !important; }
  .catalog-product-view .breadcrumbs {
    max-width: 1366px !important; }
    @media (max-width: 767px) {
      .catalog-product-view .breadcrumbs {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center; } }
  @media (min-width: 1200px) {
    .catalog-product-view #maincontent {
      box-sizing: border-box; }
      .catalog-product-view #maincontent .page.messages,
      .catalog-product-view #maincontent .columns {
        max-width: 1043px !important; } }
  @media (min-width: 768px) {
    .catalog-product-view .column.main {
      display: flex;
      flex-wrap: wrap; }
    .catalog-product-view .product.media {
      order: -1; }
    .catalog-product-view .gallery-placeholder {
      position: sticky;
      top: 120px; }
      .catalog-product-view .gallery-placeholder._block-content-loading > img {
        display: none; } }
  .catalog-product-view .product.media .product-tags {
    z-index: 1;
    top: 6px;
    left: 2px;
    display: flex;
    gap: 6px;
    align-items: flex-start;
    pointer-events: none;
    position: absolute;
    flex-direction: column;
    font-size: 12px;
    min-width: 91px; }
    .catalog-product-view .product.media .product-tags .product-tag {
      display: inline-block;
      width: 80%;
      padding: 0 9px 0 9px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #000000;
      font-family: roboto, sans-serif;
      border: 1px solid;
      border-radius: 50px;
      text-align: center;
      background: #ffffff; }
      @media screen and (max-width: 666px) {
        .catalog-product-view .product.media .product-tags .product-tag {
          font-size: 11px;
          width: 92%;
          padding: 0 0 0 0; } }
      .catalog-product-view .product.media .product-tags .product-tag.tag-sale, .catalog-product-view .product.media .product-tags .product-tag.tag-hot-offer, .catalog-product-view .product.media .product-tags .product-tag.tag-clearance {
        color: #ff0800; }
  .catalog-product-view .product-info-main .product.attribute.overview {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0; }
  .catalog-product-view .product-info-main .product-info-price {
    width: 100% !important;
    border: none !important; }
  .catalog-product-view .product-info-main .top-holder {
    border-bottom: 1px solid black;
    display: flex;
    padding-bottom: 4px;
    position: relative;
    margin-bottom: 10px; }
    .catalog-product-view .product-info-main .top-holder .top-holder-left {
      flex: 1;
      padding-bottom: 5px; }
      .catalog-product-view .product-info-main .top-holder .top-holder-left .product.attribute.sku .value {
        font-size: 13px;
        color: #333;
        font-weight: 400; }
    .catalog-product-view .product-info-main .top-holder .top-holder-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5px; }
      .catalog-product-view .product-info-main .top-holder .top-holder-right .brand-logo {
        max-width: 60px;
        margin-top: auto; }
      .catalog-product-view .product-info-main .top-holder .top-holder-right .product-reviews-summary {
        margin: 0;
        float: right;
        position: relative;
        font-size: 13px; }
        .catalog-product-view .product-info-main .top-holder .top-holder-right .product-reviews-summary .rating-summary {
          display: flex;
          align-items: center;
          margin-right: 0; }
          .catalog-product-view .product-info-main .top-holder .top-holder-right .product-reviews-summary .rating-summary .label {
            position: relative;
            width: auto;
            height: auto;
            font-size: 13px;
            margin-right: 8px;
            font-weight: 100; }
          .catalog-product-view .product-info-main .top-holder .top-holder-right .product-reviews-summary .rating-summary .rating-result:before {
            font-size: 13px; }
          .catalog-product-view .product-info-main .top-holder .top-holder-right .product-reviews-summary .rating-summary .rating-result > span:before {
            color: #f8d048;
            font-size: 13px; }
  .catalog-product-view .product-info-main .product-social-links {
    display: none; }
  .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset {
    padding: 20px 0; }
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset .price-box {
      font-weight: 700; }
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset .step-label h3 {
      border-bottom: 1px solid #000;
      font-weight: 700;
      font-size: 20px;
      line-height: 51px;
      border-color: #000 !important; }
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset .required em {
      margin-left: 2px; }
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset #card-amount,
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset .field input[type="text"] {
      height: 50px;
      padding: 12px 15px;
      font-size: 14px;
      color: #333333; }
    .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .giftcard-fieldset #card-preview {
      color: #333333; }
  .catalog-product-view .product-info-main .product-add-form .item-no-option .giftcard-info .calendar-icon {
    top: -40px; }
  .catalog-product-view .product-info-main .product-add-form .item-no-option .product-view-cart .add-cart {
    margin-right: 0 !important; }
  .catalog-product-view .product-info-main .product-add-form .item-no-option .product-view-cart .wish-list {
    display: none !important; }
  .catalog-product-view .product-info-main .page-title-wrapper.product h1.page-title {
    font-size: 32px;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 0;
    font-family: rift, sans-serif;
    font-weight: 700;
    color: #000; }
  .catalog-product-view .product-info-main .price-box.price-final_price .price {
    font-weight: bold;
    font-size: 22px;
    font-family: rift, sans-serif;
    color: #000;
    font-weight: 700; }
  .catalog-product-view .product-info-main .price-box.price-final_price .special-price .price {
    color: #ff0800 !important; }
  .catalog-product-view .product-info-main .price-box.price-final_price .old-price {
    display: inline;
    margin: 0 0 0 1rem; }
    .catalog-product-view .product-info-main .price-box.price-final_price .old-price .price {
      color: #000000 !important;
      text-decoration: line-through; }
    .catalog-product-view .product-info-main .price-box.price-final_price .old-price .price-label {
      display: none; }
    .catalog-product-view .product-info-main .price-box.price-final_price .old-price .price-wrapper {
      text-decoration: none; }
  .catalog-product-view .product-info-main .price-box.price-final_price .price-final_price {
    font-family: inherit; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute .swatch-attribute-label {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    font-family: rift, sans-serif;
    color: #000; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute .sizing-guide a {
    color: #FF7900 !important;
    text-decoration: underline;
    padding-left: 25px; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute .sizing-guide a:visited, .catalog-product-view .product-info-main .swatch-opt .swatch-attribute .sizing-guide a:hover {
      color: #FF7900; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color .swatch-attribute-selected-option {
    position: relative;
    font-size: 13px;
    font-family: roboto, sans-serif;
    display: block;
    height: 25px;
    padding-left: 0;
    margin: 0 0 1px;
    color: #333; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image),
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option.image {
    height: 45px !important;
    width: 45px !important;
    position: relative;
    background-size: contain !important;
    outline-width: 1px;
    margin-right: 1.2rem;
    padding: 0;
    box-sizing: border-box; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image):not(.disabled):hover.selected,
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option.image:not(.disabled):hover.selected {
      outline-color: #000000; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled,
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled {
      pointer-events: none;
      opacity: 0.3; }
      .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled::after,
      .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled::after {
        display: none; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image) {
    background-image: url(../images/swatch_1.png); }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size .swatch-attribute-selected-option {
    display: none; }
  .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size > div .swatch-option.text {
    height: 45px !important;
    width: 45px !important;
    position: relative;
    text-align: center;
    vertical-align: middle;
    line-height: 45px;
    font-size: 13px;
    font-family: roboto, sans-serif;
    border-radius: 0;
    outline: none !important;
    border: 1px solid #000000;
    font-weight: 100;
    margin-right: 0.8rem;
    padding: 0; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size > div .swatch-option.text.selected {
      border: 1px solid #000000 !important; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size > div .swatch-option.text:not(.disabled):hover {
      outline: none !important;
      border: 1px solid #969696 !important; }
    .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled {
      pointer-events: none;
      opacity: 0.3; }
      .catalog-product-view .product-info-main .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled::after {
        display: none; }
  .catalog-product-view .product-info-main .product-addto-links a.add-to-wishlist {
    line-height: 35px;
    text-align: center;
    width: 35px;
    border: 1px solid red; }
    .catalog-product-view .product-info-main .product-addto-links a.add-to-wishlist:hover {
      opacity: 0.5; }
    .catalog-product-view .product-info-main .product-addto-links a.add-to-wishlist span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
  .catalog-product-view .product-info-main .full-details-link {
    text-align: center;
    display: none; }
  .catalog-product-view .product-info-main .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%; }
  .catalog-product-view .product-info-main .embed-container iframe,
  .catalog-product-view .product-info-main .embed-container object,
  .catalog-product-view .product-info-main .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .catalog-product-view .product-info-main .product-options-bottom,
  .catalog-product-view .product-info-main .item-no-option {
    width: 100%; }
    .catalog-product-view .product-info-main .product-options-bottom .product-view-cart,
    .catalog-product-view .product-info-main .item-no-option .product-view-cart {
      width: 100%;
      position: relative;
      display: flex;
      overflow: visible; }
      .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions,
      .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions {
        display: flex;
        position: relative; }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.dealer-button,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.dealer-button {
          flex-grow: 1; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.dealer-button:not(:last-child),
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.dealer-button:not(:last-child) {
            margin-right: 16px; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.dealer-button a,
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.dealer-button a {
            margin-right: 0;
            background-color: #FF7900;
            border-color: #FF7900;
            border-radius: 2px;
            font-family: rift, sans-serif;
            font-size: 16px;
            line-height: 1.8;
            text-align: center; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.dealer-button a:hover,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.dealer-button a:hover {
              background-color: #000000;
              border-color: #000000; }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection {
          flex: 0 0 100px;
          position: relative;
          margin-right: 14px;
          width: 100px;
          border: 2px solid #000000;
          box-sizing: border-box;
          height: 60px;
          display: inline-flex !important;
          border-radius: 2px; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .mage-error,
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .mage-error {
            position: absolute;
            top: 100%;
            left: 0;
            line-height: 1.2; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control,
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control {
            display: inline;
            flex: 1;
            height: 56px;
            font-size: 15px;
            font-family: roboto, sans-serif;
            color: #333;
            line-height: 56px; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control.input-text.qty,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control.input-text.qty {
              background: none;
              border: none !important;
              max-width: 35px;
              padding: 0;
              margin-top: 1px; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control.qty-change,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control.qty-change {
              font-weight: 100;
              text-align: center;
              line-height: 58px;
              cursor: pointer;
              color: #333;
              font-size: calc(100% + 5px); }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control:before,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control:before {
              height: 100%;
              width: 30%;
              margin: 0 auto;
              position: relative;
              display: block;
              background-size: auto;
              background-repeat: no-repeat;
              background-position: center center; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control.qty-up:before,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control.qty-up:before {
              content: " ";
              background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" class="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>'); }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection .qty-control.qty-down:before,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection .qty-control.qty-down:before {
              content: " ";
              background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="minus" class="svg-inline--fa fa-minus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>'); }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.add-cart,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.add-cart {
          flex: 8;
          position: relative;
          display: inline-block !important;
          margin-right: 14px; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.add-cart button.action.primary.tocart,
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.add-cart button.action.primary.tocart {
            padding-left: 0 !important;
            padding-right: 0 !important;
            font-family: rift, sans-serif !important;
            border: 2px solid black;
            white-space: nowrap; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.add-cart button.action.primary.tocart:hover,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.add-cart button.action.primary.tocart:hover {
              background: white;
              color: black; }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.wish-list,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.wish-list {
          flex: 1;
          position: relative;
          flex: 0 0 44px; }
          .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.wish-list a.add-to-wishlist,
          .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.wish-list a.add-to-wishlist {
            display: block;
            line-height: 60px;
            font-size: 38px;
            color: #000000;
            transition: opacity 0.3s;
            text-align: center;
            width: 100%; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.wish-list a.add-to-wishlist:hover,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.wish-list a.add-to-wishlist:hover {
              opacity: 0.5;
              text-decoration: none !important; }
            .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.wish-list a.add-to-wishlist > span,
            .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.wish-list a.add-to-wishlist > span {
              border: 0;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
              text-decoration: none; }
      @media (max-width: 768px) {
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.qty-selection,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.qty-selection {
          flex: 0 0 auto; }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.add-cart,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.add-cart {
          flex-grow: 1; }
        .catalog-product-view .product-info-main .product-options-bottom .product-view-cart > .actions.wish-list,
        .catalog-product-view .product-info-main .item-no-option .product-view-cart > .actions.wish-list {
          flex: 0 0 auto; } }
  .catalog-product-view .click-collect-wrapper {
    clear: both;
    padding-top: 1rem; }
    .catalog-product-view .click-collect-wrapper .click-collect-item {
      border: 2px solid #000000;
      border-radius: 2px;
      width: 100%;
      display: flex;
      margin-bottom: 15px;
      flex-wrap: wrap;
      padding: 24px;
      box-sizing: border-box; }
      @media (max-width: 768px) {
        .catalog-product-view .click-collect-wrapper .click-collect-item {
          padding: 19px; } }
      .catalog-product-view .click-collect-wrapper .click-collect-item a {
        color: #FF7900;
        text-decoration: underline; }
        .catalog-product-view .click-collect-wrapper .click-collect-item a:hover {
          text-decoration: none; }
      .catalog-product-view .click-collect-wrapper .click-collect-item .click-collect-current-wrapper {
        display: flex; }
      .catalog-product-view .click-collect-wrapper .click-collect-item .click-collect-image {
        margin-right: 18px; }
        .catalog-product-view .click-collect-wrapper .click-collect-item .click-collect-image svg path {
          stroke-width: 0px !important; }
      .catalog-product-view .click-collect-wrapper .click-collect-item .content {
        font-size: 13px; }
        @media (max-width: 768px) {
          .catalog-product-view .click-collect-wrapper .click-collect-item .content {
            font-size: 11px; }
            .catalog-product-view .click-collect-wrapper .click-collect-item .content > .title {
              font-size: 13px; } }
        .catalog-product-view .click-collect-wrapper .click-collect-item .content > .title {
          font-weight: bold; }
      .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product {
        display: none;
        width: 100%;
        margin: 25px 0; }
        .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper {
          height: 40px;
          justify-content: flex-start; }
          @media (max-width: 768px) {
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper {
              padding: 0; } }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper .location {
            position: relative;
            width: 100%;
            max-width: none; }
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper .location::before {
              content: "";
              position: absolute;
              top: 0;
              left: 36px;
              z-index: 1;
              height: 100%;
              width: 1px;
              background: #ddd; }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper .location-input {
            border: 1px solid #ddd;
            font-size: 13px;
            background-size: 15px 20px;
            background-position: left 10px center; }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper .search button {
            width: 44px;
            margin-left: -1px;
            border: 1px solid #ddd;
            padding: 0;
            line-height: 0; }
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .search-wrapper .search button svg {
              width: 17.83px;
              height: 17.83px; }
        .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .stores-info {
          display: none; }
        .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block {
          padding-top: 21px;
          border-top: 0;
          display: flex; }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .brands-sold {
            display: none; }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-selector {
            margin-right: 6px; }
            @media (max-width: 768px) {
              .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-selector {
                margin-left: 8px;
                margin-right: 16px; } }
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-selector label {
              padding: 0; }
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-selector input {
              display: none; }
          @media (max-width: 768px) {
            .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info {
              padding-left: 0; } }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info .store-name {
            font-family: roboto, sans-serif !important;
            font-size: 13px;
            line-height: 1.5;
            text-transform: none;
            margin-bottom: 0;
            margin-top: 0; }
            @media (max-width: 768px) {
              .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info .store-name {
                font-size: 11px; } }
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info .directions,
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info .phone,
          .catalog-product-view .click-collect-wrapper .click-collect-item .store-locator-product .store-blocks .block .store-info .website {
            display: none; }
  .catalog-product-view .accordion {
    /** TAB: REVIEW START **/ }
    .catalog-product-view .accordion .product.data.items .item.title {
      border: none; }
      .catalog-product-view .accordion .product.data.items .item.title a {
        border-bottom: 1px solid #000000;
        font-family: rift, sans-serif;
        font-weight: bold;
        font-size: 22px;
        line-height: 56px;
        background: none;
        padding-left: 0; }
        .catalog-product-view .accordion .product.data.items .item.title a.switch {
          border-color: #000000; }
        .catalog-product-view .accordion .product.data.items .item.title a:after {
          content: none !important; }
    .catalog-product-view .accordion .product.data.items .item.content {
      padding-left: 0;
      background: none !important; }
      @media (min-width: 768px) {
        .catalog-product-view .accordion .product.data.items .item.content {
          display: block !important;
          height: auto !important; } }
      .catalog-product-view .accordion .product.data.items .item.content h2 {
        font-family: rift, sans-serif !important;
        font-size: 22px;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 15px; }
      .catalog-product-view .accordion .product.data.items .item.content p {
        font-size: 13px;
        line-height: 22px;
        color: #333; }
      .catalog-product-view .accordion .product.data.items .item.content li {
        margin-bottom: 0; }
    .catalog-product-view .accordion .counter {
      display: none; }
    .catalog-product-view .accordion .review-add .write-review-text {
      font-size: 13px; }
    .catalog-product-view .accordion .review-add .review-block {
      margin-top: 10px; }
      .catalog-product-view .accordion .review-add .review-block button.primary {
        font-family: rift, sans-serif;
        background: #000000;
        border: none;
        transition: all 0.3s ease;
        font-size: 16px;
        line-height: 40px;
        padding: 0 27px 0 27px; }
    .catalog-product-view .accordion .clear {
      clear: both; }
    .catalog-product-view .accordion .review-block {
      margin-bottom: 20px;
      font-size: 13px; }
      .catalog-product-view .accordion .review-block > span {
        display: block;
        line-height: 2em;
        margin-bottom: 10px; }
      .catalog-product-view .accordion .review-block button.primary {
        background: black;
        border: 2px solid black;
        font-family: rift, sans-serif;
        font-size: 16px;
        padding: 10px 28px; }
        .catalog-product-view .accordion .review-block button.primary:hover {
          background-color: #fff;
          color: black; }
    .catalog-product-view .accordion .block.review-list,
    .catalog-product-view .accordion #product-review-container {
      display: block;
      float: none;
      width: 100%; }
      .catalog-product-view .accordion .block.review-list li.item,
      .catalog-product-view .accordion #product-review-container li.item {
        font-size: 13px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        line-height: 1.5em;
        border-bottom: none !important; }
        .catalog-product-view .accordion .block.review-list li.item .item-header,
        .catalog-product-view .accordion #product-review-container li.item .item-header {
          display: block;
          position: relative; }
          .catalog-product-view .accordion .block.review-list li.item .item-header > .name,
          .catalog-product-view .accordion #product-review-container li.item .item-header > .name {
            float: left;
            font-weight: bold; }
            .catalog-product-view .accordion .block.review-list li.item .item-header > .name > span,
            .catalog-product-view .accordion #product-review-container li.item .item-header > .name > span {
              font-weight: 100; }
          .catalog-product-view .accordion .block.review-list li.item .item-header > .date-posted,
          .catalog-product-view .accordion #product-review-container li.item .item-header > .date-posted {
            float: right;
            color: #ABABAB;
            margin-right: 25px;
            text-align: right; }
        .catalog-product-view .accordion .block.review-list li.item > .title,
        .catalog-product-view .accordion #product-review-container li.item > .title {
          margin-bottom: 2px;
          font-weight: bold; }
        .catalog-product-view .accordion .block.review-list li.item > .content,
        .catalog-product-view .accordion #product-review-container li.item > .content {
          color: #333; }
        .catalog-product-view .accordion .block.review-list li.item .ratings .rating-result,
        .catalog-product-view .accordion #product-review-container li.item .ratings .rating-result {
          width: 102px;
          display: inline-block;
          position: relative;
          vertical-align: middle; }
          .catalog-product-view .accordion .block.review-list li.item .ratings .rating-result:before,
          .catalog-product-view .accordion #product-review-container li.item .ratings .rating-result:before {
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            -webkit-font-smoothing: antialiased;
            color: #c7c7c7;
            font-family: "FontAwesome";
            font-size: 16px;
            height: 16px;
            letter-spacing: 8px !important;
            line-height: 16px;
            content: "" "" "" "" "";
            display: block;
            font-style: normal;
            font-weight: normal;
            speak: none; }
          .catalog-product-view .accordion .block.review-list li.item .ratings .rating-result > span,
          .catalog-product-view .accordion #product-review-container li.item .ratings .rating-result > span {
            display: block;
            overflow: hidden;
            margin-bottom: 5px; }
            .catalog-product-view .accordion .block.review-list li.item .ratings .rating-result > span:before,
            .catalog-product-view .accordion #product-review-container li.item .ratings .rating-result > span:before {
              position: relative;
              z-index: 2;
              -webkit-font-smoothing: antialiased;
              color: #ff7a0e;
              color: #f8d048 !important;
              font-family: "FontAwesome";
              font-size: 16px;
              height: 16px;
              letter-spacing: 8px !important;
              line-height: 16px;
              content: "" "" "" "" "";
              display: block;
              font-style: normal;
              font-weight: normal;
              speak: none; }
            .catalog-product-view .accordion .block.review-list li.item .ratings .rating-result > span span,
            .catalog-product-view .accordion #product-review-container li.item .ratings .rating-result > span span {
              border: 0;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
              display: none; }
  .catalog-product-view .column.main .block {
    border-color: #000000; }
    .catalog-product-view .column.main .block .block-title strong {
      font-family: rift, sans-serif !important;
      font-size: 32px; }
    .catalog-product-view .column.main .block .block-actions {
      display: none; }
  .catalog-product-view aside.modal-popup._inner-scroll._show .modal-inner-wrap {
    width: max-content; }
  .catalog-product-view aside.modal-popup._inner-scroll._show .modal-footer {
    text-align: center; }
    .catalog-product-view aside.modal-popup._inner-scroll._show .modal-footer .action-primary {
      font-family: rift, sans-serif;
      background-color: #000000;
      border-radius: 2px;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-style: normal;
      font-size: 15px;
      text-transform: uppercase;
      padding: 0.5em 2em;
      text-align: center;
      transition: all 0.3s ease;
      height: 44px;
      min-width: 80px;
      border: none; }
      .catalog-product-view aside.modal-popup._inner-scroll._show .modal-footer .action-primary:hover {
        background-color: #454343; }
  .catalog-product-view .item-additional-info {
    width: 100%; }
    @media (min-width: 1200px) {
      .catalog-product-view .item-additional-info {
        margin-left: calc((100vw - 60px - 1043px) / 2); } }
    @media (min-width: 1400px) {
      .catalog-product-view .item-additional-info {
        margin-left: calc((1366px - 1043px) / 2); } }
    @media (max-width: 767px) {
      .catalog-product-view .item-additional-info {
        width: auto;
        margin-left: -2rem;
        margin-right: -2rem; }
        .catalog-product-view .item-additional-info .products-grid {
          margin-bottom: 0; }
          .catalog-product-view .item-additional-info .products-grid .products.list.items.product-items {
            display: flex;
            flex-wrap: wrap; }
            .catalog-product-view .item-additional-info .products-grid .products.list.items.product-items .item.product.product-item {
              width: 50%; }
              .catalog-product-view .item-additional-info .products-grid .products.list.items.product-items .item.product.product-item:nth-child(4) ~ .product-item {
                display: none; } }
    .catalog-product-view .item-additional-info .related {
      position: relative;
      margin-bottom: 0; }
      .catalog-product-view .item-additional-info .related::before, .catalog-product-view .item-additional-info .related::after {
        content: "";
        position: absolute;
        top: -1px;
        width: 100vw;
        height: 0px;
        box-sizing: border-box;
        outline: 1px solid #000;
        transform: scaleY(0.5); }
      .catalog-product-view .item-additional-info .related::before {
        right: 100%; }
      .catalog-product-view .item-additional-info .related::after {
        left: 100%; }
  .catalog-product-view .stock-levels-wrapper {
    padding-top: 2px;
    flex: 1; }
    .catalog-product-view .stock-levels-wrapper > div {
      float: left; }
      .catalog-product-view .stock-levels-wrapper > div.stock-level-colour {
        background: #009645;
        border-radius: 30px;
        height: 14px;
        width: 14px;
        margin: 4px 5px;
        line-height: 0.8em;
        text-align: center;
        display: inline-block; }
        .catalog-product-view .stock-levels-wrapper > div.stock-level-colour.low-stock {
          background: #FF7900; }
        .catalog-product-view .stock-levels-wrapper > div.stock-level-colour.no-stock {
          background: #ff0800; }
          .catalog-product-view .stock-levels-wrapper > div.stock-level-colour.no-stock svg {
            display: none; }
      .catalog-product-view .stock-levels-wrapper > div.stock-level-text {
        display: inline-block;
        line-height: 1.4em;
        margin: 2px 0; }

.column.main .product.media {
  text-align: center;
  position: relative; }
  @media (min-width: 1200px) {
    .column.main .product.media {
      box-sizing: border-box;
      padding-right: 17px; } }
  @media (min-width: 768px) {
    .column.main .product.media {
      margin-right: 22px; } }

.fotorama__stage__frame > img {
  width: 500px; }

.fotorama__nav {
  width: 100% !important; }
  @media (min-width: 768px) {
    .fotorama__nav {
      height: 240px !important;
      margin-top: 15px; }
      .fotorama__nav::before, .fotorama__nav::after {
        display: none !important; } }

@media (min-width: 768px) {
  .fotorama__nav__shaft .fotorama__nav__frame {
    width: 240px !important;
    height: 240px !important;
    margin-right: 18px; } }

.fotorama__thumb-border {
  display: none; }

.magnify-lens {
  border: none; }

.fotorama__fullscreen-icon {
  background-image: url(../images/close.svg);
  background-size: 25px 25px;
  z-index: 1; }
  .fotorama__fullscreen-icon::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh; }

.fotorama__fullscreen .fotorama__arr--prev .fotorama__arr__arr, .fotorama__fullscreen
.fotorama__arr--next .fotorama__arr__arr {
  background-image: url(../images/right.svg);
  background-size: 14px 24.5px; }

.fotorama__fullscreen .fotorama__arr--prev .fotorama__arr__arr {
  transform-origin: 20px 20px;
  transform: rotate(180deg); }

.fotorama--fullscreen {
  background-color: rgba(0, 0, 0, 0.3); }
  .fotorama--fullscreen .fotorama__nav-wrap,
  .fotorama--fullscreen .fotorama__zoom-in,
  .fotorama--fullscreen .fotorama__zoom-out {
    display: none !important; }
  .fotorama--fullscreen .fotorama__arr {
    width: 80px;
    height: 80px;
    margin: auto 0; }
  .fotorama--fullscreen .fotorama__arr__arr,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    background-position: center !important; }
  .fotorama--fullscreen .fotorama__arr,
  .fotorama--fullscreen .fotorama__thumb__arr,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: block !important;
    background-color: transparent !important;
    background-repeat: no-repeat; }
    .fotorama--fullscreen .fotorama__arr:hover,
    .fotorama--fullscreen .fotorama__thumb__arr:hover,
    .fotorama--fullscreen .fotorama__fullscreen-icon:hover {
      background-color: rgba(0, 0, 0, 0.3) !important; }

@media (max-width: 767px) {
  .product-reviews-summary .reviews-actions {
    display: none; }
  .column.main .product.media {
    margin-bottom: 25px; }
    .column.main .product.media .fotorama__nav__shaft {
      display: flex;
      justify-content: center;
      width: 100% !important;
      margin: 0 auto; }
      .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame--thumb {
        display: none !important; }
      .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame {
        width: auto !important;
        height: 10px !important;
        margin: 10px 5px 0px !important;
        flex: 1 1 auto;
        display: flex;
        align-items: center; }
        .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame .fotorama__dot {
          border-radius: 0;
          height: 3px;
          background: #dddddd;
          border: none;
          width: 95%;
          left: 0;
          top: 0;
          flex: 1;
          margin: 0 auto; }
        .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame.fotorama__active .fotorama__dot {
          background: #000000; } }

.review-block {
  margin-bottom: 20px; }
  .review-block > span {
    display: block;
    line-height: 2em;
    margin-bottom: 10px; }
  .review-block button.primary {
    background: black;
    border: 2px solid black;
    font-family: rift, sans-serif;
    font-size: 16px;
    padding: 10px 30px; }
    .review-block button.primary:hover {
      background-color: #fff;
      color: black; }

#review-form-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  display: block;
  z-index: 900; }
  #review-form-wrapper.active {
    width: 100%;
    height: 100%; }
    #review-form-wrapper.active .review-form-add {
      right: 0; }
  #review-form-wrapper .review-fieldset {
    margin-bottom: 0; }
    #review-form-wrapper .review-fieldset .label {
      margin-bottom: 4px; }
    #review-form-wrapper .review-fieldset input,
    #review-form-wrapper .review-fieldset textarea {
      font-size: 13px;
      border-radius: 2px; }
  #review-form-wrapper .btn {
    min-width: 127px;
    line-height: 1;
    border-width: 2px; }
  #review-form-wrapper .review-form-add {
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 414px;
    background: white;
    z-index: 901;
    overflow-y: auto;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    transition: right 0.3s linear; }
    #review-form-wrapper .review-form-add .inner-wrapper {
      top: 0;
      margin: 0 auto !important;
      border: 0;
      min-width: auto;
      background: white; }
      #review-form-wrapper .review-form-add .inner-wrapper .sidebar-heading {
        background: black;
        color: white;
        padding-top: 5.3rem;
        padding-left: 3.2rem;
        padding-right: 2.5rem;
        padding-bottom: 2rem;
        background-color: #000;
        box-sizing: border-box; }
        #review-form-wrapper .review-form-add .inner-wrapper .sidebar-heading h2 {
          margin: 0;
          font-weight: 700;
          font-size: 32px;
          font-family: rift, sans-serif;
          display: inline-block;
          line-height: 1.2; }
        #review-form-wrapper .review-form-add .inner-wrapper .sidebar-heading .btn.close {
          position: absolute;
          top: 6px;
          right: 6px;
          display: inline-block;
          min-width: 0;
          width: 40px;
          height: 40px;
          padding: 0; }
      #review-form-wrapper .review-form-add .inner-wrapper .block-content {
        width: 100%;
        margin: 0 auto; }
        #review-form-wrapper .review-form-add .inner-wrapper .block-content form {
          padding: 30px; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .product-reviewing {
            margin-bottom: 15px; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .fieldset .review-field-ratings > .label,
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .fieldset .field,
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .field {
            margin-bottom: 15px;
            margin-left: 0;
            padding-inline-start: 2px; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .fieldset .review-field-ratings {
            margin: 0;
            padding: 0; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .field.required label.label:after {
            content: "*" !important;
            color: red;
            margin-left: 3px; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .label span {
            font-family: roboto, sans-serif;
            font-weight: bold;
            color: black;
            display: inline-block;
            font-family: roboto, sans-serif;
            text-transform: none;
            font-size: 12px; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form input,
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form textarea {
            border: 1px solid #dddddd; }
          #review-form-wrapper .review-form-add .inner-wrapper .block-content form .review-control-vote {
            margin: 0 0 35px 0; }
            #review-form-wrapper .review-form-add .inner-wrapper .block-content form .review-control-vote:before {
              content: "" "" "" "" "";
              color: rgba(248, 208, 72, 0.3);
              font-size: 24px;
              letter-spacing: 8px !important;
              line-height: 24px;
              height: auto; }
            #review-form-wrapper .review-form-add .inner-wrapper .block-content form .review-control-vote label:before {
              color: #f8d048;
              font-size: 24px;
              letter-spacing: 8px !important;
              line-height: 24px;
              height: auto; }
  #review-form-wrapper:before {
    content: " ";
    background: rgba(0, 0, 0, 0.3);
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }

.tarkikComandSlider {
  width: 1100px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  position: relative; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  display: none; }

.slick-dots li:before {
  top: 1px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  position: absolute; }

.slick-dots li.slick-active:before {
  top: 0;
  width: 8px;
  height: 8px;
  margin-left: -2px;
  background-color: #964848; }

.slick-prev {
  left: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.slick-next {
  right: 0; }

.slick-arrow {
  top: 50%;
  height: 40px;
  width: 26px;
  transform: translateY(-50%);
  position: absolute;
  font-size: 0;
  cursor: pointer;
  background-color: transparent;
  border: none; }
  .slick-arrow.slick-next::before, .slick-arrow.slick-prev::before {
    content: "";
    display: block;
    width: 26px;
    height: 40px;
    background-image: url(../images/right.svg);
    background-size: 17.74px 35.47px;
    background-repeat: no-repeat;
    background-position: center; }
  .slick-arrow.slick-prev::before {
    transform: rotate(180deg); }
  .desktop-home-categories .slick-arrow {
    margin-top: -38px;
    background: none; }
    .desktop-home-categories .slick-arrow.slick-prev {
      left: 36px; }
    .desktop-home-categories .slick-arrow.slick-next {
      right: 36px; }

.desktop-home-categories .slick-dots {
  bottom: 95px; }

.slick-dots li {
  max-width: 100px;
  width: 29%; }
  .slick-dots li::before {
    position: absolute;
    background-color: #ffffff;
    border-radius: 0;
    width: 100%;
    height: 3px;
    margin-left: 5px;
    margin-right: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    left: 0; }
  .slick-dots li.slick-active::before {
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    height: 3px;
    background-color: #000000; }

.comandSlider__item {
  -webkit-transition: box-shadow 2s;
  transition: box-shadow 2s;
  cursor: pointer;
  position: relative;
  background-color: #344c65;
  margin: 0 10px;
  padding: 30px;
  box-shadow: inset -141px -129px 200px rgba(0, 0, 0, 0.25); }

.comandSlider__item:hover {
  box-shadow: inset 141px 129px 200px rgba(0, 0, 0, 0.25); }

.comandSlider__item_name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #3eaef2;
  margin: 0;
  margin-bottom: 7px; }

.comandSlider__item_status {
  margin: 0;
  color: #fff;
  font-size: 12px;
  margin-bottom: 80px; }

.comandSlider__item_sendMail {
  width: 25px;
  height: 20px;
  position: absolute;
  background-image: url(https://tarkikromanski.github.io/files/mail-ico.png);
  top: 34px;
  right: 30px; }

.comandSlider__item_photo {
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  margin-bottom: 54px; }

.comandSlider__item_contact {
  font-size: 12px;
  color: #fff;
  margin: 2px; }

.comandSlider__item_contact span {
  color: #3eaef2;
  font-weight: 700;
  margin-right: 5px; }

.price-box.price-final_price {
  display: flex; }

.old-price .price-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sly-old-price {
  display: block;
  margin-left: 0.5rem; }

.product-gift-card .click-collect-wrapper,
.product-gift-card .top-holder-left .product.attribute.sku,
.product-gift-card .top-holder-right,
.product-gift-card .accordion.product.info {
  display: none !important; }

.rating-result.rating-result {
  width: 102px; }
  .rating-result.rating-result::before,
  .rating-result.rating-result span::before {
    content: "" !important;
    display: block;
    letter-spacing: 0px;
    font-size: 2.4rem;
    height: 14px;
    padding: 2px 0px;
    white-space: nowrap;
    background-image: url(../images/star.svg);
    background-size: 20.7px 13.6px;
    background-repeat: repeat-x;
    background-position: left center; }
  .rating-result.rating-result::before {
    width: 100%;
    opacity: 0.5; }
  .rating-result.rating-result .rating-1::before {
    width: 19px; }
  .rating-result.rating-result .rating-2::before {
    width: 39px; }
  .rating-result.rating-result .rating-3::before {
    width: 59px; }
  .rating-result.rating-result .rating-4::before {
    width: 80px; }
  .rating-result.rating-result .rating-5::before {
    width: 102px; }

.gallery-placeholder {
  position: relative; }

.magnifier-preview {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.modal-popup .modal-inner-wrap {
  padding: 0;
  width: 100vw !important;
  max-width: 993px;
  height: 580px;
  overflow: hidden;
  border: 2px solid black; }
  .modal-popup .modal-inner-wrap #modal-content-24,
  .modal-popup .modal-inner-wrap .modal-content {
    padding: 0;
    overflow: hidden; }
  .modal-popup .modal-inner-wrap .modal-title {
    font-weight: 700;
    color: #000000; }
  .modal-popup .modal-inner-wrap .modal-header {
    padding: 0;
    height: 0; }
  .modal-popup .modal-inner-wrap .action-close {
    height: 40px;
    position: absolute; }
    .modal-popup .modal-inner-wrap .action-close:before {
      content: " " !important;
      background: no-repeat url("../images/close_loader.svg");
      height: 45px;
      width: 45px;
      position: absolute;
      left: -40px; }
    .modal-popup .modal-inner-wrap .action-close:active {
      transform: none; }
  .modal-popup .modal-inner-wrap iframe {
    background: url("../images/loader.svg") no-repeat center center; }

/* Quick View Styling */
.quickview-scroll .page-wrapper {
  padding-top: 40px; }

.quickview-scroll .column.main .product-info-main {
  width: 51% !important;
  margin-bottom: 20px; }

.quickview-scroll .column.main .product.media {
  width: 377px !important; }

.quickview-scroll .column.main .product.media .fotorama__nav__shaft .fotorama__nav__frame {
  width: 111px !important;
  height: 111px !important; }

.quickview-scroll .column.main .product.media .fotorama__nav {
  height: 111px !important; }

.quickview-scroll .product-info-main {
  position: relative; }
  .quickview-scroll .product-info-main:before {
    content: " ";
    background: #e6e6e6;
    position: absolute;
    left: -15px;
    width: 6px;
    height: 100%; }
  .quickview-scroll .product-info-main .product-social-links {
    display: none; }
  .quickview-scroll .product-info-main .full-details-link {
    display: block;
    font-size: 13px; }
    .quickview-scroll .product-info-main .full-details-link a {
      color: #FF7900 !important;
      text-decoration: underline; }
      .quickview-scroll .product-info-main .full-details-link a:visited, .quickview-scroll .product-info-main .full-details-link a:hover {
        color: #FF7900; }

.quickview-scroll .catalog-product-view
.product-info-main
.product-options-bottom
.product-view-cart
> .actions.add-cart {
  flex: 4; }

.quickview-scroll .fotorama__stage.fotorama__shadows--left:before,
.quickview-scroll .fotorama__nav.fotorama__shadows--left:before,
.quickview-scroll .fotorama__stage.fotorama__shadows--right:after,
.quickview-scroll .fotorama__nav.fotorama__shadows--right:after {
  background: none; }

.product-info-main .product.attribute.sku .value {
  font-weight: 100; }

.sizing-guide div[data-content-type="html"] {
  display: inline; }

.sizing-guide a {
  font-size: 13px; }

.column.main .product-info-main button.action.primary.tocart {
  font-family: rift, sans-serif !important; }

body.page-product-configurable .product-description.description > div > div {
  display: block !important; }

.catalog-product-view
.product-info-main
.price-box.price-final_price
.old-price {
  display: inline !important; }

.flex-prices {
  margin-bottom: 5px; }
  .flex-prices .price-box {
    width: 100% !important;
    line-height: 1em !important;
    flex-direction: column; }
    .flex-prices .price-box > div {
      width: 100%; }
    .flex-prices .price-box > div span {
      line-height: 1.1em !important; }
    .flex-prices .price-box .price-label {
      margin-right: 4px; }
    .flex-prices .price-box .price-wrapper .price.strike .price {
      margin-right: 5px !important;
      color: #b5b5b5 !important;
      text-decoration: line-through; }
    .flex-prices .price-box .price-wrapper .price.special .price {
      color: #ff0800 !important; }

.stock-level-colour.no-stock svg {
  display: none; }

.page-layout-product-reboot .top-container, .page-layout-product-hero .top-container {
  margin-left: 0;
  margin-right: 0;
  background-color: #ebede3;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0; }
  .page-layout-product-reboot .top-container,
  .page-layout-product-reboot .top-container *, .page-layout-product-hero .top-container,
  .page-layout-product-hero .top-container * {
    box-sizing: border-box; }
  .page-layout-product-reboot .top-container .cta-reboot-left-banner, .page-layout-product-hero .top-container .cta-reboot-left-banner {
    max-width: 47rem; }
  .page-layout-product-reboot .top-container .cta-reboot-banner [data-content-type='row'][data-appearance='contained'], .page-layout-product-hero .top-container .cta-reboot-banner [data-content-type='row'][data-appearance='contained'] {
    max-width: 100% !important; }
    .page-layout-product-reboot .top-container .cta-reboot-banner [data-content-type='row'][data-appearance='contained'] [data-element='inner'], .page-layout-product-hero .top-container .cta-reboot-banner [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
      margin: 0 !important;
      padding: 0 !important;
      height: 47px;
      background-repeat: repeat-x;
      background-position: center !important; }
  .page-layout-product-reboot .top-container .cta-reboot-hero-banner-container, .page-layout-product-hero .top-container .cta-reboot-hero-banner-container {
    max-width: 1920px;
    margin: 0 auto; }
  .page-layout-product-reboot .top-container .cta-reboot-hero-banner, .page-layout-product-hero .top-container .cta-reboot-hero-banner {
    height: 0;
    font-size: 10rem;
    background-position: center;
    background-size: cover;
    background-color: #000000;
    padding-bottom: 42.5%; }
    .page-layout-product-reboot .top-container .cta-reboot-hero-banner:not(:only-child), .page-layout-product-hero .top-container .cta-reboot-hero-banner:not(:only-child) {
      display: none; }
    .page-layout-product-reboot .top-container .cta-reboot-hero-banner.mobile, .page-layout-product-hero .top-container .cta-reboot-hero-banner.mobile {
      display: block;
      padding-bottom: 100%; }
    @media (min-width: 768px) {
      .page-layout-product-reboot .top-container .cta-reboot-hero-banner.cta-reboot-hero-banner, .page-layout-product-hero .top-container .cta-reboot-hero-banner.cta-reboot-hero-banner {
        display: block; }
      .page-layout-product-reboot .top-container .cta-reboot-hero-banner.mobile:not(:only-child), .page-layout-product-hero .top-container .cta-reboot-hero-banner.mobile:not(:only-child) {
        display: none; } }
  .page-layout-product-reboot .top-container .cta-reboot-grid, .page-layout-product-hero .top-container .cta-reboot-grid {
    margin-left: auto;
    margin-right: auto;
    max-width: 1366px;
    padding: 2rem 2rem 4rem;
    color: #000000; }
    @media (min-width: 1024px) {
      .page-layout-product-reboot .top-container .cta-reboot-grid, .page-layout-product-hero .top-container .cta-reboot-grid {
        display: flex;
        justify-content: space-between; } }
  .page-layout-product-reboot .top-container .cta-reboot-left-column, .page-layout-product-hero .top-container .cta-reboot-left-column {
    flex-grow: 1;
    font-size: 1.7rem; }
    @media (min-width: 1024px) {
      .page-layout-product-reboot .top-container .cta-reboot-left-column, .page-layout-product-hero .top-container .cta-reboot-left-column {
        padding-right: 2rem; } }
    .page-layout-product-reboot .top-container .cta-reboot-left-column ul, .page-layout-product-hero .top-container .cta-reboot-left-column ul {
      padding-left: 1.7rem; }
    .page-layout-product-reboot .top-container .cta-reboot-left-column p, .page-layout-product-hero .top-container .cta-reboot-left-column p {
      font-size: inherit;
      line-height: inherit; }
  .page-layout-product-reboot .top-container .cta-reboot-title, .page-layout-product-hero .top-container .cta-reboot-title {
    font-family: rift, sans-serif !important;
    font-weight: 700;
    font-size: 8rem;
    line-height: 1;
    margin: 2rem 0; }
  .page-layout-product-reboot .top-container .cta-reboot-tagline, .page-layout-product-hero .top-container .cta-reboot-tagline {
    font-family: rift, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem 0 5rem; }
  .page-layout-product-reboot .top-container .cta-reboot-right-column, .page-layout-product-hero .top-container .cta-reboot-right-column {
    flex: 0 0 427px;
    max-width: 427px; }
  .page-layout-product-reboot .top-container .cta-reboot-size, .page-layout-product-hero .top-container .cta-reboot-size {
    position: relative;
    z-index: 0;
    margin-top: 3rem;
    padding: 3rem;
    max-width: 42rem; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-top::before, .page-layout-product-reboot .top-container .cta-reboot-size .size-top::after,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::before,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::after, .page-layout-product-hero .top-container .cta-reboot-size .size-top::before, .page-layout-product-hero .top-container .cta-reboot-size .size-top::after,
    .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::before,
    .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 40px;
      height: 40px; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-top::before, .page-layout-product-reboot .top-container .cta-reboot-size .size-top::after, .page-layout-product-hero .top-container .cta-reboot-size .size-top::before, .page-layout-product-hero .top-container .cta-reboot-size .size-top::after {
      border-top: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-top::before, .page-layout-product-hero .top-container .cta-reboot-size .size-top::before {
      border-left: 1px solid #89de00;
      top: 0;
      left: 0; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-top::after, .page-layout-product-hero .top-container .cta-reboot-size .size-top::after {
      border-right: 1px solid #89de00;
      top: 0;
      right: 0; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::before, .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::after, .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::before, .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::after {
      border-bottom: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::before, .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::before {
      border-left: 1px solid #89de00;
      bottom: 0;
      left: 0; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottom::after, .page-layout-product-hero .top-container .cta-reboot-size .size-bottom::after {
      border-right: 1px solid #89de00;
      bottom: 0;
      right: 0; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-leftcenter::before, .page-layout-product-reboot .top-container .cta-reboot-size .size-leftcenter::after,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-rightcenter::before,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-rightcenter::after,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-topcenter::before,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-topcenter::after,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottomcenter::before,
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottomcenter::after, .page-layout-product-hero .top-container .cta-reboot-size .size-leftcenter::before, .page-layout-product-hero .top-container .cta-reboot-size .size-leftcenter::after,
    .page-layout-product-hero .top-container .cta-reboot-size .size-rightcenter::before,
    .page-layout-product-hero .top-container .cta-reboot-size .size-rightcenter::after,
    .page-layout-product-hero .top-container .cta-reboot-size .size-topcenter::before,
    .page-layout-product-hero .top-container .cta-reboot-size .size-topcenter::after,
    .page-layout-product-hero .top-container .cta-reboot-size .size-bottomcenter::before,
    .page-layout-product-hero .top-container .cta-reboot-size .size-bottomcenter::after {
      content: '';
      position: absolute; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-leftcenter::before, .page-layout-product-hero .top-container .cta-reboot-size .size-leftcenter::before {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      border-left: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-leftcenter::after, .page-layout-product-hero .top-container .cta-reboot-size .size-leftcenter::after {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 6px;
      border-top: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-rightcenter::before, .page-layout-product-hero .top-container .cta-reboot-size .size-rightcenter::before {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 12px;
      border-right: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-rightcenter::after, .page-layout-product-hero .top-container .cta-reboot-size .size-rightcenter::after {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 6px;
      border-top: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-topcenter::before, .page-layout-product-hero .top-container .cta-reboot-size .size-topcenter::before {
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 12px;
      border-top: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-topcenter::after, .page-layout-product-hero .top-container .cta-reboot-size .size-topcenter::after {
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 6px;
      border-left: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottomcenter::before, .page-layout-product-hero .top-container .cta-reboot-size .size-bottomcenter::before {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 12px;
      border-bottom: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size .size-bottomcenter::after, .page-layout-product-hero .top-container .cta-reboot-size .size-bottomcenter::after {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 6px;
      border-left: 1px solid #89de00; }
    .page-layout-product-reboot .top-container .cta-reboot-size ul, .page-layout-product-hero .top-container .cta-reboot-size ul {
      margin-bottom: 0;
      padding-left: 1.7rem; }
      .page-layout-product-reboot .top-container .cta-reboot-size ul li:last-child, .page-layout-product-hero .top-container .cta-reboot-size ul li:last-child {
        margin-bottom: 0; }
  .page-layout-product-reboot .top-container .cta-reboot-size-heading, .page-layout-product-hero .top-container .cta-reboot-size-heading {
    font-family: rift, sans-serif !important;
    margin: 0 0 2rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase; }
  .page-layout-product-reboot .top-container .cta-reboot-durability-gauge, .page-layout-product-hero .top-container .cta-reboot-durability-gauge {
    position: relative;
    margin-top: 5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid #000000;
    padding: 0.2rem 1.2rem;
    font-size: 1.3rem;
    background: transparent linear-gradient(90deg, rgba(239, 239, 239, 0) 0%, #838383 100%) 0% 0% no-repeat padding-box; }
    .page-layout-product-reboot .top-container .cta-reboot-durability-gauge .cta-reboot-durability-gauge-value, .page-layout-product-hero .top-container .cta-reboot-durability-gauge .cta-reboot-durability-gauge-value {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../images/fox-star.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 42px;
      height: 42px; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute .swatch-attribute-label, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute .swatch-attribute-label {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    font-family: rift, sans-serif;
    color: #000; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute .sizing-guide div[data-content-type="html"], .page-layout-product-hero .top-container .swatch-opt .swatch-attribute .sizing-guide div[data-content-type="html"] {
    display: inline; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute .sizing-guide a, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute .sizing-guide a {
    color: #FF7900 !important;
    text-decoration: underline;
    padding-left: 25px; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute .sizing-guide a:visited, .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute .sizing-guide a:hover, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute .sizing-guide a:visited, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute .sizing-guide a:hover {
      color: #FF7900; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color .swatch-attribute-selected-option, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color .swatch-attribute-selected-option {
    position: relative;
    font-size: 13px;
    font-family: roboto, sans-serif;
    display: block;
    height: 25px;
    padding-left: 0;
    margin: 0 0 1px;
    color: #333; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image),
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image),
  .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image {
    height: 45px !important;
    width: 45px !important;
    position: relative;
    background-size: contain !important;
    outline-width: 1px;
    margin-left: 0.1rem;
    margin-right: 1.2rem;
    padding: 0;
    box-sizing: border-box; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image):not(.disabled):hover.selected,
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image:not(.disabled):hover.selected, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image):not(.disabled):hover.selected,
    .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image:not(.disabled):hover.selected {
      outline-color: #000000; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled,
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled,
    .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled {
      pointer-events: none;
      opacity: 0.3; }
      .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled::after,
      .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled::after, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image).disabled::after,
      .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option.image.disabled::after {
        display: none; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image), .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.color > div .swatch-option:not(.image) {
    background-image: url(../images/swatch_1.png); }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size .swatch-attribute-selected-option, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size .swatch-attribute-selected-option {
    display: none; }
  .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text {
    height: 45px !important;
    width: 45px !important;
    position: relative;
    text-align: center;
    vertical-align: middle;
    line-height: 45px;
    font-size: 13px;
    font-family: roboto, sans-serif;
    border-radius: 0;
    outline: none !important;
    border: 1px solid #000000;
    font-weight: 100;
    margin-right: 0.8rem;
    padding: 0; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.selected, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.selected {
      border: 1px solid #000000 !important; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text:not(.disabled):hover, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text:not(.disabled):hover {
      outline: none !important;
      border: 1px solid #969696 !important; }
    .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled {
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.3); }
      .page-layout-product-reboot .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled::after, .page-layout-product-hero .top-container .swatch-opt .swatch-attribute.size > div .swatch-option.text.disabled::after {
        display: none; }
  .page-layout-product-reboot .top-container .product-addto-links a.add-to-wishlist, .page-layout-product-hero .top-container .product-addto-links a.add-to-wishlist {
    line-height: 35px;
    text-align: center;
    width: 35px;
    border: 1px solid red; }
    .page-layout-product-reboot .top-container .product-addto-links a.add-to-wishlist:hover, .page-layout-product-hero .top-container .product-addto-links a.add-to-wishlist:hover {
      opacity: 0.5; }
    .page-layout-product-reboot .top-container .product-addto-links a.add-to-wishlist span, .page-layout-product-hero .top-container .product-addto-links a.add-to-wishlist span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
  .page-layout-product-reboot .top-container .product-view-cart, .page-layout-product-hero .top-container .product-view-cart {
    width: 100%;
    position: relative;
    display: flex;
    overflow: visible; }
    .page-layout-product-reboot .top-container .product-view-cart > .actions, .page-layout-product-hero .top-container .product-view-cart > .actions {
      display: flex;
      position: relative; }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.dealer-button, .page-layout-product-hero .top-container .product-view-cart > .actions.dealer-button {
        flex-grow: 1; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.dealer-button:not(:last-child), .page-layout-product-hero .top-container .product-view-cart > .actions.dealer-button:not(:last-child) {
          margin-right: 16px; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.dealer-button a, .page-layout-product-hero .top-container .product-view-cart > .actions.dealer-button a {
          margin-right: 0;
          background-color: #FF7900;
          border-color: #FF7900;
          border-radius: 2px;
          font-family: rift, sans-serif;
          font-size: 16px;
          line-height: 1.8;
          text-align: center; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.dealer-button a:hover, .page-layout-product-hero .top-container .product-view-cart > .actions.dealer-button a:hover {
            background-color: #000000;
            border-color: #000000; }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection {
        flex: 0 0 100px;
        position: relative;
        margin-right: 14px;
        width: 100px;
        border: 2px solid #000000;
        box-sizing: border-box;
        height: 60px;
        display: inline-flex !important;
        border-radius: 2px;
        background-color: #ffffff; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .mage-error, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .mage-error {
          position: absolute;
          top: 100%;
          left: 0;
          line-height: 1.2; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control {
          display: inline;
          flex: 1;
          height: 56px;
          font-size: 15px;
          font-family: roboto, sans-serif;
          color: #333;
          line-height: 56px; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control.input-text.qty, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control.input-text.qty {
            background: none;
            border: none !important;
            max-width: 35px;
            padding: 0;
            margin-top: 1px; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-change, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-change {
            font-weight: 100;
            text-align: center;
            line-height: 58px;
            cursor: pointer;
            color: #333;
            font-size: calc(100% + 5px); }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control:before, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control:before {
            height: 100%;
            width: 30%;
            margin: 0 auto;
            position: relative;
            display: block;
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center center; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-up:before, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-up:before {
            content: " ";
            background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" class="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>'); }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-down:before, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection .qty-control.qty-down:before {
            content: " ";
            background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="minus" class="svg-inline--fa fa-minus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>'); }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.add-cart, .page-layout-product-hero .top-container .product-view-cart > .actions.add-cart {
        display: flex;
        width: 100%; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.add-cart button.action.primary.tocart, .page-layout-product-hero .top-container .product-view-cart > .actions.add-cart button.action.primary.tocart {
          padding-left: 0 !important;
          padding-right: 0 !important;
          font-family: rift, sans-serif !important;
          border: 2px solid black;
          background-color: #000000;
          white-space: nowrap; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.add-cart button.action.primary.tocart:hover, .page-layout-product-hero .top-container .product-view-cart > .actions.add-cart button.action.primary.tocart:hover {
            background: white;
            color: black; }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.wish-list, .page-layout-product-hero .top-container .product-view-cart > .actions.wish-list {
        flex: 1;
        position: relative;
        flex: 0 0 44px; }
        .page-layout-product-reboot .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist, .page-layout-product-hero .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist {
          display: block;
          line-height: 60px;
          font-size: 38px;
          color: #000000;
          transition: opacity 0.3s;
          text-align: center;
          width: 100%; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist:hover, .page-layout-product-hero .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist:hover {
            opacity: 0.5;
            text-decoration: none !important; }
          .page-layout-product-reboot .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist > span, .page-layout-product-hero .top-container .product-view-cart > .actions.wish-list a.add-to-wishlist > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            text-decoration: none; }
    @media (max-width: 768px) {
      .page-layout-product-reboot .top-container .product-view-cart > .actions.qty-selection, .page-layout-product-hero .top-container .product-view-cart > .actions.qty-selection {
        flex: 0 0 auto; }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.add-cart, .page-layout-product-hero .top-container .product-view-cart > .actions.add-cart {
        flex-grow: 1; }
      .page-layout-product-reboot .top-container .product-view-cart > .actions.wish-list, .page-layout-product-hero .top-container .product-view-cart > .actions.wish-list {
        flex: 0 0 auto;
        margin-left: 1.4rem; } }

.page-layout-product-reboot .cta-reboot-right-column .product-add-form .product-options-bottom, .page-layout-product-hero .cta-reboot-right-column .product-add-form .product-options-bottom {
  width: 100%;
  float: none; }

.page-layout-product-reboot .cta-reboot-right-column .product-options-wrapper, .page-layout-product-hero .cta-reboot-right-column .product-options-wrapper {
  float: none;
  margin-bottom: 0 !important;
  overflow: auto; }

.page-layout-product-reboot .swatch-attribute-options .swatch-option.text, .page-layout-product-hero .swatch-attribute-options .swatch-option.text {
  opacity: 1;
  color: #000000;
  background-color: #ffffff; }

.page-layout-product-reboot .item-additional-info, .page-layout-product-hero .item-additional-info {
  max-width: 1366px !important;
  margin: 0 auto !important; }
  @media (max-width: 767.98px) {
    .page-layout-product-reboot .item-additional-info .tradein-offer, .page-layout-product-hero .item-additional-info .tradein-offer {
      margin: 0 -2rem; } }
  .page-layout-product-reboot .item-additional-info .cta-hero-cashback-offer, .page-layout-product-hero .item-additional-info .cta-hero-cashback-offer {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    color: #000000; }
    @media (min-width: 768px) {
      .page-layout-product-reboot .item-additional-info .cta-hero-cashback-offer, .page-layout-product-hero .item-additional-info .cta-hero-cashback-offer {
        margin-top: 8rem;
        font-size: 6.5rem; } }
  .page-layout-product-reboot .item-additional-info [data-content-type='row'][data-appearance='contained'] [data-element='inner'], .page-layout-product-hero .item-additional-info [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    padding: 0 !important; }
  .page-layout-product-reboot .item-additional-info input, .page-layout-product-hero .item-additional-info input {
    display: block;
    max-width: 597px;
    width: 100%;
    margin: 0 auto;
    border-radius: 3px;
    font-family: rift, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    padding: 2.5rem 2rem; }
  .page-layout-product-reboot .item-additional-info .store-list, .page-layout-product-hero .item-additional-info .store-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1208px !important;
    margin: 3rem auto;
    padding: 0.3rem 0; }
    @media (min-width: 1024px) {
      .page-layout-product-reboot .item-additional-info .store-list, .page-layout-product-hero .item-additional-info .store-list {
        overflow-y: auto;
        max-height: 393px;
        margin-top: 6rem; } }
    .page-layout-product-reboot .item-additional-info .store-list .block-wrapper, .page-layout-product-hero .item-additional-info .store-list .block-wrapper {
      flex: 0 100%;
      box-sizing: border-box;
      margin-bottom: 1.9rem;
      padding-left: 0.95rem;
      padding-right: 0.95rem; }
      @media (min-width: 550px) {
        .page-layout-product-reboot .item-additional-info .store-list .block-wrapper, .page-layout-product-hero .item-additional-info .store-list .block-wrapper {
          flex: 0 50%; } }
      @media (min-width: 768px) {
        .page-layout-product-reboot .item-additional-info .store-list .block-wrapper, .page-layout-product-hero .item-additional-info .store-list .block-wrapper {
          flex: 0 33.333%; } }
      @media (min-width: 1024px) {
        .page-layout-product-reboot .item-additional-info .store-list .block-wrapper, .page-layout-product-hero .item-additional-info .store-list .block-wrapper {
          flex: 0 25%; } }
      .page-layout-product-reboot .item-additional-info .store-list .block-wrapper .block, .page-layout-product-hero .item-additional-info .store-list .block-wrapper .block {
        position: relative;
        display: flex;
        border-radius: 3px;
        margin-bottom: 0;
        height: 100%;
        padding: 2.1rem 5.6rem 2.1rem 1.8rem;
        box-sizing: border-box;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: none; }
        @media (max-width: 767.98px) {
          .page-layout-product-reboot .item-additional-info .store-list .block-wrapper .block, .page-layout-product-hero .item-additional-info .store-list .block-wrapper .block {
            padding: 2.5rem 5.6rem 2.5rem 1.9rem; } }
        .page-layout-product-reboot .item-additional-info .store-list .block-wrapper .block .brands-sold, .page-layout-product-hero .item-additional-info .store-list .block-wrapper .block .brands-sold {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          padding: 1rem 1.6rem 0 0; }
          @media (max-width: 767.98px) {
            .page-layout-product-reboot .item-additional-info .store-list .block-wrapper .block .brands-sold, .page-layout-product-hero .item-additional-info .store-list .block-wrapper .block .brands-sold {
              padding: 0.7rem 1.6rem 0 0; } }
          .page-layout-product-reboot .item-additional-info .store-list .block-wrapper .block .brands-sold .filter-icon, .page-layout-product-hero .item-additional-info .store-list .block-wrapper .block .brands-sold .filter-icon {
            flex: 0 0 3.6rem;
            margin-bottom: 0.4rem; }

.page-layout-product-reboot .product-options-bottom .box-tocart, .page-layout-product-hero .product-options-bottom .box-tocart {
  float: none; }

.page-layout-product-reboot #maincontent .columns, .page-layout-product-hero #maincontent .columns {
  max-width: 100% !important; }

.page-layout-product-reboot .price-box .price, .page-layout-product-hero .price-box .price {
  font-size: 2.2rem;
  font-family: rift, sans-serif;
  color: #000000;
  font-weight: 700; }

.page-layout-product-reboot .page-title-wrapper .page-title, .page-layout-product-hero .page-title-wrapper .page-title {
  margin-bottom: 2rem; }

.contact-index-index #maincontent {
  max-width: 1366px; }
  .contact-index-index #maincontent .page-title-wrapper {
    display: none; }
  @media (max-width: 678px) {
    .contact-index-index #maincontent {
      padding: 0; } }

.contact-index-index .contact-page .hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 600px) {
    .contact-index-index .contact-page .hero {
      height: 160px; } }
  .contact-index-index .contact-page .hero .page-title {
    margin: 0;
    color: #ffffff;
    font-size: 62px;
    font-weight: 700;
    line-height: 75px;
    text-shadow: 0px 3px 6px #00000073; }
    @media (max-width: 678px) {
      .contact-index-index .contact-page .hero .page-title {
        font-size: 42px; } }

.contact-index-index .contact-page div[data-background-type="image"] {
  padding: 0 !important;
  margin: 0 !important; }

.contact-index-index .contact-page .contact {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px; }
  .contact-index-index .contact-page .contact .contact-left, .contact-index-index .contact-page .contact .contact-right {
    flex-grow: 1; }
    .contact-index-index .contact-page .contact .contact-left .legend, .contact-index-index .contact-page .contact .contact-right .legend {
      background-color: #000000;
      padding: 8px 25px; }
      .contact-index-index .contact-page .contact .contact-left .legend span, .contact-index-index .contact-page .contact .contact-right .legend span {
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        font-family: rift, sans-serif;
        font-weight: 700; }
    .contact-index-index .contact-page .contact .contact-left .contact-info, .contact-index-index .contact-page .contact .contact-right .contact-info {
      padding: 40px 30px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .note, .contact-index-index .contact-page .contact .contact-right .contact-info .note {
        font-size: 22px;
        line-height: 27px;
        font-weight: 700;
        font-family: rift, sans-serif;
        margin-bottom: 28px;
        color: #000; }
      .contact-index-index .contact-page .contact .contact-left .contact-info input[type="text"],
      .contact-index-index .contact-page .contact .contact-left .contact-info input[type="email"],
      .contact-index-index .contact-page .contact .contact-left .contact-info textarea, .contact-index-index .contact-page .contact .contact-right .contact-info input[type="text"],
      .contact-index-index .contact-page .contact .contact-right .contact-info input[type="email"],
      .contact-index-index .contact-page .contact .contact-right .contact-info textarea {
        border-radius: 2px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info ul, .contact-index-index .contact-page .contact .contact-right .contact-info ul {
        list-style: none;
        padding: 0; }
        .contact-index-index .contact-page .contact .contact-left .contact-info ul li, .contact-index-index .contact-page .contact .contact-right .contact-info ul li {
          margin: 0; }
      .contact-index-index .contact-page .contact .contact-left .contact-info h3, .contact-index-index .contact-page .contact .contact-right .contact-info h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 27px; }
  .contact-index-index .contact-page .contact .contact-left {
    margin-right: 35px;
    max-width: 720px; }
    .contact-index-index .contact-page .contact .contact-left .contact-info .fieldset {
      margin-bottom: 20px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .fieldset .field.required > .label:after {
        margin: 0 0 0 2px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .fieldset .field label span {
        font-size: 12px;
        margin-bottom: 8px;
        font-family: roboto, sans-serif;
        font-weight: 700; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .fieldset textarea {
        padding: 12px 15px;
        min-height: 145px; }
    .contact-index-index .contact-page .contact .contact-left .contact-info .user-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field.required > .label:after {
        content: '*';
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 2px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field {
        width: 320px;
        padding-bottom: 15px; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field label {
          font-size: 12px;
          margin-bottom: 8px;
          font-family: roboto, sans-serif;
          font-weight: 700; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field .control input[type="text"], .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field .control input[type="email"], .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field .control input[type="tel"] {
          height: 40px;
          padding: 12px 15px; }
    .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar {
      display: flex;
      flex-wrap: wrap; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox {
        display: flex;
        align-items: center;
        margin-right: auto; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox .d-none {
          display: none; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox .checkbox {
          width: 40px;
          height: 40px;
          border: solid 1px #DDDDDD;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox label {
          font-family: roboto, sans-serif;
          font-size: 12px;
          font-weight: 700; }
          .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox label p {
            font-size: inherit;
            margin-top: 1rem;
            font-weight: inherit; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox input[type="checkbox"] + .checkbox .check {
          fill: transparent; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .newsletter.input-checkbox input[type="checkbox"]:checked + .checkbox .check {
          fill: #000; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .primary {
        float: right; }
        .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .primary .submit {
          background-color: #000000;
          color: #ffffff;
          font-size: 16px;
          border: 2px solid #000;
          padding: 10px 43px;
          margin: 0;
          line-height: 20px;
          font-family: rift, sans-serif;
          border-radius: 2px;
          transition: all .3s ease; }
          .contact-index-index .contact-page .contact .contact-left .contact-info .actions-toolbar .primary .submit:hover {
            background: #ffffff;
            color: #000000; }
  @media (max-width: 1134px) {
    .contact-index-index .contact-page .contact .contact-left {
      margin-right: 10px;
      max-width: 620px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field {
        width: 49%; } }
  @media (max-width: 1019px) {
    .contact-index-index .contact-page .contact .contact-left {
      margin-right: 0;
      max-width: 100%; } }
  @media (max-width: 1019px) and (max-width: 678px) {
    .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field {
      width: 100%; } }
  @media (max-width: 678px) {
    .contact-index-index .contact-page .contact .contact-left .contact-info {
      padding-top: 16px;
      padding-bottom: 16px; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .user-details .field {
        width: 100%; }
      .contact-index-index .contact-page .contact .contact-left .contact-info .primary {
        margin-top: 20px;
        width: 100%; }
    .contact-index-index .contact-page .contact .contact-right .legend, .contact-index-index .contact-page .contact .contact-left .legend {
      display: none; }
    .contact-index-index .contact-page .contact .contact-right .contact-info .note, .contact-index-index .contact-page .contact .contact-left .contact-info .note {
      font-size: 22px;
      margin-bottom: 35px; } }

.page-wrapper {
  box-shadow: none !important; }

.cms-page-view .columns .columns {
  display: block; }

.cms-page-view .breadcrumbs {
  max-width: 1386px !important;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .cms-page-view .breadcrumbs {
      padding: 0 10px !important; } }

.cms-page-view h1 {
  font-weight: 700;
  color: #000000; }

.minicart {
  overflow-y: auto; }
  .minicart .block-minicart {
    padding: 0;
    box-shadow: 0px 3px 6px #00000029;
    right: -100%;
    transition: right 0.3s; }
    .minicart .block-minicart .minicart-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      background: #ffffff; }
      .minicart .block-minicart .minicart-content-wrapper .block-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0; }
    .minicart .block-minicart #btn-minicart-close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 6px;
      top: 6px;
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-shadow: none;
      font-weight: 400;
      color: #333333;
      display: inline-block;
      text-decoration: none; }
      .minicart .block-minicart #btn-minicart-close svg {
        fill: #ffffff; }
    .minicart .block-minicart .your-cart {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding-top: 5.3rem;
      padding-left: 3.2rem;
      padding-right: 2.5rem;
      padding-bottom: 2rem;
      background-color: #000; }
      .minicart .block-minicart .your-cart h2 {
        margin: 0;
        font-weight: 700;
        color: #ffffff;
        font-size: 32px;
        line-height: 1.2; }
      .minicart .block-minicart .your-cart .cart-wrapper {
        display: flex; }
        .minicart .block-minicart .your-cart .cart-wrapper svg {
          fill: #ffffff;
          color: #ffffff;
          margin-top: 4px; }
        .minicart .block-minicart .your-cart .cart-wrapper .badge {
          padding-left: 9px;
          padding-right: 9px;
          -webkit-border-radius: 9px;
          -moz-border-radius: 9px;
          border-radius: 9px; }
        .minicart .block-minicart .your-cart .cart-wrapper .label-warning[href],
        .minicart .block-minicart .your-cart .cart-wrapper .badge-warning[href] {
          background-color: #c67605; }
        .minicart .block-minicart .your-cart .cart-wrapper #lblCartCount {
          font-size: 12px;
          background: #FF7900;
          color: #fff;
          vertical-align: top;
          margin-left: 0;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin-left: -5px; }
      .minicart .block-minicart .your-cart .items-in-cart-count {
        color: #fff;
        font-size: 24px; }
    .minicart .block-minicart .free-shipping-wrapper {
      padding: 2rem; }
      .minicart .block-minicart .free-shipping-wrapper .free-shipping {
        position: relative;
        background: #eee;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 1rem;
        height: 100px; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping p {
          font-family: "Roboto";
          font-size: 13px;
          color: #333; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping strong {
          font-weight: bold; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping span {
          font-weight: bold;
          color: #FF7900; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping .continue-shopping {
          color: #333;
          text-decoration: underline; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping svg {
          width: 100px; }
          .minicart .block-minicart .free-shipping-wrapper .free-shipping svg .a {
            fill: #fff;
            color: #fff; }
      .minicart .block-minicart .free-shipping-wrapper .free-shipping:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #eee;
        border-width: 15px;
        margin-left: -15px; }
    .minicart .block-minicart .minicart-items-wrapper {
      margin: 0;
      border: 0;
      padding: 30px 40px;
      height: auto !important;
      overflow-y: auto; }
      .minicart .block-minicart .minicart-items-wrapper li.item.product-item.odd.last:first-child {
        padding-top: 0; }
      .minicart .block-minicart .minicart-items-wrapper li.item.product-item:last-child {
        padding-bottom: 0; }
      .minicart .block-minicart .minicart-items-wrapper .product-item {
        padding: 22px 0; }
      .minicart .block-minicart .minicart-items-wrapper .product {
        display: flex;
        justify-content: center;
        width: 100%;
        line-height: 15px; }
        .minicart .block-minicart .minicart-items-wrapper .product .product-image-wrapper {
          display: flex;
          flex: 0 20%;
          align-items: center; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-image-wrapper .product-image-container {
            width: 70px; }
        .minicart .block-minicart .minicart-items-wrapper .product .product-item-details {
          flex: 0 80%;
          padding-left: 0; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-sku-wrapper p {
            font-size: 13px;
            font-family: "Roboto";
            color: #333; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper {
            display: flex;
            justify-content: space-between; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper a {
              font-family: "Roboto";
              font-weight: bold;
              font-size: 13px;
              color: #000; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper .price-wrapper .price-including-tax {
              margin: 0; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper .price-wrapper .price-including-tax .price {
                font-family: "Roboto";
                font-size: 13px;
                color: #000; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-details {
            color: #333;
            font-size: 13px;
            font-weight: 400;
            font-family: "Roboto"; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-top: 1rem;
            height: 35px; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper {
              width: 100%;
              height: 30px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border: 2px solid #000000; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper .product-qty {
                width: 25px;
                padding: 0 5px;
                margin: 0;
                text-align: center;
                border: 0;
                font-weight: 700;
                background: transparent; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button {
                border: 0;
                padding: 0 5px; }
                .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:hover, .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:focus {
                  background-color: transparent; }
                .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button svg {
                  vertical-align: middle; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .actions .secondary .action.delete::before {
              content: none !important; }
    .minicart .block-minicart .totals-wrapper {
      width: 80%;
      margin: 0 auto;
      padding: 22px 0;
      border-top: 1px solid #cccccc; }
      .minicart .block-minicart .totals-wrapper > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem; }
      .minicart .block-minicart .totals-wrapper span {
        font-family: "Roboto";
        font-size: 13px; }
      .minicart .block-minicart .totals-wrapper .total {
        font-weight: bold; }
        .minicart .block-minicart .totals-wrapper .total > .price {
          font-weight: bold; }
    .minicart .block-minicart .minicart-footer {
      width: 80%;
      margin: 0 auto 25px; }
      .minicart .block-minicart .minicart-footer .actions {
        justify-content: center;
        text-align: center;
        margin-bottom: 1rem; }
        .minicart .block-minicart .minicart-footer .actions a {
          font-family: roboto, sans-serif;
          font-size: 12px;
          color: #FF7900;
          text-decoration: underline; }
      .minicart .block-minicart .minicart-footer .checkout-button {
        width: 100%;
        padding: 2rem;
        background-color: #000000;
        font-family: "Rift";
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        border: 0;
        border-radius: 0.2rem;
        display: block;
        width: auto;
        text-align: center;
        border: 2px solid #000000; }
        .minicart .block-minicart .minicart-footer .checkout-button:hover {
          background: #ffffff;
          color: #000000; }
      .minicart .block-minicart .minicart-footer .payments-accepted-wrapper {
        margin-top: 2rem; }
        .minicart .block-minicart .minicart-footer .payments-accepted-wrapper p {
          font-family: "Roboto";
          font-size: 12px;
          text-align: center;
          margin-bottom: 1rem; }
        .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted {
          display: flex;
          justify-content: center; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .mastercard {
            content: url("../images/mastercard.png");
            width: 30px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .visa {
            content: url("../images/visa.png");
            width: 40px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .afterpay {
            content: url("../images/afterpay.png");
            width: 70px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .zip {
            content: url("../images/zip.png");
            width: 40px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .paypal {
            content: url("../images/paypal.png");
            width: 60px;
            height: auto; }

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.mage-dropdown-dialog {
  display: block !important; }

.minicart-wrapper.minicart.active #ui-id-1::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  pointer-events: none; }

.minicart-wrapper.minicart.active #ui-id-1 {
  position: fixed;
  right: 0; }

.modal-popup.confirm .modal-inner-wrap {
  height: auto;
  max-width: 450px; }
  .modal-popup.confirm .modal-inner-wrap .modal-header .action-close {
    padding: 0; }
    .modal-popup.confirm .modal-inner-wrap .modal-header .action-close:before {
      content: " " !important;
      background: no-repeat url(../images/close_loader.svg);
      height: 20px;
      width: 20px;
      position: absolute;
      left: -35px;
      background-size: contain;
      top: 10px;
      padding: 0; }
  .modal-popup.confirm .modal-inner-wrap .modal-footer {
    margin-top: 0; }

@media (max-width: 600px) {
  .minicart-wrapper .block-minicart {
    max-width: 100% !important;
    top: 0; }
    .minicart-wrapper .block-minicart .cart-wrapper {
      z-index: -1; }
    .minicart-wrapper .block-minicart #btn-minicart-close {
      right: 20px;
      top: 33px; }
      .minicart-wrapper .block-minicart #btn-minicart-close svg {
        width: 27px;
        height: 27px; }
    .minicart-wrapper .block-minicart .logo {
      z-index: 999; }
    .minicart-wrapper .block-minicart .your-cart {
      padding-top: 3rem;
      width: auto; } }

.filter.block {
  margin-bottom: 20px; }

.products-grid,
.amsearch-pagebuilder-carousel {
  margin-top: 0; }
  .products-grid ol.products.list.items.product-items,
  .amsearch-pagebuilder-carousel ol.products.list.items.product-items {
    width: 100%;
    margin-left: 0 !important;
    text-align: left;
    display: flex !important;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap; }
    .products-grid ol.products.list.items.product-items .product-item .old-price .price,
    .products-grid ol.products.list.items.product-items .product-item .old-price .regular-price,
    .amsearch-pagebuilder-carousel ol.products.list.items.product-items .product-item .old-price .price,
    .amsearch-pagebuilder-carousel ol.products.list.items.product-items .product-item .old-price .regular-price {
      color: #000000 !important; }
    .products-grid ol.products.list.items.product-items .product-item .special-price .price,
    .amsearch-pagebuilder-carousel ol.products.list.items.product-items .product-item .special-price .price {
      color: #ff0800 !important; }
    .products-grid ol.products.list.items.product-items .product-item .special-price .special-price,
    .amsearch-pagebuilder-carousel ol.products.list.items.product-items .product-item .special-price .special-price {
      color: #ff0800 !important; }
    .products-grid ol.products.list.items.product-items li.item.product.product-item,
    .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item {
      display: flex;
      padding: 0 !important;
      width: 22.623%;
      max-width: 240px !important;
      height: auto;
      text-align: left;
      border: 3px solid #F0F0F0 !important;
      max-height: none !important;
      position: relative; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item:hover,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item:hover {
        border: inherit;
        border-color: #000000 !important; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item:hover .product-item-info,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item:hover .product-item-info {
          border: none !important;
          position: relative;
          box-shadow: none; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item:hover .product-item-inner,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item:hover .product-item-inner {
          display: inline-block !important;
          box-shadow: none !important; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item:before,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item:before {
        content: none !important; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item > div,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item > div {
        height: 100%; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-colour,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-colour {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        background: #FF7900;
        border-radius: 30px;
        height: 14px;
        width: 14px;
        margin: 4px 5px;
        line-height: 0.8em;
        text-align: center;
        display: inline-block; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.low-stock,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.low-stock {
          background: #FF7900; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.no-stock,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.no-stock {
          background: #ff0800; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.no-stock svg,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.no-stock svg {
            display: none; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.in-stock,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-colour.in-stock {
          background: #009645; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-levels-wrapper,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-levels-wrapper {
        display: flex;
        align-items: center;
        flex: 0; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item .stock-level-text,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .stock-level-text {
        font-family: roboto, sans-serif;
        font-size: 12px;
        display: inline-block;
        margin: 2px 0; }
      .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info,
      .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        z-index: 1 !important; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info a,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info a {
          display: block;
          line-height: 0; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info a:hover .product-image-container:not(.primary-image) .product-image-wrapper,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info a:hover .product-image-container:not(.primary-image) .product-image-wrapper {
            display: block; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info a:hover .product-image-container.primary-image:not(:last-of-type) .product-image-wrapper,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info a:hover .product-image-container.primary-image:not(:last-of-type) .product-image-wrapper {
            display: none; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container {
          display: block; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container ~ .product-image-container.secondary-image,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container ~ .product-image-container.secondary-image {
            position: static !important; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container ~ .product-image-container .product-image-wrapper,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container ~ .product-image-container .product-image-wrapper {
            display: none; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .amlabel-position-wrapper,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .amlabel-position-wrapper {
            z-index: 95; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .amlabel-position-wrapper .amasty-label-container:nth-child(2),
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .amlabel-position-wrapper .amasty-label-container:nth-child(2) {
              display: none !important;
              margin-top: 0 !important; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-wrapper,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-wrapper {
          padding-bottom: 100% !important; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-wrapper img.product-image-photo,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-wrapper img.product-image-photo {
            height: 100%; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .secondary-image,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .secondary-image {
          display: none; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .secondary-image:hover,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-image-container .secondary-image:hover {
            display: block; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-photo .product-image-container .secondary-image:hover,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-photo .product-image-container .secondary-image:hover {
          display: block; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form [data-role="tocart-form"],
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form [data-role="tocart-form"] {
          display: flex;
          flex-wrap: wrap;
          gap: 8px; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .qty-wrapper,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .qty-wrapper {
          flex: 1 1 32%; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .qty-wrapper input,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .qty-wrapper input {
            height: 40px; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form button,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form button {
          flex: 1 1 62%; }
          @media screen and (max-width: 1024px) {
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form button,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form button {
              padding-left: 10px;
              padding-right: 10px; } }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary {
          font-family: rift, sans-serif !important;
          display: inline-block;
          padding: 8px 15px;
          margin: 0 !important;
          border-radius: 2px;
          background: #ffffff;
          border: 2px solid #000000;
          color: #000000;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          white-space: nowrap; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary:hover,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary:hover {
            background: #000000;
            color: #ffffff; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert {
            border: 2px solid #000000;
            color: #ffffff;
            background: #000000; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert:hover,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert:hover {
              background: #ffffff;
              color: #000000; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert.orange,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert.orange {
              border-color: #FF7900 !important;
              background: #FF7900 !important; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert.orange:hover,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .cart-form .btn.btn-secondary.invert.orange:hover {
                background-color: #ffffff !important;
                color: #FF7900 !important; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags {
          z-index: 1;
          top: 6px;
          left: 2px;
          display: flex;
          gap: 6px;
          align-items: flex-start;
          pointer-events: none;
          position: absolute;
          flex-direction: column;
          font-size: 12px;
          min-width: 91px; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag {
            display: inline-block;
            width: 80%;
            padding: 0 9px 0 9px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: #000000;
            font-family: roboto, sans-serif;
            border: 1px solid;
            border-radius: 50px;
            text-align: center;
            background: #ffffff; }
            @media screen and (max-width: 666px) {
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag {
                font-size: 11px;
                width: 92%;
                padding: 0 0 0 0; } }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-sale, .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-hot-offer, .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-clearance,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-sale,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-hot-offer,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-tags .product-tag.tag-clearance {
              color: #ff0800; }
        .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details,
        .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex-grow: 1;
          padding: 0 20px 15px; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details [class*="swatch-opt-"]:last-child,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details [class*="swatch-opt-"]:last-child {
            margin-bottom: auto; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .cart-form,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .cart-form {
            margin-top: auto; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-item-name,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-item-name {
            width: 100%;
            display: block;
            margin: 0;
            padding-bottom: 0;
            flex-basis: 5rem;
            overflow: hidden; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-item-name .product-item-link,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-item-name .product-item-link {
              width: 100%;
              display: block;
              margin: 0;
              padding-bottom: 0;
              font-size: 20px;
              line-height: 24px;
              font-family: rift, sans-serif;
              font-style: italic;
              font-weight: 700;
              color: #000000;
              white-space: normal;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'],
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] {
            display: flex;
            flex-direction: column;
            flex-grow: 1; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size {
              margin-top: auto; }
              @media (max-width: 767.98px) {
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-attribute-options,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-attribute-options {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 7px;
                  padding-bottom: 1.2rem; } }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-option.text,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-option.text {
                margin: 0 0.4rem 0.7rem 0; }
                @media (max-width: 767.98px) {
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-option.text,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.size .swatch-option.text {
                    flex: 0 0 27%;
                    margin: 0;
                    padding: 0; } }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options {
              display: flex;
              flex-wrap: wrap;
              gap: 5px; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option {
                background-size: contain !important;
                flex: 0 0 16%;
                margin: 0; }
                @media (max-width: 767.98px) {
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option {
                    flex: 0 0 27.8%; } }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image),
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image) {
                background-position: center;
                background-repeat: no-repeat; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image):not(.disabled):hover.selected,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image):not(.disabled):hover.selected {
                  outline-color: #969696; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image).disabled,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image).disabled {
                  pointer-events: none;
                  opacity: 0.3; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image).disabled::after,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image).disabled::after {
                    display: none; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image),
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details div[class^='swatch-opt-'] .swatch-attribute.color .swatch-attribute-options .swatch-option:not(.image) {
                background-image: url(../images/swatch_1.png); }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars {
            display: block;
            width: 100%; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary {
              margin-bottom: 0;
              position: relative;
              height: 17px; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary.empty,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary.empty {
                font-size: 10px;
                line-height: 1em; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary {
                margin-right: 0;
                width: 100%;
                display: flex;
                flex-direction: row-reverse;
                align-items: center; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary > span.label,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary > span.label {
                  position: relative;
                  display: inline-block;
                  flex: 1;
                  width: auto;
                  height: auto;
                  line-height: 20px;
                  font-size: 12px;
                  font-family: roboto, sans-serif;
                  margin-left: 9px;
                  margin-top: 1px; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result {
                  display: inline-block;
                  position: relative !important;
                  width: 100px !important; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result:before,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result:before {
                    font-size: 15px;
                    letter-spacing: 8px !important;
                    width: 100%; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result > span:before,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-rating-stars .product-reviews-summary .rating-summary .rating-result > span:before {
                    letter-spacing: 8px !important;
                    color: #f8d048;
                    font-size: 15px;
                    width: 100px !important; }
          .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row,
          .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row {
            justify-content: space-between;
            width: 100%;
            display: flex; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box {
              margin: 0;
              flex: 1;
              flex-direction: row;
              width: 100%;
              vertical-align: middle;
              font-family: rift, sans-serif;
              font-weight: bold;
              font-size: 16px; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box > div,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box > div {
                line-height: 1em; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price {
                font-family: rift, sans-serif;
                font-weight: bold;
                font-size: 16px;
                line-height: 1em; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price {
                display: inline;
                margin: 0 0 0 0.5rem; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price .price-label,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .old-price .price-label {
                  display: none; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price-final_price,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .price-box .price-final_price {
                font-family: inherit; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-inner,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-inner {
              display: inline-block; }
            .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions,
            .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions {
              display: flex;
              margin: 0; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary {
                vertical-align: middle; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form {
                  display: inline-block; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary {
                    background: none;
                    border: none;
                    width: 35px;
                    height: 30px;
                    margin: 3px 0 0 0;
                    padding: 5px;
                    fill: #000000;
                    transition: opacity 0.3s; }
                    .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary:hover,
                    .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-primary form button.action.tocart.primary:hover {
                      opacity: 0.5; }
              .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary,
              .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary {
                display: flex;
                align-items: center; }
                .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action,
                .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action {
                  color: #000000;
                  transition: opacity 0.3s;
                  height: 30px;
                  width: 25px !important;
                  border: none;
                  padding: 0;
                  margin-bottom: 0;
                  line-height: 43px; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:before,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:before {
                    content: none !important; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:hover,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action:hover {
                    opacity: 0.5; }
                  .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action svg,
                  .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action svg {
                    height: 17px;
                    width: 17px; }
                  @media screen and (max-width: 1023px) {
                    .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action.quickview, .products-grid ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action.tocompare,
                    .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action.quickview,
                    .amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item .product-item-info .product-item-details .product-bottom-row .product-item-actions .actions-secondary .action.tocompare {
                      display: none; } }

.amsearch-pagebuilder-carousel .slick-track {
  display: flex; }

.amsearch-pagebuilder-carousel .slick-slide {
  height: inherit; }
  .amsearch-pagebuilder-carousel .slick-slide > div {
    height: 100%; }

@media screen and (max-width: 1023px) {
  .amsearch-pagebuilder-carousel ol.products.list.items.product-items {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box; } }

.amsearch-pagebuilder-carousel ol.products.list.items.product-items li.item.product.product-item {
  max-width: calc(100% - 16px) !important;
  height: 100%; }

@media (max-width: 1024px) {
  .column.main {
    margin-top: 0; } }

.page-products .page-title {
  font-weight: 700;
  color: #000000; }

.page-products .page-title-wrapper {
  border-bottom: solid 1px #000000;
  margin-bottom: 35px; }
  .page-products .page-title-wrapper h1#page-title-heading {
    font-size: 32px;
    font-weight: bold;
    font-family: rift, sans-serif;
    color: #000000; }
    @media (min-width: 1025px) {
      .page-products .page-title-wrapper h1#page-title-heading {
        margin-bottom: 1rem; } }

.page-products .toolbar-products {
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 4px; }

.page-products select {
  border-radius: 2px;
  height: 40px;
  padding-top: 7px;
  padding-bottom: 7px; }

.page-products .sorter {
  position: relative;
  display: flex;
  align-items: center;
  right: 0; }

.page-products .toolbar-amount {
  float: none;
  font-family: roboto, sans-serif;
  font-weight: 400;
  font-size: 13px; }
  .page-products .toolbar-amount strong {
    font-weight: 700; }

@media (max-width: 1024px) {
  .page-products .page-main {
    margin-bottom: 4rem;
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1025px) {
  .page-products .breadcrumbs,
  .page-products #maincontent.page-main {
    max-width: 1406px !important;
    padding-left: 20px;
    padding-right: 20px; }
  .page-products .breadcrumbs {
    margin-bottom: 0; }
  .page-products .page-main .breadcrumbs {
    padding: 0 !important; }
  .page-products .page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .page-products .columns {
    display: flex; }
  .page-products .sidebar-main {
    float: none;
    order: -1;
    width: 207px;
    padding-right: 0;
    flex: 0 0 207px; }
  .page-products .column.main {
    float: none;
    flex: 1 1 0;
    width: auto;
    padding-left: 80px; } }

@media (min-width: 1366px) {
  .page-products .column.main {
    padding-left: 50px; } }

@media (max-width: 1023px) {
  .page-products #layered-filter-block .block-subtitle.filter-subtitle {
    height: 80px;
    padding: 0 30px;
    line-height: 80px;
    background-color: black;
    color: white;
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 30px;
    box-sizing: border-box; }
  .page-products #layered-filter-block.active .filter-title {
    height: 80px; }
    .page-products #layered-filter-block.active .filter-title strong {
      position: absolute;
      right: 0;
      transform: none;
      bottom: auto;
      height: 100%;
      width: 80px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../images/close.svg);
      background-size: 26px 34.39px;
      background-repeat: no-repeat;
      background-position: center; }
      .page-products #layered-filter-block.active .filter-title strong::after {
        display: none; }
  .page-products #layered-filter-block.active .filter-options {
    top: 80px; }
    .page-products #layered-filter-block.active .filter-options .filter-options-content {
      padding: 1.5rem 3.5rem;
      border-bottom: 1px solid #ddd; }
  .page-products #layered-filter-block.active .filter-options-title {
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    padding: 8px 35px;
    border: solid 1px #ddd;
    border-top: none;
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1024px) {
  .page-products .breadcrumbs {
    display: block;
    max-width: 100% !important;
    margin: 10px auto 0;
    text-align: center;
    box-sizing: border-box; }
  .page-products .page-title {
    width: 100%;
    text-align: center;
    margin-top: 0; }
  .page-products .toolbar-amount {
    position: relative;
    top: -65px;
    font-size: 11px;
    color: black;
    text-align: center;
    padding: 0; }
  .page-products .sorter {
    width: 50%;
    right: 0; }
    .page-products .sorter::before {
      content: "Sort";
      position: absolute;
      background: white;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      z-index: 1;
      pointer-events: none;
      font-family: rift, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 39px; }
  .page-products .toolbar-products {
    position: relative;
    justify-content: flex-end;
    margin: 0; }
  .page-products select {
    margin: 0 0 0 -2px;
    width: 100%;
    border-radius: 0;
    font-family: rift, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 39px;
    padding: 0 1.5rem;
    border-right: none;
    border-color: black;
    border-bottom: none; }
  .page-products .filter-title strong {
    bottom: 100%;
    top: auto !important;
    display: block;
    transform: translateY(-36px);
    margin: 0 !important;
    box-sizing: border-box;
    border-color: black;
    border-bottom: none;
    width: 50%;
    height: 40px;
    border-radius: 0;
    border-left: none;
    font-family: rift, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 39px;
    padding: 0; } }

.page-products .block-title,
.page-products .toolbar-products {
  background-image: none;
  border-image: none;
  border: none; }
  @media (max-width: 1023px) {
    .page-products .block-title,
    .page-products .toolbar-products {
      margin: 0;
      padding: 0; } }

.page-products .filter-actions {
  margin-bottom: 37.5px;
  padding: 0;
  margin-bottom: 1.5rem;
  font-size: 13px;
  text-decoration: underline; }

.category-image {
  margin-bottom: 0; }

@media (max-width: 1024px) {
  .catalog-topnav {
    display: block; } }

#layered-filter-block .block-content.filter-content {
  padding: 0;
  border: none; }
  @media (max-width: 1024px) {
    #layered-filter-block .block-content.filter-content {
      margin-top: -75px;
      margin-bottom: 83px;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #eeeeee; }
      #layered-filter-block .block-content.filter-content .filter-current-subtitle {
        display: none !important; }
      #layered-filter-block .block-content.filter-content .filter-title::before {
        display: none; }
      #layered-filter-block .block-content.filter-content .filter-current {
        border: none;
        margin: 0; }
      #layered-filter-block .block-content.filter-content .amshopby-items.items {
        display: flex;
        justify-content: center;
        padding-right: 0;
        text-align: center; }
      #layered-filter-block .block-content.filter-content .amshopby-item.item {
        margin: 0;
        padding: 0; }
      #layered-filter-block .block-content.filter-content .amshopby-remove {
        display: none; }
      #layered-filter-block .block-content.filter-content .amshopby-filter-name {
        font-size: 11px; }
        #layered-filter-block .block-content.filter-content .amshopby-filter-name::after {
          display: none; }
      #layered-filter-block .block-content.filter-content .amshopby-filter-value {
        padding-right: 1.5rem;
        font-size: 11px;
        color: #333333; } }
    @media (max-width: 1024px) and (max-width: 410px) {
      #layered-filter-block .block-content.filter-content .amshopby-filter-value {
        padding-right: 0.7rem; } }
  @media (max-width: 1024px) {
      #layered-filter-block .block-content.filter-content .swatch-option-link-layered > .swatch-option.text {
        border: none;
        font-size: 11px;
        color: #333333;
        height: auto;
        padding: 0;
        margin: 0;
        min-width: 0;
        line-height: inherit; }
      #layered-filter-block .block-content.filter-content .block-actions.filter-actions {
        margin: 0;
        padding: 6.25px 6px;
        font-size: 11px; }
        #layered-filter-block .block-content.filter-content .block-actions.filter-actions span {
          font-size: 0; }
          #layered-filter-block .block-content.filter-content .block-actions.filter-actions span::before {
            content: "Clear All";
            font-size: 11px; } }
  @media (min-width: 1025px) {
    #layered-filter-block .block-content.filter-content {
      display: flex;
      flex-direction: column; }
      #layered-filter-block .block-content.filter-content .filter-actions {
        order: -1; } }

.filter .block-subtitle {
  display: none; }

#am-shopby-container .amshopby-items.items {
  display: none; }

#layered-filter-block .block-actions.filter-actions {
  padding-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px; }

@media (max-width: 1023px) {
  .filter-options .filter-options-item {
    padding-bottom: 0; } }

.filter-options .filter-options-item .filter-options-content {
  padding-left: 0; }
  .filter-options .filter-options-item .filter-options-content .items {
    max-height: none;
    overflow: hidden;
    margin-left: 13px; }
    .filter-options .filter-options-item .filter-options-content .items .amshopby-link-selected .label {
      font-weight: 700 !important; }
  .filter-options .filter-options-item .filter-options-content .count {
    display: none; }
  .filter-options .filter-options-item .filter-options-content .amshopby-slider-wrapper {
    margin: 0; }

.filter-options .filter-options-item .filter-options-title {
  font-family: rift, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  padding: 12px 35px;
  border: solid 1px #ddd;
  margin-left: 0 !important;
  margin-right: 0 !important; }
  .filter-options .filter-options-item .filter-options-title:after {
    content: "" !important;
    width: 20px;
    height: 20px;
    background-image: url(../images/right-dark.svg);
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg); }

.filter-options .filter-options-item .filter-options-content {
  padding: 10px 0 0 0; }

.filter-options .filter-options-item.active .filter-options-title::after {
  transform: rotate(-90deg); }

.filter-options .swatch-option.text {
  border: solid 1px #000000;
  background-color: #ffffff;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 5px;
  margin-bottom: 5px; }
  .filter-options .swatch-option.text.selected {
    background-color: #000 !important;
    color: #ffffff; }

.filter-options .am-filter-items-attr_category_ids.items input[type="checkbox"] {
  display: block;
  position: relative !important;
  top: auto !important;
  margin: 0 !important; }

.filter-options .am-filter-items-attr_category_ids.items ul.items-children.-folding {
  padding-left: 13px; }

.filter-options .am-filter-items-attr_category_ids.items li.item {
  margin: 7px 0;
  padding-left: 0; }
  .filter-options .am-filter-items-attr_category_ids.items li.item a {
    padding-left: 40px !important;
    line-height: 29px;
    color: #333; }
    @media (max-width: 1023px) {
      .filter-options .am-filter-items-attr_category_ids.items li.item a {
        line-height: 27px; } }
    .filter-options .am-filter-items-attr_category_ids.items li.item a:visited {
      color: #333; }
    .filter-options .am-filter-items-attr_category_ids.items li.item a:hover {
      background: transparent;
      color: #FF7900; }
    .filter-options .am-filter-items-attr_category_ids.items li.item a .label {
      padding: 0 !important; }
  .filter-options .am-filter-items-attr_category_ids.items li.item .am-collapse-icon {
    top: 0;
    right: 0;
    left: auto; }
    .filter-options .am-filter-items-attr_category_ids.items li.item .am-collapse-icon:after {
      content: "" !important;
      display: block;
      width: 15px;
      height: 15px;
      background-image: url(../images/right-dark.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      transform: rotate(0deg);
      transition: none; }
    .filter-options .am-filter-items-attr_category_ids.items li.item .am-collapse-icon._active:after {
      transform: rotate(90deg); }
  .filter-options .am-filter-items-attr_category_ids.items li.item input.am-input {
    opacity: 0;
    width: 0; }
  .filter-options .am-filter-items-attr_category_ids.items li.item input.am-input ~ a {
    position: relative;
    font-weight: 400; }
  .filter-options .am-filter-items-attr_category_ids.items li.item input.am-input ~ a:before, .filter-options .am-filter-items-attr_category_ids.items li.item span.amshopby-choice::before {
    width: 25px;
    height: 25px;
    border: solid 1px #dddddd;
    border-radius: 2px;
    position: absolute;
    display: block !important;
    left: 0;
    top: 0;
    content: ""; }
    @media (max-width: 1023px) {
      .filter-options .am-filter-items-attr_category_ids.items li.item input.am-input ~ a:before, .filter-options .am-filter-items-attr_category_ids.items li.item span.amshopby-choice::before {
        width: 23px;
        height: 23px; } }
  .filter-options .am-filter-items-attr_category_ids.items li.item input.am-input:checked ~ a:before {
    background-image: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat; }

.filter-options .amshopby-slider-container {
  margin: 10px 2px 15px 1px; }
  .filter-options .amshopby-slider-container .ui-slider-horizontal {
    height: 3px;
    background-color: #b1b1b1;
    border-radius: 0; }
  .filter-options .amshopby-slider-container .ui-slider-range {
    height: 3px;
    background-color: #000000; }
  .filter-options .amshopby-slider-container .am-slider .ui-slider-handle {
    background-color: transparent !important;
    border-radius: 0;
    margin-top: -8px;
    cursor: pointer;
    margin-top: -8px;
    margin-left: -5px;
    padding: 0;
    width: 20px;
    height: 20px; }
    .filter-options .amshopby-slider-container .am-slider .ui-slider-handle:before {
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 4px; }
  .filter-options .amshopby-slider-container .amshopby-slider-display {
    text-align: left;
    padding-bottom: 15px;
    font-size: 13px; }

body.catalog-category-view div.swatch-option-tooltip {
  display: none !important; }

.category-view {
  font-family: rift, sans-serif;
  font-weight: 700;
  font-size: 42px;
  text-shadow: 0px 3px 6px #00000073; }
  @media (min-width: 1025px) {
    .category-view {
      font-size: 62px; } }

.category-details-holder {
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000; }
  @media (min-width: 1025px) {
    .category-details-holder {
      height: 240px; } }
  .category-details-holder .category-image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
    z-index: 1; }
    @media (max-width: 1024px) {
      .category-details-holder .category-image-background.cat-full {
        display: none; } }
    @media (min-width: 1025px) {
      .category-details-holder .category-image-background.cat-thumb {
        display: none; } }
  .category-details-holder .category-description {
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 42px;
    text-shadow: 0px 3px 6px #00000073;
    position: relative;
    z-index: 2;
    color: #ffffff;
    text-align: center;
    line-height: 1; }
    .category-details-holder .category-description [data-element="inner"] {
      margin-bottom: 0 !important; }
    @media (min-width: 1025px) {
      .category-details-holder .category-description {
        font-size: 62px; } }

.sorter-action {
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 2px;
  height: 38px;
  position: relative;
  top: 0;
  width: 35px; }
  .sorter-action:before {
    line-height: 36px; }

.swatch-option-tooltip {
  display: none !important; }

@media (max-width: 1023px) {
  .sizing-guide-holder {
    display: none; } }

@media (min-width: 1024px) {
  .sizing-guide-holder {
    text-align: center;
    border: solid 2px #000000;
    font-size: 13px;
    padding: 30px 20px 20px 20px;
    margin-bottom: 30px; }
    .sizing-guide-holder h3 {
      font-family: rift, sans-serif;
      font-size: 22px;
      line-height: 27px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0; }
    .sizing-guide-holder a {
      color: #ffffff;
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      font-family: rift, sans-serif;
      font-weight: 700;
      font-size: 16px;
      padding: 7px 15px;
      border: solid 2px #000000;
      border-radius: 2px;
      background-color: #000000; }
      .sizing-guide-holder a:hover {
        color: #000000;
        background-color: #ffffff; } }

@media (max-width: 1023px) {
  #amasty-shopby-product-list .toolbar-products .toolbar-amount {
    font-size: 13px;
    color: #333;
    top: 0;
    padding-top: 10px; } }

#amasty-shopby-product-list .products-grid + .toolbar.toolbar-products {
  border-top: none; }

#amasty-shopby-product-list .toolbar-products:first-child {
  display: none; }

#amasty-shopby-product-list .toolbar-products .pages {
  display: none; }

#amasty-shopby-product-list > dl.block:first-child,
#amasty-shopby-product-list > dl.block:first-child + dl {
  display: none; }

#amasty-shopby-product-list .ias-trigger {
  position: relative; }
  #amasty-shopby-product-list .ias-trigger a {
    position: absolute;
    z-index: 20;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background: #000000;
    color: #ffffff;
    border: 2px solid #000000;
    font-size: 16px;
    font-family: rift, sans-serif;
    font-weight: 700;
    line-height: 20px;
    border-radius: 2px; }
    #amasty-shopby-product-list .ias-trigger a:hover {
      background-color: #ffffff;
      color: #000000;
      text-decoration: none; }

#amasty-shopby-product-list .ias-spinner {
  font-size: 0;
  color: transparent !important;
  height: 12px;
  background-image: url(../images/loader.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }
  #amasty-shopby-product-list .ias-spinner img {
    display: none !important; }

@media (max-width: 1024px) {
  #amasty-shopby-product-list .product-items {
    margin-top: 0; } }

.column.main .toolbar-products .field.limiter {
  display: none; }

.column.main .toolbar-products .toolbar-amount {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  position: relative; }
  .column.main .toolbar-products .toolbar-amount .percentage-holder {
    display: inline-block;
    width: 204px;
    height: 3px;
    background-color: #000;
    position: relative; }
    .column.main .toolbar-products .toolbar-amount .percentage-holder .percentage-display {
      background-color: #b1b1b1;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: block; }

.catalogsearch-result-index .breadcrumbs {
  display: none; }

.catalogsearch-result-index .category-details-holder {
  margin-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .catalogsearch-result-index .category-details-holder::before {
    content: "Search Results";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    color: white;
    font-family: "Rift";
    font-weight: 700;
    font-size: 42px;
    text-shadow: 0 3px 6px #00000073;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    line-height: 1; }
  @media (min-width: 768px) {
    .catalogsearch-result-index .category-details-holder {
      background-image: url(../images/search-banner-desktop.jpg); }
      .catalogsearch-result-index .category-details-holder::before {
        font-size: 62px; } }
  @media (max-width: 767px) {
    .catalogsearch-result-index .category-details-holder {
      background-image: url(../images/search-banner-mobile.jpg); } }

.catalog-category-view .filter-options .swatch-option.color,
.catalog-category-view .filter-options .swatch-option.image {
  margin-right: 5px;
  margin-bottom: 5px;
  background-size: cover;
  border: solid 1px #fff;
  outline: solid 1px #000; }
  .catalog-category-view .filter-options .swatch-option.color.selected, .catalog-category-view .filter-options .swatch-option.color:hover,
  .catalog-category-view .filter-options .swatch-option.image.selected,
  .catalog-category-view .filter-options .swatch-option.image:hover {
    outline: solid 2px #000; }
  .catalog-category-view .filter-options .swatch-option.color.selected + .am-shopby-swatch-label .label,
  .catalog-category-view .filter-options .swatch-option.image.selected + .am-shopby-swatch-label .label {
    font-weight: bold; }

.catalog-category-view .filter-options .swatch-option.selected + .am-shopby-swatch-label .label {
  font-weight: bold; }

tbody .product-tag {
  max-width: fit-content; }

.filter-options span.amshopby-choice.amshopby-choice::before {
  width: 25px;
  height: 25px;
  border: solid 1px #dddddd;
  border-radius: 2px;
  position: absolute;
  display: block !important;
  left: 0;
  top: 0;
  content: ""; }
  @media (max-width: 1023px) {
    .filter-options span.amshopby-choice.amshopby-choice::before {
      width: 23px;
      height: 23px; } }

.filter-options input[type='checkbox']:checked + span.amshopby-choice.amshopby-choice::before {
  border: solid 1px #dddddd;
  border-radius: 2px; }

.filter-options input[type='checkbox']:checked + span.amshopby-choice.amshopby-choice::after {
  background: none; }

.block-actions.filter-actions a.action.clear.filter-clear {
  color: #ff7900; }

.filter-options .am-labels-folding .item {
  margin: 7px 0;
  padding-left: 0; }

.filter-options li.item .am-collapse-icon.am-collapse-icon {
  top: 0;
  right: 0;
  left: auto; }

.filter-options li.item .am-collapse-icon:after {
  content: "" !important;
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  transform: rotate(270deg);
  transition: none; }

.filter-options li.item .am-collapse-icon.-active:after {
  transform: rotate(0deg); }

.filter-options li.item .am-collapse-icon {
  top: 0;
  right: 0;
  left: auto; }

.filter-options.filter-options li.item .am-collapse-icon.am-collapse-icon ~ a {
  position: relative;
  font-weight: 400;
  padding-left: 0; }

.filter-options ol.am-filter-items-brand li.item {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: flex-end; }

.filter-options li.item a {
  padding-left: 38px !important;
  line-height: 29px;
  color: #333; }

.filter-options ol.am-filter-items-brand li.item .amshopby-choice {
  display: none; }

.label.label {
  padding: 0; }

.filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view), .filter-options-item .filter-options-content .am-category-wrapper, .filter-options-item .filter-options-content .amshopby-fromto-wrap {
  margin: 0;
  margin-left: 13px; }

.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type='checkbox'] + span.amshopby-choice {
  left: 0;
  top: 0; }

.filter-content [class*='am-filter-item']:hover ~ .amshopby-choice:before {
  border: solid 1px #ddd; }

.filter-options input[type='checkbox'] + .amshopby-choice:hover:before {
  border: solid 1px #ddd !important;
  border-color: #ddd !important; }

.filter-options-item .filter-options-content .item.item.item.item {
  line-height: 1.5em;
  margin: 7px 0;
  padding-left: 0;
  display: flex;
  margin-left: 0 !important; }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

input [type="checkbox"] {
  margin: 2px 6.25px 0 0;
  position: relative;
  top: 2px; }

.filter-options-content .items a {
  margin-left: 0; }

.am-collapse-icon {
  height: 29px; }

.filter-options li.item {
  margin: 7px 0;
  padding-left: 0; }

.am-filter-items-size, .am-filter-items-color {
  display: flex;
  flex-wrap: wrap; }

.am-shopby-form {
  margin: 0 !important; }

.filter-options-item .filter-options-content .am-filter-items-stock.items [class*='am-filter-item'] {
  line-height: 21px; }

.filter-options.filter-options li.item .am-collapse-icon.am-collapse-icon ~ a,
.filter-options-item .filter-options-content .items [class*=am-filter-item] {
  padding-left: 40px !important; }

.items[class*='category_ids']:not(.items-children) > .item > a {
  font-weight: 400; }

.filter-options-item .filter-options-content .am-ranges .items.am-filter-items-stock .item [class*='am-filter-item'] {
  padding-left: 10px !important;
  padding-right: 7px !important; }

.filter-options-content .am-filter-items-stock.items a:hover {
  background-color: #e8e8e8 !important;
  text-decoration: none; }

.filter-options-content a {
  line-height: 21px; }

.filter-options-item .filter-options-content .am-swatch-wrapper {
  margin-bottom: 5px !important;
  margin-right: 5px !important; }

.am-filter-items-brand input {
  width: 13px !important;
  height: 13px !important;
  opacity: 1 !important;
  position: relative !important; }

.filter-options-content .am-filter-items-brand.am-filter-items-brand .item > a {
  padding-left: 20px !important; }

.filter-options li.item input.am-input:checked ~ span:before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='4.48' height='5.12' viewBox='0 0 448 512' ><path fill='currentColor' d='M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z' class=''></path></svg>");
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat; }

.mgz-products-grid .products-grid .product-item.product-item,
.page-products .products-grid .product-item.product-item,
.block-new-products .products-grid .product-item.product-item,
.pagebuilder-column-group .products-grid .product-item.product-item {
  padding: 0;
  margin: 0 auto !important;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  overflow: hidden !important;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 0; }
  @media (max-width: 767.98px) {
    .mgz-products-grid .products-grid .product-item.product-item,
    .page-products .products-grid .product-item.product-item,
    .block-new-products .products-grid .product-item.product-item,
    .pagebuilder-column-group .products-grid .product-item.product-item {
      margin: 0 8px 16px !important;
      width: auto;
      max-width: 224px;
      flex-basis: calc(50% - 20px); } }
  @media (min-width: 768px) {
    .mgz-products-grid .products-grid .product-item.product-item,
    .page-products .products-grid .product-item.product-item,
    .block-new-products .products-grid .product-item.product-item,
    .pagebuilder-column-group .products-grid .product-item.product-item {
      margin: 0 !important;
      margin-bottom: 18px !important;
      margin-right: 18px !important;
      margin-left: 18px !important; } }
  .mgz-products-grid .products-grid .product-item.product-item:hover,
  .page-products .products-grid .product-item.product-item:hover,
  .block-new-products .products-grid .product-item.product-item:hover,
  .pagebuilder-column-group .products-grid .product-item.product-item:hover {
    border: inherit;
    box-shadow: inherit;
    border-color: #FF7900 !important; }
    .mgz-products-grid .products-grid .product-item.product-item:hover .product-item-info,
    .page-products .products-grid .product-item.product-item:hover .product-item-info,
    .block-new-products .products-grid .product-item.product-item:hover .product-item-info,
    .pagebuilder-column-group .products-grid .product-item.product-item:hover .product-item-info {
      border: none;
      box-shadow: none; }
  .mgz-products-grid .products-grid .product-item.product-item .product-item-info,
  .page-products .products-grid .product-item.product-item .product-item-info,
  .block-new-products .products-grid .product-item.product-item .product-item-info,
  .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    position: static !important;
    flex-grow: 1; }
    .mgz-products-grid .products-grid .product-item.product-item .product-item-info:hover,
    .page-products .products-grid .product-item.product-item .product-item-info:hover,
    .block-new-products .products-grid .product-item.product-item .product-item-info:hover,
    .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info:hover {
      border: none;
      box-shadow: none; }
    .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details,
    .page-products .products-grid .product-item.product-item .product-item-info .product-item-details,
    .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details,
    .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details {
      margin: 0;
      padding: 0 20px 15px;
      font-family: rift, sans-serif;
      text-align: left;
      color: #000000;
      flex-direction: column;
      display: flex;
      flex-grow: 1; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name {
        font-family: rift, sans-serif;
        white-space: normal;
        text-overflow: initial;
        line-height: 1em; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link {
          font-family: rift, sans-serif; }
          .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link:hover,
          .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link:hover,
          .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link:hover,
          .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-name.product-item-name .product-item-link:hover {
            text-decoration: none; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .amrelated-attributes,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .amrelated-attributes,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .amrelated-attributes,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .amrelated-attributes {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2em; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        background: #FF7900;
        border-radius: 30px;
        height: 14px;
        width: 14px;
        margin: 4px 5px;
        line-height: 0.8em;
        text-align: center;
        display: inline-block; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.low-stock,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.low-stock,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.low-stock,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.low-stock {
          background: #FF7900; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock {
          background: #ff0800; }
          .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock svg,
          .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock svg,
          .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock svg,
          .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-colour.no-stock svg {
            display: none; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-levels-wrapper,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-levels-wrapper,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-levels-wrapper,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-levels-wrapper {
        display: flex;
        align-items: center;
        flex: 0; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-text,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-text,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-text,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .stock-level-text {
        font-family: roboto, sans-serif;
        font-size: 12px;
        display: inline-block;
        margin: 2px 0; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details a,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details a,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details a,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details a {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        font-style: italic; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price {
        font-family: rift, sans-serif;
        font-weight: bold;
        line-height: 1.2em; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price-wrapper,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price-wrapper,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price-wrapper,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price-wrapper {
          border-bottom: none;
          padding: 0; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .rrp-price::before,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .rrp-price::before,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .rrp-price::before,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .rrp-price::before {
          content: 'RRP';
          padding-right: 4px;
          color: #666;
          font-size: 12px; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .special-price,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .special-price,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .special-price,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .special-price {
          color: #ff0800;
          display: inline-block; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .price {
          font-size: 1.6rem; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .bundle-strike,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .bundle-strike,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .bundle-strike,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .bundle-strike {
          text-decoration: line-through;
          color: #999;
          padding-right: 10px; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .wholesale-price::before,
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .wholesale-price::before,
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .wholesale-price::before,
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .price-container.price-final_price .wholesale-price::before {
          content: 'WHOLESALE';
          padding-right: 4px;
          color: #666;
          font-size: 12px;
          text-decoration: none; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary {
        display: block; }
        .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"],
        .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"],
        .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"],
        .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] {
          display: flex;
          flex-wrap: wrap;
          gap: 8px; }
          .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] input,
          .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] input,
          .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] input,
          .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .actions-primary [data-role="tocart-form"] input {
            height: 44px;
            flex: 1 1 32%; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary {
        position: relative;
        z-index: 2;
        font-family: rift, sans-serif !important;
        display: block;
        padding: 10px 15px !important;
        margin: 0 !important;
        border-radius: 2px;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        border: 2px solid #FF7900;
        color: #ffffff;
        background: #FF7900;
        height: auto;
        flex: 1 1 62%; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary:hover,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary:hover,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary:hover,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .action.primary:hover {
        background: #ffffff;
        color: #FF7900; }
      .mgz-products-grid .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-actions,
      .page-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-actions,
      .block-new-products .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-actions,
      .pagebuilder-column-group .products-grid .product-item.product-item .product-item-info .product-item-details .product-item-actions {
        margin: auto 0 0;
        padding-top: 1rem;
        display: block; }

@media (min-width: 768px) {
  body.account #maincontent,
  body.customer-account-login #maincontent {
    margin-top: 50px; }
  body.account.page-layout-2columns-left .column.main,
  body.customer-account-login.page-layout-2columns-left .column.main {
    width: 70.7%;
    margin-top: 0; }
  body.account.page-layout-2columns-left .sidebar-main,
  body.account.page-layout-2columns-left .sidebar-additional,
  body.customer-account-login.page-layout-2columns-left .sidebar-main,
  body.customer-account-login.page-layout-2columns-left .sidebar-additional {
    width: 27.3%; } }

@media (max-width: 767px) {
  body.account .page-main,
  body.customer-account-login .page-main {
    padding-left: 20px;
    padding-right: 20px; }
  body.account .columns .sidebar-main,
  body.customer-account-login .columns .sidebar-main {
    order: -1; } }

body.account .block-collapsible-nav,
body.customer-account-login .block-collapsible-nav {
  border: solid 2px #000;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px !important;
  box-sizing: border-box; }
  body.account .block-collapsible-nav .title,
  body.customer-account-login .block-collapsible-nav .title {
    position: relative;
    width: auto;
    display: block;
    height: auto;
    margin: 0;
    clip: none;
    background-color: #000;
    padding: 5px 24px; }
    body.account .block-collapsible-nav .title strong,
    body.customer-account-login .block-collapsible-nav .title strong {
      font-family: rift, sans-serif;
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase; }
  body.account .block-collapsible-nav .content,
  body.customer-account-login .block-collapsible-nav .content {
    background-color: #fff; }
  body.account .block-collapsible-nav .nav.item,
  body.customer-account-login .block-collapsible-nav .nav.item {
    font-size: 13px; }
    body.account .block-collapsible-nav .nav.item.current strong,
    body.customer-account-login .block-collapsible-nav .nav.item.current strong {
      font-weight: 700;
      color: #FF7900;
      padding-left: 30px; }
    body.account .block-collapsible-nav .nav.item a,
    body.customer-account-login .block-collapsible-nav .nav.item a {
      color: #000;
      padding-left: 30px; }
      body.account .block-collapsible-nav .nav.item a:hover,
      body.customer-account-login .block-collapsible-nav .nav.item a:hover {
        background: none;
        color: #FF7900; }
  body.account .block-collapsible-nav .nav.item:last-child,
  body.customer-account-login .block-collapsible-nav .nav.item:last-child {
    margin-top: 20px; }
    @media (min-width: 768px) {
      body.account .block-collapsible-nav .nav.item:last-child,
      body.customer-account-login .block-collapsible-nav .nav.item:last-child {
        margin-top: 70px; } }
    body.account .block-collapsible-nav .nav.item:last-child a,
    body.customer-account-login .block-collapsible-nav .nav.item:last-child a {
      display: inline-block;
      width: auto;
      padding: 8px 46px;
      background-color: #000;
      border-radius: 2px;
      border: 2px solid black;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      font-family: rift, sans-serif;
      color: #fff;
      margin-left: 30px; }
      body.account .block-collapsible-nav .nav.item:last-child a:hover,
      body.customer-account-login .block-collapsible-nav .nav.item:last-child a:hover {
        background-color: #ffffff;
        color: #000000;
        border-color: #000000; }
  @media (max-width: 767px) {
    body.account .block-collapsible-nav,
    body.customer-account-login .block-collapsible-nav {
      position: relative; }
      body.account .block-collapsible-nav .title,
      body.customer-account-login .block-collapsible-nav .title {
        border-top: 0;
        border-bottom: 0; }
        body.account .block-collapsible-nav .title:after,
        body.customer-account-login .block-collapsible-nav .title:after {
          color: #fff;
          font-size: 20px;
          line-height: 16px; } }
  body.account .block-collapsible-nav .content,
  body.customer-account-login .block-collapsible-nav .content {
    padding: 21px 0 30px; }

body.account .page-main.page-main .page-title-wrapper .page-title,
body.customer-account-login .page-main.page-main .page-title-wrapper .page-title {
  font-size: 32px;
  line-height: 38px;
  font-family: rift, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 40px; }

body.account .page-main.page-main .fieldset > .legend,
body.account .page-main.page-main .block:not(.widget) .block-title,
body.customer-account-login .page-main.page-main .fieldset > .legend,
body.customer-account-login .page-main.page-main .block:not(.widget) .block-title {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding-bottom: 3px; }
  body.account .page-main.page-main .fieldset > .legend > strong,
  body.account .page-main.page-main .fieldset > .legend > span,
  body.account .page-main.page-main .block:not(.widget) .block-title > strong,
  body.account .page-main.page-main .block:not(.widget) .block-title > span,
  body.customer-account-login .page-main.page-main .fieldset > .legend > strong,
  body.customer-account-login .page-main.page-main .fieldset > .legend > span,
  body.customer-account-login .page-main.page-main .block:not(.widget) .block-title > strong,
  body.customer-account-login .page-main.page-main .block:not(.widget) .block-title > span {
    font-size: 22px;
    line-height: 28px;
    font-family: rift, sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
  @media (max-width: 767px) {
    body.account .page-main.page-main .fieldset > .legend:before, body.account .page-main.page-main .fieldset > .legend:after,
    body.account .page-main.page-main .block:not(.widget) .block-title:before,
    body.account .page-main.page-main .block:not(.widget) .block-title:after,
    body.customer-account-login .page-main.page-main .fieldset > .legend:before,
    body.customer-account-login .page-main.page-main .fieldset > .legend:after,
    body.customer-account-login .page-main.page-main .block:not(.widget) .block-title:before,
    body.customer-account-login .page-main.page-main .block:not(.widget) .block-title:after {
      display: none; } }

body.account .page-main.page-main .block:not(.widget) .block-title > a.action,
body.customer-account-login .page-main.page-main .block:not(.widget) .block-title > a.action {
  text-decoration: underline;
  font-size: 13px; }

body.account .page-main.page-main .block:not(.widget) .block-content,
body.customer-account-login .page-main.page-main .block:not(.widget) .block-content {
  font-family: roboto, sans-serif;
  font-size: 13px; }
  body.account .page-main.page-main .block:not(.widget) .block-content .box-title span,
  body.customer-account-login .page-main.page-main .block:not(.widget) .block-content .box-title span {
    font-size: 13px !important; }
  body.account .page-main.page-main .block:not(.widget) .block-content .box-content,
  body.customer-account-login .page-main.page-main .block:not(.widget) .block-content .box-content {
    font-size: 13px; }
  body.account .page-main.page-main .block:not(.widget) .block-content a.action,
  body.customer-account-login .page-main.page-main .block:not(.widget) .block-content a.action {
    text-decoration: underline;
    font-size: 13px; }

body.account .block-addresses-list address,
body.account .block-addresses-list .items.addresses > .item,
body.customer-account-login .block-addresses-list address,
body.customer-account-login .block-addresses-list .items.addresses > .item {
  font-size: 13px; }

body.account .block-dashboard-addresses .box-actions,
body.customer-account-login .block-dashboard-addresses .box-actions {
  display: none; }

body.account .actions-toolbar > .primary,
body.account .actions-toolbar > .secondary,
body.customer-account-login .actions-toolbar > .primary,
body.customer-account-login .actions-toolbar > .secondary {
  text-align: left; }

body.account .actions-toolbar button.action,
body.account .address-book-actions button.action,
body.customer-account-login .actions-toolbar button.action,
body.customer-account-login .address-book-actions button.action {
  color: #fff;
  background-color: #000;
  border-color: #000;
  font-family: rift, sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 47px;
  width: auto; }
  body.account .actions-toolbar button.action:hover,
  body.account .address-book-actions button.action:hover,
  body.customer-account-login .actions-toolbar button.action:hover,
  body.customer-account-login .address-book-actions button.action:hover {
    background-color: #fff;
    color: #000; }

body.account .form-edit-account .fieldset,
body.customer-account-login .form-edit-account .fieldset {
  float: none;
  width: 100%; }

body.account .form-edit-account .fieldset.password,
body.customer-account-login .form-edit-account .fieldset.password {
  display: block !important; }

body.account .login-container,
body.customer-account-login .login-container {
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 768px) {
    body.account .login-container,
    body.customer-account-login .login-container {
      display: flex; } }
  body.account .login-container:before, body.account .login-container:after,
  body.customer-account-login .login-container:before,
  body.customer-account-login .login-container:after {
    display: none; }
  @media (min-width: 768px) {
    body.account .login-container .login-tabs,
    body.customer-account-login .login-container .login-tabs {
      max-width: 600px;
      width: 100%;
      margin: 0 auto; } }
  body.account .login-container .login-tabs .tab-headers,
  body.customer-account-login .login-container .login-tabs .tab-headers {
    display: flex; }
    body.account .login-container .login-tabs .tab-headers .item.title,
    body.customer-account-login .login-container .login-tabs .tab-headers .item.title {
      width: 50%;
      text-align: center;
      background-color: #000; }
      body.account .login-container .login-tabs .tab-headers .item.title.active, body.account .login-container .login-tabs .tab-headers .item.title:hover,
      body.customer-account-login .login-container .login-tabs .tab-headers .item.title.active,
      body.customer-account-login .login-container .login-tabs .tab-headers .item.title:hover {
        background-color: #FF7900; }
      body.account .login-container .login-tabs .tab-headers .item.title a,
      body.customer-account-login .login-container .login-tabs .tab-headers .item.title a {
        display: inline-block;
        font-family: rift, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 50px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff; }
  body.account .login-container .login-tabs .tab-content,
  body.customer-account-login .login-container .login-tabs .tab-content {
    background-color: #fff;
    border: solid #FF7900 2px;
    padding: 40px 50px; }
  body.account .login-container .login-tabs #password-error,
  body.customer-account-login .login-container .login-tabs #password-error {
    line-height: 1.3; }
  body.account .login-container .fieldset:after,
  body.customer-account-login .login-container .fieldset:after {
    display: none; }
  body.account .login-container .block,
  body.customer-account-login .login-container .block {
    margin-bottom: 0;
    display: block;
    float: none;
    width: 100%; }
    body.account .login-container .block .block-title,
    body.customer-account-login .login-container .block .block-title {
      border-bottom: none;
      padding-bottom: 0; }
  body.account .login-container .fieldset > .field > .control,
  body.customer-account-login .login-container .fieldset > .field > .control {
    width: 100%; }
  body.account .login-container .fieldset > .field > .label,
  body.customer-account-login .login-container .fieldset > .field > .label {
    margin: 0; }
  body.account .login-container .actions-toolbar,
  body.customer-account-login .login-container .actions-toolbar {
    display: flex;
    justify-content: space-between; }
    body.account .login-container .actions-toolbar .primary .action,
    body.customer-account-login .login-container .actions-toolbar .primary .action {
      margin-right: 0; }
    body.account .login-container .actions-toolbar:before, body.account .login-container .actions-toolbar:after,
    body.customer-account-login .login-container .actions-toolbar:before,
    body.customer-account-login .login-container .actions-toolbar:after {
      display: none; }

body.customer-account-login .page-wrapper {
  background-color: #000; }

body.customer-account-login .page-title-wrapper {
  display: none; }

body.customer-account-login .page-main.page-main .block:not(.widget) .block-title > strong {
  font-size: 32px;
  line-height: 38px; }

body.customer-account-login .block-new-customer .new-customer-message ul {
  padding: 0;
  list-style: none;
  margin-top: 30px; }

body.customer-account-login .block-new-customer .new-customer-message li {
  font-size: 16px;
  line-height: 20px;
  font-family: rift, sans-serif;
  padding-left: 33px;
  margin-bottom: 15px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 18px 14px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18.344' height='13.794' viewBox='0 0 18.344 13.794'><g transform='translate(0 -63.498)'><g transform='translate(0 63.498)'><path d='M18.076,63.656a.717.717,0,0,0-1.007.11L7.534,75.633a.717.717,0,0,1-1.015.032L1.212,70.577A.717.717,0,1,0,.22,71.611L5.53,76.7a2.131,2.131,0,0,0,1.479.592l.089,0a2.132,2.132,0,0,0,1.515-.714l.025-.029,9.549-11.884A.717.717,0,0,0,18.076,63.656Z' transform='translate(0 -63.498)'/></g></g></svg>"); }

body.customer-account-login .block-new-customer .actions-toolbar a.action.action.action.action {
  padding: 20px 77px;
  background-color: #FF7900;
  color: #fff;
  text-decoration: none;
  font-family: rift, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 2px;
  border-color: #FF7900; }
  body.customer-account-login .block-new-customer .actions-toolbar a.action.action.action.action:hover {
    background-color: #fff;
    color: #FF7900; }

body.customer-account-login .block-customer-register .form.create.account {
  min-width: 0;
  width: 100%; }

body.customer-account-login .block-customer-register fieldset {
  margin-bottom: 25px; }
  body.customer-account-login .block-customer-register fieldset .field.field-name-firstname {
    width: 48%;
    float: left;
    margin-bottom: 0; }
  body.customer-account-login .block-customer-register fieldset .field.field-name-lastname {
    width: 48%;
    float: right; }

body.customer-account-login .block-customer-register .actions-toolbar button.action {
  padding: 20px 40px;
  width: 100%;
  background-color: #FF7900;
  border-color: #FF7900; }
  body.customer-account-login .block-customer-register .actions-toolbar button.action .primary {
    width: 100%; }
  body.customer-account-login .block-customer-register .actions-toolbar button.action:hover {
    background-color: #fff;
    color: #FF7900; }

.block-dashboard-addresses .box-title > span {
  font-size: 13px !important; }

body.sales-order-history .table-wrapper.orders-history,
.block-dashboard-orders .table-wrapper {
  margin-top: 0;
  border-bottom: none;
  border-top: none; }
  body.sales-order-history .table-wrapper.orders-history tr td:first-child,
  body.sales-order-history .table-wrapper.orders-history tr th:first-child,
  .block-dashboard-orders .table-wrapper tr td:first-child,
  .block-dashboard-orders .table-wrapper tr th:first-child {
    padding-left: 0; }
  body.sales-order-history .table-wrapper.orders-history .table > tbody > tr > th,
  body.sales-order-history .table-wrapper.orders-history .table > tbody > tr > td,
  .block-dashboard-orders .table-wrapper .table > tbody > tr > th,
  .block-dashboard-orders .table-wrapper .table > tbody > tr > td {
    border-top: none; }
  body.sales-order-history .table-wrapper.orders-history .table > tbody > tr > td,
  .block-dashboard-orders .table-wrapper .table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px; }
  body.sales-order-history .table-wrapper.orders-history .status-icon,
  .block-dashboard-orders .table-wrapper .status-icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    line-height: 17px;
    background-color: #FF7900;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px; }
    @media (max-width: 639px) {
      body.sales-order-history .table-wrapper.orders-history .status-icon,
      .block-dashboard-orders .table-wrapper .status-icon {
        display: none; } }
    body.sales-order-history .table-wrapper.orders-history .status-icon svg,
    .block-dashboard-orders .table-wrapper .status-icon svg {
      display: inline-block;
      vertical-align: middle;
      fill: #fff;
      color: #fff; }
    body.sales-order-history .table-wrapper.orders-history .status-icon.status-complete svg,
    .block-dashboard-orders .table-wrapper .status-icon.status-complete svg {
      padding-bottom: 2px; }
  @media (min-width: 640px) {
    body.sales-order-history .table-wrapper.orders-history .table .mobile-action,
    .block-dashboard-orders .table-wrapper .table .mobile-action {
      display: none; } }
  @media (max-width: 639px) {
    body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td[data-th]:before,
    body.sales-order-history .table-wrapper.orders-history .table > tbody > tr th[data-th]:before,
    .block-dashboard-orders .table-wrapper .table > tbody > tr td[data-th]:before,
    .block-dashboard-orders .table-wrapper .table > tbody > tr th[data-th]:before {
      content: attr(data-th) !important;
      width: 100px;
      font-weight: 700; }
    body.sales-order-history .table-wrapper.orders-history .table.table.table > tbody > tr,
    .block-dashboard-orders .table-wrapper .table.table.table > tbody > tr {
      margin-bottom: 15px; }
      body.sales-order-history .table-wrapper.orders-history .table.table.table > tbody > tr td,
      .block-dashboard-orders .table-wrapper .table.table.table > tbody > tr td {
        padding: 0; }
    body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td.col.actions,
    .block-dashboard-orders .table-wrapper .table > tbody > tr td.col.actions {
      display: none; }
    body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td.col.id,
    .block-dashboard-orders .table-wrapper .table > tbody > tr td.col.id {
      display: flex;
      justify-content: space-between; }
      body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td.col.id span.id-span,
      .block-dashboard-orders .table-wrapper .table > tbody > tr td.col.id span.id-span {
        display: inline-block;
        background-color: #000;
        color: #fff;
        padding: 3px 5px; }
      body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td.col.id .mobile-action,
      .block-dashboard-orders .table-wrapper .table > tbody > tr td.col.id .mobile-action {
        text-decoration: underline; }
      body.sales-order-history .table-wrapper.orders-history .table > tbody > tr td.col.id[data-th]:before,
      .block-dashboard-orders .table-wrapper .table > tbody > tr td.col.id[data-th]:before {
        display: none; } }

body.customer-account-edit .page-title-wrapper {
  margin-bottom: 20px; }

body.customer-account-edit .form-edit-account .fieldset .field.choice.custom-radio label.label {
  font-weight: 700; }

body.sales-order-history .order-history-title {
  margin-bottom: 0; }

body.sales-order-history .table-order-items {
  font-size: 13px; }
  body.sales-order-history .table-order-items .col.actions a {
    text-decoration: underline; }

body.customer-account-edit .form-edit-account,
body.customer-account-edit .form-address-edit,
body.customer-address-form .form-edit-account,
body.customer-address-form .form-address-edit {
  font-size: 13px; }
  body.customer-account-edit .form-edit-account .fieldset,
  body.customer-account-edit .form-address-edit .fieldset,
  body.customer-address-form .form-edit-account .fieldset,
  body.customer-address-form .form-address-edit .fieldset {
    float: none;
    width: 100%; }
    @media (min-width: 768px) {
      body.customer-account-edit .form-edit-account .fieldset .field,
      body.customer-account-edit .form-address-edit .fieldset .field,
      body.customer-address-form .form-edit-account .fieldset .field,
      body.customer-address-form .form-address-edit .fieldset .field {
        width: 70%; }
        body.customer-account-edit .form-edit-account .fieldset .field .nested .field,
        body.customer-account-edit .form-address-edit .fieldset .field .nested .field,
        body.customer-address-form .form-edit-account .fieldset .field .nested .field,
        body.customer-address-form .form-address-edit .fieldset .field .nested .field {
          width: 100%; } }
  body.customer-account-edit .form-edit-account .actions-toolbar,
  body.customer-account-edit .form-address-edit .actions-toolbar,
  body.customer-address-form .form-edit-account .actions-toolbar,
  body.customer-address-form .form-address-edit .actions-toolbar {
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 768px) {
      body.customer-account-edit .form-edit-account .actions-toolbar,
      body.customer-account-edit .form-address-edit .actions-toolbar,
      body.customer-address-form .form-edit-account .actions-toolbar,
      body.customer-address-form .form-address-edit .actions-toolbar {
        width: 70%; } }
    body.customer-account-edit .form-edit-account .actions-toolbar .primary .action,
    body.customer-account-edit .form-address-edit .actions-toolbar .primary .action,
    body.customer-address-form .form-edit-account .actions-toolbar .primary .action,
    body.customer-address-form .form-address-edit .actions-toolbar .primary .action {
      margin-right: 0;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 40px;
      border-radius: 2px; }

body.account.wishlist-index-index .actions-toolbar {
  display: none; }

body.account.wishlist-index-index .toolbar.wishlist-toolbar .limiter,
body.account.wishlist-index-index .toolbar.wishlist-toolbar .toolbar-amount {
  display: none; }

body.customer-account-logoutsuccess h1.page-title {
  font-family: rift, sans-serif;
  font-weight: 700;
  margin-top: 30px; }

body.sales-order-view .order-links .item strong {
  color: #fff;
  font-weight: 700; }

body.sales-order-view .product-item-name,
body.sales-order-view .product-item-link,
body.sales-order-view .price-final_price {
  font-size: 14px;
  font-family: rift, sans-serif; }

body.sales-order-view .order-details-items .col.price .price,
body.sales-order-view .order-details-items .col.price .price-including-tax,
body.sales-order-view .order-details-items .col.subtotal .price,
body.sales-order-view .order-details-items .col.subtotal .price-including-tax {
  font-size: 14px; }

.amlocator-index-index {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .amlocator-index-index #maincontent {
    max-width: 1500px !important;
    padding: 0; }
  .amlocator-index-index .pac-container {
    border: none !important; }
  .amlocator-index-index .page-title-wrapper {
    display: none; }

.amlocator-index-index .hidden, .catalog-product-view .hidden {
  display: none; }

.amlocator-index-index .page-main, .catalog-product-view .page-main {
  max-width: 1366px !important; }
  .amlocator-index-index .page-main .amasty_locator_filter, .catalog-product-view .page-main .amasty_locator_filter {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1366px;
    padding: 2rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 400;
    background-color: #848484;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/fox-stockist-image.png); }
    @media (min-width: 1024px) {
      .amlocator-index-index .page-main .amasty_locator_filter, .catalog-product-view .page-main .amasty_locator_filter {
        padding-left: 8rem;
        padding-right: 8rem; } }
    .amlocator-index-index .page-main .amasty_locator_filter::before, .catalog-product-view .page-main .amasty_locator_filter::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #000000;
      opacity: 0.6; }
    .amlocator-index-index .page-main .amasty_locator_filter .header-wrapper, .catalog-product-view .page-main .amasty_locator_filter .header-wrapper {
      margin: 0 auto;
      padding: 0 2rem 2rem; }
    .amlocator-index-index .page-main .amasty_locator_filter .block-title, .catalog-product-view .page-main .amasty_locator_filter .block-title {
      text-align: center; }
      .amlocator-index-index .page-main .amasty_locator_filter .block-title h1, .catalog-product-view .page-main .amasty_locator_filter .block-title h1 {
        margin: 0;
        font-family: rift, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 4rem;
        line-height: 1; }
      .amlocator-index-index .page-main .amasty_locator_filter .block-title p, .catalog-product-view .page-main .amasty_locator_filter .block-title p {
        font-family: roboto, sans-serif;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 200;
        color: inherit; }
  .amlocator-index-index .page-main .filter-location-wrapper, .catalog-product-view .page-main .filter-location-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 542px;
    margin: 2rem auto; }
    @media (max-width: 600) {
      .amlocator-index-index .page-main .filter-location-wrapper, .catalog-product-view .page-main .filter-location-wrapper {
        max-width: 560px;
        margin: 2rem auto; }
        .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper {
          flex: 1 0 auto;
          margin: 0 6px; } }
    .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper {
      cursor: pointer; }
      @media (max-width: 600px) {
        .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper {
          flex: 1 0 auto;
          margin: 0 6px; }
          .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper {
            margin: 0 6px !important; } }
      .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper input, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper input {
        display: none; }
      .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper {
        display: flex;
        align-items: center;
        padding: 0 1.5rem; }
        @media (min-width: 601px) {
          .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper {
            padding-top: 10px;
            padding-bottom: 10px; } }
        .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper svg, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper svg {
          width: 50px;
          margin-right: 10px; }
          @media (max-width: 600px) {
            .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper svg, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper svg {
              width: 36px;
              height: 36px; } }
        .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper p, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper p {
          font-family: rift, sans-serif;
          font-weight: bold;
          color: inherit;
          font-size: 26px;
          margin-bottom: 0;
          user-select: none; }
          @media (max-width: 600px) {
            .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper .content-wrapper p, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper .content-wrapper p {
              font-size: 18px; } }
      .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper.checked, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper.checked {
        background-color: #ffffff;
        color: #000000;
        border: 2px solid #000000;
        border-radius: 0.25rem; }
      .amlocator-index-index .page-main .filter-location-wrapper .filter-wrapper.not-checked, .catalog-product-view .page-main .filter-location-wrapper .filter-wrapper.not-checked {
        background: transparent;
        border: 2px solid #000000;
        border-radius: 0.25rem; }
  .amlocator-index-index .page-main .search-wrapper, .catalog-product-view .page-main .search-wrapper {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 100%; }
    @media (max-width: 600px) {
      .amlocator-index-index .page-main .search-wrapper, .catalog-product-view .page-main .search-wrapper {
        padding: 0 6px; } }
    .amlocator-index-index .page-main .search-wrapper .amlocator_input, .catalog-product-view .page-main .search-wrapper .amlocator_input {
      margin-top: 0; }
    .amlocator-index-index .page-main .search-wrapper .location, .catalog-product-view .page-main .search-wrapper .location {
      flex-grow: 1; }
      .amlocator-index-index .page-main .search-wrapper .location .location-input, .catalog-product-view .page-main .search-wrapper .location .location-input {
        font-family: roboto, sans-serif;
        font-size: 1.3rem;
        background: url("../images/pin-black.svg") no-repeat scroll left 13px center;
        background-size: 15px 20px;
        padding-left: 5.5rem;
        background-color: #FFF;
        margin-top: 0;
        border: 1px solid #ddd;
        border-radius: 0.3rem 0 0 0.3rem;
        width: 100%;
        height: 100%; }
        @media (max-width: 767.98px) {
          .amlocator-index-index .page-main .search-wrapper .location .location-input, .catalog-product-view .page-main .search-wrapper .location .location-input {
            padding-left: 4.5rem; } }
        .amlocator-index-index .page-main .search-wrapper .location .location-input::placeholder, .amlocator-index-index .page-main .search-wrapper .location .location-input::-ms-input-placeholder, .catalog-product-view .page-main .search-wrapper .location .location-input::placeholder, .catalog-product-view .page-main .search-wrapper .location .location-input::-ms-input-placeholder {
          font-family: inherit;
          font-size: inherit;
          color: inherit; }
    .amlocator-index-index .page-main .search-wrapper .distance, .catalog-product-view .page-main .search-wrapper .distance {
      width: 20%;
      max-width: 100px; }
      .amlocator-index-index .page-main .search-wrapper .distance .radius-select, .catalog-product-view .page-main .search-wrapper .distance .radius-select {
        border: 1px solid #ddd;
        border-left: 0;
        width: 100%;
        height: 100%;
        background-color: white; }
    .amlocator-index-index .page-main .search-wrapper .search button, .catalog-product-view .page-main .search-wrapper .search button {
      width: 44px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -1px;
      background-color: #000;
      height: 100%;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      border: 1px solid black;
      color: white;
      transition: all .2s linear; }
      .amlocator-index-index .page-main .search-wrapper .search button:hover, .catalog-product-view .page-main .search-wrapper .search button:hover {
        background: white;
        border-color: #ddd;
        color: black; }
      .amlocator-index-index .page-main .search-wrapper .search button svg path, .catalog-product-view .page-main .search-wrapper .search button svg path {
        fill: currentColor; }
  .amlocator-index-index .page-main .search-button-mobile, .catalog-product-view .page-main .search-button-mobile {
    display: none; }
  .amlocator-index-index .page-main .store-locator-results, .catalog-product-view .page-main .store-locator-results {
    display: flex;
    width: 100%; }
    .amlocator-index-index .page-main .store-locator-results .map, .catalog-product-view .page-main .store-locator-results .map {
      flex: 50%;
      max-height: 600px;
      overflow-y: hidden; }
    .amlocator-index-index .page-main .store-locator-results .stores, .catalog-product-view .page-main .store-locator-results .stores {
      width: 100%;
      font-family: rift, sans-serif;
      padding-left: 3rem;
      padding-right: 3rem; }
      @media (min-width: 1400px) {
        .amlocator-index-index .page-main .store-locator-results .stores, .catalog-product-view .page-main .store-locator-results .stores {
          padding-left: 6.7rem;
          padding-right: 6.7rem; } }
      @media (max-width: 1023.98px) {
        .amlocator-index-index .page-main .store-locator-results .stores, .catalog-product-view .page-main .store-locator-results .stores {
          box-sizing: border-box;
          padding-left: 1.5rem;
          padding-right: 1.5rem; } }
      .amlocator-index-index .page-main .store-locator-results .stores .breadcrumbs, .catalog-product-view .page-main .store-locator-results .stores .breadcrumbs {
        margin: 3rem 0 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important; }
        .amlocator-index-index .page-main .store-locator-results .stores .breadcrumbs .item.item:not(:last-child):after, .catalog-product-view .page-main .store-locator-results .stores .breadcrumbs .item.item:not(:last-child):after {
          padding: 0 0.2rem; }
        .amlocator-index-index .page-main .store-locator-results .stores .breadcrumbs a:hover, .catalog-product-view .page-main .store-locator-results .stores .breadcrumbs a:hover {
          text-decoration: none !important; }
        @media (max-width: 767.98px) {
          .amlocator-index-index .page-main .store-locator-results .stores .breadcrumbs, .catalog-product-view .page-main .store-locator-results .stores .breadcrumbs {
            display: none; } }
      .amlocator-index-index .page-main .store-locator-results .stores .stores-info, .catalog-product-view .page-main .store-locator-results .stores .stores-info {
        margin-top: 3rem;
        padding: 0 0 2rem; }
        @media (min-width: 768px) {
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info, .catalog-product-view .page-main .store-locator-results .stores .stores-info {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid black;
            margin-bottom: 4rem; } }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info .location-text, .catalog-product-view .page-main .store-locator-results .stores .stores-info .location-text {
          font-weight: 700; }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info h2, .catalog-product-view .page-main .store-locator-results .stores .stores-info h2 {
          margin-top: 0;
          margin-bottom: 1rem;
          font-weight: 700;
          font-size: 3.2rem;
          color: black; }
          @media (max-width: 767.98px) {
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info h2, .catalog-product-view .page-main .store-locator-results .stores .stores-info h2 {
              text-align: center; } }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info h3, .catalog-product-view .page-main .store-locator-results .stores .stores-info h3 {
          font-size: 1.4rem; }
          @media (max-width: 767.98px) {
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info h3, .catalog-product-view .page-main .store-locator-results .stores .stores-info h3 {
              text-align: center; } }
        @media (max-width: 767.98px) {
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info p, .catalog-product-view .page-main .store-locator-results .stores .stores-info p {
            text-align: center; } }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info a, .catalog-product-view .page-main .store-locator-results .stores .stores-info a {
          font-size: 18px;
          margin-top: auto;
          margin-bottom: auto;
          font-weight: bold;
          text-transform: uppercase; }
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info a span, .catalog-product-view .page-main .store-locator-results .stores .stores-info a span {
            color: #FF7900; }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info button, .catalog-product-view .page-main .store-locator-results .stores .stores-info button {
          font-family: rift, sans-serif;
          font-weight: 700;
          color: #fff;
          background-color: #000;
          padding: 1.1rem 3.3rem;
          border-radius: 2px;
          font-size: 16px; }
        .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters {
          font-family: roboto, sans-serif; }
          @media (max-width: 767.98px) {
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters {
              width: calc(100% + 1.5rem);
              overflow-x: auto;
              scroll-snap-type: x mandatory;
              padding-top: 0.25rem;
              padding-bottom: 1rem; } }
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters,
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters ul,
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters a, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters,
          .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters ul,
          .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters a {
            display: flex;
            align-items: center; }
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters ul, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters ul {
            list-style: none;
            margin-bottom: 0;
            padding-left: 1rem; }
            @media (max-width: 767.98px) {
              .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters ul, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters ul {
                margin: auto;
                padding-left: 0;
                padding-right: 1.5rem;
                width: max-content; }
                .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters ul li:first-child, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters ul li:first-child {
                  margin-left: 0; } }
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters li, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters li {
            margin-left: 1rem;
            margin-bottom: 0;
            white-space: nowrap; }
            @media (max-width: 767.98px) {
              .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters li, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters li {
                width: max-content;
                scroll-snap-align: start; } }
          .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters a, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters a {
            color: inherit;
            text-transform: none;
            background: #F5F5F5;
            border: 1px solid #E3E3E3;
            border-radius: 20px;
            font-size: 1.3rem;
            padding: 0.1rem 1.5rem 0.1rem 1.1rem;
            font-weight: 400; }
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters a.active, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters a.active {
              border-color: #000; }
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters a img, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters a img {
              margin-right: 0.5rem; }
          @media (max-width: 767.98px) {
            .amlocator-index-index .page-main .store-locator-results .stores .stores-info .stores-filters b, .catalog-product-view .page-main .store-locator-results .stores .stores-info .stores-filters b {
              display: none; } }
      .amlocator-index-index .page-main .store-locator-results .stores .store-blocks, .catalog-product-view .page-main .store-locator-results .stores .store-blocks {
        display: flex;
        flex-wrap: wrap;
        padding: 0.3rem 0; }
        @media (min-width: 1024px) {
          .amlocator-index-index .page-main .store-locator-results .stores .store-blocks, .catalog-product-view .page-main .store-locator-results .stores .store-blocks {
            overflow-y: auto;
            max-height: 600px; } }
        @media (min-width: 1200px) {
          .amlocator-index-index .page-main .store-locator-results .stores .store-blocks, .catalog-product-view .page-main .store-locator-results .stores .store-blocks {
            padding-left: 2rem; } }
        @media (min-width: 1400px) {
          .amlocator-index-index .page-main .store-locator-results .stores .store-blocks, .catalog-product-view .page-main .store-locator-results .stores .store-blocks {
            padding-left: 5.25rem; } }
        .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper {
          flex: 0 100%;
          padding-left: 0.95rem;
          padding-right: 0.95rem;
          box-sizing: border-box;
          margin-bottom: 1.9rem; }
          @media (min-width: 550px) {
            .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper {
              flex: 0 50%; } }
          @media (min-width: 768px) {
            .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper {
              flex: 0 33.333%; } }
          @media (min-width: 1024px) {
            .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper {
              flex: 0 25%; } }
          .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper .block, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper .block {
            position: relative;
            display: flex;
            border-radius: 3px;
            margin-bottom: 0;
            height: 100%;
            padding: 2.1rem 5.6rem 2.1rem 1.8rem;
            box-sizing: border-box;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
            @media (max-width: 767.98px) {
              .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper .block, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper .block {
                padding: 2.5rem 5.6rem 2.5rem 1.9rem; } }
            .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 0;
              right: 0;
              padding: 1rem 1.6rem 0 0; }
              @media (max-width: 767.98px) {
                .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold {
                  padding: 0.7rem 1.6rem 0 0; } }
              .amlocator-index-index .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold .filter-icon, .catalog-product-view .page-main .store-locator-results .stores .store-blocks .block-wrapper .block .brands-sold .filter-icon {
                flex: 0 0 3.6rem;
                margin-bottom: 0.4rem; }
  .amlocator-index-index .page-main .store-info, .catalog-product-view .page-main .store-info {
    word-break: break-word;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.6; }
    .amlocator-index-index .page-main .store-info .address-container,
    .amlocator-index-index .page-main .store-info .phone-container,
    .amlocator-index-index .page-main .store-info .link-container, .catalog-product-view .page-main .store-info .address-container,
    .catalog-product-view .page-main .store-info .phone-container,
    .catalog-product-view .page-main .store-info .link-container {
      display: flex;
      margin-bottom: 0.6rem; }
      .amlocator-index-index .page-main .store-info .address-container::before,
      .amlocator-index-index .page-main .store-info .phone-container::before,
      .amlocator-index-index .page-main .store-info .link-container::before, .catalog-product-view .page-main .store-info .address-container::before,
      .catalog-product-view .page-main .store-info .phone-container::before,
      .catalog-product-view .page-main .store-info .link-container::before {
        content: '';
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 0.6rem;
        display: none; }
    .amlocator-index-index .page-main .store-info .address-container::before, .catalog-product-view .page-main .store-info .address-container::before {
      background-image: url("../images/icon-location.svg"); }
    .amlocator-index-index .page-main .store-info .phone-container::before, .catalog-product-view .page-main .store-info .phone-container::before {
      background-image: url("../images/icon-phone.svg"); }
    .amlocator-index-index .page-main .store-info .link-container::before, .catalog-product-view .page-main .store-info .link-container::before {
      background-image: url("../images/icon-web.svg"); }
    .amlocator-index-index .page-main .store-info .pickup-container, .catalog-product-view .page-main .store-info .pickup-container {
      font-family: roboto, sans-serif;
      color: #FF7900; }
    .amlocator-index-index .page-main .store-info .store-name, .catalog-product-view .page-main .store-info .store-name {
      font-family: roboto, sans-serif !important;
      font-weight: 700;
      font-size: 1.4rem;
      margin-top: 2px;
      margin-bottom: 1rem;
      line-height: 1.1;
      color: black;
      padding-right: 7rem; }
    .amlocator-index-index .page-main .store-info .address, .catalog-product-view .page-main .store-info .address {
      font-family: roboto, sans-serif;
      font-size: 12px;
      margin-bottom: 0; }
    .amlocator-index-index .page-main .store-info .phone, .catalog-product-view .page-main .store-info .phone {
      margin-bottom: 0;
      font-family: roboto, sans-serif; }
    .amlocator-index-index .page-main .store-info .directions,
    .amlocator-index-index .page-main .store-info .website, .catalog-product-view .page-main .store-info .directions,
    .catalog-product-view .page-main .store-info .website {
      font-family: roboto, sans-serif;
      font-weight: 400;
      cursor: pointer;
      color: inherit;
      text-decoration: underline;
      text-transform: initial; }
      .amlocator-index-index .page-main .store-info .directions:hover,
      .amlocator-index-index .page-main .store-info .website:hover, .catalog-product-view .page-main .store-info .directions:hover,
      .catalog-product-view .page-main .store-info .website:hover {
        text-decoration: none; }
  .amlocator-index-index .page-main .gm-style .gm-style-iw-t:after, .catalog-product-view .page-main .gm-style .gm-style-iw-t:after {
    box-shadow: none;
    margin-top: -1px;
    height: 25px;
    width: 25px;
    border-bottom: 3px solid black;
    border-left: 3px solid black; }
  .amlocator-index-index .page-main .gm-style .gm-style-iw-d > div, .catalog-product-view .page-main .gm-style .gm-style-iw-d > div {
    font-size: 0; }
  .amlocator-index-index .page-main .gm-style .gm-style-iw-d .store-info, .catalog-product-view .page-main .gm-style .gm-style-iw-d .store-info {
    line-height: 1.2; }
  .amlocator-index-index .page-main .gm-style .gm-style-iw-c, .catalog-product-view .page-main .gm-style .gm-style-iw-c {
    border: 3px solid black; }
    .amlocator-index-index .page-main .gm-style .gm-style-iw-c *, .catalog-product-view .page-main .gm-style .gm-style-iw-c * {
      margin: 5px 0; }

.storelocator-container {
  display: flex;
  margin-top: 0.3rem; }
  .storelocator-container #amasty_locator_block {
    flex: 0 0 41.4%; }
    @media (max-width: 767.98px) {
      .storelocator-container #amasty_locator_block {
        flex: 0 0 100%;
        height: 250px; } }
  .storelocator-container .map {
    flex: 0 0 58.6%;
    padding-left: 0.3rem; }
    @media (max-width: 767.98px) {
      .storelocator-container .map {
        display: none; } }
    .storelocator-container .map .gm-style-cc {
      display: none; }
    .storelocator-container .map .gm-style img[style*='150px'] {
      width: 34.4px !important;
      height: 34.4px !important;
      margin: auto !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .storelocator-container .amasty_locator_filter {
    height: 100%;
    box-sizing: border-box; }
  .storelocator-container .amlocator-map-canvas {
    height: 400px; }

.global-store-toggler {
  display: flex;
  align-items: center;
  position: relative;
  color: inherit;
  padding: 0 2rem;
  transition: none;
  height: 100%;
  box-sizing: border-box;
  border: none !important;
  border-radius: 0;
  margin-right: -1rem;
  font-size: 1.4rem;
  white-space: nowrap; }
  @media (min-width: 1200px) {
    .global-store-toggler {
      padding: 0 3rem; } }
  .global-store-toggler:hover {
    color: #FF7900; }
  .global-store-toggler svg {
    margin-right: 0.5rem; }
  @media (max-width: 1023.98px) {
    .global-store-toggler.set-store-toggler {
      width: 50%;
      justify-content: center;
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .global-store-toggler.set-store-toggler {
      width: auto;
      justify-content: flex-start;
      padding: 1rem 0; } }
  .global-store-toggler.active {
    background-color: #FF7900;
    color: #ffffff; }
    .global-store-toggler.active::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #FF7900; }
    .global-store-toggler.active::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: calc(100vh);
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
      opacity: 1;
      transition: opacity 0.15s linear 0.2s; }
    .global-store-toggler.active.set-store-toggler {
      background: none; }
      .global-store-toggler.active.set-store-toggler::before {
        display: none; }
      .global-store-toggler.active.set-store-toggler::after {
        z-index: 1; }

.global-store-toggler-mob {
  margin-left: 0.25rem;
  padding: 0.3rem 1.5rem;
  transform: translateY(2px); }
  @media (min-width: 1024px) {
    .global-store-toggler-mob {
      display: none; } }

.global-store-form {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  flex: 0 1 471px;
  max-width: 40%;
  margin: auto; }
  @media (max-width: 1023.98px) {
    .global-store-form {
      position: absolute;
      top: 106px;
      left: 2.5rem;
      right: 2.5rem;
      max-width: none;
      width: auto; }
      .global-store-form input {
        width: auto; }
      .global-store-form .btn.btn.btn {
        flex-shrink: 1; } }
  .global-store-form input {
    flex: 1 0 auto;
    height: auto;
    border-radius: 0.3rem 0 0 0.3rem;
    background: url("../images/pin-black.svg") no-repeat scroll left 13px center;
    background-size: 15px 20px;
    padding-left: 5.5rem;
    background-color: white; }
    @media (max-width: 767.98px) {
      .global-store-form input {
        padding-left: 4.5rem; } }
  .global-store-form .btn.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0.3rem 0.3rem 0;
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
    width: 44px;
    height: 40px;
    padding: 0;
    margin-left: -1px;
    box-sizing: border-box;
    flex-shrink: 0; }
    .global-store-form .btn.btn:hover {
      background: #fff;
      border-color: #ddd;
      color: #000; }
    .global-store-form .btn.btn svg path {
      fill: currentColor; }

.global-store-container {
  display: flex;
  align-items: center;
  max-width: 1406px !important;
  width: 100%;
  margin: auto;
  padding: 0 !important;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 400; }
  @media (max-width: 1023.98px) {
    .offcanvas-body .global-store-container {
      padding-top: 3.4rem !important; } }
  @media (min-width: 1024px) {
    .global-store-container {
      padding: 0 10px 0 20px !important; } }
  @media (min-width: 1460px) {
    .global-store-container .pagebuilder-column-group {
      margin: 0 -4rem; }
    .global-store-container .pagebuilder-column {
      padding-left: 4rem !important;
      padding-right: 4rem !important; } }
  .global-store-container h3 {
    font-size: 2.6rem; }
  .global-store-container a {
    color: inherit; }
  @media (max-width: 900px) {
    .global-store-container .pagebuilder-column-group {
      flex-wrap: wrap; }
      .global-store-container .pagebuilder-column-group .pagebuilder-column {
        flex: 0 0 50%;
        width: 50% !important;
        align-self: flex-start !important;
        justify-self: flex-start !important; } }
  @media (min-width: 1024px) {
    .global-store-container .add-border {
      border-right: 1px solid rgba(112, 112, 112, 0.13) !important; } }
  @media (max-width: 1023.98px) {
    .global-store-container .stockist-map {
      display: none !important; } }
  @media (min-width: 1024px) {
    .global-store-container .stockist-map {
      position: relative;
      align-self: flex-start !important; }
      .global-store-container .stockist-map p {
        font-family: rift, sans-serif;
        font-size: 2.3rem;
        font-weight: 700;
        max-width: 200px;
        width: 100%;
        margin: auto;
        position: absolute;
        top: 42%;
        left: 52%;
        transform: translate(-50%, -50%);
        color: black;
        text-align: center; } }

.find-store-global-label {
  flex-shrink: 0;
  font-family: rift, sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .find-store-global-label {
      font-size: 2.9rem; } }

.find-store-global {
  font-family: roboto, sans-serif;
  text-transform: none; }
  @media (min-width: 1024px) {
    .find-store-global {
      margin-top: 3.5rem; } }
  @media (max-width: 1023.98px) {
    .find-store-global:not(.show) {
      transform: translateX(100%); } }
  .find-store-global .offcanvas-header {
    background-color: #FF7900;
    color: white; }

.set-store-global .offcanvas-header {
  background-color: black;
  color: white; }
  .set-store-global .offcanvas-header .offcanvas-title {
    font-family: rift, sans-serif;
    font-weight: 700;
    font-size: 3.2rem; }

.set-store-global .btn-sm {
  font-size: 1.3rem;
  padding: 0.3rem 1.5rem;
  margin-left: 1.5rem;
  font-weight: 400;
  border-width: 1px;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem; }
  @media (min-width: 768px) {
    .set-store-global .btn-sm {
      opacity: 0; } }

@media (min-width: 768px) {
  .set-store-global li:hover {
    background-color: #f5f5f5; }
    .set-store-global li:hover .btn-sm {
      opacity: 1; } }

.set-store-global .offcanvas-body {
  padding: 3.25rem; }
  .set-store-global .offcanvas-body p {
    margin-bottom: 1.7rem;
    line-height: 1.3; }

.set-store-global .global-store-form {
  position: static;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 1rem; }
  .set-store-global .global-store-form input {
    flex: 1 1 auto;
    padding-left: 1.5rem;
    background: none;
    border-color: #ddd; }
  .set-store-global .global-store-form .btn.btn {
    width: auto;
    height: 48px;
    padding: 0 2.7rem; }

.set-store-global .btn-uselocation {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: inherit;
  font-family: roboto, sans-serif;
  font-size: 1.2rem;
  padding: 0;
  text-transform: none;
  font-weight: normal;
  text-decoration: underline !important; }
  .set-store-global .btn-uselocation:hover {
    text-decoration: none !important; }

.set-store-global .address-list {
  border-top: 1px solid #ddd;
  margin-top: 2.4rem;
  margin-bottom: 0;
  padding-top: 2.5rem;
  padding-left: 0;
  list-style: none;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap; }
  .set-store-global .address-list li {
    padding: 0.7rem 2rem;
    border: solid 2px transparent;
    border-radius: 3px;
    width: 100%;
    position: relative;
    padding-right: 90px; }
    .set-store-global .address-list li.active {
      border-color: #FF7900; }
    .set-store-global .address-list li.initial-active {
      order: -1; }
    .set-store-global .address-list li:last-child {
      margin-bottom: 0; }

.columns a.hidden-print {
  display: block;
  color: #333;
  font-weight: bold;
  font-family: 'Roboto';
  margin-bottom: 15px;
  text-align: right;
  text-decoration: none; }

.table-comparison th.cell.label, .table-comparison td:last-child {
  border-right: 1px solid #000000; }

.table-comparison th.cell.product.label, .table-comparison td.cell.product.info {
  border-bottom: 1px solid #000000; }

.table-comparison th.cell.label, .table-comparison td.cell.product.attribute {
  text-align: center; }

.table-comparison td.product.info {
  text-align: center; }
  .table-comparison td.product.info .product-item-name {
    font-family: 'rift';
    font-size: 18px;
    font-weight: bold; }
  .table-comparison td.product.info .product-item-actions {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center; }
    .table-comparison td.product.info .product-item-actions .actions-primary button.primary {
      background: #000000;
      border-radius: 2px;
      border: none;
      padding: 12px 45px; }
      .table-comparison td.product.info .product-item-actions .actions-primary button.primary span {
        font-size: 16px;
        font-family: 'rift';
        font-weight: bold;
        line-height: 20px; }
    .table-comparison td.product.info .product-item-actions .actions-secondary {
      margin-right: 12px; }
      .table-comparison td.product.info .product-item-actions .actions-secondary .wishlist-icon {
        vertical-align: middle;
        fill: none;
        stroke: #000000;
        stroke-width: 1.2px; }
        .table-comparison td.product.info .product-item-actions .actions-secondary .wishlist-icon path.a:hover {
          fill: #000000; }

.modal-popup.confirm .modal-inner-wrap {
  max-width: 480px;
  border: 2px solid #000000; }
  .modal-popup.confirm .modal-inner-wrap .modal-header {
    padding: 10px;
    height: auto; }
    .modal-popup.confirm .modal-inner-wrap .modal-header .action-close {
      padding: 15px 25px 0; }
      .modal-popup.confirm .modal-inner-wrap .modal-header .action-close :before {
        color: #000000; }
  .modal-popup.confirm .modal-inner-wrap .modal-content {
    padding: 0 40px 15px 15px;
    line-height: 1.3;
    font-family: roboto, sans-serif;
    font-size: 14px; }
  .modal-popup.confirm .modal-inner-wrap .modal-footer {
    padding: 0 25px 25px !important;
    display: flex;
    justify-content: space-evenly;
    background-color: #ffffff !important; }
    .modal-popup.confirm .modal-inner-wrap .modal-footer span {
      font-family: rift, sans-serif;
      color: #ffffff;
      font-size: 15px; }
    .modal-popup.confirm .modal-inner-wrap .modal-footer .action-primary, .modal-popup.confirm .modal-inner-wrap .modal-footer .action-secondary {
      padding: 10px 35px;
      border-radius: 2px;
      min-width: 100px; }
    .modal-popup.confirm .modal-inner-wrap .modal-footer .action-primary {
      background-color: #FF7900;
      border-color: #FF7900; }
      .modal-popup.confirm .modal-inner-wrap .modal-footer .action-primary:hover {
        border-color: #000000;
        background-color: #000000; }
        .modal-popup.confirm .modal-inner-wrap .modal-footer .action-primary:hover span {
          color: #ffffff !important; }
    .modal-popup.confirm .modal-inner-wrap .modal-footer .action-secondary {
      background-color: #000000;
      border-color: #000000; }
      .modal-popup.confirm .modal-inner-wrap .modal-footer .action-secondary:hover {
        background-color: #ffffff; }
        .modal-popup.confirm .modal-inner-wrap .modal-footer .action-secondary:hover span {
          color: #000000 !important; }

@media (max-width: 768px) {
  .columns a.hidden-print {
    text-align: left; } }

.products-grid.wishlist {
  margin-top: 0; }
  @media screen and (max-width: 1023px) {
    .products-grid.wishlist ol.products.list.items.product-items {
      justify-content: center; } }
  .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item {
    position: relative;
    margin-left: calc((100% - 3 * 29%) / 2) !important;
    width: 29% !important; }
    .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item .actions-primary, .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item .actions-secondary {
      margin: 0; }
    @media screen and (min-width: 1023px) {
      .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item:nth-child(3n + 1) {
        margin-left: 0 !important; } }
    @media screen and (max-width: 1023px) {
      .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item {
        margin-left: 5% !important;
        width: 40% !important; }
        .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item:nth-child(2n + 1) {
          margin-left: 0 !important; } }
    .products-grid.wishlist ol.products.list.items.product-items li.item.product.product-item .btn-remove.action.delete {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2; }

#wishlist-view-form .product-item-inner {
  margin-top: 0; }

#wishlist-view-form .price-box .price {
  font-family: rift, sans-serif;
  font-size: 16px; }

.products-grid.wishlist .product-item-photo {
  float: none;
  text-align: center; }

.products-grid.wishlist .product-image-container {
  max-width: 100%; }

/* FOOTER */
.page-footer {
  min-height: 0;
  background: #000000 !important;
  color: #ffffff;
  font-family: rift, sans-serif;
  font-weight: bold; }
  @media (min-width: 768px) {
    .page-footer {
      padding-top: 42px !important; } }
  .page-footer [data-content-type="row"][data-appearance="contained"] {
    max-width: none; }
  .page-footer .footer.content {
    max-width: 1366px !important;
    margin-bottom: 0;
    padding: 0; }
    .page-footer .footer.content ul {
      padding: 0;
      flex-grow: 1;
      width: auto;
      margin-right: 40px; }
      .page-footer .footer.content ul:last-child {
        margin-right: 0; }
  .page-footer .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-items: top;
    justify-content: space-between; }
  .page-footer .links-wrapper,
  .page-footer .contact-wrapper,
  .page-footer .subscribe-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%; }
    @media (min-width: 768px) {
      .page-footer .links-wrapper,
      .page-footer .contact-wrapper,
      .page-footer .subscribe-wrapper {
        min-width: fit-content;
        max-width: fit-content; } }
  .page-footer .links-wrapper {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between; }
    .page-footer .links-wrapper h4 {
      color: #FF7900;
      font-size: 20px;
      line-height: 44px;
      margin-top: 0;
      margin-bottom: 6px;
      font-weight: bold; }
    .page-footer .links-wrapper li a {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      text-decoration: none;
      transition: color 0.2s linear;
      color: inherit; }
      .page-footer .links-wrapper li a:hover {
        color: #FF7900; }
  .page-footer .contact-wrapper {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
    .page-footer .contact-wrapper .wrap {
      display: flex;
      flex-direction: column; }
      .page-footer .contact-wrapper .wrap > a:not(.btn) {
        font-weight: bold;
        font-size: 37px;
        line-height: 44px;
        text-decoration: none;
        transition: color 0.2 linear;
        color: #ffffff; }
        .page-footer .contact-wrapper .wrap > a:not(.btn):hover {
          color: #FF7900; }
    .page-footer .contact-wrapper .social-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 40px; }
      .page-footer .contact-wrapper .social-wrapper a {
        margin: 0 13px 0;
        color: inherit; }
        @media (min-width: 768px) {
          .page-footer .contact-wrapper .social-wrapper a {
            margin: 0 23px 0 0; } }
        .page-footer .contact-wrapper .social-wrapper a:hover svg path {
          fill: #FF7900; }
    .page-footer .contact-wrapper p {
      margin-bottom: 0.5rem;
      font-family: roboto, sans-serif;
      font-weight: normal;
      font-size: 13px;
      line-height: 22px;
      color: #ffffff;
      text-transform: none; }
  .page-footer .subscribe-wrapper {
    width: 100%;
    max-width: 468px;
    height: 238px;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box; }
    .page-footer .subscribe-wrapper h3 {
      font-family: rift, sans-serif;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 10px; }
    .page-footer .subscribe-wrapper p {
      font-family: roboto, sans-serif;
      font-weight: normal;
      text-transform: none;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 20px;
      width: 100%;
      max-width: 350px;
      padding: 0 10px;
      color: inherit; }
    .page-footer .subscribe-wrapper .input-group {
      background-color: #fff;
      border-radius: 3px; }
  .page-footer .footer-toolbar-bottom {
    max-width: 100% !important;
    margin-top: 30px; }
    .page-footer .footer-toolbar-bottom .content {
      max-width: 1366px !important;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      @media (min-width: 1024px) {
        .page-footer .footer-toolbar-bottom .content {
          max-width: 1406px !important;
          padding: 1.5rem 20px; } }
    .page-footer .footer-toolbar-bottom .copyright {
      display: flex;
      align-items: center;
      color: #333;
      text-transform: none;
      line-height: 1.5;
      font-family: roboto, sans-serif;
      font-size: 10px;
      font-weight: normal; }
      .page-footer .footer-toolbar-bottom .copyright span {
        padding-left: 1.2rem; }
  .page-footer .subscribe-wrapper .input-group {
    max-width: 353px;
    width: inherit; }
    .page-footer .subscribe-wrapper .input-group .input-group-text,
    .page-footer .subscribe-wrapper .input-group .form-control,
    .page-footer .subscribe-wrapper .input-group .btn {
      height: 40px; }
    .page-footer .subscribe-wrapper .input-group .input-group-text {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
      background-image: url(../images/envelope.svg);
      background-size: 15.63px 11.72px;
      width: 36px; }
    @media (min-width: 768px) {
      .page-footer .subscribe-wrapper .input-group {
        max-width: 353px; } }
    .page-footer .subscribe-wrapper .input-group .btn {
      border-width: 0 0 0 1px; }
    .page-footer .subscribe-wrapper .input-group #newsletter-email-error {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: white;
      font-weight: 500;
      width: 100%; }

@media (max-width: 1280px) {
  .page-footer .flex-row {
    justify-content: space-around; } }

@media (max-width: 900px) {
  .page-footer .flex-row {
    align-items: center;
    padding-left: 30px;
    padding-right: 30px; }
  .page-footer .links-wrapper {
    margin-top: 0;
    border-top: 1px solid #ffffff;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px; }
  .page-footer .contact-wrapper .social-wrapper {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto; }
  .page-footer .contact-wrapper .wrap {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .page-footer .contact-wrapper .wrap .btn {
      margin-left: auto;
      margin-right: auto; }
  .page-footer .footer-toolbar-bottom .content .copyright {
    align-items: center;
    justify-content: center; }
    .page-footer .footer-toolbar-bottom .content .copyright span {
      display: none; } }

/* Buttons */
.page-footer .btn {
  width: fit-content;
  background-color: #FF7900;
  color: #ffffff;
  padding: 10px 35px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s linear; }
  .page-footer .btn:hover {
    background-color: #ffffff;
    color: #000000; }

/* Footer override */
.page-footer .flex-row > div.links-wrapper {
  flex: 0 0; }
  @media (max-width: 768px) {
    .page-footer .flex-row > div.links-wrapper {
      flex: 1 0 100%; } }
  @media (min-width: 1420px) {
    .page-footer .flex-row > div.links-wrapper {
      min-width: 400px; } }
  .page-footer .flex-row > div.links-wrapper ul {
    margin-right: 10px; }

@media screen and (min-width: 1117px) and (max-width: 1215px) {
  .page-footer .flex-row > div.subscribe-wrapper {
    max-width: 350px; } }

.minicart {
  overflow-y: auto; }
  .minicart .block-minicart {
    padding: 0;
    box-shadow: 0px 3px 6px #00000029;
    right: -100%;
    transition: right 0.3s; }
    .minicart .block-minicart .minicart-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      background: #ffffff; }
      .minicart .block-minicart .minicart-content-wrapper .block-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0; }
    .minicart .block-minicart #btn-minicart-close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 6px;
      top: 6px;
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-shadow: none;
      font-weight: 400;
      color: #333333;
      display: inline-block;
      text-decoration: none; }
      .minicart .block-minicart #btn-minicart-close svg {
        fill: #ffffff; }
    .minicart .block-minicart .your-cart {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding-top: 5.3rem;
      padding-left: 3.2rem;
      padding-right: 2.5rem;
      padding-bottom: 2rem;
      background-color: #000; }
      .minicart .block-minicart .your-cart h2 {
        margin: 0;
        font-weight: 700;
        color: #ffffff;
        font-size: 32px;
        line-height: 1.2; }
      .minicart .block-minicart .your-cart .cart-wrapper {
        display: flex; }
        .minicart .block-minicart .your-cart .cart-wrapper svg {
          fill: #ffffff;
          color: #ffffff;
          margin-top: 4px; }
        .minicart .block-minicart .your-cart .cart-wrapper .badge {
          padding-left: 9px;
          padding-right: 9px;
          -webkit-border-radius: 9px;
          -moz-border-radius: 9px;
          border-radius: 9px; }
        .minicart .block-minicart .your-cart .cart-wrapper .label-warning[href],
        .minicart .block-minicart .your-cart .cart-wrapper .badge-warning[href] {
          background-color: #c67605; }
        .minicart .block-minicart .your-cart .cart-wrapper #lblCartCount {
          font-size: 12px;
          background: #FF7900;
          color: #fff;
          vertical-align: top;
          margin-left: 0;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin-left: -5px; }
      .minicart .block-minicart .your-cart .items-in-cart-count {
        color: #fff;
        font-size: 24px; }
    .minicart .block-minicart .free-shipping-wrapper {
      padding: 2rem; }
      .minicart .block-minicart .free-shipping-wrapper .free-shipping {
        position: relative;
        background: #eee;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 1rem;
        height: 100px; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping p {
          font-family: "Roboto";
          font-size: 13px;
          color: #333; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping strong {
          font-weight: bold; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping span {
          font-weight: bold;
          color: #FF7900; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping .continue-shopping {
          color: #333;
          text-decoration: underline; }
        .minicart .block-minicart .free-shipping-wrapper .free-shipping svg {
          width: 100px; }
          .minicart .block-minicart .free-shipping-wrapper .free-shipping svg .a {
            fill: #fff;
            color: #fff; }
      .minicart .block-minicart .free-shipping-wrapper .free-shipping:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #eee;
        border-width: 15px;
        margin-left: -15px; }
    .minicart .block-minicart .minicart-items-wrapper {
      margin: 0;
      border: 0;
      padding: 30px 40px;
      height: auto !important;
      overflow-y: auto; }
      .minicart .block-minicart .minicart-items-wrapper li.item.product-item.odd.last:first-child {
        padding-top: 0; }
      .minicart .block-minicart .minicart-items-wrapper li.item.product-item:last-child {
        padding-bottom: 0; }
      .minicart .block-minicart .minicart-items-wrapper .product-item {
        padding: 22px 0; }
      .minicart .block-minicart .minicart-items-wrapper .product {
        display: flex;
        justify-content: center;
        width: 100%;
        line-height: 15px; }
        .minicart .block-minicart .minicart-items-wrapper .product .product-image-wrapper {
          display: flex;
          flex: 0 20%;
          align-items: center; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-image-wrapper .product-image-container {
            width: 70px; }
        .minicart .block-minicart .minicart-items-wrapper .product .product-item-details {
          flex: 0 80%;
          padding-left: 0; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-sku-wrapper p {
            font-size: 13px;
            font-family: "Roboto";
            color: #333; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper {
            display: flex;
            justify-content: space-between; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper a {
              font-family: "Roboto";
              font-weight: bold;
              font-size: 13px;
              color: #000; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper .price-wrapper .price-including-tax {
              margin: 0; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-name-price-wrapper .price-wrapper .price-including-tax .price {
                font-family: "Roboto";
                font-size: 13px;
                color: #000; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-details {
            color: #333;
            font-size: 13px;
            font-weight: 400;
            font-family: "Roboto"; }
          .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-top: 1rem;
            height: 35px; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper {
              width: 100%;
              height: 30px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border: 2px solid #000000; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper .product-qty {
                width: 25px;
                padding: 0 5px;
                margin: 0;
                text-align: center;
                border: 0;
                font-weight: 700;
                background: transparent; }
              .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button {
                border: 0;
                padding: 0 5px; }
                .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:hover, .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:focus {
                  background-color: transparent; }
                .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button svg {
                  vertical-align: middle; }
            .minicart .block-minicart .minicart-items-wrapper .product .product-item-details .product-qty-actions-wrapper .actions .secondary .action.delete::before {
              content: none !important; }
    .minicart .block-minicart .totals-wrapper {
      width: 80%;
      margin: 0 auto;
      padding: 22px 0;
      border-top: 1px solid #cccccc; }
      .minicart .block-minicart .totals-wrapper > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem; }
      .minicart .block-minicart .totals-wrapper span {
        font-family: "Roboto";
        font-size: 13px; }
      .minicart .block-minicart .totals-wrapper .total {
        font-weight: bold; }
        .minicart .block-minicart .totals-wrapper .total > .price {
          font-weight: bold; }
    .minicart .block-minicart .minicart-footer {
      width: 80%;
      margin: 0 auto 25px; }
      .minicart .block-minicart .minicart-footer .actions {
        justify-content: center;
        text-align: center;
        margin-bottom: 1rem; }
        .minicart .block-minicart .minicart-footer .actions a {
          font-family: roboto, sans-serif;
          font-size: 12px;
          color: #FF7900;
          text-decoration: underline; }
      .minicart .block-minicart .minicart-footer .checkout-button {
        width: 100%;
        padding: 2rem;
        background-color: #000000;
        font-family: "Rift";
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        border: 0;
        border-radius: 0.2rem;
        display: block;
        width: auto;
        text-align: center;
        border: 2px solid #000000; }
        .minicart .block-minicart .minicart-footer .checkout-button:hover {
          background: #ffffff;
          color: #000000; }
      .minicart .block-minicart .minicart-footer .payments-accepted-wrapper {
        margin-top: 2rem; }
        .minicart .block-minicart .minicart-footer .payments-accepted-wrapper p {
          font-family: "Roboto";
          font-size: 12px;
          text-align: center;
          margin-bottom: 1rem; }
        .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted {
          display: flex;
          justify-content: center; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .mastercard {
            content: url("../images/mastercard.png");
            width: 30px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .visa {
            content: url("../images/visa.png");
            width: 40px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .afterpay {
            content: url("../images/afterpay.png");
            width: 70px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .zip {
            content: url("../images/zip.png");
            width: 40px;
            height: auto; }
          .minicart .block-minicart .minicart-footer .payments-accepted-wrapper .payments-accepted .paypal {
            content: url("../images/paypal.png");
            width: 60px;
            height: auto; }

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.mage-dropdown-dialog {
  display: block !important; }

.minicart-wrapper.minicart.active #ui-id-1::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  pointer-events: none; }

.minicart-wrapper.minicart.active #ui-id-1 {
  position: fixed;
  right: 0; }

.modal-popup.confirm .modal-inner-wrap {
  height: auto;
  max-width: 450px; }
  .modal-popup.confirm .modal-inner-wrap .modal-header .action-close {
    padding: 0; }
    .modal-popup.confirm .modal-inner-wrap .modal-header .action-close:before {
      content: " " !important;
      background: no-repeat url(../images/close_loader.svg);
      height: 20px;
      width: 20px;
      position: absolute;
      left: -35px;
      background-size: contain;
      top: 10px;
      padding: 0; }
  .modal-popup.confirm .modal-inner-wrap .modal-footer {
    margin-top: 0; }

@media (max-width: 600px) {
  .minicart-wrapper .block-minicart {
    max-width: 100% !important;
    top: 0; }
    .minicart-wrapper .block-minicart .cart-wrapper {
      z-index: -1; }
    .minicart-wrapper .block-minicart #btn-minicart-close {
      right: 20px;
      top: 33px; }
      .minicart-wrapper .block-minicart #btn-minicart-close svg {
        width: 27px;
        height: 27px; }
    .minicart-wrapper .block-minicart .logo {
      z-index: 999; }
    .minicart-wrapper .block-minicart .your-cart {
      padding-top: 3rem;
      width: auto; } }

.size-accordion {
  width: 100%;
  border: 1px solid #eeeeee;
  box-sizing: border-box; }
  .size-accordion .content {
    max-width: 95%;
    margin: 0 auto;
    transition: max-height 1s linear;
    max-height: 0;
    overflow: hidden; }
    .size-accordion .content .sub-title {
      font-family: rift, sans-serif;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;
      margin-top: 20px; }
      .size-accordion .content .sub-title:first-child {
        margin-top: 0; }
  .size-accordion .title {
    font-family: rift, sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    padding: 20px 30px;
    cursor: pointer; }
    @media (max-width: 500px) {
      .size-accordion .title {
        padding: 20px 15px; } }
    .size-accordion .title.active {
      border-bottom: 1px solid #eeeeee; }
      .size-accordion .title.active + .content {
        max-height: max-content; }
  @media (max-width: 500px) {
    .size-accordion table {
      font-size: 10px; } }
  .size-accordion table tr th {
    color: #ffffff;
    background-color: #333333; }
  .size-accordion table tr:nth-child(odd) {
    background-color: #eeeeee; }
  .size-accordion table tr:nth-child(even) {
    background-color: #ffffff; }

.product-qty-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 0.2rem;
  height: 35px; }
  .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper {
    position: relative;
    width: 75px !important; }
    .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper .product-qty {
      padding-left: 3rem;
      width: 4rem;
      height: 3rem;
      font-weight: normal;
      border: 2px solid #000000;
      background-color: #ffffff; }
    .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper .update-cart-decrement {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 20%;
      top: 20%;
      left: 7px;
      color: #ddd; }
    .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper .update-cart-increment {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 20%;
      top: 20%;
      right: 7px;
      color: #ddd; }
    .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button {
      padding: 0;
      border: 0; }
      .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:hover, .product-qty-actions-wrapper .product-item-pricing .adjust-qty-wrapper button:focus {
        background-color: transparent; }
  .product-qty-actions-wrapper .actions .secondary .action.delete::before {
    content: none !important; }

.checkout-cart-index h1 {
  display: none !important; }

.checkout-cart-index .cart-empty h2 {
  margin-bottom: 0;
  font-weight: 700;
  color: #000000; }

.checkout-cart-index .cart-empty a {
  background: inherit !important;
  border: none;
  color: inherit !important;
  font-weight: inherit;
  text-transform: none;
  box-shadow: inherit !important;
  outline: inherit !important;
  padding: 0; }
  .checkout-cart-index .cart-empty a:hover {
    text-decoration: underline; }

.cart-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 1140px;
  margin: 2rem auto 0;
  margin-top: 5rem; }
  @media (max-width: 1170px) {
    .cart-container {
      max-width: 775px; } }
  @media (max-width: 767px) {
    .cart-container {
      width: calc(100% - 48px);
      margin: 0 24px; } }
  .cart-container h2 {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-family: rift, sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: #000000; }
    @media (max-width: 767px) {
      .cart-container h2 {
        margin: 10px 0 18px;
        font-size: 22px; } }
    .cart-container h2 .btn.btn-secondary {
      background-color: #FF7900;
      border: 1px solid #FF7900;
      border-radius: 2px;
      color: #ffffff;
      margin-left: 2rem; }
      .cart-container h2 .btn.btn-secondary:hover {
        background-color: #ffffff;
        color: #FF7900; }

.cart-summary {
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  float: none;
  order: 2;
  top: 0 !important; }
  @media (min-width: 1171px) {
    .cart-summary {
      margin-top: -6rem; } }
  .cart-summary .btn {
    display: block;
    width: 100%;
    margin: 20px auto;
    background-color: #FF7900;
    color: #ffffff;
    text-align: center;
    box-sizing: border-box;
    height: 60px;
    padding-top: 18px; }
    .cart-summary .btn:hover {
      background-color: #000000; }
  .cart-summary p {
    text-align: center;
    font-family: roboto, sans-serif;
    font-size: 12px; }
    @media (max-width: 767px) {
      .cart-summary p {
        margin: 1rem; } }
  .cart-summary svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 40px auto; }
  @media (max-width: 1170px) {
    .cart-summary {
      margin-left: auto; } }
  @media (max-width: 767px) {
    .cart-summary {
      margin: 0 auto;
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
      box-shadow: none !important;
      border-top: 1px solid #DDDDDD !important;
      max-width: 100%; } }
  .cart-summary .totals-tax-summary {
    display: table-row !important; }
    .cart-summary .totals-tax-summary .price {
      padding-right: 0 !important;
      padding-left: 25px; }
      .cart-summary .totals-tax-summary .price:after {
        left: 0;
        right: unset !important;
        top: 6px !important; }

.form-cart {
  order: 1;
  max-width: 775px;
  margin-right: 45px;
  margin-bottom: 0;
  padding: 0 !important; }
  .form-cart .cart.main.actions {
    display: none; }
  .form-cart #shopping-cart-table .table-caption {
    display: none; }
  @media (max-width: 1170px) {
    .form-cart {
      margin: 0 auto;
      width: 100%; } }

.cart.table-wrapper th.col {
  border: none !important; }

.cart.table-wrapper .cart.item {
  border-top: 1px solid #DDDDDD !important; }
  .cart.table-wrapper .cart.item .input-text.qty,
  .cart.table-wrapper .cart.item .item-actions {
    display: none !important; }
  .cart.table-wrapper .cart.item .item-info {
    display: flex;
    padding: 20px 0;
    justify-content: flex-end;
    position: relative;
    border-top: 1px solid #ddd; }
    @media (max-width: 767px) {
      .cart.table-wrapper .cart.item .item-info {
        padding: 20px 0 40px 0; } }
    .cart.table-wrapper .cart.item .item-info .col.item {
      padding: 0 !important;
      display: flex !important;
      align-items: center;
      margin-right: auto; }
      .cart.table-wrapper .cart.item .item-info .col.item a {
        display: block;
        width: 100%;
        margin-right: 20px;
        padding: 0;
        line-height: 1.2;
        color: #000000; }
        @media (max-width: 767px) {
          .cart.table-wrapper .cart.item .item-info .col.item a {
            position: static; } }
        .cart.table-wrapper .cart.item .item-info .col.item a img {
          width: 100%;
          max-width: 110px;
          height: auto;
          max-height: 110px; }
      .cart.table-wrapper .cart.item .item-info .col.item .product-item-details {
        padding: 0; }
    .cart.table-wrapper .cart.item .item-info .col.price {
      display: flex !important;
      align-items: center;
      padding: 0 22px; }
      .cart.table-wrapper .cart.item .item-info .col.price:before {
        display: none; }
      @media (min-width: 768px) {
        .cart.table-wrapper .cart.item .item-info .col.price {
          padding: 0 40px; } }
    .cart.table-wrapper .cart.item .item-info .col.qty {
      display: flex !important;
      align-items: center;
      padding: 0; }
      .cart.table-wrapper .cart.item .item-info .col.qty:before {
        display: none; }
      @media (max-width: 767px) {
        .cart.table-wrapper .cart.item .item-info .col.qty {
          position: absolute;
          bottom: 10px;
          left: 87px; } }
    .cart.table-wrapper .cart.item .item-info .col.subtotal {
      display: flex !important;
      align-items: center;
      padding: 0 40px; }
      .cart.table-wrapper .cart.item .item-info .col.subtotal:before {
        display: none; }
      @media (max-width: 767px) {
        .cart.table-wrapper .cart.item .item-info .col.subtotal {
          display: none !important; } }
    .cart.table-wrapper .cart.item .item-info .col.action {
      display: flex !important;
      align-items: center; }
      .cart.table-wrapper .cart.item .item-info .col.action .primary {
        margin-right: 18px; }
      @media (max-width: 767px) {
        .cart.table-wrapper .cart.item .item-info .col.action {
          position: absolute;
          bottom: 10px;
          right: 22px;
          padding: 0; } }
      @media (min-width: 768px) {
        .cart.table-wrapper .cart.item .item-info .col.action {
          padding: 0 25px 0 0; } }

.store-pickup-index .page-main {
  padding: 0; }

.store-pickup-index .columns .column.main {
  padding-bottom: 0; }

.store-pickup-index .instagram-holder {
  display: none; }

.store-confirm {
  display: flex;
  justify-content: start;
  overflow: auto;
  width: 100%;
  background-color: #ffffff;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%; }
  @media (min-width: 768px) {
    .store-confirm {
      flex-direction: row;
      justify-content: center; } }
  .store-confirm .store-pickup-footer-mobile {
    margin-bottom: 0;
    background-color: #000000;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    padding: 3.5rem 2rem; }
    @media (min-width: 768px) {
      .store-confirm .store-pickup-footer-mobile {
        display: none; } }
  .store-confirm,
  .store-confirm * {
    box-sizing: border-box; }
  .store-confirm.store-thanks .image-block {
    width: 100%;
    height: 100%; }
    @media (min-width: 768px) {
      .store-confirm.store-thanks .image-block {
        width: 100%; } }
    .store-confirm.store-thanks .image-block-logo {
      margin-bottom: auto; }
    .store-confirm.store-thanks .image-block-title {
      font-size: 3.9rem;
      letter-spacing: 7.8px; }
      @media (min-width: 768px) {
        .store-confirm.store-thanks .image-block-title {
          font-size: 4.4rem;
          letter-spacing: 8.8px; } }
    .store-confirm.store-thanks .image-block-text {
      max-width: 100%;
      margin-bottom: auto;
      font-size: 1.8rem; }
      @media (min-width: 768px) {
        .store-confirm.store-thanks .image-block-text {
          font-size: 2rem; } }
    .store-confirm.store-thanks .image-block-footer {
      display: block; }
      @media (max-width: 767.98px) {
        .store-confirm.store-thanks .image-block-footer .image-block-menu {
          display: none; } }
  .store-confirm .image-block {
    background-image: url("../images/store-confirm-wide.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8vh 2.5rem 3rem;
    text-align: center;
    color: #ffffff; }
    @media (max-width: 767.98px) {
      .store-confirm .image-block {
        min-height: 500px; } }
    @media (min-width: 768px) {
      .store-confirm .image-block {
        width: 40%; } }
    @media (min-width: 1024px) {
      .store-confirm .image-block {
        padding: 11vh 3rem 5rem; } }
    .store-confirm .image-block-link-home {
      display: none; }
      @media (min-width: 768px) {
        .store-confirm .image-block-link-home {
          display: block;
          position: absolute;
          top: 2.7rem;
          right: 2.7rem;
          width: 41px;
          height: 41px;
          z-index: 2;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='41'%3E%3Ccircle cx='20.5' cy='20.5' r='20.5' fill='%23fff'/%3E%3Cpath d='m31.625 19.11-10.613-8.5-10.636 8.5 1.249 1.562L13 19.573v10.318h16v-10.32l1.375 1.1zM19 27.891v-6h4v6zm8 0h-2v-8h-8v8h-2v-9.916l6.011-4.8L27 17.969z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; }
          .store-confirm .image-block-link-home:hover {
            opacity: 0.7; } }
    .store-confirm .image-block-logo {
      background-image: url("../images/logo-fox-racing.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 165px;
      height: 38px;
      position: relative;
      z-index: 2;
      margin-bottom: 3.8rem;
      flex-shrink: 0; }
    .store-confirm .image-block-title {
      font-size: 3rem;
      color: #E1FE36;
      font-weight: 600;
      letter-spacing: 1rem;
      line-height: 1.1;
      text-align: center;
      z-index: 2;
      position: relative;
      max-width: 40rem;
      margin-bottom: 2.5rem; }
      @media (min-width: 1024px) {
        .store-confirm .image-block-title {
          font-size: 4.4rem; } }
    .store-confirm .image-block-text {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      z-index: 2;
      position: relative;
      max-width: 40rem; }
    .store-confirm .image-block-overlay {
      background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%) 0% 0% no-repeat padding-box;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
    .store-confirm .image-block-footer {
      display: none;
      z-index: 2;
      position: relative;
      width: 100%;
      margin-top: auto; }
      .store-confirm .image-block-footer p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1.5rem; }
      @media (min-width: 768px) {
        .store-confirm .image-block-footer {
          display: block; } }
    .store-confirm .image-block-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 2;
      position: relative;
      list-style: none;
      margin-top: auto;
      margin-bottom: 4.3rem;
      padding-left: 0;
      font-size: 1.3rem; }
      .store-confirm .image-block-menu .image-block-menu-item {
        margin: 0 1.1rem; }
        @media (min-width: 1024px) {
          .store-confirm .image-block-menu .image-block-menu-item {
            margin: 0 2.4rem; } }
    .store-confirm .image-block p,
    .store-confirm .image-block a {
      color: inherit; }
  .store-confirm .form-block {
    width: 100%;
    position: relative;
    z-index: 1; }
    @media (min-width: 768px) {
      .store-confirm .form-block {
        width: 60%;
        overflow-y: auto; } }
    .store-confirm .form-block form {
      padding: 3.5rem 2rem; }
      @media (min-width: 768px) {
        .store-confirm .form-block form {
          padding-left: 5rem;
          padding-right: 5rem; } }
      @media (min-width: 1024px) {
        .store-confirm .form-block form {
          box-sizing: content-box;
          max-width: 500px;
          padding: 4.9rem 11rem; } }
      .store-confirm .form-block form .btn {
        width: 100%;
        margin-top: 1.4rem;
        color: #E1FE36;
        font-size: 2.2rem;
        border-width: 1px;
        height: 50px; }
        .store-confirm .form-block form .btn:hover {
          color: #000000; }
        .store-confirm .form-block form .btn:focus {
          box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15); }
    .store-confirm .form-block fieldset {
      margin-bottom: 2rem;
      padding: 0;
      border: none; }
    .store-confirm .form-block .customer-details {
      margin-bottom: 1rem; }
    .store-confirm .form-block legend {
      margin-bottom: 1rem;
      font-family: rift, sans-serif;
      font-weight: 700;
      font-size: 2.2rem;
      color: black; }
    .store-confirm .form-block .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: -10px;
      margin-right: -10px; }
    .store-confirm .form-block .col-sm-6 {
      padding-left: 10px;
      padding-right: 10px; }
      @media (max-width: 767.98px) {
        .store-confirm .form-block .col-sm-6 {
          width: 100%; } }
    .store-confirm .form-block .form-check {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
    .store-confirm .form-block label {
      font-weight: 700;
      font-size: 1.2rem; }
      .store-confirm .form-block label .required {
        color: #ff0800; }
    .store-confirm .form-block input {
      border-color: #A3A3A3; }
      .store-confirm .form-block input[type='text'], .store-confirm .form-block input[type='email'] {
        height: 40px;
        margin-bottom: 1rem;
        padding-left: 1.4rem;
        padding-right: 4rem; }
        .store-confirm .form-block input[type='text'][readonly], .store-confirm .form-block input[type='email'][readonly] {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='m10.28 16.357-5.03-5.131 1.071-1.05 3.97 4.049 6.964-6.964 1.061 1.06z' fill='%23a3a3a3'/%3E%3C/svg%3E");
          background-position: right 1rem center;
          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-color: #EDEDED;
          color: #555; }
      .store-confirm .form-block input:not([readonly]):focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15); }
      .store-confirm .form-block input[type='checkbox'] {
        flex-shrink: 0;
        appearance: none;
        border: 1px solid #C2C2C2;
        border-radius: 0.2rem;
        width: 40px;
        height: 40px;
        margin-top: 0;
        margin-right: 1rem; }
        .store-confirm .form-block input[type='checkbox']:checked {
          border-color: #000000;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='m10.28 16.357-5.03-5.131 1.071-1.05 3.97 4.049 6.964-6.964 1.061 1.06z' fill='%23000'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px 24px; }
  .store-confirm .image-block-call.image-block-call {
    display: inline-block;
    margin-top: 1rem;
    font-family: rift, sans-serif;
    font-size: 2.9rem;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1.45px;
    color: #E1FE36; }
    .store-confirm .image-block-call.image-block-call:hover {
      text-decoration: underline; }
