body{
    &.page-layout-checkout,
    &.checkout-index-index{
        .g-recaptcha{ display: none; }
        .no-quotes-block{ display: none; }
        .form-login{
            span.note{ display: none !important; }
        }
        fieldset{
            .actions-toolbar{
                .primary {
                    display: flex;
                    align-items: center;
                    button{
                        margin: 5px !important;
                    }

                    @media (max-width: 767px) {

                        .action {
                            margin: 0 !important;
                        }
                    }
                }
            }
        }
        h1{
            display: none;
        }
        h3{
            font-family: $rift;
            font-weight: bold;
            font-size: 22px !important;
            line-height: 27px;
            margin-top: 10px !important;
            margin-bottom: 0px !important;
            color: $black;
        }
        .step-title{
            h3{
                margin-top: 10px !important;
            }
        }
        #shipping,
        #opc-shipping_method,
        #payment{
            display: block !important;
            overflow: hidden;
        }
        .page-wrapper{
            overflow: hidden;
            background-color: $white;
            background: linear-gradient(180deg, rgba(221,221,221,0.5) 0%, rgba(221,221,221,0.5) 338px, rgba(255,255,255,1) 338px, rgba(255,255,255,1) 100%);
        }
        // header + div
        header.page-header { display: none !important; }
        .page-main{
            max-width: 1056px !important;
            padding-top: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
        }
        .container{
            // max-width: 1056px;
            padding-left: 33px;
            padding-right: 33px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            box-sizing: border-box;
        }
        .step-title{
            background-color: $black;
            color: $white !important;
            font-weight: 700;
            font-size: 32px !important;
            line-height: 38px !important;
            padding: 5px 24px !important;
            font-family: $rift;

            @media (max-width: 767px) {
                font-size: 22px !important;
            }
        }
        .authentication-wrapper{
            position: relative;
            float: none;
            margin-top: unset;
        }
        .field{
            &.additional{ display: none; }
            label,
            legend{
                margin-bottom: 3px !important;
                span{
                    font-family: $roboto;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    color: $black;
                }
            }
            input,
            select{
                height: 40px;
                border: 1px solid #DDDDDD !important;
                border-radius: 2px !important;
                box-shadow: unset !important;
                font-family: $roboto !important;
                font-size: 13px !important;
                line-height: 30px !important;
                background-color: $white;
                opacity: 1 !important;
            }
            input,
            select option{
                padding: 12px 15px !important;
            }
        }
        .free-shipping-reminder{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            background-color: #eeeeee;
            padding: 17px 22px;
            position: relative;
            margin-top: 16px;
            margin-bottom: 20px;
            &:before{
                content: '';
                border-top: 23px solid transparent;
                border-left: 23px solid transparent;
                border-right: 23px solid transparent;
                border-bottom: 23px solid #eeeeee;
                top: -35px;
                left: calc(50% - 11.5px);
                position: absolute;
            }
            p,
            p *{
                margin: 0;
                font-family: $roboto;
                font-size: 13px;
                line-height: 22px;
            }
            p{
                margin-left: 15px;
                a{
                    color: $black;
                    &:hover{ color: $orange; }
                }
                .price{
                    color: $orange;
                    font-weight: bold;
                }
                b{
                    font-weight: bold;
                }
            }
        }
        #fatzebra_gateway-form{
            iframe{
                width: 100%;
                max-width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

/* Cart */
.checkout-cart-index {

    .page-main {

        .action.update {
            background-color: $black;
            box-shadow: none;
            border-radius: 2px;
            margin-top: 0;
            padding: 10px 25px;
            font-family: $rift;
            font-size: 16px;
            border: 2px solid black;
            line-height: 1;

            &:hover {
                background-color: $white;
                color: $black;
            }
        }

        .page-title {
            font-weight: 700;
            font-size: 32px;
        }

        .cart {

            .col {
                margin-top: 0;

                @media (min-width: 768px) {
                    display: table-cell !important;
                }
            }

            .product-item-name {
                margin: -3px 0;

                a {
                    font-family: $roboto !important;
                    font-weight: 700;
                    text-transform: none;
                }
            }

            .item-options {
                margin-top: 0;

                dd {
                    color: $body-color;

                    &:not(:last-child)::after {
                        content: ',';
                        padding-right: 5px;
                    }
                }
            }

            .product-item-details,
            .price.price {
                font-size: 13px !important;
                color: #333;
            }
        }

        .checkout-methods-items {

            .action.primary.checkout {
                border-radius: 2px;
                padding: 18px 10px;
                background-color: $orange;
                border-color: $orange;
                font-family: $rift;
                font-size: 16px;

                &:hover {
                    background-color: $black;
                    border-color: $black;
                }
            }
        }

        .cart-summary {
            background: none;
            border: 2px solid #000000;
            box-shadow: 0px 3px 6px #00000029;
            padding: 0;

            &::before {
                display: none;
            }

            .summary.title {
                margin: 0;
                padding: 6px 25px;
                background: black;
                border: none;
                font-family: $rift;
                font-weight: 700;
                font-size: 32px;
                color: $white;

                &::after {
                    display: none;
                }
            }


            .mark::before,
            .amount::before {
                display: none;
            }

            tr,
            th,
            td,
            .cart-totals {
                background: none;
                border: none !important;
            }
            th,
            td {

                @media (max-width: 767px) {
                    padding-bottom: 3px !important;
                    font-size: 13px;

                    strong {
                        font-size: inherit;
                    }
                }
            }

            .totals-tax-summary {
                display: none;
            }

            .cart-totals,
            .checkout-methods-items,
            .accept {
                padding-left: 27px;
                padding-right: 27px;

                @media (max-width: 767px) {
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }

            .cart-totals {
                padding-top: 27px;

                @media (max-width: 767px) {
                    padding-top: 18px;
                }

                th,
                td {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .checkout-methods-items {
                padding-bottom: 28px;
            }
        }
    }

    .block.crosssell {

        @media (max-width: 767px) {
            display: none;
        }

        @media (min-width: 768px) {
            width: 100%;
            float: none;
            box-sizing: border-box;

            .block-title {
                text-align: center;

                h2 {
                    font-size: 32px;
                    line-height: 38px;
                    font-family: $rift;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $black;
                }
            }
        }
    }
}



/* Login */
.authentication-wrapper{
    height: 258px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
    max-width: none;
    .fieldset{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 13px;
        .field{
            flex-grow: 1;
            margin: 13px 11px 0 0;
            .control{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: #ffffff;
                border: 1px solid #dddddd;
                border-radius: 2px;
                .wrap{
                    width: 40px;
                    height: 40px;
                    margin-right: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                input{
                    border-top: 0 !important;
                    border-bottom: 0 !important;
                    border-left: 1px solid #dddddd !important;
                    border-right: 0 !important;
                    width: 220px;
                    height: 40px;
                    font-family: $roboto;
                    font-size: 13px;
                    line-height: 22px;
                }
            }
        }
    }
    button{
        background: $black;
        color: $white;
        font-family: $rift;
        font-weight: bold;
        border-color: $black;
        font-size: 16px;
        height: 40px;
        border-width: 2px;
        box-sizing: border-box;
        padding: 8px 30px;
        &:hover{
            background: $white;
            color: $orange;
            border-color: $orange;
        }
    }
    .secondary{
        a{
            text-decoration: underline;
            font-family: $roboto;
            font-size: 13px;
            line-height: 22px;
        }
    }
}




/* Product Item */
.opc-block-summary {
    padding: 0 0 2.8rem;
    border: 2px solid $black;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0px 3px 6px #00000029;

    .minicart-items-wrapper{
        margin: 0 !important;
        padding: 0 !important;
    }
    > .wrap{
        padding: 5px 24px;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font-family: $rift;
            font-weight: bold;
            font-size: 32px !important;
            line-height: 38px !important;
            color: $white;

            @media (max-width: 767px) {
                font-size: 22px!important;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .items-in-cart{
        padding: 28px 23px;
        margin: 0;
    }
    .product-item{
        border-bottom: 1px solid #DDDDDD !important;
        // &:first-child{ border-top: 0 !important; }
        .product-item-name-block{
            .sku{
                font-family: $roboto;
                font-size: 13px;
                line-height: 18px;
            }
            .product-item-name{
                margin: 0;
                font-family: $roboto;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
            }
            .wrap{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .subtotal{
                    width: 80px;
                    text-align: left;
                    span{
                        font-family: $roboto;
                        font-weight: bold;
                        font-size: 13px;
                        line-height: 18px;
                        .price{ margin: 0 10px 0 0; }
                    }
                    .price-including-tax{ margin: 0 !important; }
                }
                .details-qty{
                    margin: 0;
                    .label span{
                        font-weight: bold;
                    }
                    span{
                        font-family: $roboto;
                        font-size: 13px;
                        line-height: 22px;
                    }
                }
            }
        }
        .content{
            display: block;
        }
    }
    .product-item{
        padding-top: 14.5px !important;
        padding-bottom: 14.5px !important;
        border-color: #DDDDDD;
        &:last-child{
            border: 0;
        }
        .product{
            display: flex;
            align-items: top;
            justify-content: flex-start;
            .product-image-container,
            .product-image-wrapper{
                width: 89px !important;
                height: 89px !important;

            }
            .product-item-details{
                padding-left: 21px !important;
                .item-options{
                display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0 !important;
                    .values{
                        padding-right: 5px;
                        font-family: $roboto;
                        font-size: 13px;
                        line-height: 22px;
                        margin: 0 !important;
                        &:after{
                            content: ',';
                        }
                        &:last-child:after{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .table-totals{
        order: 8;
        padding: 0 23px;
        border-top: 0;
        *{ border: 0 !important; }
    }
    .totals{
        *{
            font-family: $roboto;
            font-size: 13px !important;
            line-height: 20px !important;
            text-transform: none !important;
            padding: 0 !important;
        }
        th,
        span.price{
            line-height: 30px !important;
        }
    }
    .totals-tax-summary .amount .price{
        padding-left: 25px;
        padding-right: 0 !important;
        &:after{
            display: none !important;
        }
    }
    .totals-tax-details {
        display: none !important;
    }
    .place-order-wrap{
        order: 9;
        margin-top: 30px;
        padding: 0 23px;
        .place-order{
            height: 60px;
            cursor: pointer;
            padding: 20px;
            display: block;
            text-align: center;
            background-color: $orange;
            width: 100%;
            transition: background-color 0.2s linear;
            span{
                font-family: $rift;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: $white;
            }
            &:hover{ background-color: $black; }
        }
        p{
            margin-top: 17px;
            margin-bottom: 35px;
            text-align: center;
            // a{ display: block; }
        }
        p,
        p a{
            font-family: $roboto;
            font-size: 12px;
            line-height: 18px;
        }
        p a {
            display: block;
        }
    }
}
#co-shipping-form{
    h3{
    }
}
#shipping-new-address-form,
.billing-address-same-as-shipping-block fieldset{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0;
    .field{
        margin-bottom: 10px;
        width: calc(50% - 8px);
        &:nth-child(3){
            width: 100%;
        }
    }
    fieldset{
        width: 100% !important;
        .field{
            width: 100% !important;
        }
    }
}
@media( max-width: 767px ){
    #shipping-new-address-form{
        .field{
            width: 100%;
        }
    }
}

.payment-method{
    margin-top: 18px !important;
    margin-bottom: 18px !important;
    border-color: transparent !important;
    .payment-method-title{
        position: relative;
        padding: 0;
        border: 2px solid $black !important;
        border-radius: 2px;
        box-shadow: 0 3px 6px #00000029;
        transition: border 0.2s linear;
        &:hover{
            border: 2px solid $orange !important;
        }
        input{ display: none; }
        label{
            display: flex !important;
            align-items: center;
            padding: 16px 28px;
            margin-top: 8px !important;
            svg{
                margin-right: 10px;
            }
            cursor: pointer;
            span{
                font-family: $roboto;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
            }
            span.title + span{
                font-weight: 500 !important;
            }
        }
    }
    &._active{
        .payment-method-title{
            border: 2px solid $orange !important;
        }
    }
    .payment-method-content{
        padding: 38px 30px;
        background-color: #eeeeee;
    }
}
#checkout-step-payment{
    #co-payment-form .fieldset{
        display: flex;
        flex-direction: column;
        .billing-address-details{ display: none; }

        .action-update {
            margin-right: 0 !important;

            @media (max-width: 767px) {
                margin: 0 !important;
            }
        }
    }
}





/* Mobile Responsiveness */
@media(max-width: 1000px){
    .opc-wrapper{
        .form-login,
        .form-shipping-address,
        .methods-shipping{
            background-color: $white;
        }
    }
    body.page-layout-checkout{
        .page-main{
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
        .continue-banner{
            .container{
                width: 100%;
                margin: 0 33px !important;
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                a{
                    line-height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            svg{
                height: 26px !important;
            }
            span{ display: none; }
            h1{
                display: block;
                font-family: $rift;
                font-weight: bold;
                font-size: 30px;
                line-height: 36px;
                color: $white;
                margin: auto;
            }
        }
        .page-wrapper{
            background-color: $white;
            background: linear-gradient(180deg, rgba(221,221,221,0.5) 0%, rgba(221,221,221,0.5) 390px, rgba(255,255,255,1) 390px, rgba(255,255,255,1) 100%);
        }
        .authentication-wrapper{
            width: 100%;
            height: 310px;
            h3{
                margin-bottom: 2px !important;
            }
            form{ width: 100%; }
            .fieldset{
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;
                .field{
                    margin: 0 !important;
                    width: 100% !important;
                    label{
                        margin-top: 8px;
                        margin-bottom: 0 !important;
                    }
                    .control{
                        input{
                            width: 100%;
                        }
                    }
                }
            }
            button[type=submit]{
                position: absolute;
                right: 0;
                bottom: 20px;
            }
            .actions-toolbar{
                margin-top: 30px;
                width: fit-content;
                .secondary{
                    width: fit-content;
                }
            }
        }
        #shipping{
            margin-top: 30px;
            // margin-bottom: 30px;
        }
        .step-title{
            display: block !important;
        }
    }



    .opc-estimated-wrapper{ display: none !important; }
    .opc-wrapper,
    aside.opc-sidebar{
        float: none;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100%;
        max-width: 660px !important;
    }
    .opc-wrapper{
        padding-right: 0;
    }
    aside.opc-sidebar{
        position: relative;
        visibility: visible;
        opacity: 1;
        left: 0;
        .modal-inner-wrap{
            position: relative !important;
            transform: unset !important;
            .action-close{ display: none; }
        }
        .opc-block-summary{ margin: 0 auto; }
    }
    #co-shipping-method-form{
        background-color: $white;
        padding: 10px 0 !important;
        margin: 0 !important;
        box-sizing: border-box;
    }
    .free-shipping-reminder{
        width: calc(100% - 20px) !important;
        padding: 17px 10px !important;
        p{
            width: calc(100% - 52px);
        }
    }
}

body.page-layout-checkout{
    .field .control._with-tooltip{
        input{
            width: 100%;
            margin: 0;
        }
        .field-tooltip{ display: none; }
    }
    .continue-banner{
        position: relative;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-color: $black;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        a{
            text-decoration: none;
            span{
                color: $white;
                margin-left: 18px;
                font-size: 13px;
                line-height: 18px;
            }
            svg{
                path{ fill: $white; }
            }
            &:hover{
                span{
                    color: $orange;
                }
                svg{
                    path{ fill: $orange; }
                }
            }
        }
        svg{
            height: 10px;
        }
    }
    .minicart-items{
        display: block !important;
    }
    .opc-sidebar{
        // margin: 54px 0 20px 0 !important;
    }
    label{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
        span{ line-height: 45px; }
        span.check{
            border: 1px solid #ddd;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            svg{
                path{ fill: transparent; }
            }
        }
    }
    input:checked + label{
        span.check{
            svg{
                path{ fill: $black; }
            }
        }
    }
    input[type=checkbox]{ display: none; }
    .opc-wrapper {
        max-width: 660px;
        padding-right: 130px;
        .form-login,
        .form-shipping-address{
            width: 100%;
        }
    }
    label[for=same-billing]{
        font-family: $roboto;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
    }
    .checkout-payment-method{
        .payment-methods{
            margin: 0 !important;
        }
        .field-select-billing,
        .billing-address-form{
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 0;
            form{
                fieldset{
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                .field{
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10){
                        width: calc(50% - 8px) !important;
                        @media (max-width: 767px){
                            width: 100% !important;
                        }
                    }
                }
                fieldset,
                fieldset.street{
                    width: 100% !important;
                    .field{
                        width: 100% !important;
                    }
                }
            }
            .field:nth-child(1),
            .field:nth-child(2){
                width: calc(50% - 8px);
                box-sizing: border-box;
            }
        }
    }
    #shipping-method-buttons-container{
        display: none;
    }
    .checkout-billing-address{
        .field.choice{
            display: none;
        }

    }
    #co-payment-form{
        h3{
            margin-bottom: 0 !important;
        }
    }
    #shipping{
        margin-top: 20px;
    }
}
.checkout-payment-method{
    .payment-,
    .payment-option{
        margin: 0 !important;
    }
    h3{
        *{
            color: $black;
        }
    }
    #customerbalance-placer{
        .payment-option-title{
            background-color: $black;
            color: $white !important;
            font-weight: 700;
            font-size: 32px !important;
            line-height: 38px !important;
            padding: 5px 24px !important;
            font-family: $rift;
            @media (max-width: 767px) {
                font-size: 22px !important;
            }
        }
    }
    #afterpaypayovertime-method{
        label{
            display: flex;
            .payment-icon{
                max-width: 114px;
                height: auto;
                order: 0;
            }
            &:before{
                content: 'Afterpay';
                display: block;
                color: $black;
                font-size: 13px;
                line-height: 15px;
                font-family: $roboto;
                font-weight: bold;
                order: 1;
                margin-left: 20px;
            }
        }
        li{
            span{
                color: $black;
            }
        }
        .afterpay-checkout-redirect{ background: transparent; }
    }
    // .button {
    //     font-family: $rift;
    //     font-weight: bold;
    //     font-size: 16px;
    //     line-height: 20px;
    //     padding: 10px 30px;
    //     text-decoration: none !important;
    //     transition: all 0.2s linear;
    //     width: fit-content;
    //     border-radius: 2px;
    //     border-color: transparent;
    //     background-color: $orange;
    //     color: $white;
    //     &:hover{
    //         backgrOund-color: $black;
    //         color: $white;
    //     }
    // }
}




#checkout-step-shipping_method{
    table tbody{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media ( min-width: 1001px ){
            align-items: center;
        }
        @media ( max-width: 1000px ){
            flex-direction: column;
            align-items: flex-start;
        }
        tr{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            position: relative;
            width: 100% !important;
            margin-top: 9px;
            margin-bottom: 9px;
            padding: 11px 15px;
            box-sizing: border-box;
            line-height: 1;
            @media ( max-width: 1000px ){
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 13px;
            }
            td.col-price{
                /*order: 2;*/
                margin: 0;
                padding: 0 4px;
                font-weight: bold;
                border: 0 !important;
            }
            td.col-method{
                font-weight: bold;
                order: 1;
                position: static !important;
                padding: 0 0 0 10px;
                display: block !important;
                border: 0 !important;
            }
            td.col-carrier{
                order: 3;
                margin: 0 !important;
                padding: 0 !important;
                border: 0 !important;
            }
            td.icon{
                order: 0;
                padding: 0 !important;
                border: 0 !important;
                .collect{ display: none; }
            }
            td.col-price + td.col-method:after{
                content: ':';
            }
            input[type="radio"]{
                width: 0;
                height: 0;
                order: 1;
                &:after{
                    content: '';
                }
                & + .border{
                    background-color: transparent !important;
                    border: 2px solid $black;
                    border-radius: 2px;
                    width: 100% !important;
                    height: 100% !important;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    position: absolute;
                    order: 0;
                    cursor: pointer;
                    box-sizing: border-box;
                    &:after{
                        display: none !important;
                    }
                }
                &:checked{
                    & + .border{

                        background-color: transparent !important;
                        border: 2px solid $orange;
                        box-shadow: 0px 3px 6px #00000029;
                        &:after{
                            background-color: $white;
                        }
                    }
                }
            }
            &:hover{
                input[type="radio"] + .border{
                    border-color: $orange !important;
                }
            }
            .col-method + .col{
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                border: 0;
                width:auto;
                .wrap{
                    display: flex !important;
                    align-items: center;
                    justify-content: flex-start;
                    .col-price{
                        font-weight: bold;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

.payment-option-title{
    background-color: $black;
        padding: 0 !important;
    .action-toggle{
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        span{
            color: $white;
            font-size: 32px!important;
            line-height: 38px!important;
            padding: 5px 24px!important;
            font-weight: 700;
            font-family: rift,sans-serif;

            @media (max-width: 767px) {
                font-size: 22px!important;
            }
        }
        &:after{
            content: '' !important;
            background-image: url(../images/right.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(90deg);
            margin-right: 19px !important;
            height: 14px;
            width: 7px;
        }
    }
}
._active .action-toggle::after {
    transform: rotate(270deg);
}
.payment-option-content{
    padding: 22px 0 !important;
}

#checkout{
    max-width: 1106px !important;
    margin: 0 auto;
    button.action:not(.action-login){
        background-color: $orange;
        border: 1px solid $orange;
        border-radius: 2px;
        font-family: $rift;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 30px;
        color: $white;
        &:hover,
        &:focus{
            background-color: $black !important;
            border-color: $black !important;
        }
    }
    .opc-wrapper{
        width: 100%;
        max-width: 660px;
        padding: 0;
        form,
        table{
            width: 100%;
            max-width: 100%;
        }
    }
    #shipping{
        h3{
            margin-bottom: 5px !important;
        }
        p{
            font-size: 13px;
            line-height: 22px;
            font-family: $roboto;
            color: #333333;
        }
        span.note{ display: none; }
        .form-login{
            margin: 0;
            border: 0;
            padding: 0;
        }
    }
    #checkout-step-shipping_method{
        h3{
            margin-bottom: 18px !important;

            @media (max-width: 767px) {
                font-size: 16px !important;
            }

            @media (min-width: 768px) {
                margin-top: 3rem !important;
            }
        }
    }
}

#afterpaypayovertime{
    .payment-method-note {
        h3{
            padding-bottom: 20px !important;
            text-align: center;
        }
    }
}
@media (max-width: 1000px ){
    .opc-wrapper .step-content{ margin: 0 !important; }
    .step-title{
        position: relative;
    }
}

.opc-block-shipping-information,
#giftcardaccount-placer,
.shippit-options{
    display: none !important;
}

.checkout-shipping-method.checkout-shipping-method {

    h3 {
        margin: 1rem 0 !important;
    }
}

/* Openning and closing of checkout steps */
/*
@media (max-width: 1000px ){
    .form-shipping-address,
    .checkout-shipping-method,
    .checkout-billing-address .fieldset{
        height: 49px;
        overflow: hidden;
        &.active{
            overflow: visible !important;
            height: fit-content !important;
        }

    }
    .step-title{
        position: relative;
        &:after{
            color: #fff!important;
            display: block;
            font-size: 28px!important;
            line-height: 45px!important;
            margin: 0 20px!important;
            content: '\f107';
            font-family: 'FontAwesome';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            speak: none;
            text-align: center;
            overflow: visible;
            position: absolute;
            transform: rotate(0deg);
            right: 0;
        }
    }
    .active{
        .step-title{
            &:after{
                transform: rotate(180deg);
            }
        }
    }
}
*/

.checkout-onepage-success {

    .page-main {
        margin-top: 7rem;
        margin-bottom: 0;
    }

    h1 {
        margin-bottom: 2rem;
        font-weight: 700;
        color: $black;
    }

    .main {

        p {
            margin-bottom: 0;
        }

        a {
            margin-top: 5px !important;

            &:focus {
                background-color: $black;
                border-color: $black;
            }
        }
    }
}

#checkout-step-shipping_method table tbody tr {

    td.col-title {
        width: 100%;
        display: flex;
        padding-left: 0;

        .col-carrier:after {
            content: ':';
            padding-right: 5px;
        }

        .col-price {
            font-weight: 400 !important;
        }
    }

    .col-title:before {
        content: '' !important;
        background-image: url(../images/icon-delivery.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 32px;
        width: 32px;
    }

    .col-method-title {
        display: flex;
        align-items: center;
    }

    &#shipping_method_amstorepickup_amstorepickup {

        .col-carrier:after,
        .col-price,
        .col-title .col-method {
            display: none !important;
        }

        .col-title:before {
            background-image: url(../images/icon-basket.svg);
        }
    }
}

#checkout-step-shipping_method .free-shipping-reminder {
    display: none !important;
}
