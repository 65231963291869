
body.account,
body.customer-account-login {

    @media (min-width: 768px) {
        #maincontent {
            margin-top: 50px;
        }

        &.page-layout-2columns-left .column.main {
            width: 70.7%;
            margin-top: 0;
        }

        &.page-layout-2columns-left .sidebar-main,
        &.page-layout-2columns-left .sidebar-additional {
            width: 27.3%;
        }
    }

    @media (max-width: 767px) {
        .page-main {
            padding-left: 20px;
            padding-right: 20px;
        }

        .columns .sidebar-main {
            order: -1;
        }
    }

    .block-collapsible-nav {

        border: solid 2px #000;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 20px !important;
        box-sizing: border-box;

        .title {
            position: relative;
            width: auto;
            display: block;
            height: auto;
            margin: 0;
            clip: none;
            background-color: #000;
            padding: 5px 24px;

            strong {
                font-family: $rift;
                font-size: 32px;
                line-height: 38px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
            }
        }

        .content {
            background-color: #fff;
        }

        .nav.item {
            font-size: 13px;

            &.current {
                strong {
                    font-weight: 700;
                    color: $orange;
                    padding-left: 30px;
                }
            }

            a {
                color: #000;
                padding-left: 30px;
                &:hover {
                    background: none;
                    color: $orange;
                }
            }

        }

        .nav.item:last-child {
            margin-top: 20px;

            @media (min-width: 768px) {
                margin-top: 70px;
            }

            a {
                display: inline-block;
                width: auto;
                padding: 8px 46px;
                background-color: #000;
                border-radius: 2px;
                border: 2px solid black;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                font-family: $rift;
                color: #fff;
                margin-left: 30px;

                &:hover {
                    background-color: $white;
                    color: $black;
                    border-color: $black;
                }
            }

        }

        @media (max-width: 767px) {
            position: relative;

            .title {
                border-top: 0;
                border-bottom: 0;

                &:after {
                    color: #fff;
                    font-size: 20px;
                    line-height: 16px;
                }
            }
        }

        .content {
            padding: 21px 0 30px;
        }
    }

    .page-main.page-main {

        .page-title-wrapper .page-title {
            font-size: 32px;
            line-height: 38px;
            font-family: $rift;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 5px;
            margin-bottom: 40px;
        }

        .fieldset > .legend,
        .block:not(.widget) .block-title {
            display: flex;
            justify-content: space-between;
            vertical-align: middle;
            padding-bottom: 3px;

            & > strong,
            & > span {
                font-size: 22px;
                line-height: 28px;
                font-family: $rift;
                font-weight: 700;
                text-transform: uppercase;
            }

            @media (max-width: 767px) {
                &:before,
                &:after {
                    display: none;
                }
            }
        }

        .block:not(.widget) .block-title > a.action {
            text-decoration: underline;
            font-size: 13px;
        }

        .block:not(.widget) .block-content {
            font-family: $roboto;
            font-size: 13px;

            .box-title span {
                font-size: 13px !important;
            }

            .box-content {
                font-size: 13px;
            }

            a.action {
                text-decoration: underline;
                font-size: 13px;
            }
        }
    }

    .block-addresses-list address,
    .block-addresses-list .items.addresses > .item {
        font-size: 13px;
    }

    .block-dashboard-addresses {
        .box-actions {
            display: none;
        }
    }

    .actions-toolbar > .primary,
    .actions-toolbar > .secondary {
        text-align: left;
    }

    .actions-toolbar,
    .address-book-actions {
        button.action {
            color: #fff;
            background-color: #000;
            border-color: #000;
            font-family: $rift;
            font-size: 16px;
            font-weight: 700;
            padding: 10px 47px;
            width: auto;

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
    }

    .form-edit-account {
        .fieldset {
            float: none;
            width: 100%;
        }

        .fieldset.password {
            display: block !important;

        }
    }

    .login-container {
        @media (min-width: 768px) {
            display: flex;
        }
        justify-content: space-between;
        padding-top: 60px;
        padding-bottom: 60px;

        &:before,
        &:after {
            display: none;
        }

        .login-tabs {
            @media (min-width: 768px) {
                max-width: 600px;
                width: 100%;
                margin: 0 auto;
            }

            .tab-headers {
                display: flex;

                .item.title {
                    width: 50%;
                    text-align: center;
                    background-color: #000;

                    &.active,
                    &:hover {
                        background-color: $orange;
                    }

                    a {
                        display: inline-block;
                        font-family: $rift;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 50px;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }

            .tab-content {
                background-color: #fff;
                border: solid $orange 2px;
                padding: 40px 50px;
            }

            #password-error {
                line-height: 1.3;
            }
        }

        .fieldset:after {
            display: none;
        }

        .block {
            margin-bottom: 0;
            display: block;
            float: none;
            width: 100%;

            .block-title {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .fieldset > .field > .control {
            width: 100%;
        }

        .fieldset > .field > .label {
            margin: 0;
        }

        .actions-toolbar {
            display: flex;
            justify-content: space-between;

            .primary .action {
                margin-right: 0;
            }

            &:before,
            &:after {
                display: none;
            }
        }
    }
}

body.customer-account-login {

    .page-wrapper {
        background-color: #000;
    }

    .page-title-wrapper {
        display: none;
    }

    .page-main.page-main .block:not(.widget) .block-title > strong {
        font-size: 32px;
        line-height: 38px;
    }

    .block-new-customer {

        .new-customer-message {

            ul {
                padding: 0;
                list-style: none;
                margin-top: 30px;
            }

            li {
                font-size: 16px;
                line-height: 20px;
                font-family: $rift;
                padding-left: 33px;
                margin-bottom: 15px;
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 18px 14px;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18.344' height='13.794' viewBox='0 0 18.344 13.794'><g transform='translate(0 -63.498)'><g transform='translate(0 63.498)'><path d='M18.076,63.656a.717.717,0,0,0-1.007.11L7.534,75.633a.717.717,0,0,1-1.015.032L1.212,70.577A.717.717,0,1,0,.22,71.611L5.53,76.7a2.131,2.131,0,0,0,1.479.592l.089,0a2.132,2.132,0,0,0,1.515-.714l.025-.029,9.549-11.884A.717.717,0,0,0,18.076,63.656Z' transform='translate(0 -63.498)'/></g></g></svg>");
            }
        }

        .actions-toolbar {
            a.action.action.action.action {
                padding: 20px 77px;
                background-color: $orange;
                color: #fff;
                text-decoration: none;
                font-family: $rift;
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                border-radius: 2px;
                border-color: $orange;

                &:hover {
                    background-color: #fff;
                    color: $orange;
                }
            }
        }
    }

    .block-customer-register {

        .form.create.account {
            min-width: 0;
            width: 100%;
        }

        fieldset {
            margin-bottom: 25px;

            .field.field-name-firstname {
                width: 48%;
                float: left;
                margin-bottom: 0;
            }

            .field.field-name-lastname {
                width: 48%;
                float: right;
            }
        }

        .actions-toolbar button.action {
            padding: 20px 40px;
            width: 100%;
            background-color: $orange;
            border-color: $orange;

            .primary {
                width: 100%;
            }

            &:hover {
                background-color: #fff;
                color: $orange;
            }
        }
    }
}

.block-dashboard-addresses {

    .box-title > span {
        font-size: 13px !important;
    }
}

body.sales-order-history .table-wrapper.orders-history,
.block-dashboard-orders .table-wrapper {
    margin-top: 0;
    border-bottom: none;
    border-top: none;

    tr td:first-child,
    tr th:first-child {
        padding-left: 0;
    }

    .table > tbody > tr > th,
    .table > tbody > tr > td {
        border-top: none;
    }

    .table > tbody > tr > td {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .status-icon {
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 17px;
        background-color: $orange;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        margin-right: 5px;

        @media (max-width: 639px) {
            display: none;
        }

        svg {
            display: inline-block;
            vertical-align: middle;
            fill: #fff;
            color: #fff;
        }

        &.status-complete {
            svg {
                padding-bottom: 2px;
            }
        }
    }

    @media (min-width: 640px) {
        .table .mobile-action {
            display: none;
        }
    }

    @media (max-width: 639px) {
        .table > tbody > tr td[data-th]:before,
        .table > tbody > tr th[data-th]:before {
            content: attr(data-th) !important;
            width: 100px;
            font-weight: 700;
        }

        .table.table.table > tbody > tr {
            margin-bottom: 15px;

            td {
                padding: 0;
            }
        }

        .table > tbody > tr td.col.actions {
            display: none;
        }

        .table > tbody > tr td.col.id {
            display: flex;
            justify-content: space-between;

            span.id-span {
                display: inline-block;
                background-color: #000;
                color: #fff;
                padding: 3px 5px;
            }

            .mobile-action {
                text-decoration: underline;
            }

            &[data-th]:before {
                display: none;
            }
        }
    }
}

body.customer-account-edit {

    .page-title-wrapper {
        margin-bottom: 20px;
    }

    .form-edit-account {
        .fieldset .field.choice.custom-radio label.label {
            font-weight: 700;
        }
    }
}

body.sales-order-history {

    .order-history-title {
        margin-bottom: 0;
    }

    .table-order-items {
        font-size: 13px;

        .col.actions a {
            text-decoration: underline;
        }
    }
}

body.customer-account-edit,
body.customer-address-form {

    .form-edit-account,
    .form-address-edit {
        font-size: 13px;

        .fieldset {
            float: none;
            width: 100%;

            @media (min-width: 768px) {
                .field {
                    width: 70%;

                    .nested .field {
                        width: 100%;
                    }
                }
            }
        }

        .actions-toolbar {
            display: flex;
            justify-content: flex-end;
            @media (min-width: 768px) {
                width: 70%;
            }

            .primary .action {
                margin-right: 0;
                font-size: 16px;
                line-height: 20px;
                padding: 10px 40px;
                border-radius: 2px;
            }
        }
    }
}

body.account.wishlist-index-index {

    .actions-toolbar {
        display: none;
    }

    .toolbar.wishlist-toolbar {

        .limiter,
        .toolbar-amount {
            display: none;
        }
    }
}

body.customer-account-logoutsuccess {
    h1.page-title {
        font-family: $rift;
        font-weight: 700;
        margin-top: 30px;
    }
}

body.sales-order-view {
    .order-links .item strong {
        color: #fff;
        font-weight: 700;
    }

    .product-item-name,
    .product-item-link,
    .price-final_price {
        font-size: 14px;
        font-family: $rift;
    }

    .order-details-items .col.price,
    .order-details-items .col.subtotal {
        .price,
        .price-including-tax {
            font-size: 14px;
        }
    }
}
