.breadcrumbs{
    display: block;
    width: 100% !important;
    max-width: calc(100% - 40px) !important;
    padding: 0 20px !important;
    margin-top: 25px;
    margin-bottom: 25px;
    box-sizing: border-box;
    .item.item {
        &:not(:last-child):after{
            content: '/';
            font-family: $roboto;
            margin: 0;

            @media (max-width: 767px) {
                line-height: 20px;
            }
        }

        @media (max-width: 767px) {
            font-size: 11px;
        }
    }
    a{
        color: $orange !important;
        text-decoration: underline !important;
    }
    a,
    strong{
        font-family: $roboto;
        font-weight: normal;
    }
}
