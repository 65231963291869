.store-pickup-index {

    .page-main {
        padding: 0;
    }

    .columns .column.main {
        padding-bottom: 0;
    }

    .instagram-holder {
        display: none;
    }
}


.store-confirm {
    display: flex;
    justify-content: start;
    overflow: auto;
    width: 100%;
    background-color: $white;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
    }

    .store-pickup-footer-mobile {
        margin-bottom: 0;
        background-color: $black;
        color: $white;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        padding: 3.5rem 2rem;

        @media (min-width: 768px) {
            display: none;
        }
    }

    &,
    & * {
        box-sizing: border-box;
    }

    &.store-thanks .image-block {
        width: 100%;
        height: 100%;

        @media (min-width: 768px) {
            width: 100%;
        }

        &-logo {
            margin-bottom: auto;
        }

        &-title {
            font-size: 3.9rem;
            letter-spacing: 7.8px;

            @media (min-width: 768px) {
                font-size: 4.4rem;
                letter-spacing: 8.8px;
            }
        }

        &-text {
            max-width: 100%;
            margin-bottom: auto;
            font-size: 1.8rem;

            @media (min-width: 768px) {
                font-size: 2rem;
            }
        }

        &-footer {
            display: block;

            .image-block-menu {

                @media (max-width: 767.98px) {
                    display: none;
                }
            }
        }
    }

    .image-block {
        background-image: url('../images/store-confirm-wide.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8vh 2.5rem 3rem;
        text-align: center;
        color: $white;



        @media (max-width: 767.98px) {
            min-height: 500px;
        }

        @media (min-width: 768px) {
            width: 40%;
        }

        @media (min-width: 1024px) {
            padding: 11vh 3rem 5rem;
        }

        &-link-home {
            display: none;

            @media (min-width: 768px) {
                display: block;
                position: absolute;
                top: 2.7rem;
                right: 2.7rem;
                width: 41px;
                height: 41px;
                z-index: 2;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='41'%3E%3Ccircle cx='20.5' cy='20.5' r='20.5' fill='%23fff'/%3E%3Cpath d='m31.625 19.11-10.613-8.5-10.636 8.5 1.249 1.562L13 19.573v10.318h16v-10.32l1.375 1.1zM19 27.891v-6h4v6zm8 0h-2v-8h-8v8h-2v-9.916l6.011-4.8L27 17.969z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &-logo {
            background-image: url('../images/logo-fox-racing.svg');
            background-size: contain;
            background-repeat: no-repeat;
            width: 165px;
            height: 38px;
            position: relative;
            z-index: 2;
            margin-bottom: 3.8rem;
            flex-shrink: 0;
        }

        &-title {
            font-size: 3rem;
            color: $newGreen;
            font-weight: 600;
            letter-spacing: 1rem;
            line-height: 1.1;
            text-align: center;
            z-index: 2;
            position: relative;
            max-width: 40rem;
            margin-bottom: 2.5rem;

            @media (min-width: 1024px) {
                font-size: 4.4rem;
            }
        }
        &-text {
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
            z-index: 2;
            position: relative;
            max-width: 40rem;
        }

        &-overlay {
            background: transparent linear-gradient(180deg, rgba($black, 0.2) 0%, rgba($black, 0.9) 100%) 0% 0% no-repeat padding-box;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &-footer {
            display: none;
            z-index: 2;
            position: relative;
            width: 100%;
            margin-top: auto;

            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 1.5rem;
            }

            @media (min-width: 768px) {
                display: block;
            }
        }

        &-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            z-index: 2;
            position: relative;
            list-style: none;
            margin-top: auto;
            margin-bottom: 4.3rem;
            padding-left: 0;
            font-size: 1.3rem;

            .image-block-menu-item {
                margin: 0 1.1rem;

                @media (min-width: 1024px) {
                    margin: 0 2.4rem;
                }
            }
        }

        p,
        a {
            color: inherit;
        }
    }

    .form-block {
        width: 100%;
        position: relative;
        z-index: 1;

        @media (min-width: 768px) {
            width: 60%;
            overflow-y: auto;
        }

        form {
            padding: 3.5rem 2rem;

            @media (min-width: 768px) {
                padding-left: 5rem;
                padding-right: 5rem;
            }

            @media (min-width: 1024px) {
                box-sizing: content-box;
                max-width: 500px;
                padding: 4.9rem 11rem;
            }

            .btn {
                width: 100%;
                margin-top: 1.4rem;
                color: $newGreen;
                font-size: 2.2rem;
                border-width: 1px;
                height: 50px;

                &:hover {
                    color: $black;
                }

                &:focus {
                    box-shadow: 0 0 0 0.25rem rgba($black, 0.15);
                }
            }
        }

        fieldset {
            margin-bottom: 2rem;
            padding: 0;
            border: none;
        }

        .customer-details {
            margin-bottom: 1rem;
        }

        legend {
            margin-bottom: 1rem;
            font-family: $rift;
            font-weight: 700;
            font-size: 2.2rem;
            color: black;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -10px;
            margin-right: -10px;
        }

        .col-sm-6 {
            padding-left: 10px;
            padding-right: 10px;

            @media (max-width: 767.98px) {
                width: 100%;
            }
        }

        .form-check {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }

        label {
            font-weight: 700;
            font-size: 1.2rem;

            .required {
                color: $red;
            }
        }

        input {
            border-color: #A3A3A3;

            &[type='text'],
            &[type='email'] {
                height: 40px;
                margin-bottom: 1rem;
                padding-left: 1.4rem;
                padding-right: 4rem;

                &[readonly] {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='m10.28 16.357-5.03-5.131 1.071-1.05 3.97 4.049 6.964-6.964 1.061 1.06z' fill='%23a3a3a3'/%3E%3C/svg%3E");
                    background-position: right 1rem center;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-color: #EDEDED;
                    color: #555;
                }
            }

            &:not([readonly]):focus {
                box-shadow: 0 0 0 0.25rem rgba($black, 0.15);
            }

            &[type='checkbox'] {
                flex-shrink: 0;
                appearance: none;
                border: 1px solid #C2C2C2;
                border-radius: 0.2rem;
                width: 40px;
                height: 40px;
                margin-top: 0;
                margin-right: 1rem;

                &:checked {
                    border-color: $black;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='m10.28 16.357-5.03-5.131 1.071-1.05 3.97 4.049 6.964-6.964 1.061 1.06z' fill='%23000'/%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 24px 24px;
                }
            }
        }
    }

    .image-block-call.image-block-call {
        display: inline-block;
        margin-top: 1rem;
        font-family: $rift;
        font-size: 2.9rem;
        text-decoration: none;
        font-weight: 700;
        letter-spacing: 1.45px;
        color: $newGreen;

        &:hover {
            text-decoration: underline;
        }
    }
}