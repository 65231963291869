.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: black;
  visibility: hidden;
  background-color: white;
  background-clip: padding-box;
  outline: 0;
  transition: all 0.3s;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.121rem 2.5rem;

  @media (min-width: 1024px) {
    padding: 2rem 3rem;
  }

  .btn-close {
    padding: (2rem * .5) (2rem * .5);
    margin-top: 2rem * -.5;
    margin-right: 2rem * -.5;
    margin-bottom: 2rem * -.5;
    border: none;

    @media (max-width: 1023.98px) {
      margin-left: auto;
    }
  }
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 2rem 2rem;

  @media (min-width: 1024px) {
    overflow-y: auto;
  }
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: min-content;
  max-height: 100%;
  border: none;
  transform: translateY(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  bottom: 0;
  border: none;
  transform: translateX(100%);
  max-width: 414px;
  width: 100%;
}

.offcanvas.show {
  transform: none;
  visibility: visible;

  @media (max-width: 1023.98px) {
    overflow-y: auto;
  }
}
