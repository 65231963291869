.products-grid {
    margin-top: 0;

    // Slick Slider styles
    .slick-slider {
        padding-top: 0;
        margin-top: 0;
        position: relative !important;
        max-height: 350px;

        .slick-list {
            margin-left: 25px;
            margin-right: 25px;
        }

        .slick-arrow {
            height: 100%;
            top: 0;
            margin: 0;
            padding: 0;
            width: 50px;
            z-index: 1;
            background: none;

            &.slick-next {
                right: -25px;
                top:50%;
            }

            &.slick-prev {
                left: -25px;
                transform: none;
            }

            &.slick-next::before,
            &.slick-prev::before {
                background-image: url(../images/right-dark.svg);
            }
        }
    }

    div.products.list.items.product-items {
        display: inline-block;
        width: 100%;
        margin-left: 0;
        text-align: left;

        .wrapper {
            padding: 0 17px 0 17px;
        }

        div.item.product.product-item {
            margin: 0;
            padding: 0 !important;
            width: 240px;
            //max-width: 300px !important; // 4 Items on larger screens
            margin-bottom: 25px;
            height: auto;
            text-align: left;


            &:hover {
                .product-item-info {
                    position: relative;
                    box-shadow: none;
                }
            }

            @media screen and (max-width: 767px) {
                //width: 240px !important;
            }

            .product-item-info {
                padding: 0 !important;
                border-bottom: 1px solid black !important;

                // Product Image
                .product-image-wrapper {
                    padding-bottom: 100% !important;

                    .product-image-container {
                        max-width: 240px !important;
                    }

                    img.product-image-photo {
                        height: 100%;
                    }
                }

                // Product Tags
                .product-tags {
                    height: 26px;
                    margin-bottom: 1px;
                    font-size: 16px;

                    .product-tag {
                        @media screen and (max-width: 666px) {
                            font-size: 11px;
                            width: 92%;
                            padding: 0 0 0 0;
                        }

                        display: inline-block;
                        width: 80%;
                        padding: 0 9px 0 9px;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 400;
                        color: $white;
                        font-family: $roboto;
                        border: 1px solid;
                        border-radius: 50px;
                        text-align: center;
                        background:$white;

                        &.tag-sale,
                        &.tag-hot-offer,
                        &.tag-clearance {
                            color: $red;
                        }
                    }
                }

                // The rest
                .product-item-details {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: space-between;

                    // Title
                    .product-item-name {
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding-bottom: 0;

                        .product-item-link {
                            width: 100%;
                            display: block;
                            margin: 0;
                            padding-bottom: 0;
                            font-size: 20px;
                            line-height: 24px;
                            font-family: $rift;
                            font-style: italic;
                            font-weight: 700;
                            color: #000;
                            white-space: normal;
                        }
                    }

                    // Ratings / Reviews
                    .product-rating-stars {
                        display: block;
                        width: 100%;

                        .product-reviews-summary {
                            margin-bottom: 0;
                            position: relative;
                            height: 17px;

                            &.empty {
                                font-size: 10px;
                                line-height: 1em;
                            }

                            .rating-summary {
                                margin-right: 0;
                                width: 100%;
                                display: flex;
                                flex-direction: row-reverse;

                                // The Label
                                > span.label {
                                    position: relative;
                                    display: inline-block;
                                    flex: 1;
                                    width: auto;
                                    height: auto;
                                    line-height: 19px;
                                    font-size: 13px;
                                    font-family: $roboto;
                                    margin-left: 10px;
                                }

                                // Stars Container
                                .rating-result {
                                    display: inline-block;
                                    position: relative !important;
                                    width: $starLetterWidth;

                                    &:before {
                                        font-size: 15px;
                                        letter-spacing: $starLetterSpacing;
                                        width: 100%;
                                    }

                                    > span:before {
                                        letter-spacing: $starLetterSpacing;
                                        color: $starYellow;
                                        font-size: 15px;
                                        width: $starLetterWidth;
                                    }
                                }
                            }
                        }
                    }

                    // Bottom Row
                    .product-bottom-row {
                        justify-content: space-between;
                        width: 100%;
                        display: flex;

                        // Price
                        .price-box {
                            margin: 0;
                            flex: 1;
                            width: 40%;
                            vertical-align: middle;
                            font-family: $rift;
                            font-weight: bold;
                            font-size: 16px;

                            .price {
                                font-family: $rift;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 38px;
                            }

                            .old-price {
                                display: inline;

                                .price-label {
                                    display: none;
                                }
                            }

                            .price-final_price {
                                font-family: inherit;
                            }
                        }

                        // Just because...
                        .product-item-inner {
                            display: block;
                        }

                        // Actions (Buttons)
                        .product-item-actions {
                            margin: 0;

                            .actions-primary {
                                vertical-align: middle;

                                form {
                                    display: inline-block;

                                    button.action.tocart.primary {
                                        background: none;
                                        border: none;
                                        width: 35px;
                                        height: 30px;
                                        margin: 3px 0 0 0;
                                        padding: 5px;
                                        fill: #000;
                                        transition: opacity 0.3s;

                                        &:hover {
                                            opacity: 0.5;
                                        }
                                    }
                                }
                            }

                            .actions-secondary {
                                .action {
                                    color: #000;
                                    transition: opacity 0.3s;
                                    height: 30px;
                                    width: 25px !important;
                                    border: none;
                                    padding: 0;
                                    margin-bottom: 0;
                                    line-height: 43px;
                                    float: none;

                                    &:before {
                                        content: none;
                                    }

                                    &:hover {
                                        opacity: 0.5;
                                    }

                                    svg {
                                        height: 17px;
                                        width: 17px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        &.tocompare {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.block.related {
    .block-title {

        &.title {
            margin-bottom: 35px;
        }

        h2 {
            font-weight: 700;
            color: #000;
            font-size: 32px;
            letter-spacing: 3.2px;
            text-align: center;
        }
    }
}
