
body.-amsearch-overlay-opened {
    overflow: hidden;
    touch-action: none;
}

.amsearch-overlay-block {
    background: rgba($black, 0.4);
    backdrop-filter: blur(2px);
    z-index: 98;
}

.amsearch-result-section {
    margin-top: 0;
    border-radius: 0;
    max-height: calc(90vh - 100px);

    &::before {
        content: '';
        box-shadow: inset 0px 1px 3px rgba($black, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

/* I don't know why this is needed - stuff's just out of alignment after search was put in... */
@media (min-width: 1024px) {

    header .header.content a.logo {

        &,
        svg {
            height: 100%;
        }
    }
}

.-amsearch-overlay-opened {

    .btn-search {
        display: none;
    }

    .nav-sections {
        display: none;
    }

    .header.content a.logo {
        margin-right: auto;
    }
}

.amsearch-form-block {
    display: none;
    width: 100%;

    &.-opened {
        display: flex;
    }

    .amsearch-input-wrapper + .amsearch-result-section {

        .amsearch-result-block.-preload {
            display: none;

            @media (min-width: 768px) {
                width: 15%;
            }
        }
    }

    .amsearch-input-wrapper:not(.-match) + .amsearch-result-section {
        display: none;

        .search-cms-block {

            @media (min-width: 768px) {
                width: 85%;
            }

            @media (max-width: 767.98px) {

                img {
                    width: 100%;
                }
            }
        }
    }

    .amsearch-input-wrapper {
        position: absolute;
        max-width: 455px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1023.98px) {
            display: flex;
            align-items: center;
            transform: none;
            left: 0;
            top: 0;
            background: $white;
            height: 100%;
            max-width: 100%;
            padding: 1rem 3rem;
            box-sizing: border-box;
        }

        &.-match + .amsearch-result-section {

            .search-cms-block {
                display: none;
            }
        }

        .amsearch-input {
            transition: none;
            border: none;
            border-bottom: 2px solid $black;
            background: none;
            padding-left: 0;
            font-size: 1.2rem;
            font-family: $roboto;

            @media (max-width: 1023.98px) {
                font-size: 1.6rem;
                margin-right: 55px;
            }
        }

        .amsearch-loader-block {
            width: 34px;
            height: 34px;
            background-color: #eeeeee;

            @media (max-width: 1023.98px) {
                background-color: $white;
            }

            @media (min-width: 1024px) {
                right: 0;
                margin-right: 0;
            }
        }

        .amsearch-button {

            &.-loupe {
                position: absolute !important;
                width: 1px !important;
                height: 1px !important;
                padding: 0 !important;
                margin: -1px !important;
                overflow: hidden !important;
                clip: rect(0,0,0,0) !important;
                white-space: nowrap !important;
                border: 0 !important;
            }

            &.-close {
                filter: brightness(0%);
                right: -9px;
                display: block !important;

                @media (max-width: 1023.98px) {
                    right: 39px;
                    background-size: 18px;
                }
            }
        }
    }
}

.search-cms-block {
    padding: 0 1.2rem 1.2rem 1.2rem;

    @media (min-width: 1024px) {
        padding-top: 1.2rem;
    }

    .pagebuilder-column {
        padding: 1.8rem 1.2rem 2.3rem;

        @media (max-width: 767.98px) {
            padding: 0.8rem 1.2rem 0.8rem 1.2rem;

            &:first-child {
                position: absolute;
                top: 11px;
                left: calc(50% + 3rem);
                right: 0;
                width: auto !important;
                z-index: 3;
            }
        }

        figure {
            line-height: 0;
        }
    }

    h3 {
        margin-top: 0;
        font-weight: 700;
        color: $black;
        font-size: 1.6rem;
        letter-spacing: 0.8px;

        @media (max-width: 767.98px) {
            letter-spacing: 1px;
            font-size: 2rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            font-size: 1.2rem;

            @media (max-width: 767.98px) {
                font-size: 1.6rem;
            }

            + li {
                margin-top: 0.6rem;
            }
        }
    }

    a {
        color: $body-color;
        text-decoration: none;

        &:hover {
            color: $body-color;
            text-decoration: underline;
        }

        &:visited {
            color: $body-color;
        }
    }
}

.amsearch-items-section.amsearch-items-section.amsearch-items-section {

    @media (min-width: 768px) {
        max-width: 40%;
        display: flex;

        > div {
            width: 50%;
        }
    }
}

.amsearch-result-block.-preload {
    order: -1;

    .amsearch-items-section {
        display: flex;
        padding-top: 3rem;

        &.amsearch-items-section.amsearch-items-section {
            max-width: 100%;
        }

        @media (min-width: 768px) and (max-width: 1023.98px) {
            padding-top: 1.8rem;
        }

        .amsearch-block-header {
            line-height: 2rem;
        }

        @media (max-width: 767.98px) {
            padding: 1.8rem 2.4rem 1.8rem 2.4rem;

            .amsearch-block-header {
                padding-left: 0;
            }

            .amsearch-link.amsearch-link {
                padding-left: 0;
            }
        }
    }

    .amsearch-item-container {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}

.amsearch-item-container.-recent_searches {

    .amsearch-tab-items {
        padding: 0;
        margin: 1rem 0 0 0;
    }

    .amsearch-block-header.amsearch-block-header {
        padding: 0.1rem 1.5rem;
    }

    .amsearch-item {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        display: block;

        .amsearch-link {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.-match {
            background-color: transparent;
        }
    }
}

.amsearch-item-container,
.amsearch-products-section {

    .amsearch-link.amsearch-link.amsearch-link {
        color: $body-color;
        font-family: $roboto;

        &:hover {
            color: $body-color;
            background-color: transparent;
            text-decoration: underline;
        }

        .amsearch-highlight {
            color: $body-color;
        }
    }
}

.amsearch-item-container {
    padding-left: 0.9rem;
    padding-right: 0.9rem;

    .amsearch-tab-items {
        margin-top: 1rem;
    }

    .amsearch-link.amsearch-link {
        font-size: 1.2rem;
        padding: 0 1.5rem;

        @media (max-width: 767.98px) {
            font-size: 1.6rem;
        }
    }

    .amsearch-item + .amsearch-item {
        margin-top: 0.6rem;
    }
}

.page-header .amsearch-wrapper-block {
    position: static;

    @media (max-width: 1023.98px) {
        width: auto;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }

    .amsearch-result-section .search-popup {
        max-width: 1400px;
        display: flex;
        width: 100%;
        min-width: 0;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 768px) {
            flex-wrap: nowrap;
        }

        @media (max-width: 767.98px) {
            flex-wrap: wrap;
        }
    }

    .amsearch-form-block {
        position: static;
        width: 100% !important;
    }

    &.-opened {
        width: 100% !important;
    }
}

.amsearch-block-header {
    padding: 0 1.5rem 0.2rem 1.5rem;

    .amsearch-title {
        font-family: $rift;
        font-weight: 700;
        font-size: 1.6rem;
        color: $black;
        letter-spacing: 0.8px;

        @media (max-width: 767.98px) {
            font-size: 2rem;
            letter-spacing: 1px;
        }
    }
}

.amsearch-title.pop-main-title {
    display: none;
}

.amsearch-result-block.-preload {

    @media (max-width: 767.98px) {

        .amsearch-item-container .amsearch-tab-items {
            width: 44%;
        }
    }

    .amsearch-title.pop-alt-title {
        display: none;
    }

    .amsearch-title.pop-main-title {
        display: inline-block;
    }
}

.btn-search {
    border: none !important;

    &:hover {
        background-color: transparent;
    }

    svg {

        &:last-child {
            display: none;
        }
    }

    @media (max-width: 1023.98px) {

        .-amsearch-overlay-opened & {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 99;
            transform: translateY(-50%);

            svg {

                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }
    }
}

.amsearch-products-section {
    border-top: none;

    @media (max-width: 1024px) {
        padding-top: 0;
    }

    .amsearch-block-header {
        display: none;
    }

    .amsearch-highlight {
        font-weight: 400;
    }

    .amsearch-item:hover {
        background-color: transparent;
    }

    &:not(:first-child) {
        border-left: none;
    }

    &.-grid:not(.-slider) {

        .amsearch-item {
            width: 100%;
            max-width: 100%;
            flex-wrap: nowrap;

            @media (min-width: 768px) {
                max-width: calc(50%);
            }

            @media (min-width: 1024px) {
                max-width: calc(33.333333%);
            }

            @media (max-width: 767.98px) {
                justify-content: center;

                .product-item-details {
                    width: 50%;
                }
            }

            .amsearch-image {
                width: 100px;
                margin-right: 1.5rem;
            }

            .amsearch-link {
                min-height: 38px;
            }

            .amsearch-wrapper-inner {
                min-height: 25px;
            }

            .product-item-link {
                padding-bottom: 0;
                line-height: 1.2;
            }

            .amsearch-description {
                justify-content: center;
            }

            .product-image-wrapper {
                padding-bottom: 100% !important;
            }

            .price-wrapper {
                border-bottom: none;
            }

            [data-role='priceBox'] {
                padding-bottom: 0;

                span {
                    line-height: 1.2;
                }
            }
        }
    }

    .cart-form {

        [data-role="tocart-form"]{
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .qty-wrapper {
            flex: 1 1 32%;

            input {
                height: 40px;
            }
        }

        button {
            flex: 1 1 62%;

            @media screen and (max-width: 1024px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .btn {
            &.btn-secondary {
                font-family: $rift !important;
                display: inline-block;
                padding: 8px 15px;
                margin: 0 !important;
                border-radius: 2px;
                background: $white;
                border: 2px solid $green;
                color: $green;
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
                white-space: nowrap;

                &:hover {
                    background: $green;
                    color: $white;
                }

                &.invert {
                    border: 2px solid $green;
                    color: $white;
                    background: $green;

                    &:hover {
                        background: $white;
                        color: $green;
                    }

                    &.orange {
                        border-color: $orange !important;
                        background: $orange !important;
                        &:hover {
                            background-color: $white !important;
                            color: $orange !important;
                        }
                    }
                }
            }
        }
    }
}

.amsearch-search-page.page-products .columns {
    z-index: 2;
}

.page-wrapper .jumbotron {
    z-index: 0;
}

@keyframes spinner-border {

    to {
        transform: rotate(360deg);
    }
}

.amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block.amsearch-loader-block {
    background-color: $white;
}

.amsearch-form-block {
    display: block;

    &:not(.-opened) {

        .amsearch-input-wrapper .amsearch-button.-close {
            display: none !important;
        }
    }

    &.-opened {

        .amsearch-input-wrapper .amsearch-button.-loupe {
            display: none !important;
        }
    }

    .amsearch-input-wrapper {

        @media (min-width: 1024px) {
            margin-top: -5px;
            max-width: 650px;
        }

        @media (max-width: 1023.98px) {
            top: 60px;
            border-top: solid 1px $grey;
            border-bottom: solid 1px $grey;
            padding-bottom: 1rem;
            padding-top: 1rem;
            height: 70px;
        }

        .amsearch-loader-block.amsearch-loader-block {
            background-color: transparent;
            right: 19px;
            border-radius: 50%;
            animation: .75s linear infinite spinner-border;
            color: $orange;
            border: 0.25em solid currentColor;
            border-right-color: transparent;
            background-image: none;
            width: 20px;
            height: 20px;

            @media (max-width: 1023.98px) {
                right: 40px !important;
            }
        }

        .amsearch-input {
            background-color: $white;
            border: solid 2px $black;
            border-radius: 4px;
            padding: 0.8rem 1.5rem;
            height: 47px;
            font-size: 1.5rem;
            color: $black;

            &:focus {
                border-color: $orange;
            }

            @media (max-width: 1023.98px) {
                margin-right: 0;
            }
        }

        .amsearch-loader-block:not([style="display: none;"])+.amsearch-button.-close {
            display: none !important;
        }

        .amsearch-button.-close {
            background-image: url("../images/cross-v2.svg");
            right: 22px;
            background-color: $orange;
            border-radius: 10rem;
            height: 18px;
            width: 18px;
            padding: 0;
            background-size: 6px;
            filter: none;

            @media (max-width: 1023.98px) {
                right: 48px;
            }
        }

        .amsearch-button.-loupe {
            right: 5px;
            top: 0;
            bottom: 0;
            margin: auto !important;
            width: 28px !important;
            height: 28px !important;
            clip: auto !important;
            right: 20px !important;
            left: auto !important;
            background-image: url("../images/icon-mag-black.svg");
            background-size: cover;
            opacity: 1;

            @media (max-width: 1023.98px) {
                right: 46px !important;
            }
        }
    }
}

.amsearch-wrapper-block {

    >.btn-search {
        display: none;
    }
}

.amsearch-result-section {
    top: 99%;

    @media (max-width: 1023.98px) {
        margin-top: 70px;
        max-height: calc(90vh - 150px);
        border-top: solid 1px $grey;
    }
}

.-amsearch-overlay-opened .nav-sections {
    display: block;

    nav.rootmenu:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .4);
        backdrop-filter: blur(2px);
        z-index: 100;
    }
}

.page-header .amsearch-wrapper-block:before {
    display: none;
}
