.contact-index-index {
    #maincontent {
        max-width: 1366px;

        .page-title-wrapper {
            display: none;
        }

        @media (max-width: 678px) {
            padding: 0;
        }
    }
    .contact-page {
        .hero {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 270px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 600px) {
                height: 160px;
            }

            .page-title {
                margin: 0;
                color: $white;
                font-size: 62px;
                font-weight: 700;
                line-height: 75px;
                text-shadow: 0px 3px 6px #00000073;

                @media (max-width: 678px) {
                    font-size: 42px;
                }
            }
        }
        
        div[data-background-type="image"] {
            padding: 0!important;
            margin: 0!important;
        }

        .contact {
            display: flex;
            flex-wrap: wrap;
            padding-top: 30px;

            .contact-left, .contact-right {
                flex-grow: 1;

                .legend {
                    background-color: $black;
                    padding: 8px 25px;

                    span {
                        font-size: 28px;
                        line-height: 34px;
                        color: $white;
                        font-family: $rift;
                        font-weight: 700;
                    }
                }

                .contact-info {
                    padding: 40px 30px;

                    .note {
                        font-size: 22px;
                        line-height: 27px;
                        font-weight: 700;
                        font-family: $rift;
                        margin-bottom: 28px;
                        color: #000;
                    }

                    input[type="text"],
                    input[type="email"],
                    textarea {
                        border-radius: 2px;
                    }

                    ul {
                        list-style: none;
                        padding: 0;

                        li {
                            margin: 0;
                        }
                    }

                    h3 {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 27px;
                    }
                }
            }
           
            .contact-left {
                margin-right: 35px;
                max-width: 720px;

                .contact-info {
                    .fieldset {
                        margin-bottom: 20px;

                        .field.required > .label:after {
                            margin: 0 0 0 2px;
                        }

                        .field label span{
                            font-size: 12px;
                            margin-bottom: 8px;
                            font-family: $roboto;
                            font-weight: 700;
                        }

                        textarea {
                            padding: 12px 15px;
                            min-height: 145px;
                        }
                    }

                    .user-details {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .field.required > .label:after {
                            content: '*';
                            color: #e02b27;
                            font-size: 1.2rem;
                            margin: 0 0 0 2px;
                        }

                        .field {
                            width: 320px;
                            padding-bottom: 15px;

                            label {
                                font-size: 12px;
                                margin-bottom: 8px;
                                font-family: $roboto;
                                font-weight: 700;
                            }

                            .control {
                                input[type="text"], input[type="email"], input[type="tel"] {
                                    height: 40px;
                                    padding: 12px 15px;
                                }
                            }
                        }
                    }

                    .actions-toolbar {
                        display: flex;
                        flex-wrap: wrap;
                        .newsletter.input-checkbox {
                            display: flex;
                            align-items: center;
                            margin-right: auto;
                            
                            .d-none {
                                display: none;
                            }

                            .checkbox {
                                width: 40px;
                                height: 40px;
                                border: solid 1px #DDDDDD;
                                margin-right: 12px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            
                            label {
                                font-family: $roboto;
                                font-size: 12px;
                                font-weight: 700;

                                p {
                                    font-size: inherit;
                                    margin-top: 1rem;
                                    font-weight: inherit;
                                }
                            }
    
                            input[type="checkbox"] + .checkbox .check {
                                fill: transparent;
                            }

                            input[type="checkbox"]:checked + .checkbox .check {
                                fill: #000;
                            }
    
                        }
                        .primary {
                            float: right;

                            .submit {
                                background-color: $black;
                                color: $white;
                                font-size: 16px;
                                border: 2px solid #000;
                                padding: 10px 43px;
                                margin: 0;
                                line-height: 20px;
                                font-family: $rift;
                                border-radius: 2px;
                                transition: all .3s ease;

                                &:hover {
                                    background: $white;
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1134px) {
                .contact-left {
                    margin-right: 10px;
                    max-width: 620px;

                    .contact-info {
                        .user-details {
                            .field {
                                width: 49%;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1019px) {
                .contact-left {
                    margin-right: 0;
                    max-width: 100%;

                    .contact-info {
                        .user-details {
                            .field {
                                @media (max-width: 678px) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 678px) {
                .contact-left {
                    .contact-info {
                        padding-top: 16px;
                        padding-bottom: 16px;

                        .user-details {
                            .field {
                                width: 100%;
                            }
                        }

                        .primary {
                            margin-top: 20px;
                            width: 100%;
                        }
                    }
                }

                .contact-right, .contact-left {
                    .legend {
                       display: none;
                    }

                    .contact-info .note {
                        font-size: 22px;
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }
}

.page-wrapper {
    box-shadow: none !important;
}

.cms-page-view {

    .columns .columns {
        display: block;
    }

    .breadcrumbs {
        max-width: 1386px !important;
        margin-bottom: 15px;

        @media (max-width: 767px) {
            padding: 0 10px !important;
        }
    }

    h1 {
        font-weight: 700;
        color: $black;
    }
}
