.catalog-product_compare-index {

  .page-title {
    font-weight: 700;
    color: $black;
  }

  .reviews-actions {
    display: block;
    margin-top: 0;
    line-height: 1.5;
    text-decoration: underline;
    font-size: 13px;
  }

  .table-comparison {

    .cell.remove .action.delete {
      background-image: url(../images/close_loader.svg);
      background-size: 26px 34.39px;
      background-repeat: no-repeat;
      background-position: center;
  
      &::before {
        display: none;
      }
    }

    .product-item-name,
    .product-reviews-summary,
    .price-box.price-final_price,
    .product-item-actions {
      margin: 0;
    }

    .actions-secondary.actions-secondary.actions-secondary.actions-secondary {
      margin-right: 0;
    }
  }

  .price-box.price-final_price {
    display: flex;
    justify-content: center;
  }

  .price {
    font-family: $rift;
    font-weight: 700;
    font-size: 16px;
    line-height: 38px;
  }

  .actions-primary {
    display: none;
  }
}
