
@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Bold.woff2') format('woff2'),
        url('../fonts/Rift-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Medium.woff2') format('woff2'),
        url('../fonts/Rift-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



/* GLOBAL */
body {
    font-family: $roboto;
}
#maincontent {
    max-width: $max_width !important;
}

.uk-dotnav > .uk-active > * {
    transform: none !important;
}
h1 {
    font-family: $rift !important;
    font-family: bold;
    font-size: 32px;
    line-height: 38px;
}
h1.hero-heading {
    font-family: $rift !important;
    font-family: bold;
    font-size: 76px;
    line-height: 91px;
    letter-spacing: 15.2px;
}
h1.alternative {
    font-family: $rift !important;
    font-family: bold;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 3.2px;
}
h2 {
    font-family: $rift !important;
    font-family: bold;
    font-size: 22px;
    line-height: 27px;
}
h3 {
    font-family: $rift !important;
    font-family: bold;
    font-size: 16px;
    line-height: 20px;
}
p {
    color: $body-color;
    font-family: $roboto;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    a {
        color: $orange;
        font-family: $roboto;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        text-decoration: underline;
    }
}
.btn {
    font-family: $rift;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 30px;
    text-decoration: none !important;
    transition: all 0.2s linear;
    width: fit-content;
    border-radius: 2px;
    border-color: transparent;
    &.black {
        background-color: $black;
        color: $white;
        &:hover {
            background-color: $orange;
            color: $white;
        }
    }
    &.red {
        background-color: $orange;
        color: $white;
        &:hover {
            background-color: $black;
            color: $white;
        }
    }
}
[data-content-type="row"][data-appearance="contained"] {
    max-width: $max_width !important;
    width: 100%;

    [data-element='inner'] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

a,
a:hover,
a:visited {
    color: $orange;
}

.page-header {
    z-index: 99;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);

    @media (min-width: 768px) {
        position: sticky !important;
        top: 0;
        width: 100%;
    }
}

/* CART - FLOATING */
.mini-cart-wrapper,
.minicart-wrapper .block-minicart {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 414px !important;
    width: 100%;
    bottom: 0;
    z-index: 9000;
    margin: 0 !important;
    border: 0;
    min-width: auto;
}

/* Target Simple Pages (Privacy Policy, etc) */
.column.main .columns {
    h1 {
        font-weight: 700;
        margin-bottom: 25px;
    }

    h4 {
        margin: 12.5px 0;
    }

    blockquote {
        margin: 10px 0;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

select {
  appearance: none;
  background-image: url(../images/select.svg);
  background-size: 9.58px 5px, 1px 20px;
  background-repeat: no-repeat;
  background-position: right 1.3rem center;
}

.d-flex {
    display: flex;
}

.justify-content-end {
    justify-content: flex-end;
}

.btn-dark {
    background-color: black;
    color: white;
    border-width: 2px;

    &:hover {
        background-color: white;
        color: black;
    }
}

.action.primary.disabled, .action-primary.disabled, .action.primary[disabled], .action-primary[disabled], fieldset[disabled] .action.primary, fieldset[disabled] .action-primary {
    opacity: 0.4;
}

.action.primary {
    background-color: $orange;
    border: 1px solid $orange;
    border-radius: 2px;
    font-family: $rift;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 30px;

    &:hover {
        background-color: $black;
        border-color: $black;
    }
}

.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(0px, 0px, 0px, 0px) !important;
    white-space: nowrap !important;
    padding: 0px !important;
    margin: -1px !important;
    overflow: hidden !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
}