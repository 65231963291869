.columns {
    a.hidden-print {
        display: block;
        color: $body-color;
        font-weight: bold;
        font-family: 'Roboto';
        margin-bottom: 15px;
        text-align: right;
        text-decoration: none;
    }
}
.table-comparison {
    th.cell.label, td:last-child {
        border-right: 1px solid $black;
    }
    th.cell.product.label, td.cell.product.info {
        border-bottom: 1px solid $black;
    }
    th.cell.label, td.cell.product.attribute{
        text-align: center;
    } 
    td.product.info {
        text-align: center;
        .product-item-name {
            font-family: 'rift';
            font-size: 18px;
            font-weight: bold;
        }
        .product-item-actions {
            display: flex;
            display: -ms-flexbox;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: center;
            .actions-primary {
                button.primary {
                    background: $black;
                    border-radius: 2px;
                    border: none;
                    padding: 12px 45px;
                    span{
                        font-size: 16px;
                        font-family: 'rift';
                        font-weight: bold;
                        line-height: 20px;
                    }
                }
            }
            .actions-secondary {
                margin-right: 12px;
                .wishlist-icon {
                    vertical-align: middle;
                    fill: none;
                    stroke: $black;
                    stroke-width: 1.2px;
                        path.a 
                        {
                            &:hover {
                            fill: $black;
                        }
                    }
                }
            }
        }
    }
}
.modal-popup.confirm {
    .modal-inner-wrap {
        max-width: 480px;
        border: 2px solid $black;
        .modal-header {
            padding: 10px;
            height: auto;
            .action-close {
                padding: 15px 25px 0;
                :before {
                    color: $black;
                }
            }
        }
        .modal-content {
            padding: 0 40px 15px 15px;
            line-height: 1.3;
            font-family: $roboto;
            font-size: 14px;
        }
        .modal-footer {
            padding: 0 25px 25px!important;
            display: flex;
            justify-content: space-evenly;
            background-color:$white!important;

            span{
                font-family: rift, sans-serif;
                color: $white;
                font-size: 15px;
            }
            .action-primary, .action-secondary {
                padding: 10px 35px;
                border-radius: 2px;
                min-width: 100px;
            }
            .action{
                &-primary{
                    background-color: $orange;
                    border-color: $orange;
                    &:hover {
                        border-color: $black;
                        background-color: $black;
                        span {
                            color: $white!important;
                        }
                    }
                }
                &-secondary {
                    background-color: $black;
                    border-color: $black;
                    &:hover {
                        background-color: $white;
                        span {
                            color: $black!important;
                        }
                    }
                }
            }
            
        }
    }
}

@media ( max-width: 768px ){
    .columns {
        a.hidden-print {
            text-align: left;
        }
    }
}
