.customer-account-forgotpassword {

  .page-main {
    padding: 2rem;
  }

  .page-title {
    font-weight: 700;
    color: $black;
  }

  .page-title, .columns .form .fieldset {
    margin-bottom: 20px;
  }
}
