.blurred-text {
  filter: blur(5px) !important;
}

.privacy-mode {
  svg {
    height: 15px;
  }

  &.active {
    svg path {
      fill: black !important;
    }
  }

}