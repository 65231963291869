.message.message {
    position: fixed;
    top: 120px;
    right: 20px;
    z-index: 999;
    border-radius: 2px;
    margin: 0;
    text-align: left;
    padding: 21px;
    border-radius: 4px;
    max-width: 300px;
    width: 100%;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);

    .close {
        position: absolute;
        top: 50%;
        right: 0;
        cursor: pointer;
        width: 25px;
        text-align: center;
        display: block !important;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-family: 'roboto';
    }

    *::before {
        display: none !important;
    }

    &.success {
        background-color: #00d986;
        color: #ffffff;
    }

    &.error {
        background-color: #e80000;
        color: #ffffff;
    }

    &.info {
        background-color: $orange;
        color: #FFFFFF;
    }

    a {
        color: inherit !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    dl {
        margin-bottom: 20px;
    }

    dt {
        margin-bottom: 0;
    }
}

.quickview-scroll {
    .message.message {
        margin: 0;
        padding: 12px !important;
    }
}
